import { handleActions } from 'redux-actions';
import {
  getAuthUserFailure,
  getAuthUserRequest,
  getAuthUserSuccess,
  loginAsAgentFailure,
  loginAsAgentRequest,
  loginAsAgentSuccess,
  loginAsUserFailure,
  loginAsUserRequest,
  loginAsUserSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  loginTenantsUsersFailure,
  loginTenantsUsersRequest,
  loginTenantsUsersSuccess,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  checkVerificationCodeRequest,
  checkVerificationCodeSuccess,
  checkVerificationCodeFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  logoutRequest,
  logoutSuccess,
  updateBalance,
  registerTenantUserRequest,
  registerTenantUserSuccess,
  registerTenantUserFailure,
  resendCodeRequest,
  resendCodeSuccess,
  resendCodeFailure,
  verifyTenantUserRequest,
  verifyTenantUserSuccess,
  verifyTenantUserFailure,
  loginAsCustomerRequest,
  loginAsCustomerSuccess,
  loginAsCustomerFailure,
} from './actions';

const initialState = {
  isUserLoggingIn: false,
  isUserLoggedInSuccess: false,
  isUserLoggedInFailure: false,
  user:
    JSON.parse(localStorage.getItem('userData') || JSON.stringify({})) || {},
  errorMessage: '',
  successMessage: '',
  isLoggingOut: false,
  userData:
    JSON.parse(localStorage.getItem('userData') || JSON.stringify({})) || {},
  isLoggingInAsTenant: false,
  isLoggingInAsTenantSuccess: false,
  isLoggingInAsTenantFailure: false,
  agentData: {},
  isLoggingInAsAgent: false,
  isLoggingInAsAgentSuccess: false,
  isLoggingInAsAgentFailure: false,
  isForgotPassword: false,
  isForgotPasswordSuccess: false,
  isForgotPasswordFailure: false,
  forgotPasswordData: {},
  isCheckVerificationCode: true,
  isCheckVerificationCodeSuccess: false,
  isCheckVerificationCodeFailure: false,
  isResetPassword: false,
  isResetPasswordSuccess: false,
  isResetPasswordFailure: false,
  isGetAuthUserSuccess: false,
  isGetAuthUserFailure: false,
  userBalance: 0,
  isRegisteringUser: false,
  isRegisteringUserSuccess: false,
  isRegisteringUserFailure: false,
  isResendingCode: false,
  isResendingCodeSuccess: false,
  isResendingCodeFailure: false,
  isVerifyingUser: false,
  isVerifyingUserSuccess: false,
  isVerifyingUserFailure: false,
  loginData: {},
};

const reducer = handleActions(
  {
    [loginRequest]: (state) => ({
      ...state,
      isUserLoggingIn: true,
      isUserLoggedInSuccess: false,
      isUserLoggedInFailure: false,
    }),
    [loginSuccess]: (state, { payload }) => ({
      ...state,
      user: payload.user,
      isUserLoggingIn: false,
      isUserLoggedInSuccess: true,
      successMessage: payload.message,
    }),
    [loginFailure]: (state, { payload }) => ({
      ...state,
      isUserLoggingIn: false,
      isUserLoggedInFailure: true,
      errorMessage: payload,
    }),
    [logoutRequest]: (state) => ({
      ...state,
      isLoggingOut: true,
    }),
    [logoutSuccess]: (state) => ({
      ...state,
      isLoggingOut: false,
    }),
    [loginAsUserRequest]: (state) => ({
      ...state,
      isLoggingInAsTenant: true,
      isLoggingInAsTenantSuccess: false,
      isLoggingInAsTenantFailure: false,
    }),
    [loginAsUserSuccess]: (state) => ({
      ...state,
      isLoggingInAsTenant: false,
      isLoggingInAsTenantSuccess: true,
    }),
    [loginAsUserFailure]: (state, { payload }) => ({
      ...state,
      isLoggingInAsTenant: true,
      isLoggingInAsTenantFailure: true,
      errorMessage: payload,
    }),
    [loginAsAgentRequest]: (state) => ({
      ...state,
      isLoggingInAsAgent: true,
      isLoggingInAsAgentSuccess: false,
      isLoggingInAsAgentFailure: false,
    }),
    [loginAsAgentSuccess]: (state) => ({
      ...state,
      isLoggingInAsAgent: false,
      isLoggingInAsAgentSuccess: true,
    }),
    [loginAsAgentFailure]: (state, { payload }) => ({
      ...state,
      isLoggingInAsAgent: false,
      isLoggingInAsAgentFailure: true,
      errorMessage: payload,
    }),
    [getAuthUserRequest]: (state) => ({
      ...state,
      isGetAuthUserSuccess: false,
      isGetAuthUserFailure: false,
      userData: {},
    }),
    [getAuthUserSuccess]: (state, { payload }) => ({
      ...state,
      isGetAuthUserSuccess: true,
      isGetAuthUserFailure: false,
      userData: payload,
      userBalance: payload.settings?.balance,
    }),
    [getAuthUserFailure]: (state) => ({
      ...state,
      isGetAuthUserSuccess: false,
      isGetAuthUserFailure: false,
    }),
    [forgotPasswordRequest]: (state) => ({
      ...state,
      isForgotPassword: true,
      isForgotPasswordSuccess: false,
      isForgotPasswordFailure: false,
    }),
    [forgotPasswordSuccess]: (state, { payload }) => ({
      ...state,
      isForgotPassword: false,
      isForgotPasswordSuccess: true,
      successMessage: payload.message,
      forgotPasswordData: payload.data,
    }),
    [forgotPasswordFailure]: (state, { payload }) => ({
      ...state,
      isForgotPassword: false,
      isForgotPasswordFailure: true,
      errorMessage: payload,
    }),
    [checkVerificationCodeRequest]: (state) => ({
      ...state,
      isCheckVerificationCode: true,
      isCheckVerificationCodeSuccess: false,
      isCheckVerificationCodeFailure: false,
    }),
    [checkVerificationCodeSuccess]: (state, { payload }) => ({
      ...state,
      isCheckVerificationCode: false,
      isCheckVerificationCodeSuccess: true,
      successMessage: payload.message,
    }),
    [checkVerificationCodeFailure]: (state, { payload }) => ({
      ...state,
      isCheckVerificationCode: false,
      isCheckVerificationCodeFailure: true,
      errorMessage: payload,
    }),
    [resetPasswordRequest]: (state) => ({
      ...state,
      isResetPassword: true,
      isResetPasswordSuccess: false,
      isResetPasswordFailure: false,
    }),
    [resetPasswordSuccess]: (state, { payload }) => ({
      ...state,
      isResetPassword: false,
      isResetPasswordSuccess: true,
      successMessage: payload.message,
    }),
    [resetPasswordFailure]: (state, { payload }) => ({
      ...state,
      isResetPassword: false,
      isResetPasswordFailure: true,
      errorMessage: payload,
    }),
    [loginTenantsUsersRequest]: (state) => ({
      ...state,
      isUserLoggingIn: true,
      isUserLoggedInSuccess: false,
      isUserLoggedInFailure: false,
    }),
    [loginTenantsUsersSuccess]: (state, { payload }) => ({
      ...state,
      user: payload.user,
      isUserLoggingIn: false,
      isUserLoggedInSuccess: true,
      successMessage: payload.message,
    }),
    [loginTenantsUsersFailure]: (state, { payload }) => ({
      ...state,
      isUserLoggingIn: false,
      isUserLoggedInFailure: true,
      errorMessage: payload,
    }),
    [updateBalance]: (state, { payload }) => ({
      ...state,
      userBalance: payload,
    }),
    [registerTenantUserRequest]: (state) => ({
      ...state,
      isRegisteringUser: true,
      isRegisteringUserSuccess: false,
      isRegisteringUserFailure: false,
    }),
    [registerTenantUserSuccess]: (state, { payload }) => ({
      ...state,
      isRegisteringUser: false,
      isRegisteringUserSuccess: true,
      successMessage: payload.message,
    }),
    [registerTenantUserFailure]: (state, { payload }) => ({
      ...state,
      isRegisteringUser: false,
      isRegisteringUserFailure: true,
      errorMessage: payload,
    }),
    [resendCodeRequest]: (state) => ({
      ...state,
      isResendingCode: true,
      isResendingCodeSuccess: false,
      isResendingCodeFailure: false,
    }),
    [resendCodeSuccess]: (state, { payload }) => ({
      ...state,
      isResendingCode: false,
      isResendingCodeSuccess: true,
      successMessage: payload.message,
    }),
    [resendCodeFailure]: (state, { payload }) => ({
      ...state,
      isResendingCode: false,
      isResendingCodeFailure: true,
      errorMessage: payload,
    }),
    [verifyTenantUserRequest]: (state) => ({
      ...state,
      isVerifyingUser: true,
      isVerifyingUserSuccess: false,
      isVerifyingUserFailure: false,
    }),
    [verifyTenantUserSuccess]: (state, { payload }) => ({
      ...state,
      isVerifyingUser: false,
      isVerifyingUserSuccess: true,
      successMessage: payload.message,
    }),
    [verifyTenantUserFailure]: (state, { payload }) => ({
      ...state,
      isVerifyingUser: false,
      isVerifyingUserFailure: true,
      errorMessage: payload,
    }),
    [loginAsCustomerRequest]: (state) => ({
      ...state,
      loginAsCustomerSuccess: false,
      loginAsCustomerFailure: false,
    }),
    [loginAsCustomerSuccess]: (state, { payload }) => ({
      ...state,
      loginAsCustomerSuccess: true,
      successMessage: payload.message,
      loginData: payload.data,
    }),
    [loginAsCustomerFailure]: (state, { payload }) => ({
      ...state,
      loginAsCustomerFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
