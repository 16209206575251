import { createAction } from 'redux-actions';

export const createCheckoutSessionRequest = createAction(
  'CREATE_CHECKOUT_SESSION_REQUEST'
);
export const createCheckoutSessionSuccess = createAction(
  'CREATE_CHECKOUT_SESSION_SUCCESS'
);
export const createCheckoutSessionFailure = createAction(
  'CREATE_CHECKOUT_SESSION_FAILURE'
);

export const getProductPricesRequest = createAction(
  'GET_PRODUCT_PRICES_REQUEST'
);
export const getProductPricesSuccess = createAction(
  'GET_PRODUCT_PRICES_SUCCESS'
);
export const getProductPricesFailure = createAction(
  'GET_PRODUCT_PRICES_FAILURE'
);

export const retrieveCheckoutSessionRequest = createAction(
  'RETRIEVE_CHECKOUT_SESSION_REQUEST'
);
export const retrieveCheckoutSessionSuccess = createAction(
  'RETRIEVE_CHECKOUT_SESSION_SUCCESS'
);
export const retrieveCheckoutSessionFailure = createAction(
  'RETRIEVE_CHECKOUT_SESSION_FAILURE'
);

export const getCustomersSubscriptionsRequest = createAction(
  'GET_CUSTOMERS_SUBSCRIPTIONS_REQUEST'
);
export const getCustomersSubscriptionsSuccess = createAction(
  'GET_CUSTOMERS_SUBSCRIPTIONS_SUCCESS'
);
export const getCustomersSubscriptionsFailure = createAction(
  'GET_CUSTOMERS_SUBSCRIPTIONS_FAILURE'
);

export const cancelSubscriptionRequest = createAction(
  'CANCEL_SUBSCRIPTION_REQUEST'
);
export const cancelSubscriptionSuccess = createAction(
  'CANCEL_SUBSCRIPTION_SUCCESS'
);
export const cancelSubscriptionFailure = createAction(
  'CANCEL_SUBSCRIPTION_FAILURE'
);

export const getSubscriptionDetailsRequest = createAction(
  'GET_SUBSCRIPTION_DETAILS_REQUEST'
);
export const getSubscriptionDetailsSuccess = createAction(
  'GET_SUBSCRIPTION_DETAILS_SUCCESS'
);
export const getSubscriptionDetailsFailure = createAction(
  'GET_SUBSCRIPTION_DETAILS_FAILURE'
);

export const cancelSubscriptionItemRequest = createAction(
  'CANCEL_SUBSCRIPTION_ITEM_REQUEST'
);
export const cancelSubscriptionItemFailure = createAction(
  'CANCEL_SUBSCRIPTION_ITEM_SUCCESS'
);
export const cancelSubscriptionItemSuccess = createAction(
  'CANCEL_SUBSCRIPTION_ITEM_FAILURE'
);

export const getCustomerInvoicesListRequest = createAction(
  'GET_CUSTOMER_INVOICES_LIST_REQUEST'
);
export const getCustomerInvoicesListSuccess = createAction(
  'GET_CUSTOMER_INVOICES_LIST_SUCCESS'
);
export const getCustomerInvoicesListFailure = createAction(
  'GET_CUSTOMER_INVOICES_LIST_FAILURE'
);

export const refundChargeRequest = createAction('REFUND_CHARGE_REQUEST');
export const refundChargeSuccess = createAction('REFUND_CHARGE_SUCCESS');
export const refundChargeFailure = createAction('REFUND_CHARGE_FAILURE');

export const getRefundListRequest = createAction('GET_REFUND_LIST_REQUEST');
export const getRefundListSuccess = createAction('GET_REFUND_LIST_SUCCESS');
export const getRefundListFailure = createAction('GET_REFUND_LIST_FAILURE');

export const getCustomerChargesRequest = createAction(
  'GET_CUSTOMER_CHARGES_REQUEST'
);
export const getCustomerChargesSuccess = createAction(
  'GET_CUSTOMER_CHARGES_SUCCESS'
);
export const getCustomerChargesFailure = createAction(
  'GET_CUSTOMER_CHARGES_FAILURE'
);

export const changeSubscriptionDateRequest = createAction(
  'CHANGE_SUBSCRIPTION_DATE_REQUEST'
);
export const changeSubscriptionDateSuccess = createAction(
  'CHANGE_SUBSCRIPTION_DATE_SUCCESS'
);
export const changeSubscriptionDateFailure = createAction(
  'CHANGE_SUBSCRIPTION_DATE_FAILURE'
);

export const updateSubscriptionRequest = createAction(
  'UPDATE_SUBSCRIPTION_REQUEST'
);
export const updateSubscriptionSuccess = createAction(
  'UPDATE_SUBSCRIPTION_SUCCESS'
);
export const updateSubscriptionFailure = createAction(
  'UPDATE_SUBSCRIPTION_FAILURE'
);

export const sendInvoiceToCustomerRequest = createAction(
  'SEND_INVOICE_TO_CUSTOMER_REQUEST'
);
export const sendInvoiceToCustomerSuccess = createAction(
  'SEND_INVOICE_TO_CUSTOMER_SUCCESS'
);
export const sendInvoiceToCustomerFailure = createAction(
  'SEND_INVOICE_TO_CUSTOMER_FAILURE'
);

export const payInvoiceRequest = createAction('PAY_INVOICE_REQUEST');
export const payInvoiceSuccess = createAction('PAY_INVOICE_SUCCESS');
export const payInvoiceFailure = createAction('PAY_INVOICE_FAILURE');

export const createBillingWithDesiredDateRequest = createAction(
  'CREATE_BILLING_WITH_DESIRED_DATE_REQUEST'
);
export const createBillingWithDesiredDateSuccess = createAction(
  'CREATE_BILLING_WITH_DESIRED_DATE_SUCCESS'
);
export const createBillingWithDesiredDateFailure = createAction(
  'CREATE_BILLING_WITH_DESIRED_DATE_FAILURE'
);

export const getDetailedProfitAndLossRequest = createAction(
  'GET_DETAILED_PROFIT_AND_LOSS_REQUEST'
);
export const getDetailedProfitAndLossSuccess = createAction(
  'GET_DETAILED_PROFIT_AND_LOSS_SUCCESS'
);
export const getDetailedProfitAndLossFailure = createAction(
  'GET_DETAILED_PROFIT_AND_LOSS_FAILURE'
);
