import { createAction } from 'redux-actions';

export const uploadPhoneNumbersRequest = createAction(
  'UPLOAD_PHONE_NUMBERS_REQUEST'
);
export const uploadPhoneNumbersSuccess = createAction(
  'UPLOAD_PHONE_NUMBERS_SUCCESS'
);
export const uploadPhoneNumbersFailure = createAction(
  'UPLOAD_PHONE_NUMBERS_FAILURE'
);

export const getPhoneNumbersRequest = createAction('GET_PHONE_NUMBERS_REQUEST');
export const getPhoneNumbersSuccess = createAction('GET_PHONE_NUMBERS_SUCCESS');
export const getPhoneNumbersFailure = createAction('GET_PHONE_NUMBERS_FAILURE');

export const createPhoneNumberRequest = createAction(
  'CREATE_PHONE_NUMBER_REQUEST'
);
export const createPhoneNumberSuccess = createAction(
  'CREATE_PHONE_NUMBER_SUCCESS'
);
export const createPhoneNumberFailure = createAction(
  'CREATE_PHONE_NUMBER_FAILURE'
);

export const editPhoneNumberRequest = createAction('EDIT_PHONE_NUMBER_REQUEST');
export const editPhoneNumberSuccess = createAction('EDIT_PHONE_NUMBER_SUCCESS');
export const editPhoneNumberFailure = createAction('EDIT_PHONE_NUMBER_FAILURE');

export const deletePhoneNumberRequest = createAction(
  'DELETE_PHONE_NUMBER_REQUEST'
);
export const deletePhoneNumberSuccess = createAction(
  'DELETE_PHONE_NUMBER_SUCCESS'
);
export const deletePhoneNumberFailure = createAction(
  'DELETE_PHONE_NUMBER_FAILURE'
);
