import { axiosApiInstance, config } from 'custom-configs';

import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createZipCodesRequest,
  createZipCodesSuccess,
  createZipCodesFailure,
  getZipCodeListRequest,
  getZipCodeListSuccess,
  getZipCodeListFailure,
  getAllAcpStatesRequest,
  getAllAcpStatesSuccess,
  getAllAcpStatesFailure,
  updateZipCodeRequest,
  updateZipCodeSuccess,
  updateZipCodeFailure,
  deleteZipCodeRequest,
  deleteZipCodeSuccess,
  deleteZipCodeFailure,
  addSpinCodeSuccess,
  addSpinCodeRequest,
  addSpinCodeFailure,
} from './actions';

const URL = `${config.TENANT}/settings`;

function* createZipCodes({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/acp-zip-code`, payload)
    );

    if (response?.status === 200) {
      yield put(createZipCodesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createZipCodes, error', e);
    if (e?.response?.data) {
      yield put(createZipCodesFailure(catchResponseMessages(e)));
    }
  }
}

function* getAcpZipCodeList({ payload }) {
  try {
    const query = payload || '';

    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/acp-zip-code${query}`)
    );

    if (response?.status === 200) {
      yield put(getZipCodeListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAcpZipCodeList, error', e);
    if (e?.response?.data) {
      yield put(getZipCodeListFailure(catchResponseMessages(e)));
    }
  }
}

function* getAllAcpStates() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/all-acp-states`)
    );

    if (response?.status === 200) {
      yield put(getAllAcpStatesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAllAcpStates, error', e);
    if (e?.response?.data) {
      yield put(getAllAcpStatesFailure(catchResponseMessages(e)));
    }
  }
}

function* updateZipCodes({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/acp-zip-code/${payload.id}`, payload.data)
    );

    if (response?.status === 200) {
      yield put(updateZipCodeSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateZipCodes, error', e);
    if (e?.response?.data) {
      yield put(updateZipCodeFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteZipCodes({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/acp-zip-code/${payload}`)
    );

    if (response?.status === 200) {
      yield put(deleteZipCodeSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteZipCodes, error', e);
    if (e?.response?.data) {
      yield put(deleteZipCodeFailure(catchResponseMessages(e)));
    }
  }
}

function* addSpinCode({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/spin-code`, payload)
    );

    if (response?.status === 200) {
      yield put(addSpinCodeSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for addSpinCode, error', e);
    if (e?.response?.data) {
      yield put(addSpinCodeFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createZipCodesRequest, createZipCodes);
  yield takeLatest(getZipCodeListRequest, getAcpZipCodeList);
  yield takeLatest(getAllAcpStatesRequest, getAllAcpStates);
  yield takeLatest(updateZipCodeRequest, updateZipCodes);
  yield takeLatest(deleteZipCodeRequest, deleteZipCodes);
  yield takeLatest(addSpinCodeRequest, addSpinCode);
}
