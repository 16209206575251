import { createAction } from 'redux-actions';

export const createNoteRequest = createAction('CREATE_NOTE_REQUEST');
export const createNoteSuccess = createAction('CREATE_NOTE_SUCCESS');
export const createNoteFailure = createAction('CREATE_NOTE_FAILURE');

export const getNotesListRequest = createAction('GET_NOTES_LIST_REQUEST');
export const getNotesListSuccess = createAction('GET_NOTES_LIST_SUCCESS');
export const getNotesListFailure = createAction('GET_NOTES_LIST_FAILURE');

export const deleteNoteRequest = createAction('DELETE_NOTE_REQUEST');
export const deleteNoteSuccess = createAction('DELETE_NOTE_SUCCESS');
export const deleteNoteFailure = createAction('DELETE_NOTE_FAILURE');

export const updateNoteRequest = createAction('UPDATE_NOTE_REQUEST');
export const updateNoteSuccess = createAction('UPDATE_NOTE_SUCCESS');
export const updateNoteFailure = createAction('UPDATE_NOTE_FAILURE');

export const bulkDeleteNotesRequest = createAction('BULK_DELETE_NOTES_REQUEST');
export const bulkDeleteNotesSuccess = createAction('BULK_DELETE_NOTES_SUCCESS');
export const bulkDeleteNotesFailure = createAction('BULK_DELETE_NOTES_FAILURE');
