import { handleActions } from 'redux-actions';

import {
  createCheckoutSessionRequest,
  createCheckoutSessionSuccess,
  createCheckoutSessionFailure,
  getProductPricesRequest,
  getProductPricesSuccess,
  getProductPricesFailure,
  retrieveCheckoutSessionRequest,
  retrieveCheckoutSessionSuccess,
  retrieveCheckoutSessionFailure,
  getCustomersSubscriptionsRequest,
  getCustomersSubscriptionsSuccess,
  getCustomersSubscriptionsFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  getSubscriptionDetailsRequest,
  getSubscriptionDetailsSuccess,
  getSubscriptionDetailsFailure,
  cancelSubscriptionItemRequest,
  cancelSubscriptionItemSuccess,
  cancelSubscriptionItemFailure,
  getCustomerInvoicesListRequest,
  getCustomerInvoicesListSuccess,
  getCustomerInvoicesListFailure,
  refundChargeRequest,
  refundChargeSuccess,
  refundChargeFailure,
  getRefundListRequest,
  getRefundListSuccess,
  getRefundListFailure,
  getCustomerChargesRequest,
  getCustomerChargesSuccess,
  getCustomerChargesFailure,
  changeSubscriptionDateRequest,
  changeSubscriptionDateSuccess,
  changeSubscriptionDateFailure,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
  updateSubscriptionFailure,
  sendInvoiceToCustomerRequest,
  sendInvoiceToCustomerSuccess,
  sendInvoiceToCustomerFailure,
  payInvoiceRequest,
  payInvoiceSuccess,
  payInvoiceFailure,
  createBillingWithDesiredDateRequest,
  createBillingWithDesiredDateSuccess,
  createBillingWithDesiredDateFailure,
  getDetailedProfitAndLossRequest,
  getDetailedProfitAndLossFailure,
  getDetailedProfitAndLossSuccess,
} from './actions';

const initialState = {
  isCheckoutSubscriptions: false,
  isCheckoutSubscriptionsSuccess: false,
  isCheckoutSubscriptionsFailure: false,
  errorMessage: '',
  successMessage: '',
  isGettingPrices: true,
  isGettingPricesSuccess: false,
  isGettingPricesFailure: false,
  prices: [],
  pricesCount: 0,
  session: {},
  isRetrievingCheckout: true,
  isRetrievingCheckoutSuccess: false,
  isRetrievingCheckoutFailure: false,
  newSession: [],
  isGettingSubscriptions: true,
  subscriptions: [],
  subscriptionsCount: 0,
  isCancelingSubscription: false,
  isCancelingSubscriptionSuccess: false,
  isCancelingSubscriptionFailure: false,
  isGettingSubscriptionDetails: true,
  isGettingSubscriptionDetailsSuccess: false,
  isGettingSubscriptionDetailsFailure: false,
  subscriptionDetails: {},
  isCancelingSubscriptionItem: false,
  isCancelingSubscriptionItemSuccess: false,
  isCancelingSubscriptionItemFailure: false,
  isGettingInvoicesList: false,
  invoicesList: [],
  customerPaymentsList: {},
  isRefundingCharge: false,
  isRefundingChargeSuccess: false,
  isRefundingChargeFailure: false,
  isGettingRefundList: true,
  isGettingRefundListSuccess: false,
  isGettingRefundListFailure: false,
  refundList: [],
  isGetCharges: false,
  isGetChargesSuccess: true,
  chargesList: [],
  hasMore: '',
  isChangingSubscriptionDate: false,
  isChangingSubscriptionDateSuccess: false,
  isChangingSubscriptionDateFailure: false,
  isUpdatingSubscription: false,
  isUpdatingSubscriptionSuccess: false,
  isUpdatingSubscriptionFailure: false,
  isSendInvoice: false,
  isSendInvoiceSuccess: false,
  isSendInvoiceFailure: false,
  isPayingInvoice: false,
  isPayingInvoiceSuccess: false,
  isPayingInvoiceFailure: false,
  isCreateDesiredDateBilling: false,
  isCreateDesiredDateBillingSuccess: false,
  isCreateDesiredDateBillingFailure: false,
  totalProfit: 0,
  isGettingDetailedProfitLoss: true,
  isGettingDetailedProfitLossSuccess: false,
  isGettingDetailedProfitLossFailure: false,
  detailedProfitLoss: [],
  detailedProfitLossCount: 0,
};

const reducer = handleActions(
  {
    [createCheckoutSessionRequest]: (state) => ({
      ...state,
      isCheckoutSubscriptions: true,
      isCheckoutSubscriptionsSuccess: false,
      isCheckoutSubscriptionsFailure: false,
    }),
    [createCheckoutSessionSuccess]: (state, { payload }) => ({
      ...state,
      isCheckoutSubscriptions: false,
      isCheckoutSubscriptionsSuccess: true,
      session: payload.data,
      successMessage: payload.message,
    }),
    [createCheckoutSessionFailure]: (state, { payload }) => ({
      ...state,
      isCheckoutSubscriptions: false,
      isCheckoutSubscriptionsFailure: true,
      errorMessage: payload,
    }),
    [getProductPricesRequest]: (state) => ({
      ...state,
      isGettingPrices: true,
      isGettingPricesSuccess: false,
      isGettingPricesFailure: false,
    }),
    [getProductPricesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPrices: false,
      isGettingPricesSuccess: true,
      successMessage: payload.message,
      prices: payload.data.rows,
      pricesCount: payload.data.count,
    }),
    [getProductPricesFailure]: (state, { payload }) => ({
      ...state,
      isGettingPrices: false,
      isGettingPricesFailure: true,
      errorMessage: payload,
    }),
    [retrieveCheckoutSessionRequest]: (state) => ({
      ...state,
      isRetrievingCheckout: true,
      isRetrievingCheckoutSuccess: false,
      isRetrievingCheckoutFailure: false,
    }),
    [retrieveCheckoutSessionSuccess]: (state, { payload }) => ({
      ...state,
      isRetrievingCheckout: false,
      isRetrievingCheckoutSuccess: true,
      newSession: payload.data,
      successMessage: payload.message,
    }),
    [retrieveCheckoutSessionFailure]: (state, { payload }) => ({
      ...state,
      isRetrievingCheckout: false,
      isRetrievingCheckoutFailure: true,
      errorMessage: payload,
    }),
    [getCustomersSubscriptionsRequest]: (state) => ({
      ...state,
      isGettingSubscriptions: true,
    }),
    [getCustomersSubscriptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSubscriptions: false,
      successMessage: payload.message,
      subscriptions: payload.data.rows,
      subscriptionsCount: payload.data.count,
    }),
    [getCustomersSubscriptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSubscriptions: false,
      errorMessage: payload,
    }),
    [cancelSubscriptionRequest]: (state) => ({
      ...state,
      isCancelingSubscription: true,
      isCancelingSubscriptionSuccess: false,
      isCancelingSubscriptionFailure: false,
    }),
    [cancelSubscriptionSuccess]: (state, { payload }) => {
      const subscriptionsCopy = [...state.subscriptions];
      const index = subscriptionsCopy.findIndex(
        (i) => i.id === payload.data.subscriptionId
      );

      if (index !== -1) {
        subscriptionsCopy[index] = payload.data;
      }
      return {
        ...state,
        isCancelingSubscription: false,
        isCancelingSubscriptionSuccess: true,
        successMessage: payload.message,
        subscriptions: subscriptionsCopy,
      };
    },
    [cancelSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      isCancelingSubscription: false,
      isCancelingSubscriptionFailure: true,
      errorMessage: payload,
    }),
    [getSubscriptionDetailsRequest]: (state) => ({
      ...state,
      isGettingSubscriptionDetails: true,
      isGettingSubscriptionDetailsSuccess: false,
      isGettingSubscriptionDetailsFailure: false,
    }),
    [getSubscriptionDetailsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSubscriptionDetails: false,
      isGettingSubscriptionDetailsSuccess: true,
      subscriptionDetails: payload.data,
      customerPaymentsList: payload.data.customerPaymentsList,
      successMessage: payload.message,
    }),
    [getSubscriptionDetailsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSubscriptionDetails: false,
      isGettingSubscriptionDetailsFailure: true,
      errorMessage: payload,
    }),
    [cancelSubscriptionItemRequest]: (state) => ({
      ...state,
      isCancelingSubscriptionItem: true,
      isCancelingSubscriptionItemSuccess: false,
      isCancelingSubscriptionItemFailure: false,
    }),
    [cancelSubscriptionItemSuccess]: (state, { payload }) => ({
      ...state,
      isCancelingSubscriptionItem: false,
      isCancelingSubscriptionItemSuccess: true,
      successMessage: payload.message,
      subscriptionDetails: payload.data,
    }),
    [cancelSubscriptionItemFailure]: (state, { payload }) => ({
      ...state,
      isCancelingSubscriptionItem: false,
      isCancelingSubscriptionItemFailure: true,
      errorMessage: payload,
    }),
    [getCustomerInvoicesListRequest]: (state) => ({
      ...state,
      isGettingInvoicesList: true,
    }),
    [getCustomerInvoicesListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInvoicesList: false,
      successMessage: payload.message,
      invoicesList: payload.data,
    }),
    [getCustomerInvoicesListFailure]: (state, { payload }) => ({
      ...state,
      isGettingInvoicesList: false,
      errorMessage: payload,
    }),
    [refundChargeRequest]: (state) => ({
      ...state,
      isRefundingCharge: true,
      isRefundingChargeSuccess: false,
      isRefundingChargeFailure: false,
    }),
    [refundChargeSuccess]: (state, { payload }) => ({
      ...state,
      isRefundingCharge: false,
      isRefundingChargeSuccess: true,
      successMessage: payload.message,
    }),
    [refundChargeFailure]: (state, { payload }) => ({
      ...state,
      isRefundingCharge: false,
      isRefundingChargeFailure: true,
      errorMessage: payload,
    }),
    [getRefundListRequest]: (state) => ({
      ...state,
      isGettingRefundList: true,
      isGettingRefundListSuccess: false,
      isGettingRefundListFailure: false,
    }),
    [getRefundListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingRefundList: false,
      isGettingRefundListSuccess: true,
      refundList: payload.data,
      successMessage: payload.message,
    }),
    [getRefundListFailure]: (state, { payload }) => ({
      ...state,
      isGettingRefundList: false,
      isGettingRefundListFailure: true,
      errorMessage: payload,
    }),
    [getCustomerChargesRequest]: (state) => ({
      ...state,
      isGetCharges: true,
      isGetChargesSuccess: false,
      isGetChargesFailure: false,
    }),
    [getCustomerChargesSuccess]: (state, { payload }) => ({
      ...state,
      isGetCharges: false,
      isGetChargesSuccess: true,
      chargesList: payload.data.data,
      hasMore: payload.data.hasMore,
    }),
    [getCustomerChargesFailure]: (state, { payload }) => ({
      ...state,
      isGetCharges: false,
      isGetChargesFailure: true,
      errorMessage: payload,
    }),
    [changeSubscriptionDateRequest]: (state) => ({
      ...state,
      isChangingSubscriptionDate: true,
      isChangingSubscriptionDateSuccess: false,
      isChangingSubscriptionDateFailure: false,
    }),
    [changeSubscriptionDateSuccess]: (state, { payload }) => ({
      ...state,
      isChangingSubscriptionDate: false,
      isChangingSubscriptionDateSuccess: true,
      successMessage: payload.message,
    }),
    [changeSubscriptionDateFailure]: (state, { payload }) => ({
      ...state,
      isChangingSubscriptionDate: false,
      isChangingSubscriptionDateFailure: true,
      errorMessage: payload,
    }),
    [updateSubscriptionRequest]: (state) => ({
      ...state,
      isUpdatingSubscription: true,
      isUpdatingSubscriptionSuccess: false,
      isUpdatingSubscriptionFailure: false,
    }),
    [updateSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingSubscription: false,
      isUpdatingSubscriptionSuccess: true,
      successMessage: payload.message,
    }),
    [updateSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingSubscription: false,
      isUpdatingSubscriptionFailure: true,
      errorMessage: payload,
    }),
    [sendInvoiceToCustomerRequest]: (state) => ({
      ...state,
      isSendInvoice: true,
      isSendInvoiceSuccess: false,
      isSendInvoiceFailure: false,
    }),
    [sendInvoiceToCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isSendInvoice: false,
      isSendInvoiceSuccess: true,
      successMessage: payload.message,
    }),
    [sendInvoiceToCustomerFailure]: (state, { payload }) => ({
      ...state,
      isSendInvoice: false,
      isSendInvoiceFailure: true,
      errorMessage: payload,
    }),
    [payInvoiceRequest]: (state) => ({
      ...state,
      isPayingInvoice: true,
      isPayingInvoiceSuccess: false,
      isPayingInvoiceFailure: false,
    }),
    [payInvoiceSuccess]: (state, { payload }) => ({
      ...state,
      isPayingInvoice: false,
      isPayingInvoiceSuccess: true,
      successMessage: payload.message,
    }),
    [payInvoiceFailure]: (state, { payload }) => ({
      ...state,
      isPayingInvoice: false,
      isPayingInvoiceFailure: true,
      errorMessage: payload,
    }),
    [createBillingWithDesiredDateRequest]: (state) => ({
      ...state,
      isCreateDesiredDateBilling: true,
      isCreateDesiredDateBillingSuccess: false,
      isCreateDesiredDateBillingFailure: false,
    }),
    [createBillingWithDesiredDateSuccess]: (state, { payload }) => ({
      ...state,
      isCreateDesiredDateBilling: false,
      isCreateDesiredDateBillingSuccess: true,
      successMessage: payload.message,
    }),
    [createBillingWithDesiredDateFailure]: (state, { payload }) => ({
      ...state,
      isCreateDesiredDateBilling: false,
      isCreateDesiredDateBillingFailure: true,
      errorMessage: payload,
    }),
    [getDetailedProfitAndLossRequest]: (state) => ({
      ...state,
      isGettingDetailedProfitLoss: true,
      isGettingDetailedProfitLossSuccess: false,
      isGettingDetailedProfitLossFailure: false,
    }),
    [getDetailedProfitAndLossSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDetailedProfitLoss: false,
      isGettingDetailedProfitLossSuccess: true,
      successMessage: payload.message,
      detailedProfitLoss: payload.data.rows,
      detailedProfitLossCount: payload.data.count,
      totalProfit: payload.data.totalProfit,
    }),
    [getDetailedProfitAndLossFailure]: (state, { payload }) => ({
      ...state,
      isGettingDetailedProfitLoss: false,
      isGettingDetailedProfitLossFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
