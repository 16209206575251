import { handleActions } from 'redux-actions';

import {
  createAgentFailure,
  createAgentRequest,
  createAgentSuccess,
  generateAgentIdFailure,
  generateAgentIdRequest,
  generateAgentIdSuccess,
  getAgentsListFailure,
  getAgentsListRequest,
  getAgentsListSuccess,
  getAgentSelectOptionsFailure,
  getAgentSelectOptionsRequest,
  getAgentSelectOptionsSuccess,
  findOneAgentRequest,
  findOneAgentSuccess,
  findOneAgentFailure,
  clearAgentData,
  editAgentRequest,
  editAgentSuccess,
  editAgentFailure,
  deleteAgentRequest,
  deleteAgentSuccess,
  deleteAgentFailure,
  getSubAgentsListDataRequest,
  getSubAgentsListDataSuccess,
  getSubAgentsListDataFailure,
  createSubAgentRequest,
  createSubAgentSuccess,
  createSubAgentFailure,
  findSubAgentRequest,
  findSubAgentSuccess,
  findSubAgentFailure,
  updateSubAgentRequest,
  updateSubAgentSuccess,
  updateSubAgentFailure,
  toggleAgentRequest,
  toggleAgentSuccess,
  toggleAgentFailure,
  duplicateAgentRequest,
  duplicateAgentSuccess,
  duplicateAgentFailure,
} from './actions';

const initialState = {
  errorMessage: '',
  successMessage: '',
  isGeneratedAgentIdFailure: false,
  agentId: '',
  isCreatingAgent: false,
  isCreatingAgentSuccess: false,
  isCreatingAgentFailure: false,
  isGettingAgentsList: true,
  isGettingAgentsListSuccess: false,
  isGettingAgentsListFailure: false,
  agentsList: [],
  agentsCount: 0,
  agentSelectData: [],
  isGettingSelectDataFailure: false,
  isFindingOneAgent: false,
  isFindingOneAgentFailure: false,
  agent: {},
  isUpdatingAgent: false,
  isUpdatingAgentSuccess: false,
  isUpdatingAgentFailure: false,
  isDeletedAgentSuccess: false,
  isDeletedAgentFailure: false,
  subAgents: [],
  subAgentsCount: 0,
  isGettingSubAgentsList: true,
  isGettingSubAgentsListSuccess: false,
  isGettingSubAgentsListFailure: false,
  isCreatingSubAgent: false,
  isCreatingSubAgentSuccess: false,
  isCreatingSubAgentFailure: false,
  isToggleAgentSuccess: false,
  isToggleAgentFailure: false,
  isDuplicating: false,
  isDuplicatingSuccess: false,
  isDuplicatingFailure: false,
};

const reducer = handleActions(
  {
    [generateAgentIdRequest]: (state) => ({
      ...state,
      isGeneratedAgentIdFailure: false,
    }),
    [generateAgentIdSuccess]: (state, { payload }) => ({
      ...state,
      agentId: payload.data,
      successMessage: payload.message,
    }),
    [generateAgentIdFailure]: (state, { payload }) => ({
      ...state,
      isGeneratedAgentIdFailure: true,
      errorMessage: payload,
    }),
    [createAgentRequest]: (state) => ({
      ...state,
      isCreatingAgent: true,
      isCreatingAgentSuccess: false,
      isCreatingAgentFailure: false,
    }),
    [createAgentSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingAgent: false,
      isCreatingAgentSuccess: true,
      successMessage: payload.message,
    }),
    [createAgentFailure]: (state, { payload }) => ({
      ...state,
      isCreatingAgent: false,
      isCreatingAgentFailure: true,
      errorMessage: payload,
    }),
    [getAgentsListRequest]: (state) => ({
      ...state,
      isGettingAgentsList: true,
      isGettingAgentsListSuccess: true,
      isGettingAgentsListFailure: true,
    }),
    [getAgentsListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAgentsList: false,
      isGettingAgentsListSuccess: true,
      successMessage: payload.message,
      agentsList: payload.data.rows,
      agentsCount: payload.data.count,
    }),
    [getAgentsListFailure]: (state, { payload }) => ({
      ...state,
      isGettingAgentsList: false,
      isGettingAgentsListFailure: true,
      errorMessage: payload,
    }),
    [getAgentSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingSelectDataFailure: false,
    }),
    [getAgentSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSelectDataFailure: false,
      successMessage: payload.message,
      agentSelectData: payload.data,
    }),
    [getAgentSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSelectDataFailure: true,
      errorMessage: payload,
    }),
    [findOneAgentRequest]: (state) => ({
      ...state,
      isFindingOneAgent: true,
      isFindingOneAgentFailure: false,
    }),
    [findOneAgentSuccess]: (state, { payload }) => ({
      ...state,
      isFindingOneAgent: false,
      successMessage: payload.message,
      agent: payload.data,
    }),
    [findOneAgentFailure]: (state, { payload }) => ({
      ...state,
      isFindingOneAgent: false,
      isFindingOneAgentFailure: true,
      errorMessage: payload,
    }),
    [clearAgentData]: (state) => ({
      ...state,
      agent: {},
    }),
    [editAgentRequest]: (state) => ({
      ...state,
      isUpdatingAgent: true,
      isUpdatingAgentSuccess: false,
      isUpdatingAgentFailure: false,
    }),
    [editAgentSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingAgent: false,
      isUpdatingAgentSuccess: true,
      successMessage: payload.message,
    }),
    [editAgentFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingAgent: false,
      isUpdatingAgentFailure: true,
      errorMessage: payload,
    }),
    [deleteAgentRequest]: (state) => ({
      ...state,
      isDeletedAgentSuccess: false,
      isDeletedAgentFailure: false,
    }),
    [deleteAgentSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedAgentSuccess: true,
      successMessage: payload.message,
      agentsList: state.agentsList.filter((i) => i.id !== +payload.data),
    }),
    [deleteAgentFailure]: (state, { payload }) => ({
      ...state,
      isDeletedAgentFailure: true,
      errorMessage: payload,
    }),
    [getSubAgentsListDataRequest]: (state) => ({
      ...state,
      isGettingSubAgentsList: true,
      isGettingSubAgentsListSuccess: false,
      isGettingSubAgentsListFailure: false,
    }),
    [getSubAgentsListDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSubAgentsList: false,
      isGettingSubAgentsListSuccess: true,
      subAgents: payload.data.rows,
      subAgentsCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getSubAgentsListDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingSubAgentsList: false,
      isGettingSubAgentsListFailure: true,
      errorMessage: payload,
    }),
    [createSubAgentRequest]: (state) => ({
      ...state,
      isCreatingSubAgent: true,
      isCreatingSubAgentSuccess: false,
      isCreatingSubAgentFailure: false,
    }),
    [createSubAgentSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingSubAgent: false,
      isCreatingSubAgentSuccess: true,
      successMessage: payload.message,
    }),
    [createSubAgentFailure]: (state, { payload }) => ({
      ...state,
      isCreatingSubAgent: false,
      isCreatingSubAgentFailure: true,
      errorMessage: payload,
    }),
    [findSubAgentRequest]: (state) => ({
      ...state,
      isFindingOneAgent: true,
      isFindingOneAgentFailure: false,
    }),
    [findSubAgentSuccess]: (state, { payload }) => ({
      ...state,
      isFindingOneAgent: false,
      successMessage: payload.message,
      agent: payload.data,
    }),
    [findSubAgentFailure]: (state, { payload }) => ({
      ...state,
      isFindingOneAgent: false,
      isFindingOneAgentFailure: true,
      errorMessage: payload,
    }),
    [updateSubAgentRequest]: (state) => ({
      ...state,
      isUpdatingAgent: true,
      isUpdatingAgentSuccess: false,
      isUpdatingAgentFailure: false,
    }),
    [updateSubAgentSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingAgent: false,
      isUpdatingAgentSuccess: true,
      successMessage: payload.message,
    }),
    [updateSubAgentFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingAgent: false,
      isUpdatingAgentFailure: true,
      errorMessage: payload,
    }),
    [toggleAgentRequest]: (state) => ({
      ...state,
      isToggleAgentSuccess: false,
      isToggleAgentFailure: false,
    }),
    [toggleAgentSuccess]: (state, { payload }) => {
      const agentsListCopy = [...state.agentsList];
      const index = agentsListCopy.findIndex((i) => i.id === payload.data.id);

      if (index !== -1) {
        agentsListCopy[index] = payload.data;
      } else {
        agentsListCopy.push(payload.data);
      }

      return {
        ...state,
        isToggleAgentSuccess: true,
        successMessage: payload.message,
        agentsList: agentsListCopy,
      };
    },
    [toggleAgentFailure]: (state, { payload }) => ({
      ...state,
      isToggleAgentFailure: true,
      errorMessage: payload,
    }),
    [duplicateAgentRequest]: (state) => ({
      ...state,
      isDuplicating: true,
      isDuplicatingSuccess: false,
      isDuplicatingFailure: false,
    }),
    [duplicateAgentSuccess]: (state, { payload }) => ({
      ...state,
      isDuplicating: false,
      isDuplicatingSuccess: true,
      successMessage: payload.message,
    }),
    [duplicateAgentFailure]: (state, { payload }) => ({
      ...state,
      isDuplicating: false,
      isDuplicatingFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
