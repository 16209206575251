import { createAction } from 'redux-actions';

export const createZipCodesRequest = createAction('CREATE_ZIP_CODES_REQUEST');
export const createZipCodesSuccess = createAction('CREATE_ZIP_CODES_SUCCESS');
export const createZipCodesFailure = createAction('CREATE_ZIP_CODES_FAILURE');

export const getZipCodeListRequest = createAction('GET_ZIP_CODE_LIST_REQUEST');
export const getZipCodeListSuccess = createAction('GET_ZIP_CODE_LIST_SUCCESS');
export const getZipCodeListFailure = createAction('GET_ZIP_CODE_LIST_FAILURE');

export const getAllAcpStatesRequest = createAction(
  'GET_ALL_ACP_STATES_REQUEST'
);
export const getAllAcpStatesSuccess = createAction(
  'GET_ALL_ACP_STATES_SUCCESS'
);
export const getAllAcpStatesFailure = createAction(
  'GET_ALL_ACP_STATES_FAILURE'
);

export const updateZipCodeRequest = createAction('UPDATE_ZIP_CODE_REQUEST');
export const updateZipCodeSuccess = createAction('UPDATE_ZIP_CODE_SUCCESS');
export const updateZipCodeFailure = createAction('UPDATE_ZIP_CODE_FAILURE');

export const deleteZipCodeRequest = createAction('DELETE_ZIP_CODE_REQUEST');
export const deleteZipCodeSuccess = createAction('DELETE_ZIP_CODE_SUCCESS');
export const deleteZipCodeFailure = createAction('DELETE_ZIP_CODE_FAILURE');

export const addSpinCodeRequest = createAction('ADD_SPIN_CODE_REQUEST');
export const addSpinCodeSuccess = createAction('ADD_SPIN_CODE_SUCCESS');
export const addSpinCodeFailure = createAction('ADD_SPIN_CODE_FAILURE');

export const getACPSettingsRequest = createAction('GET_ACP_SETTINGS_REQUEST');
export const getACPSettingsSuccess = createAction('GET_ACP_SETTINGS_SUCCESS');
export const getACPSettingsFailure = createAction('GET_ACP_SETTINGS_FAILURE');
