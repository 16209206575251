import { createAction } from 'redux-actions';

export const updateSimCardSettingsRequest = createAction(
  'UPDATE_SIM_CARD_SETTINGS_REQUEST'
);
export const updateSimCardSettingsSuccess = createAction(
  'UPDATE_SIM_CARD_SETTINGS_SUCCESS'
);
export const updateSimCardSettingsFailure = createAction(
  'UPDATE_SIM_CARD_SETTINGS_FAILURE'
);

export const getSimCardSettingsRequest = createAction(
  'GET_SIM_CARD_SETTINGS_REQUEST'
);
export const getSimCardSettingsSuccess = createAction(
  'GET_SIM_CARD_SETTINGS_SUCCESS'
);
export const getSimCardSettingsFailure = createAction(
  'GET_SIM_CARD_SETTINGS_FAILURE'
);
