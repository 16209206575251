import { createAction } from 'redux-actions';

export const globalSearchRequest = createAction('GLOBAL_SEARCH_REQUEST');
export const globalSearchSuccess = createAction('GLOBAL_SEARCH_SUCCESS');
export const globalSearchFailure = createAction('GLOBAL_SEARCH_FAILURE');

export const globalSearchClear = createAction('GLOBAL_SEARCH_CLEAR');

export const getSearchHistoryRequest = createAction(
  'GET_SEARCH_HISTORY_REQUEST'
);
export const getSearchHistorySuccess = createAction(
  'GET_SEARCH_HISTORY_SUCCESS'
);
export const getSearchHistoryFailure = createAction(
  'GET_SEARCH_HISTORY_FAILURE'
);

export const removeSearchHistoryRequest = createAction(
  'REMOVE_SEARCH_HISTORY_REQUEST'
);
export const removeSearchHistorySuccess = createAction(
  'REMOVE_SEARCH_HISTORY_SUCCESS'
);
export const removeSearchHistoryFailure = createAction(
  'REMOVE_SEARCH_HISTORY_FAILURE'
);
