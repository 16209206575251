import { createAction } from 'redux-actions';

export const getNotificationsRequest = createAction(
  'GET_NOTIFICATIONS_REQUEST'
);
export const getNotificationsSuccess = createAction(
  'GET_NOTIFICATIONS_SUCCESS'
);
export const getNotificationsFailure = createAction(
  'GET_NOTIFICATIONS_FAILURE'
);

export const makeSeenNotificationsRequest = createAction(
  'MAKE_SEEN_NOTIFICATION_REQUEST'
);
export const makeSeenNotificationsSuccess = createAction(
  'MAKE_SEEN_NOTIFICATION_SUCCESS'
);
export const makeSeenNotificationsFailure = createAction(
  'MAKE_SEEN_NOTIFICATION_FAILURE'
);
export const pushNotificationFromEvent = createAction(
  'PUSH_NOTIFICATION_FROM_EVENT'
);

export const getNotificationsListDataRequest = createAction(
  'GET_NOTIFICATIONS_LIST_DATA_REQUEST'
);
export const getNotificationsListDataSuccess = createAction(
  'GET_NOTIFICATIONS_LIST_DATA_SUCCESS'
);
export const getNotificationsListDataFailure = createAction(
  'GET_NOTIFICATIONS_LIST_DATA_FAILURE'
);

export const deleteNotificationRequest = createAction(
  'DELETE_NOTIFICATION_REQUEST'
);
export const deleteNotificationSuccess = createAction(
  'DELETE_NOTIFICATION_SUCCESS'
);
export const deleteNotificationFailure = createAction(
  'DELETE_NOTIFICATION_FAILURE'
);

export const bulkDeleteNotificationsRequest = createAction(
  'BULK_DELETE_NOTIFICATIONS_REQUEST'
);
export const bulkDeleteNotificationsSuccess = createAction(
  'BULK_DELETE_NOTIFICATIONS_SUCCESS'
);
export const bulkDeleteNotificationsFailure = createAction(
  'BULK_DELETE_NOTIFICATIONS_FAILURE'
);
