import { createAction } from 'redux-actions';

export const createCustomerRequest = createAction('CREATE_CUSTOMER_REQUEST');
export const createCustomerSuccess = createAction('CREATE_CUSTOMER_SUCCESS');
export const createCustomerFailure = createAction('CREATE_CUSTOMER_FAILURE');

export const createUserCustomerRequest = createAction(
  'CREATE_USER_CUSTOMER_REQUEST'
);
export const createUserCustomerSuccess = createAction(
  'CREATE_USER_CUSTOMER_SUCCESS'
);
export const createUserCustomerFailure = createAction(
  'CREATE_USER_CUSTOMER_FAILURE'
);

export const getCustomersListRequest = createAction(
  'GET_CUSTOMERS_LIST_REQUEST'
);
export const getCustomersListSuccess = createAction(
  'GET_CUSTOMERS_LIST_SUCCESS'
);
export const getCustomersListFailure = createAction(
  'GET_CUSTOMERS_LIST_FAILURE'
);

export const continueCustomerStepRequest = createAction(
  'CONTINUE_CUSTOMER_STEP_REQUEST'
);
export const continueCustomerStepSuccess = createAction(
  'CONTINUE_CUSTOMER_STEP_SUCCESS'
);
export const continueCustomerStepFailure = createAction(
  'CONTINUE_CUSTOMER_STEP_FAILURE'
);

export const deleteCustomerRequest = createAction('DELETE_CUSTOMER_REQUEST');
export const deleteCustomerSuccess = createAction('DELETE_CUSTOMER_SUCCESS');
export const deleteCustomerFailure = createAction('DELETE_CUSTOMER_FAILURE');

export const getOneCustomerRequest = createAction('GET_ONE_CUSTOMER_REQUEST');
export const getOneCustomerSuccess = createAction('GET_ONE_CUSTOMER_SUCCESS');
export const getOneCustomerFailure = createAction('GET_ONE_CUSTOMER_FAILURE');

export const availabilityCheckRequest = createAction(
  'AVAILABILITY_CHECK_REQUEST'
);
export const availabilityCheckSuccess = createAction(
  'AVAILABILITY_CHECK_SUCCESS'
);
export const availabilityCheckFailure = createAction(
  'AVAILABILITY_CHECK_FAILURE'
);

export const saveAsDraftAcpRequest = createAction('SAVE_AS_DRAFT_ACP_REQUEST');
export const saveAsDraftAcpSuccess = createAction('SAVE_AS_DRAFT_ACP_SUCCESS');
export const saveAsDraftAcpFailure = createAction('SAVE_AS_DRAFT_ACP_FAILURE');

export const updateCustomerRequest = createAction('UPDATE_CUSTOMER_REQUEST');
export const updateCustomerSuccess = createAction('UPDATE_CUSTOMER_SUCCESS');
export const updateCustomerFailure = createAction('UPDATE_CUSTOMER_FAILURE');

export const checkEligibilityRequest = createAction(
  'CHECK_ELIGIBILITY_REQUEST'
);
export const checkEligibilitySuccess = createAction(
  'CHECK_ELIGIBILITY_SUCCESS'
);
export const checkEligibilityFailure = createAction(
  'CHECK_ELIGIBILITY_FAILURE'
);

export const checkEligibilityStatusRequest = createAction(
  'CHECK_ELIGIBILITY_STATUS_REQUEST'
);
export const checkEligibilityStatusSuccess = createAction(
  'CHECK_ELIGIBILITY_STATUS_SUCCESS'
);
export const checkEligibilityStatusFailure = createAction(
  'CHECK_ELIGIBILITY_STATUS_FAILURE'
);

export const verifyDeviceEligibilityRequest = createAction(
  'VERIFY_DEVICE_ELIGIBILITY_REQUEST'
);
export const verifyDeviceEligibilitySuccess = createAction(
  'VERIFY_DEVICE_ELIGIBILITY_SUCCESS'
);
export const verifyDeviceEligibilityFailure = createAction(
  'VERIFY_DEVICE_ELIGIBILITY_FAILURE'
);

export const updateCustomerPersonalInfoRequest = createAction(
  'UPDATE_CUSTOMER_PERSONAL_INFO_REQUEST'
);
export const updateCustomerPersonalInfoSuccess = createAction(
  'UPDATE_CUSTOMER_PERSONAL_INFO_SUCCESS'
);
export const updateCustomerPersonalInfoFailure = createAction(
  'UPDATE_CUSTOMER_PERSONAL_INFO_FAILURE'
);

export const updateCustomerAddressesRequest = createAction(
  'UPDATE_CUSTOMER_ADDRESSES_REQUEST'
);
export const updateCustomerAddressesSuccess = createAction(
  'UPDATE_CUSTOMER_ADDRESSES_SUCCESS'
);
export const updateCustomerAddressesFailure = createAction(
  'UPDATE_CUSTOMER_ADDRESSES_FAILURE'
);

export const bulkDeleteCustomersRequest = createAction(
  'BULK_DELETE_CUSTOMER_REQUEST'
);
export const bulkDeleteCustomersSuccess = createAction(
  'BULK_DELETE_CUSTOMER_SUCCESS'
);
export const bulkDeleteCustomersFailure = createAction(
  'BULK_DELETE_CUSTOMER_FAILURE'
);

export const transferSubscriberRequest = createAction(
  'TRANSFER_SUBSCRIBER_REQUEST'
);
export const transferSubscriberSuccess = createAction(
  'TRANSFER_SUBSCRIBER_SUCCESS'
);
export const transferSubscriberFailure = createAction(
  'TRANSFER_SUBSCRIBER_FAILURE'
);

export const subscriberReportRequest = createAction(
  'SUBSCRIBER_REPORT_REQUEST'
);
export const subscriberReportSuccess = createAction(
  'SUBSCRIBER_REPORT_SUCCESS'
);
export const subscriberReportFailure = createAction(
  'SUBSCRIBER_REPORT_FAILURE'
);

export const transactionReportRequest = createAction(
  'TRANSACTION_REPORT_REQUEST'
);
export const transactionReportSuccess = createAction(
  'TRANSACTION_REPORT_SUCCESS'
);
export const transactionReportFailure = createAction(
  'TRANSACTION_REPORT_FAILURE'
);

export const snapshotReportRequest = createAction('SNAPSHOT_REPORT_REQUEST');
export const snapshotReportSuccess = createAction('SNAPSHOT_REPORT_SUCCESS');
export const snapshotReportFailure = createAction('SNAPSHOT_REPORT_FAILURE');

export const recertificationReportRequest = createAction(
  'RECERTIFICATION_REPORT_REQUEST'
);
export const recertificationReportSuccess = createAction(
  'RECERTIFICATION_REPORT_SUCCESS'
);
export const recertificationReportFailure = createAction(
  'RECERTIFICATION_REPORT_FAILURE'
);

export const notProcessedReportRequest = createAction(
  'NOT_PROCESSED_REPORT_REQUEST'
);
export const notProcessedReportSuccess = createAction(
  'NOT_PROCESSED_REPORT_SUCCESS'
);
export const notProcessedReportFailure = createAction(
  'NOT_PROCESSED_REPORT_FAILURE'
);

export const createSubscriberRequest = createAction(
  'CREATE_SUBSCRIBER_REQUEST'
);
export const createSubscriberSuccess = createAction(
  'CREATE_SUBSCRIBER_SUCCESS'
);
export const createSubscriberFailure = createAction(
  'CREATE_SUBSCRIBER_FAILURE'
);

export const deleteSubscriberRequest = createAction(
  'DELETE_SUBSCRIBER_REQUEST'
);
export const deleteSubscriberSuccess = createAction(
  'DELETE_SUBSCRIBER_SUCCESS'
);
export const deleteSubscriberFailure = createAction(
  'DELETE_SUBSCRIBER_FAILURE'
);

export const updateCustomerLinesRequest = createAction(
  'UPDATE_CUSTOMER_LINES_REQUEST'
);
export const updateCustomerLinesSuccess = createAction(
  'UPDATE_CUSTOMER_LINES_SUCCESS'
);
export const updateCustomerLinesFailure = createAction(
  'UPDATE_CUSTOMER_LINES_FAILURE'
);

export const getUpdateHistoryRequest = createAction(
  'GET_UPDATE_HISTORY_REQUEST'
);
export const getUpdateHistorySuccess = createAction(
  'GET_UPDATE_HISTORY_SUCCESS'
);
export const getUpdateHistoryFailure = createAction(
  'GET_UPDATE_HISTORY_FAILURE'
);

export const clearCustomerData = createAction('CLEAR_CUSTOMER_DATA');

export const getUsageDataRequest = createAction('GET_USAGE_DATA_REQUEST');
export const getUsageDataSuccess = createAction('GET_USAGE_DATA_SUCCESS');
export const getUsageDataFailure = createAction('GET_USAGE_DATA_FAILURE');

export const updateBillingDetailsRequest = createAction(
  'UPDATE_BILLING_DETAILS_REQUEST'
);
export const updateBillingDetailsSuccess = createAction(
  'UPDATE_BILLING_DETAILS_SUCCESS'
);
export const updateBillingDetailsFailure = createAction(
  'UPDATE_BILLING_DETAILS_FAILURE'
);

export const paymentMethodsSelectOptionsRequest = createAction(
  'PAYMENT_METHODS_SELECT_OPTIONS_REQUEST'
);
export const paymentMethodsSelectOptionsSuccess = createAction(
  'PAYMENT_METHODS_SELECT_OPTIONS_SUCCESS'
);
export const paymentMethodsSelectOptionsFailure = createAction(
  'PAYMENT_METHODS_SELECT_OPTIONS_FAILURE'
);
export const updateCustomerLinesAndShipRequest = createAction(
  'UPDATE_CUSTOMER_LINES_AND_SHIP_REQUEST'
);
export const updateCustomerLinesAndShipSuccess = createAction(
  'UPDATE_CUSTOMER_LINES_AND_SHIP_SUCCESS'
);
export const updateCustomerLinesAndShipFailure = createAction(
  'UPDATE_CUSTOMER_LINES_AND_SHIP_FAILURE'
);

export const resetUpdateCustomerLinesAndShip = createAction(
  'RESET_UPDATE_CUSTOMER_LINES_AND_SHIP'
);
