import { handleActions } from 'redux-actions';
import {
  createWSPlanRequest,
  createWSPlanSuccess,
  createWSPlanFailure,
  getWSPlanRequest,
  getWSPlanSuccess,
  getWSPlanFailure,
  updateWSPlanRequest,
  updateWSPlanSuccess,
  updateWSPlanFailure,
  getWSPlanSelectOptionsRequest,
  getWSPlanSelectOptionsSuccess,
  getWSPlanSelectOptionsFailure,
  findWSPlanRequest,
  findWSPlanSuccess,
  findWSPlanFailure,
  clearWSPlanData,
  duplicateWSPlanRequest,
  duplicateWSPlanSuccess,
  duplicateWSPlanFailure,
  getTopUpWSPlanRequest,
  getTopUpWSPlanSuccess,
  getTopUpWSPlanFailure,
} from './actions';

const initialState = {
  errorMessage: '',
  successMessage: '',
  isCreateWS: false,
  isCreateWSSuccess: false,
  isCreateWSFailure: false,
  isGetWSPlan: false,
  isGetWSPlanSuccess: false,
  isGetWSPlanFailure: false,
  allWSPlan: [],
  WSPlanCount: 0,
  isUpdateWsPlan: false,
  isUpdateWSPlanSuccess: false,
  isUpdateWSPlanFailure: false,
  isUpdatedWSPlan: {},
  wsSelectOptions: [],
  isGettingWsPlanOptions: false,
  isFindingWSPlan: false,
  wsPlan: {},
  isDuplicatingWSPlan: false,
  isDuplicatingWSPlanSuccess: false,
  isDuplicatingWSPlanFailure: false,
  isGettingTopUPWSPlans: false,
  isGettingTopUPWSPlansSuccess: false,
  isGettingTopUPWSPlansFailure: false,
  topUpWSPlans: [],
};

const reducer = handleActions(
  {
    // Create Wholesale Plan
    [createWSPlanRequest]: (state) => ({
      ...state,
      isCreateWS: true,
      isCreateWSSuccess: false,
      isCreateWSFailure: false,
    }),
    [createWSPlanSuccess]: (state, { payload }) => ({
      ...state,
      isCreateWS: false,
      isCreateWSSuccess: true,
      allWSPlan: [...state.allWSPlan, payload.data],
      successMessage: payload.message,
    }),
    [createWSPlanFailure]: (state, { payload }) => ({
      ...state,
      isCreateWS: false,
      isCreateWSFailure: true,
      errorMessage: payload,
    }),
    // Wholesale Plan
    [getWSPlanRequest]: (state) => ({
      ...state,
      isGetWSPlan: true,
      isGetWSPlanSuccess: false,
      isGetWSPlanFailure: false,
      WSPlanCount: 0,
    }),
    [getWSPlanSuccess]: (state, { payload }) => ({
      ...state,
      isGetWSPlan: false,
      isGetWSPlanSuccess: true,
      isGetWSPlanFailure: false,
      allWSPlan: payload.data.rows,
      WSPlanCount: payload.data.count,
    }),
    [getWSPlanFailure]: (state, { payload }) => ({
      ...state,
      isGetWSPlan: false,
      isGetWSPlanSuccess: false,
      isGetWSPlanFailure: true,
      errorMessage: payload.message,
    }),
    // Update Wholesale Plan
    [updateWSPlanRequest]: (state, { payload }) => ({
      ...state,
      isUpdateWsPlan: true,
      isUpdateWSPlanSuccess: false,
      isUpdateWSPlanFailure: false,
    }),
    [updateWSPlanSuccess]: (state, { payload }) => {
      const wsPlansCopy = [...state.allWSPlan];
      const index = wsPlansCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        wsPlansCopy[index] = payload.data;
      }

      return {
        ...state,
        isUpdateWsPlan: false,
        isUpdateWSPlanSuccess: true,
        isUpdateWSPlanFailure: false,
        allWSPlan: wsPlansCopy,
        wsPlan: payload.data,
        successMessage: payload.message,
      };
    },
    [updateWSPlanFailure]: (state, { payload }) => ({
      ...state,
      isUpdateWsPlan: false,
      isUpdateWSPlanSuccess: false,
      isUpdateWSPlanFailure: true,
      errorMessage: payload,
    }),
    [getWSPlanSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingWsPlanOptions: true,
    }),
    [getWSPlanSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingWsPlanOptions: false,
      wsSelectOptions: payload.data,
      successMessage: payload.message,
    }),
    [getWSPlanSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingWsPlanOptions: false,
      errorMessage: payload,
    }),
    [findWSPlanRequest]: (state) => ({
      ...state,
      isFindingWSPlan: true,
    }),
    [findWSPlanSuccess]: (state, { payload }) => ({
      ...state,
      isFindingWSPlan: false,
      wsPlan: payload.data,
      successMessage: payload.message,
    }),
    [findWSPlanFailure]: (state, { payload }) => ({
      ...state,
      isFindingWSPlan: false,
      errorMessage: payload,
    }),
    [clearWSPlanData]: (state) => ({
      ...state,
      wsPlan: {},
    }),
    [duplicateWSPlanRequest]: (state) => ({
      ...state,
      isDuplicatingWSPlan: true,
      isDuplicatingWSPlanSuccess: false,
      isDuplicatingWSPlanFailure: false,
    }),
    [duplicateWSPlanSuccess]: (state, { payload }) => ({
      ...state,
      isDuplicatingWSPlan: false,
      isDuplicatingWSPlanSuccess: true,
      successMessage: payload.message,
      allWSPlan: [payload.data, ...state.allWSPlan],
    }),
    [duplicateWSPlanFailure]: (state, { payload }) => ({
      ...state,
      isDuplicatingWSPlan: false,
      isDuplicatingWSPlanFailure: true,
      errorMessage: payload,
    }),
    [getTopUpWSPlanRequest]: (state) => ({
      ...state,
      isGettingTopUPWSPlans: true,
      isGettingTopUPWSPlansSuccess: false,
      isGettingTopUPWSPlansFailure: false,
    }),
    [getTopUpWSPlanSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTopUPWSPlans: false,
      isGettingTopUPWSPlansSuccess: true,
      successMessage: payload.message,
      topUpWSPlans: payload.data,
    }),
    [getTopUpWSPlanFailure]: (state, { payload }) => ({
      ...state,
      isGettingTopUPWSPlans: false,
      isGettingTopUPWSPlansFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
