const Config = {
  APP_URL: process.env.REACT_APP_URL || window.location.origin,
  DOMAIN: process.env.REACT_APP_DOMAIN || `${window.location.origin}/api/admin`,
  TENANT:
    process.env.REACT_APP_TENANT_APP_DOMAIN ||
    `${window.location.origin}/api/tenant`,
  SOCKET_DOMAIN:
    process.env.REACT_APP_SOCKET_DOMAIN || `${window.location.origin}`,
  API_HOST: process.env.REACT_APP_API_HOST || `${window.location.origin}`,
};

const config = {
  ...Config,
  DEFAULT_REQUEST_TIMEOUT: 30000,
};

export default config;
