import { handleActions } from 'redux-actions';

import {
  bulkDeleteNotesFailure,
  bulkDeleteNotesRequest,
  bulkDeleteNotesSuccess,
  createNoteFailure,
  createNoteRequest,
  createNoteSuccess,
  deleteNoteFailure,
  deleteNoteRequest,
  deleteNoteSuccess,
  getNotesListFailure,
  getNotesListRequest,
  getNotesListSuccess,
  updateNoteFailure,
  updateNoteRequest,
  updateNoteSuccess,
} from './actions';

const initialState = {
  isCreatingNote: false,
  isCreatingNoteSuccess: false,
  isCreatingNoteFailure: false,
  errorMessage: '',
  successMessage: '',
  isGettingNotesList: true,
  isGettingNotesListSuccess: false,
  isGettingNotesListFailure: false,
  notesList: [],
  notesCount: 0,
  isDeletedNodeSuccess: false,
  isDeletedNodeFailure: false,
  isUpdatedNoteSuccess: false,
  isUpdatedNoteFailure: false,
  isBulkDeletedNotesSuccess: false,
  isBulkDeletedNotesFailure: false,
};

const reducer = handleActions(
  {
    [createNoteRequest]: (state) => ({
      ...state,
      isCreatingNote: true,
      isCreatingNoteSuccess: false,
      isCreatingNoteFailure: false,
    }),
    [createNoteSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingNote: false,
      isCreatingNoteSuccess: true,
      successMessage: payload.message,
      notesList: [payload.data, ...state.notesList],
    }),
    [createNoteFailure]: (state, { payload }) => ({
      ...state,
      isCreatingNote: false,
      isCreatingNoteFailure: true,
      errorMessage: payload,
    }),
    [getNotesListRequest]: (state) => ({
      ...state,
      isGettingNotesList: true,
      isGettingNotesListSuccess: false,
      isGettingNotesListFailure: false,
    }),
    [getNotesListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingNotesList: false,
      isGettingNotesListSuccess: true,
      notesList: payload.data.rows,
      notesCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getNotesListFailure]: (state, { payload }) => ({
      ...state,
      isGettingNotesList: false,
      isGettingNotesListFailure: true,
      errorMessage: payload,
    }),
    [deleteNoteRequest]: (state) => ({
      ...state,
      isDeletedNodeSuccess: false,
      isDeletedNodeFailure: false,
    }),
    [deleteNoteSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedNodeSuccess: true,
      successMessage: payload.message,
      notesList: state.notesList.filter((i) => i.id !== +payload.data),
    }),
    [deleteNoteFailure]: (state, { payload }) => ({
      ...state,
      isDeletedNodeFailure: true,
      errorMessage: payload,
    }),
    [updateNoteRequest]: (state) => ({
      ...state,
      isUpdatedNoteSuccess: false,
      isUpdatedNoteFailure: false,
    }),
    [updateNoteSuccess]: (state, { payload }) => {
      const notesListCopy = [...state.notesList];
      const index = notesListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        notesListCopy[index] = payload.data;
      }

      return {
        ...state,
        isUpdatedNoteSuccess: true,
        successMessage: payload.message,
        notesList: notesListCopy,
      };
    },
    [updateNoteFailure]: (state, { payload }) => ({
      ...state,
      isUpdatedNoteFailure: true,
      errorMessage: payload,
    }),
    [bulkDeleteNotesRequest]: (state) => ({
      ...state,
      isBulkDeletedNotesSuccess: false,
      isBulkDeletedNotesFailure: false,
    }),
    [bulkDeleteNotesSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletedNotesSuccess: true,
      successMessage: payload.message,
      notesList: state.notesList.filter((i) => !payload.data.includes(i.id)),
      notesCount: state.notesCount - payload.data.length,
    }),
    [bulkDeleteNotesFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletedNotesFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
