import { FormattedMessage } from 'react-intl';
import { SettingOutlined, NotificationOutlined } from '@ant-design/icons';

const icons = {
  SettingOutlined,
  NotificationOutlined,
};

const settings = {
  id: 'group-settings',
  title: <FormattedMessage id="Settings" />,
  icon: icons.SettingOutlined,
  type: 'collapse',
  resource: ['tenant', 'all'],
  children: [
    {
      id: 'userSettings',
      title: 'User Settings',
      type: 'item',
      url: '/settings/users-settings',
    },
    {
      id: 'roles',
      title: 'User Roles',
      type: 'item',
      url: '/settings/users-roles',
    },
    {
      id: 'e-commerce',
      title: 'E-commerce settings',
      type: 'item',
      url: '/settings/e-commerce-settings',
      chip: {
        label: 'Soon',
        color: 'default',
        variant: 'filled',
        size: 'medium',
        icon: icons.NotificationOutlined,
      },
    },
    {
      id: 'ticketGroups',
      title: 'Tickets Groups',
      type: 'item',
      url: '/settings/tickets-groups',
    },
    {
      id: 'simCardSettings',
      title: 'SIM Card Settings',
      type: 'item',
      url: '/settings/sim-card',
      chip: {
        label: 'Soon',
        color: 'default',
        variant: 'filled',
        size: 'medium',
        icon: icons.NotificationOutlined,
      },
    },
    {
      id: 'planConfig',
      title: 'Retail Plans',
      type: 'item',
      url: '/settings/retail-plans',
    },
    {
      id: 'carrierSettings',
      title: 'Carrier Settings',
      type: 'item',
      url: '/settings/carrier-settings',
    },
    {
      id: 'group-carrier-services',
      title: 'Shipping',
      type: 'item',
      url: '/settings/shipping',
    },
    {
      id: 'site-settings',
      title: 'Site Settings ',
      type: 'item',
      url: '/settings/site-settings',
    },
  ],
};
export default settings;
