import { createAction } from 'redux-actions';

export const createCategoryRequest = createAction('CREATE_CATEGORY_REQUEST');
export const createCategorySuccess = createAction('CREATE_CATEGORY_SUCCESS');
export const createCategoryFailure = createAction('CREATE_CATEGORY_FAILURE');

export const getCategoryListRequest = createAction('GET_CATEGORY_LIST_REQUEST');
export const getCategoryListSuccess = createAction('GET_CATEGORY_LIST_SUCCESS');
export const getCategoryListFailure = createAction('GET_CATEGORY_LIST_FAILURE');

export const updateCategoryRequest = createAction('UPDATE_CATEGORY_REQUEST');
export const updateCategorySuccess = createAction('UPDATE_CATEGORY_SUCCESS');
export const updateCategoryFailure = createAction('UPDATE_CATEGORY_FAILURE');

export const handleCategoryData = createAction('HANDLE_CATEGORY_DATA');

export const deleteCategoryRequest = createAction('DELETE_CATEGORY_REQUEST');
export const deleteCategorySuccess = createAction('DELETE_CATEGORY_SUCCESS');
export const deleteCategoryFailure = createAction('DELETE_CATEGORY_FAILURE');
