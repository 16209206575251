import { FormattedMessage } from 'react-intl';
import {
  ShoppingCartOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';

const icons = {
  ShoppingCartOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
};

const lineReports = {
  id: 'lineReports',
  title: <FormattedMessage id="Line Reports" />,
  icon: icons.FileSearchOutlined,
  type: 'collapse',
  resource: ['tenant', 'all', 'masterAgent', 'subAgent'],
  children: [
    {
      id: 'hotlines',
      title: 'Hotlines (Suspended)',
      type: 'item',
      url: '/report/hotlines',
    },
    {
      id: 'deactivatedLines',
      title: 'Deactivated Lines',
      type: 'item',
      url: '/report/deactivated-lines',
    },
    {
      id: 'canceledOrders',
      title: 'Canceled Orders',
      type: 'item',
      url: '/report/canceled',
    },
    {
      id: 'abandonedOrFailed',
      title: 'Abandoned & Failed orders',
      type: 'item',
      url: '/report/abandoned-failed-orders',
    },
    {
      id: 'activeLinesWithNoSubscription',
      title: 'Active Lines With No Subscription',
      type: 'item',
      url: '/report/active-lines-with-no-subscription',
    },
    {
      id: 'cdrReport',
      title: 'CDR Report',
      type: 'item',
      url: '/report/cdr',
    },
  ],
};

export default lineReports;
