import { axiosApiInstance, config } from 'custom-configs';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

import {
  createManufacturerFailure,
  createManufacturerRequest,
  createManufacturerSuccess,
  deleteManufacturerFailure,
  deleteManufacturerFilesFailure,
  deleteManufacturerFilesRequest,
  deleteManufacturerFilesSuccess,
  deleteManufacturerRequest,
  deleteManufacturerSuccess,
  getManufacturersListFailure,
  getManufacturersListRequest,
  getManufacturersListSuccess,
  updateManufacturerFailure,
  updateManufacturerRequest,
  updateManufacturerSuccess,
} from './actions';

const URL = `${config.TENANT}/e-commerce/manufacturers`;

function* createManufacturer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createManufacturerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createManufacturer, error', e);
    if (e?.response?.data) {
      yield put(createManufacturerFailure(catchResponseMessages(e)));
    }
  }
}

function* getList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getManufacturersListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getList, error', e);
    if (e?.response?.data) {
      yield put(getManufacturersListFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteManufacturer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteManufacturerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteManufacturer, error', e);
    if (e?.response?.data) {
      yield put(deleteManufacturerFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteManufacturerFile({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/delete-file/${payload.id}/${payload.fileId}`
      )
    );
    if (response?.status === 200) {
      yield put(deleteManufacturerFilesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteManufacturerFile, error', e);
    if (e?.response?.data) {
      yield put(deleteManufacturerFilesFailure(catchResponseMessages(e)));
    }
  }
}

function* updateManufacturer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateManufacturerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateManufacturer, error', e);
    if (e?.response?.data) {
      yield put(updateManufacturerFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createManufacturerRequest, createManufacturer);
  yield takeLatest(getManufacturersListRequest, getList);
  yield takeLatest(deleteManufacturerRequest, deleteManufacturer);
  yield takeLatest(deleteManufacturerFilesRequest, deleteManufacturerFile);
  yield takeLatest(updateManufacturerRequest, updateManufacturer);
}
