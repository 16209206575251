import { handleActions } from 'redux-actions';
import {
  bulkDeleteRolesFailure,
  bulkDeleteRolesRequest,
  bulkDeleteRolesSuccess,
  clearRoleData,
  clearUserData,
  createUserFailure,
  createUserRequest,
  createUserRoleFailure,
  createUserRoleRequest,
  createUserRoleSuccess,
  createUserSuccess,
  deleteRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  findRoleFailure,
  findRoleRequest,
  findRoleSuccess,
  findUserFailure,
  findUserRequest,
  findUserSuccess,
  getRolesLIstFailure,
  getRolesLIstRequest,
  getRolesLIstSuccess,
  getRolesOptionsFailure,
  getRolesOptionsRequest,
  getRolesOptionsSuccess,
  getUsersListFailure,
  getUsersListRequest,
  getUsersListSuccess,
  toggleUsersStatusFailure,
  toggleUsersStatusRequest,
  toggleUsersStatusSuccess,
  updateRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
} from './actions';

const initialState = {
  isCreatingRole: false,
  isCreatedRoleFailure: false,
  isCreatedRoleSuccess: false,
  isCreatingUser: true,
  isCreatedUserFailure: false,
  isCreatedUserSuccess: false,
  isGettingRole: false,
  userRole: {},
  isGettingRolesList: true,
  isGettingRolesListSuccess: false,
  rolesList: [],
  rolesCount: 0,
  isUpdatingRole: false,
  isUpdatedRoleSuccess: false,
  isUpdatedRoleFailure: false,
  isDeletedRoleSuccess: false,
  isDeletedRoleFailure: false,
  isGettingRoleOptionsFailure: false,
  rolesOptions: [],
  isGettingUsersList: true,
  usersList: [],
  usersCount: 0,
  isToggledSuccess: false,
  isToggledFailure: false,
  isDeletedUserSuccess: false,
  isDeletedUserFailure: false,
  isGettingUserFailure: false,
  userData: {},
  isUpdatingUser: false,
  isUpdatedUserSuccess: false,
  isUpdatedUserFailure: false,
  isBulkDeletedRolesSuccess: false,
  isBulkDeletedRolesFailure: false,
  isGettingUser: true,
};

const reducer = handleActions(
  {
    [createUserRequest]: (state) => ({
      ...state,
      isCreatingUser: true,
      isCreatedUserFailure: false,
      isCreatedUserSuccess: false,
    }),
    [createUserSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingUser: false,
      isCreatedUserSuccess: true,
      successMessage: payload.message,
      usersList: [...state.usersList, payload.data],
    }),
    [createUserFailure]: (state, { payload }) => ({
      ...state,
      isCreatingUser: false,
      isCreatedUserFailure: true,
      errorMessage: payload,
    }),
    [createUserRoleRequest]: (state) => ({
      ...state,
      isCreatingRole: true,
      isCreatedRoleFailure: false,
      isCreatedRoleSuccess: false,
    }),
    [createUserRoleSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingRole: false,
      isCreatedRoleSuccess: true,
      rolesList: [...state.rolesList, payload.data],
      rolesCount: state.rolesCount + 1,
      successMessage: payload.message,
    }),
    [createUserRoleFailure]: (state, { payload }) => ({
      ...state,
      isCreatingRole: false,
      isCreatedRoleFailure: true,
      errorMessage: payload,
    }),
    [findRoleRequest]: (state) => ({
      ...state,
      isGettingRole: true,
    }),
    [findRoleSuccess]: (state, { payload }) => ({
      ...state,
      isGettingRole: false,
      userRole: payload.data,
      successMessage: payload.message,
    }),
    [findRoleFailure]: (state, { payload }) => ({
      ...state,
      isGettingRole: false,
      errorMessage: payload,
    }),
    [getRolesLIstRequest]: (state) => ({
      ...state,
      isGettingRolesList: true,
      isGettingRolesListSuccess: false,
      isGettingRolesListFailure: false,
    }),
    [getRolesLIstSuccess]: (state, { payload }) => ({
      ...state,
      isGettingRolesList: false,
      isGettingRolesListSuccess: true,
      rolesList: payload.data.rows,
      rolesCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getRolesLIstFailure]: (state, { payload }) => ({
      ...state,
      isGettingRolesList: false,
      isGettingRolesListFailure: true,
      errorMessage: payload,
    }),
    [updateRoleRequest]: (state) => ({
      ...state,
      isUpdatingRole: true,
      isUpdatedRoleSuccess: false,
      isUpdatedRoleFailure: false,
    }),
    [updateRoleSuccess]: (state, { payload }) => {
      const rolesCopy = [...state.rolesList];
      const index = rolesCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        rolesCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingRole: false,
        isUpdatedRoleSuccess: true,
        rolesList: rolesCopy,
        successMessage: payload.message,
      };
    },
    [updateRoleFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingRole: false,
      isUpdatedRoleFailure: true,
      errorMessage: payload,
    }),
    [deleteRoleRequest]: (state) => ({
      ...state,
      isDeletedRoleSuccess: false,
      isDeletedRoleFailure: false,
    }),
    [deleteRoleSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedRoleSuccess: true,
      successMessage: payload.message,
      rolesList: state.rolesList.filter((i) => i.id !== payload.data),
      rolesCount: state.rolesCount - 1,
    }),
    [deleteRoleFailure]: (state, { payload }) => ({
      ...state,
      isDeletedRoleFailure: true,
      errorMessage: payload,
    }),
    [getRolesOptionsRequest]: (state) => ({
      ...state,
      isGettingRoleOptionsFailure: false,
    }),
    [getRolesOptionsSuccess]: (state, { payload }) => ({
      ...state,
      rolesOptions: payload.data,
    }),
    [getRolesOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingRoleOptionsFailure: true,
      errorMessage: payload,
    }),
    [getUsersListRequest]: (state) => ({
      ...state,
      isGettingUsersList: true,
    }),
    [getUsersListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingUsersList: false,
      usersList: payload.data.rows,
      usersCount: payload.data.count,
    }),
    [getUsersListFailure]: (state, { payload }) => ({
      ...state,
      isGettingUsersList: false,
      errorMessage: payload,
    }),
    [toggleUsersStatusRequest]: (state) => ({
      ...state,
      isToggledSuccess: false,
      isToggledFailure: false,
    }),
    [toggleUsersStatusSuccess]: (state, { payload }) => {
      const usersListCopy = [...state.usersList];
      const index = usersListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        usersListCopy[index] = payload.data;
      }
      return {
        ...state,
        isToggledSuccess: true,
        usersList: usersListCopy,
        successMessage: payload.message,
      };
    },
    [toggleUsersStatusFailure]: (state, { payload }) => ({
      ...state,
      isToggledFailure: false,
      errorMessage: payload,
    }),
    [deleteUserRequest]: (state) => ({
      ...state,
      isDeletedUserSuccess: false,
      isDeletedUserFailure: false,
    }),
    [deleteUserSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedUserSuccess: true,
      successMessage: payload.message,
      usersList: state.usersList.filter((i) => i.id !== payload.data),
      usersCount: state.usersCount - 1,
    }),
    [deleteUserFailure]: (state, { payload }) => ({
      ...state,
      isDeletedUserFailure: true,
      errorMessage: payload,
    }),
    [findUserRequest]: (state) => ({
      ...state,
      isGettingUser: true,
      isGettingUserFailure: false,
      userData: {},
    }),
    [findUserSuccess]: (state, { payload }) => ({
      ...state,
      isGettingUser: false,
      userData: payload.data,
    }),
    [findUserFailure]: (state, { payload }) => ({
      ...state,
      isGettingUser: false,
      isGettingUserFailure: true,
      errorMessage: payload,
    }),
    [updateUserRequest]: (state) => ({
      ...state,
      isUpdatingUser: true,
      isUpdatedUserSuccess: false,
      isUpdatedUserFailure: false,
    }),
    [updateUserSuccess]: (state, { payload }) => {
      const usersListCopy = [...state.usersList];
      const index = usersListCopy.findIndex((i) => i.id === payload.data.id);

      if (index !== -1) {
        usersListCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingUser: false,
        isUpdatedUserSuccess: true,
        usersList: usersListCopy,
        userData: {},
        successMessage: payload.message,
      };
    },
    [updateUserFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingUser: false,
      isUpdatedUserFailure: true,
      errorMessage: payload,
    }),
    [bulkDeleteRolesRequest]: (state) => ({
      ...state,
      isBulkDeletedRolesSuccess: false,
      isBulkDeletedRolesFailure: false,
    }),
    [bulkDeleteRolesSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletedRolesSuccess: true,
      successMessage: payload.message,
      rolesList: state.rolesList.filter((i) => !payload.data.includes(i.id)),
    }),
    [bulkDeleteRolesFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletedRolesFailure: true,
      errorMessage: payload,
    }),
    [clearRoleData]: (state) => ({
      ...state,
      userRole: {},
    }),
    [clearUserData]: (state) => ({
      ...state,
      userData: {},
    }),
  },
  initialState
);
export default reducer;
