import { catchResponseMessages } from '../../../utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createCheckoutSessionRequest,
  createCheckoutSessionSuccess,
  createCheckoutSessionFailure,
  getProductPricesSuccess,
  getProductPricesFailure,
  getProductPricesRequest,
  retrieveCheckoutSessionSuccess,
  retrieveCheckoutSessionFailure,
  retrieveCheckoutSessionRequest,
  getCustomersSubscriptionsRequest,
  getCustomersSubscriptionsFailure,
  getCustomersSubscriptionsSuccess,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  cancelSubscriptionRequest,
  getSubscriptionDetailsRequest,
  getSubscriptionDetailsSuccess,
  getSubscriptionDetailsFailure,
  cancelSubscriptionItemRequest,
  cancelSubscriptionItemSuccess,
  cancelSubscriptionItemFailure,
  getCustomerInvoicesListSuccess,
  getCustomerInvoicesListFailure,
  getCustomerInvoicesListRequest,
  refundChargeSuccess,
  refundChargeFailure,
  refundChargeRequest,
  getRefundListSuccess,
  getRefundListFailure,
  getRefundListRequest,
  getCustomerChargesSuccess,
  getCustomerChargesFailure,
  getCustomerChargesRequest,
  changeSubscriptionDateSuccess,
  changeSubscriptionDateFailure,
  changeSubscriptionDateRequest,
  updateSubscriptionSuccess,
  updateSubscriptionFailure,
  updateSubscriptionRequest,
  sendInvoiceToCustomerSuccess,
  sendInvoiceToCustomerFailure,
  sendInvoiceToCustomerRequest,
  payInvoiceSuccess,
  payInvoiceFailure,
  payInvoiceRequest,
  createBillingWithDesiredDateSuccess,
  createBillingWithDesiredDateFailure,
  createBillingWithDesiredDateRequest,
  getDetailedProfitAndLossSuccess,
  getDetailedProfitAndLossFailure,
  getDetailedProfitAndLossRequest,
} from './actions';

import { axiosApiInstance, config } from 'custom-configs';

const URL_TENANT = `${config.TENANT}/payments`;

function* createCheckoutSession({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/create-checkout-session`, payload)
    );
    if (response.status === 200) {
      yield put(createCheckoutSessionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for checkoutSubscription, ERROR:', e);
    if (e?.response?.data) {
      yield put(createCheckoutSessionFailure(catchResponseMessages(e)));
    }
  }
}

function* getPrices({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/product-prices/${payload.productId}`)
    );
    if (response.status === 200) {
      yield put(getProductPricesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getPrices, ERROR:', e);
    if (e?.response?.data) {
      yield put(getProductPricesFailure(catchResponseMessages(e)));
    }
  }
}

function* retrieveSession({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/retrieve-session/${payload.session_id}`
      )
    );
    if (response.status === 200) {
      yield put(retrieveCheckoutSessionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for retrieveSession, ERROR:', e);
    if (e?.response?.data) {
      yield put(retrieveCheckoutSessionFailure(catchResponseMessages(e)));
    }
  }
}

function* getSubscriptions({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/subscriptions${payload}`)
    );
    if (response.status === 200) {
      yield put(getCustomersSubscriptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSubscriptions, ERROR:', e);
    if (e?.response?.data) {
      yield put(getCustomersSubscriptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* cancelSubscription({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL_TENANT}/cancel-subscription/${payload.subscriptionId}`
      )
    );
    if (response.status === 200) {
      yield put(cancelSubscriptionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for cancelSubscription, ERROR:', e);
    if (e?.response?.data) {
      yield put(cancelSubscriptionFailure(catchResponseMessages(e)));
    }
  }
}

function* getSubscriptionDetails({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/subscription-details/${payload.subscriptionId}`
      )
    );
    if (response.status === 200) {
      yield put(getSubscriptionDetailsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSubscriptionDetails, ERROR:', e);
    if (e?.response?.data) {
      yield put(getSubscriptionDetailsFailure(catchResponseMessages(e)));
    }
  }
}

function* cancelSubscriptionItem({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL_TENANT}/cancel-subscription-item/${payload.itemId}/${payload.subscriptionId}`
      )
    );
    if (response.status === 200) {
      yield put(cancelSubscriptionItemSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for cancelSubscriptionItem, ERROR:', e);
    if (e?.response?.data) {
      yield put(cancelSubscriptionItemFailure(catchResponseMessages(e)));
    }
  }
}

function* invoicesList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/invoices-list/${payload.customerId}${payload.query}`
      )
    );
    if (response.status === 200) {
      yield put(getCustomerInvoicesListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for invoicesList, ERROR:', e);
    if (e?.response?.data) {
      yield put(getCustomerInvoicesListFailure(catchResponseMessages(e)));
    }
  }
}

function* refundCharge({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL_TENANT}/refund-charge/${payload.intentId}`,
        payload.data
      )
    );
    if (response.status === 200) {
      yield put(refundChargeSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for refundCharge, ERROR:', e);
    if (e?.response?.data) {
      yield put(refundChargeFailure(catchResponseMessages(e)));
    }
  }
}

function* refundList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/refund-list/${payload.customerId}${payload.query}`
      )
    );
    if (response.status === 200) {
      yield put(getRefundListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for refundList, ERROR:', e);
    if (e?.response?.data) {
      yield put(getRefundListFailure(catchResponseMessages(e)));
    }
  }
}

function* getCharges({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/payments-list/${payload.customerId}${payload?.query}`
      )
    );
    if (response.status === 200) {
      yield put(getCustomerChargesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCharges, ERROR:', e);
    if (e?.response?.data) {
      yield put(getCustomerChargesFailure(catchResponseMessages(e)));
    }
  }
}

function* changeSubscriptionDate({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/change-subscription-date/${payload.subscriptionId}`,
        payload.data
      )
    );
    if (response.status === 200) {
      yield put(changeSubscriptionDateSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for changeSubscriptionDate, ERROR:', e);
    if (e?.response?.data) {
      yield put(changeSubscriptionDateFailure(catchResponseMessages(e)));
    }
  }
}

function* editSubscription({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/update-subscription/${payload.subscriptionId}`,
        payload.data
      )
    );
    if (response.status === 200) {
      yield put(updateSubscriptionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for editSubscription, ERROR:', e);
    if (e?.response?.data) {
      yield put(updateSubscriptionFailure(catchResponseMessages(e)));
    }
  }
}

function* sendInvoice({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL_TENANT}/send-invoice/${payload.subscriptionId}/${payload.invoiceId}`
      )
    );
    if (response.status === 200) {
      yield put(sendInvoiceToCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for sendInvoice, ERROR:', e);
    if (e?.response?.data) {
      yield put(sendInvoiceToCustomerFailure(catchResponseMessages(e)));
    }
  }
}

function* payInvoice({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL_TENANT}/pay-invoice/${payload.subscriptionId}/${payload.invoiceId}`
      )
    );
    if (response.status === 200) {
      yield put(payInvoiceSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for payInvoice, ERROR:', e);
    if (e?.response?.data) {
      yield put(payInvoiceFailure(catchResponseMessages(e)));
    }
  }
}

function* createBillingWithDesiredDate({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL_TENANT}/subscription-desired-date/${payload.subscriptionId}`,
        payload.data
      )
    );
    if (response.status === 200) {
      yield put(createBillingWithDesiredDateSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createBillingWithDesiredDate, ERROR:', e);
    if (e?.response?.data) {
      yield put(createBillingWithDesiredDateFailure(catchResponseMessages(e)));
    }
  }
}

function* getDetailedProfitLoss({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/detailed-profit-loss/${payload.customerId}/${payload.query}`
      )
    );
    if (response.status === 200) {
      yield put(getDetailedProfitAndLossSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getDetailedProfitLoss, ERROR:', e);
    if (e?.response?.data) {
      yield put(getDetailedProfitAndLossFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createCheckoutSessionRequest, createCheckoutSession);
  yield takeLatest(getProductPricesRequest, getPrices);
  yield takeLatest(retrieveCheckoutSessionRequest, retrieveSession);
  yield takeLatest(getCustomersSubscriptionsRequest, getSubscriptions);
  yield takeLatest(cancelSubscriptionRequest, cancelSubscription);
  yield takeLatest(getSubscriptionDetailsRequest, getSubscriptionDetails);
  yield takeLatest(cancelSubscriptionItemRequest, cancelSubscriptionItem);
  yield takeLatest(getCustomerInvoicesListRequest, invoicesList);
  yield takeLatest(refundChargeRequest, refundCharge);
  yield takeLatest(getRefundListRequest, refundList);
  yield takeLatest(getCustomerChargesRequest, getCharges);
  yield takeLatest(changeSubscriptionDateRequest, changeSubscriptionDate);
  yield takeLatest(updateSubscriptionRequest, editSubscription);
  yield takeLatest(sendInvoiceToCustomerRequest, sendInvoice);
  yield takeLatest(payInvoiceRequest, payInvoice);
  yield takeLatest(
    createBillingWithDesiredDateRequest,
    createBillingWithDesiredDate
  );
  yield takeLatest(getDetailedProfitAndLossRequest, getDetailedProfitLoss);
}
