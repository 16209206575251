import { axiosApiInstance, config } from 'custom-configs';

import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  updateSimCardSettingsRequest,
  updateSimCardSettingsSuccess,
  updateSimCardSettingsFailure,
  getSimCardSettingsRequest,
  getSimCardSettingsSuccess,
  getSimCardSettingsFailure,
} from './actions';

function* updateSimCardSettings({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${config.TENANT}/settings/sim-card`, payload)
    );
    if (response?.status === 200) {
      yield put(updateSimCardSettingsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateSimCardSettings, error', e);
    if (e?.response?.data) {
      yield put(updateSimCardSettingsFailure(catchResponseMessages(e)));
    }
  }
}

function* simCardSettings() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${config.TENANT}/settings/sim-card`)
    );
    if (response?.status === 200) {
      yield put(getSimCardSettingsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for simCardSettings, error', e);
    if (e?.response?.data) {
      yield put(getSimCardSettingsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(updateSimCardSettingsRequest, updateSimCardSettings);
  yield takeLatest(getSimCardSettingsRequest, simCardSettings);
}
