import { axiosApiInstance, config } from 'custom-configs';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

import {
  createPromotionFailure,
  createPromotionRequest,
  createPromotionSuccess,
  deletePromotionFailure,
  deletePromotionRequest,
  deletePromotionSuccess,
  enablePromotionFailure,
  enablePromotionRequest,
  enablePromotionSuccess,
  getPromotionsFailure,
  getPromotionsRequest,
  getPromotionsSuccess,
  updatePromotionFailure,
  updatePromotionRequest,
  updatePromotionSuccess,
} from './actions';

const URL = `${config.TENANT}/e-commerce/promotions`;

function* createPromotion({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createPromotionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createPromotion, error', e);
    if (e?.response?.data) {
      yield put(createPromotionFailure(catchResponseMessages(e)));
    }
  }
}

function* getPromotions({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getPromotionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getPromotions, error', e);
    if (e?.response?.data) {
      yield put(getPromotionsFailure(catchResponseMessages(e)));
    }
  }
}

function* deletePromotion({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deletePromotionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deletePromotion, error', e);
    if (e?.response?.data) {
      yield put(deletePromotionFailure(catchResponseMessages(e)));
    }
  }
}

function* updatePromotion({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update/${payload.id}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(updatePromotionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updatePromotion, error', e);
    if (e?.response?.data) {
      yield put(updatePromotionFailure(catchResponseMessages(e)));
    }
  }
}

function* enablePromotion({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/enable/${payload}`)
    );
    if (response?.status === 200) {
      yield put(enablePromotionSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for enablePromotion, error', e);
    if (e?.response?.data) {
      yield put(enablePromotionFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createPromotionRequest, createPromotion);
  yield takeLatest(deletePromotionRequest, deletePromotion);
  yield takeLatest(getPromotionsRequest, getPromotions);
  yield takeLatest(updatePromotionRequest, updatePromotion);
  yield takeLatest(enablePromotionRequest, enablePromotion);
}
