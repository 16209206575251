import { createAction } from 'redux-actions';

export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');

export const logoutRequest = createAction('LOGOUT_REQUEST');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutFailure = createAction('LOGOUT_FAILURE');
export const loginAsUserRequest = createAction('LOGIN_AS_USER_REQUEST');
export const loginAsUserSuccess = createAction('LOGIN_AS_USER_SUCCESS');
export const loginAsUserFailure = createAction('LOGIN_AS_USER_FAILURE');

export const loginAsAgentRequest = createAction('LOGIN_AS_AGENT_REQUEST');
export const loginAsAgentSuccess = createAction('LOGIN_AS_AGENT_SUCCESS');
export const loginAsAgentFailure = createAction('LOGIN_AS_AGENT_FAILURE');

export const forgotPasswordRequest = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordFailure = createAction('FORGOT_PASSWORD_FAILURE');

export const checkVerificationCodeRequest = createAction(
  'CHECK_VERIFICATION_CODE_REQUEST'
);
export const checkVerificationCodeSuccess = createAction(
  'CHECK_VERIFICATION_CODE_SUCCESS'
);
export const checkVerificationCodeFailure = createAction(
  'CHECK_VERIFICATION_CODE_FAILURE'
);

export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');

export const getAuthUserRequest = createAction('GET_AUTH_USER_REQUEST');
export const getAuthUserSuccess = createAction('GET_AUTH_USER_SUCCESS');
export const getAuthUserFailure = createAction('GET_AUTH_USER_FAILURE');

export const loginTenantsUsersRequest = createAction(
  'LOGIN_TENANTS_USERS_REQUEST'
);
export const loginTenantsUsersSuccess = createAction(
  'LOGIN_TENANTS_USERS_SUCCESS'
);
export const loginTenantsUsersFailure = createAction(
  'LOGIN_TENANTS_USERS_FAILURE'
);

export const updateBalance = createAction('UPDATE_BALANCE');

export const registerTenantUserRequest = createAction(
  'REGISTER_TENANT_USER_REQUEST'
);
export const registerTenantUserSuccess = createAction(
  'REGISTER_TENANT_USER_SUCCESS'
);
export const registerTenantUserFailure = createAction(
  'REGISTER_TENANT_USER_FAILURE'
);

export const resendCodeRequest = createAction('RESEND_CODE_REQUEST');
export const resendCodeSuccess = createAction('RESEND_CODE_SUCCESS');
export const resendCodeFailure = createAction('RESEND_CODE_FAILURE');

export const verifyTenantUserRequest = createAction(
  'VERIFY_TENANT_USER_REQUEST'
);
export const verifyTenantUserSuccess = createAction(
  'VERIFY_TENANT_USER_SUCCESS'
);
export const verifyTenantUserFailure = createAction(
  'VERIFY_TENANT_USER_FAILURE'
);

export const loginAsCustomerRequest = createAction('LOGIN_AS_CUSTOMER_REQUEST');
export const loginAsCustomerSuccess = createAction('LOGIN_AS_CUSTOMER_SUCCESS');
export const loginAsCustomerFailure = createAction('LOGIN_AS_CUSTOMER_FAILURE');
