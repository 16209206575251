import { createAction } from 'redux-actions';

export const getMetricsRequest = createAction('GET_METRICS_REQUEST');
export const getMetricsSuccess = createAction('GET_METRICS_SUCCESS');
export const getMetricsFailure = createAction('GET_METRICS_FAILURE');
export const getMetricsTotalRequest = createAction(
  'GET_METRICS_HISTORY_REQUEST'
);
export const getMetricsTotalSuccess = createAction(
  'GET_METRICS_HISTORY_SUCCESS'
);
export const getMetricsTotalFailure = createAction(
  'GET_METRICS_HISTORY_FAILURE'
);

export const getPlansStatisticsRequest = createAction(
  'GET_PLANS_STATISTICS_REQUEST'
);
export const getPlansStatisticsSuccess = createAction(
  'GET_PLANS_STATISTICS_SUCCESS'
);
export const getPlansStatisticsFailure = createAction(
  'GET_PLANS_STATISTICS_FAILURE'
);

export const getProfitLossChartRequest = createAction(
  'GET_PROFIT_LOSS_CHART_REQUEST'
);
export const getProfitLossChartSuccess = createAction(
  'GET_PROFIT_LOSS_CHART_SUCCESS'
);
export const getProfitLossChartFailure = createAction(
  'GET_PROFIT_LOSS_CHART_FAILURE'
);
