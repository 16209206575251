import { handleActions } from 'redux-actions';

import {
  createTenantRequest,
  createTenantSuccess,
  createTenantFailure,
  getTenantsListRequest,
  getTenantsListSuccess,
  getTenantsListFailure,
  toggleTenantRequest,
  toggleTenantSuccess,
  toggleTenantFailure,
  deleteTenantRequest,
  deleteTenantSuccess,
  deleteTenantFailure,
  findOneTenantRequest,
  findOneTenantSuccess,
  findOneTenantFailure,
  updateTenantRequest,
  updateTenantSuccess,
  updateTenantFailure,
  clearTenantData,
  bulkDeleteTenantsRequest,
  bulkDeleteTenantsSuccess,
  bulkDeleteTenantsFailure,
  createFromAddressRequest,
  createFromAddressSuccess,
  createFromAddressFailure,
  getFromAddressListRequest,
  getFromAddressListSuccess,
  getFromAddressListFailure,
  updateFromAddressRequest,
  updateFromAddressSuccess,
  updateFromAddressFailure,
  makeDefaultAddressRequest,
  makeDefaultAddressSuccess,
  makeDefaultAddressFailure,
  deleteFromAddressRequest,
  deleteFromAddressSuccess,
  deleteFromAddressFailure,
  getAddressSelectDataRequest,
  getAddressSelectDataSuccess,
  getAddressSelectDataFailure,
} from './actions';

const initialState = {
  isCreatingTenant: false,
  isCreatedTenantSuccess: false,
  isCreatedTenantFailure: false,
  successMessage: '',
  errorMessage: '',
  isGettingTenantsList: true,
  isGettingTenantsListSuccess: false,
  isGettingTenantsListFailure: false,
  tenantsList: [],
  tenantsCount: 0,
  isToggledFailure: false,
  isDeletingTenant: false,
  isDeletingTenantSuccess: false,
  isDeletingTenantFailure: false,
  findOneTenantFailure: false,
  tenant: {},
  isUpdatingTenant: false,
  isUpdatingTenantSuccess: false,
  isUpdatingTenantFailure: false,
  isToggledSuccess: false,
  isBulkDeletingTenants: false,
  isBulkDeletingTenantsSuccess: false,
  isBulkDeletingTenantsFailure: false,
  isGettingTenant: false,
  isCreatingFromAddress: false,
  isCreatingFromAddressSuccess: false,
  isCreatingFromAddressFailure: false,
  fromAddresses: [],
  fromAddressesCount: 0,
  isGettingFromAddressList: true,
  isUpdatingFromAddress: false,
  isUpdatingFromAddressSuccess: false,
  isUpdatingFromAddressFailure: false,
  isDefaultAddressRequest: false,
  isDefaultAddressRequestSuccess: false,
  isDefaultAddressRequestFailure: false,
  isDeletingFromAddress: false,
  isDeletingFromAddressSuccess: false,
  isDeletingFromAddressFailure: false,
  addressSelectData: [],
  isGettingAddressSelectData: false,
};

const reducer = handleActions(
  {
    [createTenantRequest]: (state) => ({
      ...state,
      isCreatingTenant: true,
      isCreatedTenantSuccess: false,
      isCreatedTenantFailure: false,
    }),
    [createTenantSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingTenant: false,
      isCreatedTenantSuccess: true,
      successMessage: payload.message,
    }),
    [createTenantFailure]: (state, { payload }) => ({
      ...state,
      isCreatingTenant: false,
      isCreatedTenantFailure: true,
      errorMessage: payload,
    }),
    [getTenantsListRequest]: (state) => ({
      ...state,
      isGettingTenantsList: true,
      isGettingTenantsListSuccess: false,
      isGettingTenantsListFailure: false,
    }),
    [getTenantsListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTenantsList: false,
      isGettingTenantsListSuccess: true,
      tenantsList: payload.data.rows,
      tenantsCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getTenantsListFailure]: (state, { payload }) => ({
      ...state,
      isGettingTenantsList: false,
      isGettingTenantsListFailure: true,
      errorMessage: payload,
    }),
    [toggleTenantRequest]: (state) => ({
      ...state,
      isToggledFailure: false,
      isToggledSuccess: false,
    }),
    [toggleTenantSuccess]: (state, { payload }) => {
      const tenantsCopy = [...state.tenantsList];
      const findIndex = tenantsCopy.findIndex((i) => i.id === payload.data.id);
      if (findIndex !== -1) {
        tenantsCopy[findIndex] = payload.data;
      }
      return {
        ...state,
        successMessage: payload.message,
        tenantsList: tenantsCopy,
        isToggledSuccess: true,
      };
    },
    [toggleTenantFailure]: (state, { payload }) => ({
      ...state,
      isToggledFailure: true,
      errorMessage: payload,
    }),
    [deleteTenantRequest]: (state) => ({
      ...state,
      isDeletingTenant: true,
      isDeletingTenantSuccess: false,
      isDeletingTenantFailure: false,
    }),
    [deleteTenantSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingTenant: false,
      isDeletingTenantSuccess: true,
      tenantsList: state.tenantsList.filter((i) => i.id !== +payload.id),
      successMessage: payload.message,
    }),
    [deleteTenantFailure]: (state, { payload }) => ({
      ...state,
      isDeletingTenant: false,
      isDeletingTenantFailure: true,
      errorMessage: payload,
    }),
    [findOneTenantRequest]: (state) => ({
      ...state,
      isGettingTenant: true,
      findOneTenantFailure: false,
    }),
    [findOneTenantSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTenant: false,
      findOneTenantFailure: false,
      tenant: payload.data,
      successMessage: payload.message,
    }),
    [findOneTenantFailure]: (state, { payload }) => ({
      ...state,
      isGettingTenant: false,
      findOneTenantFailure: true,
      errorMessage: payload,
    }),
    [updateTenantRequest]: (state) => ({
      ...state,
      isUpdatingTenant: true,
      isUpdatingTenantSuccess: false,
      isUpdatingTenantFailure: false,
    }),
    [updateTenantSuccess]: (state, { payload }) => {
      const tenantsListCopy = [...state.tenantsList];
      const index = tenantsListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        tenantsListCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingTenant: false,
        isUpdatingTenantSuccess: true,
        isUpdatingTenantFailure: false,
        successMessage: payload.message,
        tenantsList: tenantsListCopy,
        tenantsCount: state.tenantsCount + 1,
      };
    },
    [updateTenantFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingTenant: false,
      isUpdatingTenantSuccess: false,
      isUpdatingTenantFailure: true,
      errorMessage: payload,
    }),
    [clearTenantData]: (state) => ({
      ...state,
      tenant: {},
    }),
    [bulkDeleteTenantsRequest]: (state) => ({
      ...state,
      isBulkDeletingTenants: true,
      isBulkDeletingTenantsSuccess: false,
      isBulkDeletingTenantsFailure: false,
    }),
    [bulkDeleteTenantsSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletingTenants: false,
      isBulkDeletingTenantsSuccess: true,
      successMessage: payload.message,
      tenantsList: state.tenantsList.filter(
        (i) => !payload.data.includes(i.id)
      ),
      tenantsCount: state.tenantsCount - payload?.data.length,
    }),
    [bulkDeleteTenantsFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletingTenants: false,
      isBulkDeletingTenantsFailure: true,
      errorMessage: payload,
    }),
    [createFromAddressRequest]: (state) => ({
      ...state,
      isCreatingFromAddress: true,
      isCreatingFromAddressSuccess: false,
      isCreatingFromAddressFailure: false,
    }),
    [createFromAddressSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingFromAddress: false,
      isCreatingFromAddressSuccess: true,
      successMessage: payload.message,
      fromAddresses: [...state.fromAddresses, payload.data],
    }),
    [createFromAddressFailure]: (state, { payload }) => ({
      ...state,
      isCreatingFromAddress: false,
      isCreatingFromAddressFailure: true,
      errorMessage: payload,
    }),
    [getFromAddressListRequest]: (state) => ({
      ...state,
      isGettingFromAddressList: true,
    }),
    [getFromAddressListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingFromAddressList: false,
      fromAddresses: payload.data.rows,
      fromAddressesCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getFromAddressListFailure]: (state, { payload }) => ({
      ...state,
      isGettingFromAddressList: false,
      errorMessage: payload,
    }),
    [updateFromAddressRequest]: (state) => ({
      ...state,
      isUpdatingFromAddress: true,
      isUpdatingFromAddressSuccess: false,
      isUpdatingFromAddressFailure: false,
    }),
    [updateFromAddressSuccess]: (state, { payload }) => {
      const addressesCopy = [...state.fromAddresses];
      const index = addressesCopy.findIndex(
        (address) => address.id === payload.data.id
      );
      if (index !== -1) {
        addressesCopy[index] = payload.data;
      }

      return {
        ...state,
        isUpdatingFromAddress: false,
        isUpdatingFromAddressSuccess: true,
        successMessage: payload.message,
        fromAddresses: addressesCopy,
      };
    },
    [updateFromAddressFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingFromAddress: false,
      isUpdatingFromAddressFailure: true,
      errorMessage: payload,
    }),
    [makeDefaultAddressRequest]: (state) => ({
      ...state,
      isDefaultAddressRequest: true,
      isDefaultAddressRequestSuccess: false,
      isDefaultAddressRequestFailure: false,
    }),
    [makeDefaultAddressSuccess]: (state, { payload }) => ({
      ...state,
      isDefaultAddressRequest: false,
      isDefaultAddressRequestSuccess: true,
      successMessage: payload.message,
      fromAddresses: state.fromAddresses.map((address) => {
        const exists = address.id === payload.data.id;
        address.isDefault = !!exists;
        return address;
      }),
    }),
    [makeDefaultAddressFailure]: (state, { payload }) => ({
      ...state,
      isDefaultAddressRequest: false,
      isDefaultAddressRequestFailure: true,
      errorMessage: payload,
    }),
    [deleteFromAddressRequest]: (state) => ({
      ...state,
      isDeletingFromAddress: true,
      isDeletingFromAddressSuccess: false,
      isDeletingFromAddressFailure: false,
    }),
    [deleteFromAddressSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingFromAddress: false,
      isDeletingFromAddressSuccess: true,
      successMessage: payload.message,
      fromAddresses: state.fromAddresses.filter(
        (address) => address.id !== payload.data
      ),
    }),
    [deleteFromAddressFailure]: (state, { payload }) => ({
      ...state,
      isDeletingFromAddress: false,
      isDeletingFromAddressFailure: true,
      errorMessage: payload,
    }),
    [getAddressSelectDataRequest]: (state) => ({
      ...state,
      isGettingAddressSelectData: true,
    }),
    [getAddressSelectDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAddressSelectData: false,
      addressSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getAddressSelectDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingAddressSelectData: false,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
