import { handleActions } from 'redux-actions';

import {
  createVendorFailure,
  createVendorRequest,
  createVendorSuccess,
  getVendorsListFailure,
  getVendorsListRequest,
  getVendorsListSuccess,
  handleVendorData,
  updateVendorFailure,
  updateVendorRequest,
  updateVendorSuccess,
  deleteVendorRequest,
  deleteVendorSuccess,
  deleteVendorFailure,
} from './actions';

const initialState = {
  vendorsList: [],
  vendorsCount: 0,
  isCreatingVendor: false,
  isCreatingVendorSuccess: false,
  isCreatingVendorFailure: false,
  isGettingVendorsList: true,
  isGettingVendorsListFailure: false,
  vendorData: {},
  isUpdatingVendor: false,
  isUpdatingVendorSuccess: false,
  isUpdatingVendorFailure: false,
  isDeleteVendor: false,
  isDeleteVendorSuccess: false,
  isDeleteVendorFailure: false,
  errorMessage: '',
  successMessage: '',
};

const reducer = handleActions(
  {
    [createVendorRequest]: (state) => ({
      ...state,
      isCreatingVendor: true,
      isCreatingVendorSuccess: false,
      isCreatingVendorFailure: false,
    }),
    [createVendorSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingVendor: false,
      isCreatingVendorSuccess: true,
      successMessage: payload.message,
      vendorsList: [payload.data, ...state.vendorsList],
      vendorsCount: state.vendorsCount + 1,
    }),
    [createVendorFailure]: (state, { payload }) => ({
      ...state,
      isCreatingVendor: false,
      isCreatingVendorFailure: true,
      errorMessage: payload,
    }),
    [getVendorsListRequest]: (state) => ({
      ...state,
      isGettingVendorsList: true,
      isGettingVendorsListFailure: false,
    }),
    [getVendorsListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingVendorsList: false,
      successMessage: payload.message,
      vendorsList: payload.data.rows,
      vendorsCount: payload.data.count,
    }),
    [getVendorsListFailure]: (state, { payload }) => ({
      ...state,
      isGettingVendorsList: false,
      isGettingVendorsListFailure: true,
      errorMessage: payload,
    }),
    [handleVendorData]: (state, { payload }) => ({
      ...state,
      vendorData: payload,
    }),
    [updateVendorRequest]: (state) => ({
      ...state,
      isUpdatingVendor: true,
      isUpdatingVendorSuccess: false,
      isUpdatingVendorFailure: false,
    }),
    [updateVendorSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingVendor: false,
      isUpdatingVendorSuccess: true,
      successMessage: payload.message,
      vendorsList: state.vendorsList.map((i) => {
        if (i.id === payload.data.id) {
          return { ...i, ...payload.data };
        } else {
          return i;
        }
      }),
    }),
    [updateVendorFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingVendor: false,
      isUpdatingVendorFailure: true,
      errorMessage: payload,
    }),
    [deleteVendorRequest]: (state) => ({
      ...state,
      isDeleteVendor: true,
      isDeleteVendorSuccess: false,
      isDeleteVendorFailure: false,
    }),
    [deleteVendorSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteVendor: false,
      isDeleteVendorSuccess: true,
      successMessage: payload.message,
      vendorsList: state.vendorsList.filter((i) => i.id !== +payload.data),
    }),
    [deleteVendorFailure]: (state, { payload }) => ({
      ...state,
      isDeleteVendor: false,
      isDeleteVendorFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
