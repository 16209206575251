import { handleActions } from 'redux-actions';
import {
  updateSimCardSettingsRequest,
  updateSimCardSettingsFailure,
  updateSimCardSettingsSuccess,
  getSimCardSettingsRequest,
  getSimCardSettingsSuccess,
  getSimCardSettingsFailure,
} from './actions';

const initialState = {
  successMessage: '',
  errorMessage: '',
  isUpdatingSimCardSettings: false,
  isUpdatingSimCardSettingsSuccess: false,
  isUpdatingSimCardSettingsFailure: false,
  isGettingSimCardSettings: true,
  isGettingSimCardSettingsSuccess: false,
  isGettingSimCardSettingsFailure: false,
  simCardSettings: {},
};

const reducer = handleActions(
  {
    [updateSimCardSettingsRequest]: (state) => ({
      ...state,
      isUpdatingSimCardSettings: true,
      isUpdatingSimCardSettingsSuccess: false,
      isUpdatingSimCardSettingsFailure: false,
    }),
    [updateSimCardSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingSimCardSettings: false,
      isUpdatingSimCardSettingsSuccess: true,
      successMessage: payload.message,
      simCardSettings: payload.data,
    }),
    [updateSimCardSettingsFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingSimCardSettings: false,
      isUpdatingSimCardSettingsFailure: true,
      errorMessage: payload,
    }),
    [getSimCardSettingsRequest]: (state) => ({
      ...state,
      isGettingSimCardSettings: true,
      isGettingSimCardSettingsSuccess: false,
      isGettingSimCardSettingsFailure: false,
    }),
    [getSimCardSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSimCardSettings: false,
      isGettingSimCardSettingsSuccess: true,
      simCardSettings: payload.data,
      successMessage: payload.message,
    }),
    [getSimCardSettingsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSimCardSettings: false,
      isGettingSimCardSettingsFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
