import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getDefaultPathByRole, isUserLoggedIn } from 'utils/methods';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isLoggedIn } = isUserLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(
        location?.state?.from ? location?.state?.from : getDefaultPathByRole(),
        {
          state: {
            from: '',
          },
          replace: true,
        }
      );
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
