import { handleActions } from 'redux-actions';

import {
  createCategoryFailure,
  createCategoryRequest,
  createCategorySuccess,
  deleteCategoryFailure,
  deleteCategoryRequest,
  deleteCategorySuccess,
  getCategoryListFailure,
  getCategoryListRequest,
  getCategoryListSuccess,
  handleCategoryData,
  updateCategoryFailure,
  updateCategoryRequest,
  updateCategorySuccess,
} from './actions';

const initialState = {
  isCreatingCategory: false,
  isCreatingCategorySuccess: false,
  isCreatingCategoryFailure: false,
  categoriesList: [],
  categoriesCount: 0,
  isGettingCategoryList: true,
  isUpdatingCategory: false,
  isUpdatingCategorySuccess: false,
  isUpdatingCategoryFailure: false,
  successMessage: '',
  errorMessage: '',
  categoryData: {},
  isDeletedCategorySuccess: false,
  isDeletedCategoryFailure: false,
};

const reducer = handleActions(
  {
    [createCategoryRequest]: (state) => ({
      ...state,
      isCreatingCategory: true,
      isCreatingCategorySuccess: false,
      isCreatingCategoryFailure: false,
    }),
    [createCategorySuccess]: (state, { payload }) => ({
      ...state,
      isCreatingCategory: false,
      isCreatingCategorySuccess: true,
      successMessage: payload.message,
      categoriesList: [payload.data, ...state.categoriesList],
      categoriesCount: state.categoriesCount + 1,
    }),
    [createCategoryFailure]: (state, { payload }) => ({
      ...state,
      isCreatingCategory: false,
      isCreatingCategoryFailure: true,
      errorMessage: payload,
    }),
    [getCategoryListRequest]: (state) => ({
      ...state,
      isGettingCategoryList: true,
    }),
    [getCategoryListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCategoryList: false,
      successMessage: payload.message,
      categoriesList: payload.data.rows,
      categoriesCount: payload.data.count,
    }),
    [getCategoryListFailure]: (state, { payload }) => ({
      ...state,
      isGettingCategoryList: false,
      errorMessage: payload,
    }),
    [updateCategoryRequest]: (state) => ({
      ...state,
      isUpdatingCategory: true,
      isUpdatingCategorySuccess: false,
      isUpdatingCategoryFailure: false,
    }),
    [updateCategorySuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingCategory: false,
      isUpdatingCategorySuccess: true,
      successMessage: payload.message,
      categoriesList: state.categoriesList.map((i) => {
        if (i.id === payload.data.id) {
          return {
            ...i,
            ...payload.data,
          };
        } else {
          return i;
        }
      }),
    }),
    [updateCategoryFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingCategory: false,
      isUpdatingCategoryFailure: true,
      errorMessage: payload,
    }),
    [handleCategoryData]: (state, { payload }) => ({
      ...state,
      categoryData: payload,
    }),
    [deleteCategoryRequest]: (state) => ({
      ...state,
      isDeletedCategorySuccess: false,
      isDeletedCategoryFailure: false,
    }),
    [deleteCategorySuccess]: (state, { payload }) => ({
      ...state,
      isDeletedCategorySuccess: true,
      successMessage: payload.message,
      categoriesList: state.categoriesList.filter((i) => i.id !== payload.data),
    }),
    [deleteCategoryFailure]: (state, { payload }) => ({
      ...state,
      isDeletedCategoryFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
