import { axiosApiInstance, config } from 'custom-configs';

import {
  getCategorySelectRequest,
  getCategorySelectSuccess,
  getCategorySelectFailure,
  getManufacturerSelectSuccess,
  getManufacturerSelectFailure,
  getManufacturerSelectRequest,
  getVendorSelectSuccess,
  getVendorSelectFailure,
  getVendorSelectRequest,
  createDeviceSuccess,
  createDeviceRequest,
  createDeviceFailure,
  getDevicesListSuccess,
  getDevicesListFailure,
  getDevicesListRequest,
  findDeviceRequest,
  findDeviceSuccess,
  removeFilesSuccess,
  removeFilesFailure,
  removeFilesRequest,
  updateDeviceSuccess,
  updateDeviceFailure,
  updateDeviceRequest,
  deleteVariantSuccess,
  deleteVariantFailure,
  deleteVariantRequest,
  deleteVariantFileSuccess,
  deleteVariantFileFailure,
  deleteVariantFileRequest,
  createAccessoriesRequest,
  createAccessoriesFailure,
  createAccessoriesSuccess,
  getAccessoriesListSuccess,
  getAccessoriesListFailure,
  getAccessoriesListRequest,
  findAccessoryDataRequest,
  findAccessoryDataFailure,
  findAccessoryDataSuccess,
  updateAccessorySuccess,
  updateAccessoryFailure,
  updateAccessoryRequest,
  deleteProductSuccess,
  deleteProductFailure,
  deleteProductRequest,
  getDevicesSelectDataSuccess,
  getDevicesSelectDataFailure,
  getDevicesSelectDataRequest,
  getVariantsSelectDataSuccess,
  getVariantsSelectDataFailure,
  getVariantsSelectDataRequest,
  getUsedCategoriesSelectRequest,
  getUsedCategoriesSelectSuccess,
  getUsedCategoriesSelectFailure,
  duplicateDeviceSuccess,
  duplicateDeviceFailure,
  duplicateDeviceRequest,
  makeImageMainSuccess,
  makeImageMainFailure,
  makeImageMainRequest,
  getAllVariantsSelectOptionsSuccess,
  getAllVariantsSelectOptionsFailure,
  getAllVariantsSelectOptionsRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL = `${config.TENANT}/e-commerce/products`;

function* getCategorySelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/category-select`)
    );
    if (response?.status === 200) {
      yield put(getCategorySelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCategorySelect, error', e);
    if (e?.response?.data) {
      yield put(getCategorySelectFailure(catchResponseMessages(e)));
    }
  }
}
function* getManufacturerSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/manufacturer-select`)
    );
    if (response?.status === 200) {
      yield put(getManufacturerSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getManufacturerSelect, error', e);
    if (e?.response?.data) {
      yield put(getManufacturerSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* getVendorSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/vendor-select`)
    );
    if (response?.status === 200) {
      yield put(getVendorSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getVendorSelect, error', e);
    if (e?.response?.data) {
      yield put(getVendorSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* createDevice({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createDeviceSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createDevice, error', e);
    if (e?.response?.data) {
      yield put(createDeviceFailure(catchResponseMessages(e)));
    }
  }
}

function* devicesList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getDevicesListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for devicesList, error', e);
    if (e?.response?.data) {
      yield put(getDevicesListFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteProduct({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteProductSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteProduct, error', e);
    if (e?.response?.data) {
      yield put(deleteProductFailure(catchResponseMessages(e)));
    }
  }
}

function* findDevice({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findDeviceSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findDevice, error', e);
    if (e?.response?.data) {
      yield put(findDeviceSuccess(catchResponseMessages(e)));
    }
  }
}

function* removeFiles({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/remove-files`, payload)
    );
    if (response?.status === 200) {
      yield put(removeFilesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for removeFiles, error', e);
    if (e?.response?.data) {
      yield put(removeFilesFailure(catchResponseMessages(e)));
    }
  }
}

function* updateDevice({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateDeviceSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateDevice, error', e);
    if (e?.response?.data) {
      yield put(updateDeviceFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteVariant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/delete-variant/${payload.productId}/${payload.id}`
      )
    );
    if (response?.status === 200) {
      yield put(deleteVariantSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteVariant, error', e);
    if (e?.response?.data) {
      yield put(deleteVariantFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteVariantFile({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/delete-variant-file`, payload)
    );
    if (response?.status === 200) {
      yield put(deleteVariantFileSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteVariantFile, error', e);
    if (e?.response?.data) {
      yield put(deleteVariantFileFailure(catchResponseMessages(e)));
    }
  }
}

function* createAccessory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/accessory-create`, payload)
    );
    if (response?.status === 200) {
      yield put(createAccessoriesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createAccessory, error', e);
    if (e?.response?.data) {
      yield put(createAccessoriesFailure(catchResponseMessages(e)));
    }
  }
}

function* accessoriesList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getAccessoriesListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for accessoriesList, error', e);
    if (e?.response?.data) {
      yield put(getAccessoriesListFailure(catchResponseMessages(e)));
    }
  }
}

function* findAccessoryData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findAccessoryDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findAccessoryData, error', e);
    if (e?.response?.data) {
      yield put(findAccessoryDataFailure(catchResponseMessages(e)));
    }
  }
}

function* updateAccessory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/accessory-update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateAccessorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateAccessory, error', e);
    if (e?.response?.data) {
      yield put(updateAccessoryFailure(catchResponseMessages(e)));
    }
  }
}

function* devicesSelectData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/devices-select-data`, {
        categoryId: payload,
      })
    );
    if (response?.status === 200) {
      yield put(getDevicesSelectDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for devicesSelectData, error', e);
    if (e?.response?.data) {
      yield put(getDevicesSelectDataFailure(catchResponseMessages(e)));
    }
  }
}

function* variantsSelectData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/variants-select-data`, payload)
    );
    if (response?.status === 200) {
      yield put(getVariantsSelectDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for variantsSelectData, error', e);
    if (e?.response?.data) {
      yield put(getVariantsSelectDataFailure(catchResponseMessages(e)));
    }
  }
}

function* categorySelect({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/categories-select-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getUsedCategoriesSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for categorySelect, error', e);
    if (e?.response?.data) {
      yield put(getUsedCategoriesSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* duplicateDevice({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/device-duplicate`, payload)
    );
    if (response?.status === 200) {
      yield put(duplicateDeviceSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for duplicateDevice, error', e);
    if (e?.response?.data) {
      yield put(duplicateDeviceFailure(catchResponseMessages(e)));
    }
  }
}

function* makeMainImage({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/make-main-image/${payload.variantId}/${payload.imageId}`
      )
    );
    if (response?.status === 200) {
      yield put(makeImageMainSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for makeMainImage, error', e);
    if (e?.response?.data) {
      yield put(makeImageMainFailure(catchResponseMessages(e)));
    }
  }
}

function* allVariantsSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/all-variants-select-data`)
    );
    if (response?.status === 200) {
      yield put(getAllVariantsSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for allVariantsSelect, error', e);
    if (e?.response?.data) {
      yield put(getAllVariantsSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getCategorySelectRequest, getCategorySelect);
  yield takeLatest(getManufacturerSelectRequest, getManufacturerSelect);
  yield takeLatest(getVendorSelectRequest, getVendorSelect);
  yield takeLatest(createDeviceRequest, createDevice);
  yield takeLatest(getDevicesListRequest, devicesList);
  yield takeLatest(deleteProductRequest, deleteProduct);
  yield takeLatest(findDeviceRequest, findDevice);
  yield takeLatest(removeFilesRequest, removeFiles);
  yield takeLatest(updateDeviceRequest, updateDevice);
  yield takeLatest(deleteVariantRequest, deleteVariant);
  yield takeLatest(deleteVariantFileRequest, deleteVariantFile);
  yield takeLatest(createAccessoriesRequest, createAccessory);
  yield takeLatest(getAccessoriesListRequest, accessoriesList);
  yield takeLatest(findAccessoryDataRequest, findAccessoryData);
  yield takeLatest(updateAccessoryRequest, updateAccessory);
  yield takeLatest(getDevicesSelectDataRequest, devicesSelectData);
  yield takeLatest(getVariantsSelectDataRequest, variantsSelectData);
  yield takeLatest(getUsedCategoriesSelectRequest, categorySelect);
  yield takeLatest(duplicateDeviceRequest, duplicateDevice);
  yield takeLatest(makeImageMainRequest, makeMainImage);
  yield takeLatest(getAllVariantsSelectOptionsRequest, allVariantsSelect);
}
