import { axiosApiInstance, config } from 'custom-configs';

import {
  uploadPhoneNumbersSuccess,
  uploadPhoneNumbersFailure,
  uploadPhoneNumbersRequest,
  getPhoneNumbersSuccess,
  getPhoneNumbersFailure,
  getPhoneNumbersRequest,
  createPhoneNumberSuccess,
  createPhoneNumberFailure,
  createPhoneNumberRequest,
  editPhoneNumberSuccess,
  editPhoneNumberFailure,
  editPhoneNumberRequest,
  deletePhoneNumberSuccess,
  deletePhoneNumberFailure,
  deletePhoneNumberRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL = `${config.DOMAIN}/phone-numbers`;
const URLTenant = `${config.TENANT}/phone-numbers`;

function* uploadPhoneNumbers({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/upload`, payload)
    );
    if (response?.status === 200) {
      yield put(uploadPhoneNumbersSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for uploadPhoneNumbers, error', e);
    if (e?.response?.data) {
      yield put(uploadPhoneNumbersFailure(catchResponseMessages(e)));
    }
  }
}
function* getPhoneNumbers({ payload }) {
  try {
    const response = yield call(() => axiosApiInstance.get(`${URL}${payload}`));
    if (response?.status === 200) {
      yield put(getPhoneNumbersSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getPhoneNumbers, error', e);
    if (e?.response?.data) {
      yield put(getPhoneNumbersFailure(catchResponseMessages(e)));
    }
  }
}

function* createPhoneNumber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createPhoneNumberSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createPhoneNumber, error', e);
    if (e?.response?.data) {
      yield put(createPhoneNumberFailure(catchResponseMessages(e)));
    }
  }
}

function* editPhoneNumber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/edit/${payload.id}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(editPhoneNumberSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for editPhoneNumber, error', e);
    if (e?.response?.data) {
      yield put(editPhoneNumberFailure(catchResponseMessages(e)));
    }
  }
}

function* deletePhoneNumber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(deletePhoneNumberSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deletePhoneNumber, error', e);
    if (e?.response?.data) {
      yield put(deletePhoneNumberFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(uploadPhoneNumbersRequest, uploadPhoneNumbers);
  yield takeLatest(getPhoneNumbersRequest, getPhoneNumbers);
  yield takeLatest(createPhoneNumberRequest, createPhoneNumber);
  yield takeLatest(editPhoneNumberRequest, editPhoneNumber);
  yield takeLatest(deletePhoneNumberRequest, deletePhoneNumber);
}
