import { createAction } from 'redux-actions';

export const createTicketRequest = createAction('CREATE_TICKET_REQUEST');
export const createTicketSuccess = createAction('CREATE_TICKET_SUCCESS');
export const createTicketFailure = createAction('CREATE_TICKET_FAILURE');

export const getTicketUsersListRequest = createAction(
  'GET_TICKET_USERS_LIST_REQUEST'
);
export const getTicketUsersListSuccess = createAction(
  'GET_TICKET_USERS_LIST_SUCCESS'
);
export const getTicketUsersListFailure = createAction(
  'GET_TICKET_USERS_LIST_FAILURE'
);

export const getTicketsListRequest = createAction('GET_TICKETS_LIST_REQUEST');
export const getTicketsListSuccess = createAction('GET_TICKETS_LIST_SUCCESS');
export const getTicketsListFailure = createAction('GET_TICKETS_LIST_FAILURE');

export const getTicketRequest = createAction('GET_TICKET_REQUEST');
export const getTicketSuccess = createAction('GET_TICKET_SUCCESS');
export const getTicketFailure = createAction('GET_TICKET_FAILURE');

export const updateTicketStatusRequest = createAction(
  'UPDATE_TICKET_STATUS_REQUEST'
);
export const updateTicketStatusSuccess = createAction(
  'UPDATE_TICKET_STATUS_SUCCESS'
);
export const updateTicketStatusFailure = createAction(
  'UPDATE_TICKET_STATUS_FAILURE'
);

export const deleteTicketRequest = createAction('DELETE_TICKET_REQUEST');
export const deleteTicketSuccess = createAction('DELETE_TICKET_SUCCESS');
export const deleteTicketFailure = createAction('DELETE_TICKET_FAILURE');

export const updateTicketRequest = createAction('UPDATE_TICKET_REQUEST');
export const updateTicketSuccess = createAction('UPDATE_TICKET_SUCCESS');
export const updateTicketFailure = createAction('UPDATE_TICKET_FAILURE');

export const bulkDeleteTicketsRequest = createAction(
  'BULK_DELETE_TICKETS_REQUEST'
);
export const bulkDeleteTicketsSuccess = createAction(
  'BULK_DELETE_TICKETS_SUCCESS'
);
export const bulkDeleteTicketsFailure = createAction(
  'BULK_DELETE_TICKETS_FAILURE'
);

export const createTicketColumnRequest = createAction(
  'CREATE_TICKET_COLUMN_REQUEST'
);
export const createTicketColumnSuccess = createAction(
  'CREATE_TICKET_COLUMN_SUCCESS'
);
export const createTicketColumnFailure = createAction(
  'CREATE_TICKET_COLUMN_FAILURE'
);

export const getTicketsColumnsRequest = createAction(
  'GET_TICKETS_COLUMNS_REQUEST'
);

export const getTicketsColumnsSuccess = createAction(
  'GET_TICKETS_COLUMNS_SUCCESS'
);

export const getTicketsColumnsFailure = createAction(
  'GET_TICKETS_COLUMNS_FAILURE'
);

export const updateTicketColumnRequest = createAction(
  'UPDATE_TICKET_COLUMN_REQUEST'
);

export const updateTicketColumnSuccess = createAction(
  'UPDATE_TICKET_COLUMN_SUCCESS'
);

export const updateTicketColumnFailure = createAction(
  'UPDATE_TICKET_COLUMN_FAILURE'
);

export const deleteColumnRequest = createAction('DELETE_COLUMN_REQUEST');
export const deleteColumnSuccess = createAction('DELETE_COLUMN_SUCCESS');
export const deleteColumnFailure = createAction('DELETE_COLUMN_FAILURE');

export const updateColumnsOrder = createAction('UPDATE_COLUMNS_ORDER');

export const removeTicketFilesRequest = createAction(
  'REMOVE_TICKET_FILES_REQUEST'
);

export const removeTicketFilesSuccess = createAction(
  'REMOVE_TICKET_FILES_SUCCESS'
);

export const removeTicketFilesFailure = createAction(
  'REMOVE_TICKET_FILES_FAILURE'
);

export const addCommentRequest = createAction('ADD_COMMENT_REQUEST');
export const addCommentSuccess = createAction('ADD_COMMENT_SUCCESS');
export const addCommentFailure = createAction('ADD_COMMENT_FAILURE');

export const deleteCommentRequest = createAction('DELETE_COMMENT_REQUEST');
export const deleteCommentSuccess = createAction('DELETE_COMMENT_SUCCESS');
export const deleteCommentFailure = createAction('DELETE_COMMENT_FAILURE');

export const handleTicketData = createAction('HANDLE_TICKET_DATA');

export const createTicketGroupRequest = createAction(
  'CREATE_TICKET_GROUP_REQUEST'
);
export const createTicketGroupSuccess = createAction(
  'CREATE_TICKET_GROUP_SUCCESS'
);
export const createTicketGroupFailure = createAction(
  'CREATE_TICKET_GROUP_FAILURE'
);

export const getTicketGroupListRequest = createAction(
  'GET_TICKET_GROUP_LIST_REQUEST'
);
export const getTicketGroupListSuccess = createAction(
  'GET_TICKET_GROUP_LIST_SUCCESS'
);
export const getTicketGroupListFailure = createAction(
  'GET_TICKET_GROUP_LIST_FAILURE'
);

export const deleteTicketGroupRequest = createAction(
  'DELETE_TICKET_GROUP_REQUEST'
);
export const deleteTicketGroupSuccess = createAction(
  'DELETE_TICKET_GROUP_SUCCESS'
);
export const deleteTicketGroupFailure = createAction(
  'DELETE_TICKET_GROUP_FAILURE'
);

export const bulkDeleteTicketGroupsRequest = createAction(
  'BULK_DELETE_TICKET_GROUPS_REQUEST'
);
export const bulkDeleteTicketGroupsSuccess = createAction(
  'BULK_DELETE_TICKET_GROUPS_SUCCESS'
);
export const bulkDeleteTicketGroupsFailure = createAction(
  'BULK_DELETE_TICKET_GROUPS_FAILURE'
);

export const updateTicketGroupRequest = createAction(
  'UPDATE_TICKET_GROUP_REQUEST'
);
export const updateTicketGroupSuccess = createAction(
  'UPDATE_TICKET_GROUP_SUCCESS'
);
export const updateTicketGroupFailure = createAction(
  'UPDATE_TICKET_GROUP_FAILURE'
);

export const getTicketGroupSelectRequest = createAction(
  'GET_TICKET_GROUP_SELECT_REQUEST'
);
export const getTicketGroupSelectSuccess = createAction(
  'GET_TICKET_GROUP_SELECT_SUCCESS'
);
export const getTicketGroupSelectFailure = createAction(
  'GET_TICKET_GROUP_SELECT_FAILURE'
);

export const assignTicketToUserRequest = createAction(
  'ASSIGN_TICKET_TO_USER_REQUEST'
);
export const assignTicketToUserSuccess = createAction(
  'ASSIGN_TICKET_TO_USER_SUCCESS'
);
export const assignTicketToUserFailure = createAction(
  'ASSIGN_TICKET_TO_USER_FAILURE'
);

export const handleTicketGroupData = createAction('HANDLE_TICKET_GROUP_DATA');

export const getCustomersNamesRequest = createAction(
  'GET_CUSTOMERS_NAMES_REQUEST'
);

export const getCustomersNamesSuccess = createAction(
  'GET_CUSTOMERS_NAMES_SUCCESS'
);

export const getCustomersNamesFailure = createAction(
  'GET_CUSTOMERS_NAMES_FAILURE'
);

export const makeCommentsSeenRequest = createAction(
  'MAKE_COMMENTS_SEEN_REQUEST'
);
export const makeCommentsSeenSuccess = createAction(
  'MAKE_COMMENTS_SEEN_SUCCESS'
);
export const makeCommentsSeenFailure = createAction(
  'MAKE_COMMENTS_SEEN_FAILURE'
);

export const getTicketCommentsRequest = createAction(
  'GET_TICKET_COMMENTS_REQUEST'
);
export const getTicketCommentsSuccess = createAction(
  'GET_TICKET_COMMENTS_SUCCESS'
);
export const getTicketCommentsFailure = createAction(
  'GET_TICKET_COMMENTS_FAILURE'
);
