import { axiosApiInstance, config } from 'custom-configs';

import {
  getAcpDevicesRequest,
  getAcpDevicesSuccess,
  getAcpDevicesFailure,
  addToCartRequest,
  addToCartSuccess,
  addToCartFailure,
  updateProductCartRequest,
  updateProductCartSuccess,
  updateProductCartFailure,
  deleteFromCartRequest,
  deleteFromCartSuccess,
  deleteFromCartFailure,
  getCartDataRequest,
  getCartDataSuccess,
  getCartDataFailure,
  getShippingAddressesRequest,
  getShippingAddressesSuccess,
  getShippingAddressesFailure,
  getPlansRequest,
  getPlansSuccess,
  getPlansFailure,
  selectPlanRequest,
  selectPlanSuccess,
  selectPlanFailure,
  checkPhoneNumberRequest,
  checkPhoneNumberSuccess,
  checkPhoneNumberFailure,
  portInPhoneNumberRequest,
  portInPhoneNumberSuccess,
  portInPhoneNumberFailure,
  createCustomerOrderRequest,
  createCustomerOrderSuccess,
  createCustomerOrderFailure,
  createHandoverOrderRequest,
  createHandoverOrderSuccess,
  createHandoverOrderFailure,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';
import { makeSearchQuery } from 'utils/constants';

const URL = `${config.TENANT}/e-commerce`;

function* getAcpDevices() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/shop/get-acp-devices`)
    );

    if (response?.status === 200) {
      yield put(getAcpDevicesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getAcpDevices, error`, e);
    if (e?.response?.data) {
      yield put(getAcpDevicesFailure(catchResponseMessages(e)));
    }
  }
}

function* addToCart({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/shop/add-to-cart`, payload)
    );

    if (response?.status === 200) {
      yield put(addToCartSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for addToCart, error', e);
    if (e?.response?.data) {
      yield put(addToCartFailure(catchResponseMessages(e)));
    }
  }
}

function* updateProductCart({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/shop/update-product-cart`, payload)
    );

    if (response?.status === 200) {
      yield put(updateProductCartSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateProductCart, error', e);
    if (e?.response?.data) {
      yield put(updateProductCartFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteFromCart({ payload }) {
  try {
    const { id, ...rest } = payload;
    const query = makeSearchQuery(rest);

    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/shop/delete-from-cart/${id}${query}`)
    );

    if (response?.status === 200) {
      yield put(deleteFromCartSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for removeFromCart, error', e);
    if (e?.response?.data) {
      yield put(deleteFromCartFailure(catchResponseMessages(e)));
    }
  }
}

function* getCartData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/shop/cart-data/${payload.customerId}${payload?.query || ''}`
      )
    );

    if (response?.status === 200) {
      yield put(getCartDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCartData, error', e);
    if (e?.response?.data) {
      yield put(getCartDataFailure(catchResponseMessages(e)));
    }
  }
}

function* getShippingAddresses({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/orders/validate-address`, payload)
    );

    if (response?.status === 200) {
      yield put(
        getShippingAddressesSuccess({ response: response.data, data: payload })
      );
    }
  } catch (e) {
    console.log('Catch for getShippingAddresses, error', e);
    if (e?.response?.data) {
      yield put(getShippingAddressesFailure(catchResponseMessages(e)));
    }
  }
}

function* getPlans({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/shop/plan-data?apsType=${payload}`)
    );

    if (response?.status === 200) {
      yield put(getPlansSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getPlans, error', e);
    if (e?.response?.data) {
      yield put(getPlansFailure(catchResponseMessages(e)));
    }
  }
}

function* selectPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/shop/select-plan`, payload)
    );

    if (response?.status === 200) {
      yield put(selectPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for selectPlan, error', e);
    if (e?.response?.data) {
      yield put(selectPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* checkPhoneNumber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${config.TENANT}/carriers/validate-port`, payload)
    );

    if (response?.status === 200) {
      yield put(checkPhoneNumberSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for checkPhoneNumber, error`, e);
    if (e?.response?.data) {
      yield put(checkPhoneNumberFailure(catchResponseMessages(e)));
    }
  }
}

function* portInPhoneNumber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${config.TENANT}/carriers/portin-mdn-msisdn`,
        payload
      )
    );

    if (response?.status === 200) {
      yield put(portInPhoneNumberSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for portInPhoneNumber, error`, e);
    if (e?.response?.data) {
      yield put(portInPhoneNumberFailure(catchResponseMessages(e)));
    }
  }
}

function* createCustomerOrder({ payload }) {
  try {
    const { customerId = '', ...rest } = payload;

    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/orders/create-customer-order/${customerId}`,
        rest
      )
    );

    if (response?.status === 200) {
      yield put(createCustomerOrderSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createCustomerOrder, error', e);
    if (e?.response?.data) {
      yield put(createCustomerOrderFailure(catchResponseMessages(e)));
    }
  }
}

function* createHandoverOrder({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/orders/create-handover-order`, payload)
    );

    if (response?.status === 200) {
      yield put(createHandoverOrderSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createHandoverOrder, error', e);
    if (e?.response?.data) {
      yield put(createHandoverOrderFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getAcpDevicesRequest, getAcpDevices);
  yield takeLatest(addToCartRequest, addToCart);
  yield takeLatest(updateProductCartRequest, updateProductCart);
  yield takeLatest(deleteFromCartRequest, deleteFromCart);
  yield takeLatest(getCartDataRequest, getCartData);
  yield takeLatest(getShippingAddressesRequest, getShippingAddresses);
  yield takeLatest(getPlansRequest, getPlans);
  yield takeLatest(selectPlanRequest, selectPlan);
  yield takeLatest(checkPhoneNumberRequest, checkPhoneNumber);
  yield takeLatest(portInPhoneNumberRequest, portInPhoneNumber);
  yield takeLatest(createCustomerOrderRequest, createCustomerOrder);
  yield takeLatest(createHandoverOrderRequest, createHandoverOrder);
}
