import { createAction } from 'redux-actions';

export const getAcpDevicesRequest = createAction('GET_ACP_DEVICES_REQUEST');
export const getAcpDevicesSuccess = createAction('GET_ACP_DEVICES_SUCCESS');
export const getAcpDevicesFailure = createAction('GET_ACP_DEVICES_FAILURE');

export const addToCartRequest = createAction('ADD_TO_CART_REQUEST');
export const addToCartSuccess = createAction('ADD_TO_CART_SUCCESS');
export const addToCartFailure = createAction('ADD_TO_CART_FAILURE');

export const updateProductCartRequest = createAction(
  'UPDATE_PRODUCT_CART_CART_REQUEST'
);
export const updateProductCartSuccess = createAction(
  'UPDATE_PRODUCT_CART_CART_SUCCESS'
);
export const updateProductCartFailure = createAction(
  'UPDATE_PRODUCT_CART_CART_FAILURE'
);

export const deleteFromCartRequest = createAction('DELETE_FROM_CART_REQUEST');
export const deleteFromCartSuccess = createAction('DELETE_FROM_CART_SUCCESS');
export const deleteFromCartFailure = createAction('DELETE_FROM_CART_FAILURE');

export const getCartDataRequest = createAction('GET_CART_DATA_REQUEST');
export const getCartDataSuccess = createAction('GET_CART_DATA_SUCCESS');
export const getCartDataFailure = createAction('GET_CART_DATA_FAILURE');

export const getShippingAddressesRequest = createAction(
  'GET_SHIPPING_ADDRESSES_REQUEST'
);
export const getShippingAddressesSuccess = createAction(
  'GET_SHIPPING_ADDRESSES_SUCCESS'
);
export const getShippingAddressesFailure = createAction(
  'GET_SHIPPING_ADDRESSES_FAILURE'
);

export const resetShippingAddresses = createAction(
  'RESET_SHIPPING_ADDRESSES_REQUEST'
);

export const selectShippingPriceRequest = createAction(
  'SELECT_SHIPPING_PRICE_REQUEST'
);

export const getPlansRequest = createAction('GET_PLANS_REQUEST');
export const getPlansSuccess = createAction('GET_PLANS_SUCCESS');
export const getPlansFailure = createAction('GET_PLANS_FAILURE');

export const selectPlanRequest = createAction('SELECT_PLAN_REQUEST');
export const selectPlanSuccess = createAction('SELECT_PLAN_SUCCESS');
export const selectPlanFailure = createAction('SELECT_PLAN_FAILURE');
export const removeSelectedPlan = createAction('REMOVE_SELECT_PLAN_FAILURE');

export const checkPhoneNumberRequest = createAction(
  'CHECK_PHONE_NUMBER_REQUEST'
);
export const checkPhoneNumberSuccess = createAction(
  'CHECK_PHONE_NUMBER_SUCCESS'
);
export const checkPhoneNumberFailure = createAction(
  'CHECK_PHONE_NUMBER_FAILURE'
);

export const portInPhoneNumberRequest = createAction(
  'PORT_IN_PHONE_NUMBER_REQUEST'
);
export const portInPhoneNumberSuccess = createAction(
  'PORT_IN_PHONE_NUMBER_SUCCESS'
);
export const portInPhoneNumberFailure = createAction(
  'PORT_IN_PHONE_NUMBER_FAILURE'
);

export const createCustomerOrderRequest = createAction(
  'CREATE_CUSTOMER_ORDER_REQUEST'
);
export const createCustomerOrderSuccess = createAction(
  'CREATE_CUSTOMER_ORDER_SUCCESS'
);
export const createCustomerOrderFailure = createAction(
  'CREATE_CUSTOMER_ORDER_FAILURE'
);

export const createHandoverOrderRequest = createAction(
  'CREATE_HANDOVER_ORDER_REQUEST'
);
export const createHandoverOrderSuccess = createAction(
  'CREATE_HANDOVER_ORDER_SUCCESS'
);
export const createHandoverOrderFailure = createAction(
  'CREATE_HANDOVER_ORDER_FAILURE'
);
