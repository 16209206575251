import { handleActions } from 'redux-actions';
import {
  getInventoryVariantsRequest,
  getInventoryVariantsSuccess,
  getInventoryVariantsFailure,
  findVariantRequest,
  findVariantSuccess,
  findVariantFailure,
  createInventoryRequest,
  createInventorySuccess,
  createInventoryFailure,
  getVariantInventoryListRequest,
  getVariantInventoryListSuccess,
  getVariantInventoryListFailure,
  setAvailableCountInventoryRequest,
  setAvailableCountInventorySuccess,
  setAvailableCountInventoryFailure,
  deleteInventoryRequest,
  deleteInventorySuccess,
  deleteInventoryFailure,
  deleteInventoryVariantRequest,
  deleteInventoryVariantSuccess,
  deleteInventoryVariantFailure,
  getInventoryMasterAgentSelectRequest,
  getInventoryMasterAgentSelectSuccess,
  getInventoryMasterAgentSelectFailure,
  getInventorySubAgentsSelectRequest,
  getInventorySubAgentsSelectSuccess,
  getInventorySubAgentsSelectFailure,
  clearSubAgentSelectData,
  assignInventoryToAgentsRequest,
  assignInventoryToAgentsSuccess,
  assignInventoryToAgentsFailure,
  uploadInventoryRequest,
  createAndAssignInventoryRequest,
  createAndAssignInventorySuccess,
  createAndAssignInventoryFailure,
  assignSIMCardToIMEIRequest,
  assignSIMCardToIMEISuccess,
  assignSIMCardToIMEIFailure,
  requestInventoryRequest,
  requestInventorySuccess,
  requestInventoryFailure,
  getDeviceCategorySelectRequest,
  getDeviceCategorySelectSuccess,
  getSubAgentDataRequest,
  getSubAgentDataSuccess,
  getSubAgentDataFailure,
  clearSubAgentData,
  checkDeviceIEMIRequest,
  checkDeviceIEMISuccess,
  checkDeviceIEMIFailure,
} from './actions';

const initialState = {
  isGettingInventoryVariants: false,
  inventoryVariants: [],
  inventoryVariantsCount: 0,
  isGettingVariant: false,
  variantData: {},
  successMessage: '',
  errorMessage: '',
  isCreatingInventory: false,
  isCreatingInventorySuccess: false,
  isCreatingInventoryFailure: false,
  isGettingInventoryList: false,
  inventoryList: [],
  inventoryCount: 0,
  isUpdatedAvailableCountSuccess: false,
  isUpdatedAvailableCountFailure: false,
  isDeletedInventorySuccess: false,
  isDeletedInventoryFailure: false,
  isDeleteInventoryVariantSuccess: false,
  isDeleteInventoryVariantFailure: false,
  isGettingMasterAgentSelectData: false,
  masterAgentSelectData: [],
  isGettingInventorySubAgents: false,
  subAgentSelectData: [],
  isAssignInventoryToAgentsSuccess: false,
  isAssignInventoryToAgentsFailure: false,
  isUploadingInventory: false,
  isCreateAndAssignInventoryRequest: false,
  isCreateAndAssignInventorySuccess: false,
  isCreateAndAssignInventoryFailure: false,
  totalInventoryCount: 0,
  allocatedToMasterAgent: 0,
  notAllocatedToMasterAgent: 0,
  isAssigningSIMCardToIMEI: false,
  isAssigningSIMCardToIMEISuccess: false,
  isAssigningSIMCardToIMEIFailure: false,
  isRequestingInventory: false,
  isRequestingInventorySuccess: false,
  isRequestingInventoryFailure: false,
  isGettingDeviceCategorySelectDataSuccess: false,
  deviceCategorySelectData: [],
  isGettingSubAgentData: false,
  subAgentData: {},
  isCheckingIEMI: false,
  isCheckingIEMISuccess: false,
  isCheckingIEMIFailure: false,
  IEMICheckData: {},
};

const reducer = handleActions(
  {
    [getInventoryVariantsRequest]: (state) => ({
      ...state,
      isGettingInventoryVariants: true,
    }),
    [getInventoryVariantsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInventoryVariants: false,
      successMessage: payload.message,
      inventoryVariants: payload.data.rows,
      inventoryVariantsCount: payload.data.count,
      totalInventoryCount: payload.data.totalInventoryCount,
      allocatedToMasterAgent: payload.data.allocatedToMasterAgent,
      notAllocatedToMasterAgent: payload.data.notAllocatedToMasterAgent,
    }),
    [getInventoryVariantsFailure]: (state, { payload }) => ({
      ...state,
      isGettingInventoryVariants: false,
      errorMessage: payload,
    }),
    [findVariantRequest]: (state) => ({
      ...state,
      isGettingVariant: true,
    }),
    [findVariantSuccess]: (state, { payload }) => ({
      ...state,
      isGettingVariant: false,
      variantData: payload.data,
      successMessage: payload.message,
    }),
    [findVariantFailure]: (state, { payload }) => ({
      ...state,
      isGettingVariant: false,
      errorMessage: payload,
    }),
    [createInventoryRequest]: (state) => ({
      ...state,
      isCreatingInventory: true,
      isCreatingInventorySuccess: false,
      isCreatingInventoryFailure: false,
    }),
    [createInventorySuccess]: (state, { payload }) => ({
      ...state,
      isCreatingInventory: false,
      isCreatingInventorySuccess: true,
      successMessage: payload.message,
      inventoryList: [payload.data, ...state.inventoryList],
      inventoryCount: state.inventoryCount + 1,
    }),
    [createInventoryFailure]: (state, { payload }) => ({
      ...state,
      isCreatingInventory: false,
      isCreatingInventoryFailure: true,
      errorMessage: payload,
    }),
    [getVariantInventoryListRequest]: (state) => ({
      ...state,
      isGettingInventoryList: true,
    }),
    [getVariantInventoryListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInventoryList: false,
      inventoryList: payload.data.rows,
      inventoryCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getVariantInventoryListFailure]: (state, { payload }) => ({
      ...state,
      isGettingInventoryList: false,
      errorMessage: payload,
    }),
    [setAvailableCountInventoryRequest]: (state) => ({
      ...state,
      isUpdatedAvailableCountSuccess: false,
      isUpdatedAvailableCountFailure: false,
    }),
    [setAvailableCountInventorySuccess]: (state, { payload }) => {
      const inventoriesVariantsCopy = structuredClone(state.inventoryVariants);
      const index = inventoriesVariantsCopy.findIndex(
        (i) => i.id === +payload.data.variantId
      );
      if (index !== -1) {
        inventoriesVariantsCopy[index].inventories[0] = payload.data;
      }
      return {
        ...state,
        isUpdatedAvailableCountSuccess: true,
        successMessage: payload.message,
        inventoryVariants: inventoriesVariantsCopy,
      };
    },
    [setAvailableCountInventoryFailure]: (state, { payload }) => ({
      ...state,
      isUpdatedAvailableCountFailure: true,
      errorMessage: payload,
    }),
    [deleteInventoryRequest]: (state) => ({
      ...state,
      isDeletedInventorySuccess: false,
      isDeletedInventoryFailure: false,
    }),
    [deleteInventorySuccess]: (state, { payload }) => ({
      ...state,
      isDeletedInventorySuccess: true,
      successMessage: payload.message,
      inventoryList: state.inventoryList.filter((i) => i.id !== payload.data),
      inventoryCount: state.inventoryCount - 1,
    }),
    [deleteInventoryFailure]: (state, { payload }) => ({
      ...state,
      isDeletedInventoryFailure: true,
      errorMessage: payload,
    }),
    [deleteInventoryVariantRequest]: (state) => ({
      ...state,
      isDeleteInventoryVariantSuccess: false,
      isDeleteInventoryVariantFailure: false,
    }),
    [deleteInventoryVariantSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteInventoryVariantSuccess: true,
      successMessage: payload.message,
      inventoryVariants: state.inventoryVariants.filter(
        (i) => i.id !== payload.data
      ),
      inventoryVariantsCount: state.inventoryVariantsCount - 1,
    }),
    [deleteInventoryVariantFailure]: (state, { payload }) => ({
      ...state,
      isDeleteInventoryVariantFailure: true,
      errorMessage: payload,
    }),
    [getInventoryMasterAgentSelectRequest]: (state) => ({
      ...state,
      isGettingMasterAgentSelectData: true,
    }),
    [getInventoryMasterAgentSelectSuccess]: (state, { payload }) => ({
      ...state,
      isGettingMasterAgentSelectData: false,
      successMessage: payload.message,
      masterAgentSelectData: payload.data,
    }),
    [getInventoryMasterAgentSelectFailure]: (state, { payload }) => ({
      ...state,
      isGettingMasterAgentSelectData: false,
      errorMessage: payload,
    }),
    [getInventorySubAgentsSelectRequest]: (state) => ({
      ...state,
      isGettingInventorySubAgents: true,
    }),
    [getInventorySubAgentsSelectSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInventorySubAgents: false,
      successMessage: payload.message,
      subAgentSelectData: payload.data,
    }),
    [getInventorySubAgentsSelectFailure]: (state, { payload }) => ({
      ...state,
      isGettingInventorySubAgents: false,
      errorMessage: payload,
    }),
    [clearSubAgentSelectData]: (state) => ({
      ...state,
      subAgentSelectData: [],
    }),
    [assignInventoryToAgentsRequest]: (state) => ({
      ...state,
      isAssignInventoryToAgentsSuccess: false,
      isAssignInventoryToAgentsFailure: false,
    }),
    [assignInventoryToAgentsSuccess]: (state, { payload }) => ({
      ...state,
      isAssignInventoryToAgentsSuccess: true,
      successMessage: payload.message,
    }),
    [assignInventoryToAgentsFailure]: (state, { payload }) => ({
      ...state,
      isAssignInventoryToAgentsFailure: true,
      errorMessage: payload,
    }),
    [uploadInventoryRequest]: (state) => ({
      ...state,
      isUploadingInventory: true,
    }),
    [createAndAssignInventoryRequest]: (state) => ({
      ...state,
      isCreateAndAssignInventoryRequest: true,
      isCreateAndAssignInventorySuccess: false,
      isCreateAndAssignInventoryFailure: false,
    }),
    [createAndAssignInventorySuccess]: (state, { payload }) => ({
      ...state,
      isCreateAndAssignInventoryRequest: false,
      isCreateAndAssignInventorySuccess: true,
      successMessage: payload.message,
      inventoryList: payload.data.rows,
      inventoryCount: payload.data.count,
    }),
    [createAndAssignInventoryFailure]: (state, { payload }) => ({
      ...state,
      isCreateAndAssignInventoryRequest: false,
      isCreateAndAssignInventoryFailure: true,
      errorMessage: payload,
    }),
    [assignSIMCardToIMEIRequest]: (state) => ({
      ...state,
      isAssigningSIMCardToIMEI: true,
      isAssigningSIMCardToIMEISuccess: false,
      isAssigningSIMCardToIMEIFailure: false,
    }),
    [assignSIMCardToIMEISuccess]: (state, { payload }) => {
      const inventoryListCopy = [...state.inventoryList];
      const index = inventoryListCopy.findIndex(
        (i) => i.id === payload.data.id
      );

      if (index !== -1) {
        inventoryListCopy[index] = payload.data;
      }

      return {
        ...state,
        isAssigningSIMCardToIMEI: false,
        isAssigningSIMCardToIMEISuccess: true,
        inventoryList: inventoryListCopy,
        successMessage: payload.message,
      };
    },
    [assignSIMCardToIMEIFailure]: (state, { payload }) => ({
      ...state,
      isAssigningSIMCardToIMEI: false,
      isAssigningSIMCardToIMEIFailure: true,
      errorMessage: payload,
    }),
    [requestInventoryRequest]: (state) => ({
      ...state,
      isRequestingInventory: true,
      isRequestingInventorySuccess: false,
      isRequestingInventoryFailure: false,
    }),
    [requestInventorySuccess]: (state, { payload }) => ({
      ...state,
      isRequestingInventory: false,
      isRequestingInventorySuccess: true,
      successMessage: payload.message,
    }),
    [requestInventoryFailure]: (state, { payload }) => ({
      ...state,
      isRequestingInventory: false,
      isRequestingInventoryFailure: true,
      errorMessage: payload,
    }),
    [getDeviceCategorySelectSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDeviceCategorySelectDataSuccess: true,
      deviceCategorySelectData: payload.data,
      successMessage: payload.message,
    }),
    [getSubAgentDataRequest]: (state) => ({
      ...state,
      isGettingSubAgentData: true,
    }),
    [getSubAgentDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSubAgentData: false,
      subAgentData: payload.data,
      successMessage: payload.message,
    }),
    [getSubAgentDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingSubAgentData: false,
      errorMessage: payload,
    }),
    [clearSubAgentData]: (state) => ({
      ...state,
      subAgentData: {},
    }),
    [checkDeviceIEMIRequest]: (state) => ({
      ...state,
      isCheckingIEMI: true,
      isCheckingIEMISuccess: false,
      isCheckingIEMIFailure: false,
      IEMICheckData: {},
    }),
    [checkDeviceIEMISuccess]: (state, { payload }) => ({
      ...state,
      isCheckingIEMI: false,
      isCheckingIEMISuccess: true,
      successMessage: payload.message,
      IEMICheckData: payload.data,
    }),
    [checkDeviceIEMIFailure]: (state, { payload }) => ({
      ...state,
      isCheckingIEMI: false,
      isCheckingIEMIFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
