import { handleActions } from 'redux-actions';

import {
  attachCustomerDefaultPaymentMethodFailure,
  attachCustomerDefaultPaymentMethodRequest,
  attachCustomerDefaultPaymentMethodSuccess,
  clearClientSecret,
  confirmCheckoutFailure,
  confirmCheckoutRequest,
  confirmCheckoutSuccess,
  createCustomerPaymentMethodFailure,
  createCustomerPaymentMethodRequest,
  createCustomerPaymentMethodSuccess,
  createPaymentMethodFailure,
  createPaymentMethodRequest,
  createPaymentMethodSuccess,
  defaultPaymentMethodFailure,
  defaultPaymentMethodRequest,
  defaultPaymentMethodSuccess,
  deleteCustomerPaymentMethodFailure,
  deleteCustomerPaymentMethodRequest,
  deleteCustomerPaymentMethodSuccess,
  deletePaymentMethodFailure,
  deletePaymentMethodRequest,
  deletePaymentMethodSuccess,
  getCustomersPaymentMethodsFailure,
  getCustomersPaymentMethodsRequest,
  getCustomersPaymentMethodsSuccess,
  getPaymentMethodListFailure,
  getPaymentMethodListRequest,
  getPaymentMethodListSuccess,
  getPaymentMethodsSelectDataFailure,
  getPaymentMethodsSelectDataRequest,
  getPaymentMethodsSelectDataSuccess,
  getPaymentsListFailure,
  getPaymentsListRequest,
  getPaymentsListSuccess,
  makePaymentFailure,
  makePaymentRequest,
  makePaymentSuccess,
  stripePaymentAuthorizationFailure,
  stripePaymentAuthorizationRequest,
  stripePaymentAuthorizationSuccess,
} from './actions';

const initialState = {
  isAuthorizing: false,
  isAuthorizingSuccess: false,
  isAuthorizingFailure: false,
  successMessage: '',
  errorMessage: '',
  client_secret: '',
  isCreatingPaymentMethod: false,
  isCreatingPaymentMethodSuccess: false,
  isCreatingPaymentMethodFailure: false,
  isGettingPaymentMethods: true,
  isGettingPaymentMethodsSuccess: false,
  paymentMethodList: [],
  paymentMethodCount: 0,
  isGettingPaymentMethodsFailure: false,
  isAttachingDefaultPaymentMethod: false,
  isAttachingDefaultPaymentMethodFailure: false,
  isAttachingDefaultPaymentMethodSuccess: false,
  isDeletedPaymentMethodSuccess: false,
  isDeletedPaymentMethodFailure: false,
  isGettingPaymentMethodsSelectData: false,
  paymentMethodsSelectData: [],
  isMakingPayment: false,
  isMakingPaymentSuccess: false,
  isMakingPaymentFailure: false,
  isGettingPaymentsList: false,
  isGettingPaymentsListSuccess: false,
  isGettingPaymentsListFailure: false,
  paymentsList: [],
  paymentsCount: 0,
  isConfirmingCheckoutSuccess: false,
  isConfirmingCheckoutFailure: false,
  paymentSecret: '',
  isGettingCustomersPaymentMethods: true,
  isGettingCustomersPaymentMethodsSuccess: false,
  paymentMethods: [],
  isCreatingCustomerPaymentMethod: false,
  isCreatingCustomerPaymentMethodSuccess: false,
  isCreatingCustomerPaymentMethodFailure: false,
  isAttachingCustomerDefaultMethod: false,
  isAttachingCustomerDefaultMethodSuccess: false,
  isAttachingCustomerDefaultMethodFailure: false,
  isDeletingCustomerPaymentMethod: false,
  isDeletingCustomerPaymentMethodSuccess: false,
  isDeletingCustomerPaymentMethodFailure: false,
};

const reducer = handleActions(
  {
    [stripePaymentAuthorizationRequest]: (state) => ({
      ...state,
      isAuthorizing: true,
      isAuthorizingSuccess: false,
      isAuthorizingFailure: false,
    }),
    [stripePaymentAuthorizationSuccess]: (state, { payload }) => ({
      ...state,
      client_secret: payload.data,
      isAuthorizing: false,
      isAuthorizingSuccess: true,
      successMessage: payload.message,
    }),
    [stripePaymentAuthorizationFailure]: (state, { payload }) => ({
      ...state,
      isAuthorizing: false,
      isAuthorizingFailure: true,
      errorMessage: payload,
    }),
    [clearClientSecret]: (state) => ({
      ...state,
      client_secret: '',
    }),
    [createPaymentMethodRequest]: (state) => ({
      ...state,
      isCreatingPaymentMethod: true,
      isCreatingPaymentMethodSuccess: false,
      isCreatingPaymentMethodFailure: false,
    }),
    [createPaymentMethodSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingPaymentMethod: false,
      isCreatingPaymentMethodSuccess: true,
      paymentMethodList: [payload.data, ...state.paymentMethodList],
      paymentMethodCount: state.paymentMethodCount + 1,
      successMessage: payload.message,
    }),
    [createPaymentMethodFailure]: (state, { payload }) => ({
      ...state,
      isCreatingPaymentMethod: false,
      isCreatingPaymentMethodFailure: true,
      errorMessage: payload,
    }),
    [getPaymentMethodListRequest]: (state) => ({
      ...state,
      isGettingPaymentMethods: true,
      isGettingPaymentMethodsFailure: false,
      isGettingPaymentMethodsSuccess: false,
    }),
    [getPaymentMethodListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPaymentMethods: false,
      isGettingPaymentMethodsSuccess: true,
      paymentMethodList: payload.data.rows,
      paymentMethodCount: payload.data.count,
    }),
    [getPaymentMethodListFailure]: (state, { payload }) => ({
      ...state,
      isGettingPaymentMethods: false,
      isGettingPaymentMethodsFailure: true,
      errorMessage: payload,
    }),
    [defaultPaymentMethodRequest]: (state) => ({
      ...state,
      isAttachingDefaultPaymentMethod: true,
      isAttachingDefaultPaymentMethodFailure: false,
      isAttachingDefaultPaymentMethodSuccess: false,
    }),
    [defaultPaymentMethodSuccess]: (state, { payload }) => {
      const defaultMethod =
        payload.data.data.find((item) => item.id === payload.id) || {};

      const {
        brand,
        expMonth,
        expYear,
        cardNumber,
        last4,
        paymentMethodId,
        id,
      } = defaultMethod;

      const defaultMethodData = {
        brand,
        expMonth,
        expYear,
        label: cardNumber,
        last4,
        paymentMethodId,
        title: id,
      };

      return {
        ...state,
        isAttachingDefaultPaymentMethod: false,
        isAttachingDefaultPaymentMethodSuccess: true,
        successMessage: payload.data.message,
        paymentMethodList: payload.data.data,
        defaultPaymentMethod: defaultMethodData,
      };
    },
    [defaultPaymentMethodFailure]: (state, { payload }) => ({
      ...state,
      isAttachingDefaultPaymentMethod: false,
      isAttachingDefaultPaymentMethodFailure: true,
      errorMessage: payload,
    }),
    [deletePaymentMethodRequest]: (state) => ({
      ...state,
      isDeletedPaymentMethodSuccess: false,
      isDeletedPaymentMethodFailure: false,
    }),
    [deletePaymentMethodSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedPaymentMethodSuccess: true,
      paymentMethodList: state.paymentMethodList.filter(
        (i) => i.id !== payload.data
      ),
      paymentMethodCount: state.paymentMethodCount - 1,
      successMessage: payload.message,
    }),
    [deletePaymentMethodFailure]: (state, { payload }) => ({
      ...state,
      isDeletedPaymentMethodFailure: true,
      errorMessage: payload,
    }),
    [getPaymentMethodsSelectDataRequest]: (state) => ({
      ...state,
      isGettingPaymentMethodsSelectData: true,
    }),
    [getPaymentMethodsSelectDataSuccess]: (state, { payload }) => {
      const paymentsList = state.paymentsList.map((payment) => {
        const card = payload.data.rows.find(
          (paymentMethod) =>
            paymentMethod.paymentMethodId === payment.paymentMethodId
        );

        if (card) {
          const { label, brand } = card;
          payment.card = {
            label,
            brand,
          };
        }

        return payment;
      });

      return {
        ...state,
        isGettingPaymentMethodsSelectData: false,
        successMessage: payload.message,
        paymentMethodsSelectData: payload.data.rows,
        defaultPaymentMethod: payload.data.defaultPaymentMethod,
        paymentsList,
      };
    },
    [getPaymentMethodsSelectDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingPaymentMethodsSelectData: false,
      errorMessage: payload,
    }),
    [makePaymentRequest]: (state) => ({
      ...state,
      isMakingPayment: true,
      isMakingPaymentSuccess: false,
      isMakingPaymentFailure: false,
    }),
    [makePaymentSuccess]: (state, { payload }) => ({
      ...state,
      isMakingPayment: false,
      isMakingPaymentSuccess: true,
      successMessage: payload.message,
    }),
    [makePaymentFailure]: (state, { payload }) => ({
      ...state,
      isMakingPayment: false,
      isMakingPaymentFailure: true,
      errorMessage: payload,
    }),
    [getPaymentsListRequest]: (state) => ({
      ...state,
      isGettingPaymentsList: true,
      isGettingPaymentsListSuccess: false,
      isGettingPaymentsListFailure: false,
    }),
    [getPaymentsListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPaymentsList: false,
      isGettingPaymentsListSuccess: true,
      successMessage: payload.message,
      paymentsList: payload.data.rows,
      paymentsCount: payload.data.count,
    }),
    [getPaymentsListFailure]: (state, { payload }) => ({
      ...state,
      isGettingPaymentsList: false,
      isGettingPaymentsListFailure: true,
      errorMessage: payload,
    }),
    [confirmCheckoutRequest]: (state) => ({
      ...state,
      isConfirmingCheckoutSuccess: false,
      isConfirmingCheckoutFailure: false,
    }),
    [confirmCheckoutSuccess]: (state, { payload }) => ({
      ...state,
      isConfirmingCheckoutSuccess: true,
      successMessage: payload.message,
      paymentSecret: payload.data,
    }),
    [confirmCheckoutFailure]: (state, { payload }) => ({
      ...state,
      isConfirmingCheckoutFailure: true,
      errorMessage: payload,
    }),
    [getCustomersPaymentMethodsRequest]: (state) => ({
      ...state,
      isGettingCustomersPaymentMethods: true,
      isGettingCustomersPaymentMethodsSuccess: false,
      isGettingCustomersPaymentMethodsFailure: false,
    }),
    [getCustomersPaymentMethodsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomersPaymentMethods: false,
      isGettingCustomersPaymentMethodsSuccess: true,
      successMessage: payload.message,
      paymentMethods: payload.data,
    }),
    [getCustomersPaymentMethodsFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomersPaymentMethods: false,
      isGettingCustomersPaymentMethodsFailure: true,
      errorMessage: payload,
    }),
    [createCustomerPaymentMethodRequest]: (state) => ({
      ...state,
      isCreatingCustomerPaymentMethod: true,
      isCreatingCustomerPaymentMethodSuccess: false,
      isCreatingCustomerPaymentMethodFailure: false,
    }),
    [createCustomerPaymentMethodSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingCustomerPaymentMethod: false,
      isCreatingCustomerPaymentMethodSuccess: true,
      successMessage: payload.message,
      paymentMethods: [payload.data, ...state.paymentMethods],
    }),
    [createCustomerPaymentMethodFailure]: (state, { payload }) => ({
      ...state,
      isCreatingCustomerPaymentMethod: false,
      isCreatingCustomerPaymentMethodFailure: true,
      errorMessage: payload,
    }),
    [attachCustomerDefaultPaymentMethodRequest]: (state) => ({
      ...state,
      isAttachingCustomerDefaultMethod: true,
      isAttachingCustomerDefaultMethodSuccess: false,
      isAttachingCustomerDefaultMethodFailure: false,
    }),
    [attachCustomerDefaultPaymentMethodSuccess]: (state, { payload }) => ({
      ...state,
      isAttachingCustomerDefaultMethod: false,
      isAttachingCustomerDefaultMethodSuccess: true,
      successMessage: payload.message,
      paymentMethods: payload.data,
    }),
    [attachCustomerDefaultPaymentMethodFailure]: (state, { payload }) => ({
      ...state,
      isAttachingCustomerDefaultMethod: false,
      isAttachingCustomerDefaultMethodFailure: true,
      errorMessage: payload,
    }),
    [deleteCustomerPaymentMethodRequest]: (state) => ({
      ...state,
      isDeletingCustomerPaymentMethod: true,
      isDeletingCustomerPaymentMethodSuccess: false,
      isDeletingCustomerPaymentMethodFailure: false,
    }),
    [deleteCustomerPaymentMethodSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingCustomerPaymentMethod: false,
      isDeletingCustomerPaymentMethodSuccess: true,
      successMessage: payload.message,
      paymentMethods: state.paymentMethods.filter((i) => i.id !== payload.data),
    }),
    [deleteCustomerPaymentMethodFailure]: (state, { payload }) => ({
      ...state,
      isDeletingCustomerPaymentMethod: false,
      isDeletingCustomerPaymentMethodFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
