import { handleActions } from 'redux-actions';
import {
  createPromotionFailure,
  createPromotionRequest,
  createPromotionSuccess,
  deletePromotionFailure,
  deletePromotionRequest,
  deletePromotionSuccess,
  enablePromotionFailure,
  enablePromotionRequest,
  enablePromotionSuccess,
  getPromotionsFailure,
  getPromotionsRequest,
  getPromotionsSuccess,
  updatePromotionFailure,
  updatePromotionRequest,
  updatePromotionSuccess,
} from './actions';

const initialState = {
  isCreatingPromotion: false,
  isCreatingPromotionSuccess: false,
  isCreatingPromotionFailure: false,
  promotions: [],
  count: 0,
  isGettingPromotions: true,
  isDeletingPromotion: false,
  isDeletingPromotionSuccess: false,
  isDeletingPromotionFailure: false,
  isUpdatingPromotion: false,
  isUpdatingPromotionSuccess: false,
  isUpdatingPromotionFailure: false,
  isEnablingPromotion: false,
  isEnablingPromotionSuccess: false,
  isEnablingPromotionFailure: false,
};

const reducer = handleActions(
  {
    [createPromotionRequest]: (state) => ({
      ...state,
      isCreatingPromotion: true,
      isCreatingPromotionSuccess: false,
      isCreatingPromotionFailure: false,
    }),
    [createPromotionSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingPromotion: false,
      isCreatingPromotionSuccess: true,
      successMessage: payload.message,
    }),
    [createPromotionFailure]: (state, { payload }) => ({
      ...state,
      isCreatingPromotion: false,
      isCreatingPromotionFailure: true,
      errorMessage: payload,
    }),
    [getPromotionsRequest]: (state) => ({
      ...state,
      isGettingPromotions: true,
    }),
    [getPromotionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPromotions: false,
      promotions: payload.data.rows,
      count: payload.data.count,
      successMessage: payload.message,
    }),
    [getPromotionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingPromotions: false,
      errorMessage: payload,
    }),
    [deletePromotionRequest]: (state) => ({
      ...state,
      isDeletingPromotion: true,
      isDeletingPromotionSuccess: false,
      isDeletingPromotionFailure: false,
    }),
    [deletePromotionSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingPromotion: false,
      isDeletingPromotionSuccess: true,
      successMessage: payload.message,
      promotions: state.promotions.filter((i) => i.id !== payload.data),
    }),
    [deletePromotionFailure]: (state, { payload }) => ({
      ...state,
      isDeletingPromotion: false,
      isDeletingPromotionFailure: true,
      errorMessage: payload,
    }),
    [updatePromotionRequest]: (state) => ({
      ...state,
      isUpdatingPromotion: true,
      isUpdatingPromotionSuccess: false,
      isUpdatingPromotionFailure: false,
    }),
    [updatePromotionSuccess]: (state, { payload }) => {
      const promotionsCopy = [...state.promotions];
      const index = promotionsCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        promotionsCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingPromotion: false,
        isUpdatingPromotionSuccess: true,
        successMessage: payload.message,
        promotions: promotionsCopy,
      };
    },
    [updatePromotionFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingPromotion: false,
      isUpdatingPromotionFailure: true,
      errorMessage: payload,
    }),
    [enablePromotionRequest]: (state) => ({
      ...state,
      isEnablingPromotion: true,
      isEnablingPromotionSuccess: false,
      isEnablingPromotionFailure: false,
    }),
    [enablePromotionSuccess]: (state, { payload }) => {
      const promotionsCopy = [...state.promotions].map((i) => ({
        ...i,
        isActive: false,
      }));
      const promotionIndex = state.promotions.findIndex(
        (p) => p.id === payload.data.id
      );
      if (promotionIndex !== -1) {
        promotionsCopy[promotionIndex] = payload.data;
      }

      return {
        ...state,
        isEnablingPromotion: false,
        isEnablingPromotionSuccess: true,
        successMessage: payload.message,
        promotions: promotionsCopy,
      };
    },
    [enablePromotionFailure]: (state, { payload }) => ({
      ...state,
      isEnablingPromotion: false,
      isEnablingPromotionFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
