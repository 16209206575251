import { BuildOutlined } from '@ant-design/icons';

const icons = {
  BuildOutlined,
};

const tickets = {
  id: 'group-tickets',
  title: 'Tickets',
  icon: icons.BuildOutlined,
  type: 'item',
  resource: ['all', 'tenant', 'masterAgent', 'subAgent', 'all'],
  url: '/tickets/list',
};
export default tickets;
