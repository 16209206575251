import { memo } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'redux/store';

import { useIdleTimer } from 'react-idle-timer';
import { isUserLoggedIn } from 'utils/methods';

import App from 'App';
import { logoutRequest } from './redux/auth/actions';

import 'assets/scss/styles.scss';

const IdleApp = () => {
  const { adminUserData, isLoggedIn } = isUserLoggedIn();
  const { userData } = useSelector((state) => state.auth);
  const handleOnIdle = async () => {
    if (!adminUserData?.rememberMe && !userData?.rememberMe) {
      if (isLoggedIn) {
        await dispatch(logoutRequest());
      }
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      type: 'localStorage',
      channelName: 'idle-timer',
      fallbackInterval: 2000,
      responseTime: 100,
      removeTimeout: 1000 * 60,
      emitOnAllTabs: false,
    },
  });

  const renderAPP = () => {
    return <App />;
  };

  return renderAPP();
};
export default memo(IdleApp);
