import { createAction } from 'redux-actions';

export const getMasterAgentSelectRequest = createAction(
  'GET_MASTER_AGENT_SELECT_REQUEST'
);
export const getMasterAgentSelectSuccess = createAction(
  'GET_MASTER_AGENT_SELECT_SUCCESS'
);
export const getMasterAgentSelectFailure = createAction(
  'GET_MASTER_AGENT_SELECT_FAILURE'
);

export const getSubAgentSelectRequest = createAction(
  'GET_SUB_AGENT_SELECT_REQUEST'
);
export const getSubAgentSelectSuccess = createAction(
  'GET_SUB_AGENT_SELECT_SUCCESS'
);
export const getSubAgentSelectFailure = createAction(
  'GET_SUB_AGENT_SELECT_FAILURE'
);

export const getCarriersSelectDataRequest = createAction(
  'GET_CARRIERS_SELECT_DATA_REQUEST'
);
export const getCarriersSelectDataSuccess = createAction(
  'GET_CARRIERS_SELECT_DATA_SUCCESS'
);
export const getCarriersSelectDataFailure = createAction(
  'GET_CARRIERS_SELECT_DATA_FAILURE'
);

export const getCustomersSelectDataRequest = createAction(
  'GET_CUSTOMER_SELECT_DATA_REQUEST'
);
export const getCustomersSelectDataSuccess = createAction(
  'GET_CUSTOMER_SELECT_DATA_SUCCESS'
);
export const getCustomersSelectDataFailure = createAction(
  'GET_CUSTOMER_SELECT_DATA_FAILURE'
);

export const createSIMCardRequest = createAction('CREATE_SIM_CARD_REQUEST');
export const createSIMCardSuccess = createAction('CREATE_SIM_CARD_SUCCESS');
export const createSIMCardFailure = createAction('CREATE_SIM_CARD_FAILURE');

export const getSIMCardListRequest = createAction('GET_SIM_CARD_LIST_REQUEST');
export const getSIMCardListFailure = createAction('GET_SIM_CARD_LIST_SUCCESS');
export const getSIMCardListSuccess = createAction('GET_SIM_CARD_LIST_FAILURE');

export const deleteSimCardRequest = createAction('DELETE_SIM_CARD_REQUEST');
export const deleteSimCardSuccess = createAction('DELETE_SIM_CARD_SUCCESS');
export const deleteSimCardFailure = createAction('DELETE_SIM_CARD_FAILURE');

export const assignSimCardToAgentsRequest = createAction(
  'ASSIGN_SIM_CARD_TO_AGENTS_REQUEST'
);
export const assignSimCardToAgentsSuccess = createAction(
  'ASSIGN_SIM_CARD_TO_AGENTS_SUCCESS'
);
export const assignSimCardToAgentsFailure = createAction(
  'ASSIGN_SIM_CARD_TO_AGENTS_FAILURE'
);

export const updateSimCardRequest = createAction('UPDATE_SIM_CARD_REQUEST');
export const updateSimCardSuccess = createAction('UPDATE_SIM_CARD_SUCCESS');
export const updateSimCardFailure = createAction('UPDATE_SIM_CARD_FAILURE');

export const bulkUpdateSimCardsRequest = createAction(
  'BULK_UPDATE_SIM_CARDS_REQUEST'
);
export const bulkUpdateSimCardsSuccess = createAction(
  'BULK_UPDATE_SIM_CARDS_SUCCESS'
);
export const bulkUpdateSimCardsFailure = createAction(
  'BULK_UPDATE_SIM_CARDS_FAILURE'
);

export const uploadSimCardsRequest = createAction('UPLOAD_SIM_CARDS_REQUEST');
export const uploadSimCardsSuccess = createAction('UPLOAD_SIM_CARDS_SUCCESS');
export const uploadSimCardsFailure = createAction('UPLOAD_SIM_CARDS_FAILURE');

export const uploadESIMRequest = createAction('UPLOAD_ESIM_REQUEST');
export const uploadESIMSuccess = createAction('UPLOAD_ESIM_SUCCESS');
export const uploadESIMFailure = createAction('UPLOAD_ESIM_FAILURE');

export const getAvailableSimRequest = createAction('GET_AVAILABLE_SIM_REQUEST');
export const getAvailableSimSuccess = createAction('GET_AVAILABLE_SIM_SUCCESS');
export const getAvailableSimFailure = createAction('GET_AVAILABLE_SIM_FAILURE');
