import { createAction } from 'redux-actions';

export const createManufacturerRequest = createAction(
  'CREATE_MANUFACTURER_REQUEST'
);
export const createManufacturerSuccess = createAction(
  'CREATE_MANUFACTURER_SUCCESS'
);
export const createManufacturerFailure = createAction(
  'CREATE_MANUFACTURER_FAILURE'
);

export const getManufacturersListRequest = createAction(
  'GET_MANUFACTURERS_LIST_REQUEST'
);
export const getManufacturersListSuccess = createAction(
  'GET_MANUFACTURERS_LIST_SUCCESS'
);
export const getManufacturersListFailure = createAction(
  'GET_MANUFACTURERS_LIST_FAILURE'
);

export const deleteManufacturerRequest = createAction(
  'DELETE_MANUFACTURER_REQUEST'
);

export const deleteManufacturerSuccess = createAction(
  'DELETE_MANUFACTURER_SUCCESS'
);

export const deleteManufacturerFailure = createAction(
  'DELETE_MANUFACTURER_FAILURE'
);

export const handleManufacturerData = createAction('HANDLE_MANUFACTURER_DATA');

export const deleteManufacturerFilesRequest = createAction(
  'DELETE_MANUFACTURER_FILES_REQUEST'
);
export const deleteManufacturerFilesSuccess = createAction(
  'DELETE_MANUFACTURER_FILES_SUCCESS'
);
export const deleteManufacturerFilesFailure = createAction(
  'DELETE_MANUFACTURER_FILES_FAILURE'
);

export const updateManufacturerRequest = createAction(
  'UPDATE_MANUFACTURER_REQUEST'
);

export const updateManufacturerSuccess = createAction(
  'UPDATE_MANUFACTURER_SUCCESS'
);

export const updateManufacturerFailure = createAction(
  'UPDATE_MANUFACTURER_FAILURE'
);
