import { handleActions } from 'redux-actions';

import {
  getNotificationsRequest,
  getNotificationsFailure,
  getNotificationsSuccess,
  makeSeenNotificationsRequest,
  makeSeenNotificationsSuccess,
  makeSeenNotificationsFailure,
  pushNotificationFromEvent,
  getNotificationsListDataRequest,
  getNotificationsListDataSuccess,
  getNotificationsListDataFailure,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  bulkDeleteNotificationsRequest,
  bulkDeleteNotificationsSuccess,
  bulkDeleteNotificationsFailure,
} from './actions';

const initialState = {
  isGettingNotifications: true,
  isGettingNotificationsSuccess: false,
  isGettingNotificationsFailure: false,
  notificationsList: [],
  successMessage: '',
  errorMessage: '',
  isGettingNotificationsData: true,
  isGettingNotificationsDataSuccess: false,
  isGettingNotificationsDataFailure: false,
  notificationsDataList: [],
  notificationsDataCount: 0,
  isDeletingNotificationSuccess: false,
  isDeletingNotificationFailure: false,
  isBulkDeletingNotifications: false,
  isBulkDeletingNotificationsSuccess: false,
  isBulkDeletingNotificationsFailure: false,
};

const reducer = handleActions(
  {
    [getNotificationsRequest]: (state) => ({
      ...state,
      isGettingNotifications: true,
      isGettingNotificationsSuccess: false,
      isGettingNotificationsFailure: false,
    }),
    [getNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingNotifications: false,
      isGettingNotificationsSuccess: true,
      notificationsList: payload.data.rows,
      notificationsCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getNotificationsFailure]: (state, { payload }) => ({
      ...state,
      isGettingNotifications: false,
      isGettingNotificationsFailure: true,
      errorMessage: payload,
    }),
    [makeSeenNotificationsRequest]: (state) => ({
      ...state,
      isMakingNotificationsSeenSuccess: false,
      isMakingNotificationsSeenFailure: false,
    }),
    [makeSeenNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      isMakingNotificationsSeenSuccess: true,
      notificationsList: payload.data,
      notificationsCount: payload.count,
    }),
    [makeSeenNotificationsFailure]: (state, { payload }) => ({
      ...state,
      isMakingNotificationsSeenFailure: true,
      errorMessage: payload,
    }),
    [pushNotificationFromEvent]: (state, { payload }) => ({
      ...state,
      notificationsCount: state.notificationsCount + 1,
      notificationsList: [payload, ...state.notificationsList],
    }),
    [getNotificationsListDataRequest]: (state) => ({
      ...state,
      isGettingNotificationsData: true,
      isGettingNotificationsDataSuccess: false,
      isGettingNotificationsDataFailure: false,
    }),
    [getNotificationsListDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingNotificationsData: false,
      isGettingNotificationsDataSuccess: true,
      notificationsDataList: payload.data.rows,
      notificationsDataCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getNotificationsListDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingNotificationsData: false,
      isGettingNotificationsDataFailure: true,
      errorMessage: payload,
    }),
    [deleteNotificationRequest]: (state) => ({
      ...state,
      isDeletingNotificationSuccess: false,
      isDeletingNotificationFailure: false,
    }),
    [deleteNotificationSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingNotificationSuccess: true,
      successMessage: payload.message,
      notificationsDataList: state.notificationsDataList.filter(
        (i) => i.id !== +payload.data
      ),
      notificationsDataCount: state.notificationsDataCount - 1,
    }),
    [deleteNotificationFailure]: (state, { payload }) => ({
      ...state,
      isDeletingNotificationFailure: true,
      errorMessage: payload,
    }),
    [bulkDeleteNotificationsRequest]: (state) => ({
      ...state,
      isBulkDeletingNotifications: true,
      isBulkDeletingNotificationsSuccess: false,
      isBulkDeletingNotificationsFailure: false,
    }),
    [bulkDeleteNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletingNotifications: false,
      isBulkDeletingNotificationsSuccess: true,
      successMessage: payload.message,
      notificationsDataList: state.notificationsDataList.filter(
        (i) => !payload.data.includes(i.id)
      ),
      notificationsDataCount:
        state.notificationsDataCount - payload.data.length,
    }),
    [bulkDeleteNotificationsFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletingNotifications: false,
      isBulkDeletingNotificationsFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
