import { axiosApiInstance, config } from 'custom-configs';

import {
  createVendorFailure,
  createVendorRequest,
  createVendorSuccess,
  getVendorsListFailure,
  getVendorsListRequest,
  getVendorsListSuccess,
  updateVendorFailure,
  updateVendorRequest,
  updateVendorSuccess,
  deleteVendorRequest,
  deleteVendorSuccess,
  deleteVendorFailure,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

const URL = `${config.TENANT}/e-commerce/vendors`;

function* createVendor({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createVendorSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createVendor, error', e);
    if (e?.response?.data) {
      yield put(createVendorFailure(catchResponseMessages(e)));
    }
  }
}
function* getList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getVendorsListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getList, error', e);
    if (e?.response?.data) {
      yield put(getVendorsListFailure(catchResponseMessages(e)));
    }
  }
}
function* updateVendor({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateVendorSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateVendor, error', e);
    if (e?.response?.data) {
      yield put(updateVendorFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteVendor({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload.id}`)
    );

    if (response?.status === 200) {
      yield put(deleteVendorSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteVendor, error', e);
    if (e?.response?.data) {
      yield put(deleteVendorFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createVendorRequest, createVendor);
  yield takeLatest(getVendorsListRequest, getList);
  yield takeLatest(updateVendorRequest, updateVendor);
  yield takeLatest(deleteVendorRequest, deleteVendor);
}
