import { createAction } from 'redux-actions';

export const stripePaymentAuthorizationRequest = createAction(
  'STRIPE_PAYMENT_AUTHORIZATION_REQUEST'
);
export const stripePaymentAuthorizationSuccess = createAction(
  'STRIPE_PAYMENT_AUTHORIZATION_SUCCESS'
);
export const stripePaymentAuthorizationFailure = createAction(
  'STRIPE_PAYMENT_AUTHORIZATION_FAILURE'
);
export const clearClientSecret = createAction('CLEAR_CLIENT_SECRET');

export const createPaymentMethodRequest = createAction(
  'CREATE_PAYMENT_METHOD_REQUEST'
);
export const createPaymentMethodSuccess = createAction(
  'CREATE_PAYMENT_METHOD_SUCCESS'
);
export const createPaymentMethodFailure = createAction(
  'CREATE_PAYMENT_METHOD_FAILURE'
);

export const getPaymentMethodListRequest = createAction(
  'GET_PAYMENT_METHOD_LIST_REQUEST'
);
export const getPaymentMethodListSuccess = createAction(
  'GET_PAYMENT_METHOD_LIST_SUCCESS'
);
export const getPaymentMethodListFailure = createAction(
  'GET_PAYMENT_METHOD_LIST_FAILURE'
);

export const defaultPaymentMethodRequest = createAction(
  'DEFAULT_PAYMENT_METHOD_REQUEST'
);
export const defaultPaymentMethodSuccess = createAction(
  'DEFAULT_PAYMENT_METHOD_SUCCESS'
);
export const defaultPaymentMethodFailure = createAction(
  'DEFAULT_PAYMENT_METHOD_FAILURE'
);

export const deletePaymentMethodRequest = createAction(
  'DELETE_PAYMENT_METHOD_REQUEST'
);
export const deletePaymentMethodSuccess = createAction(
  'DELETE_PAYMENT_METHOD_SUCCESS'
);
export const deletePaymentMethodFailure = createAction(
  'DELETE_PAYMENT_METHOD_FAILURE'
);

export const getPaymentMethodsSelectDataRequest = createAction(
  'GET_PAYMENT_METHODS_SELECT_DATA_REQUEST'
);
export const getPaymentMethodsSelectDataSuccess = createAction(
  'GET_PAYMENT_METHODS_SELECT_DATA_SUCCESS'
);
export const getPaymentMethodsSelectDataFailure = createAction(
  'GET_PAYMENT_METHODS_SELECT_DATA_FAILURE'
);

export const makePaymentRequest = createAction('MAKE_PAYMENT_REQUEST');
export const makePaymentSuccess = createAction('MAKE_PAYMENT_SUCCESS');
export const makePaymentFailure = createAction('MAKE_PAYMENT_FAILURE');

export const getPaymentsListRequest = createAction('GET_PAYMENTS_LIST_REQUEST');
export const getPaymentsListSuccess = createAction('GET_PAYMENTS_LIST_SUCCESS');
export const getPaymentsListFailure = createAction('GET_PAYMENTS_LIST_FAILURE');

export const confirmCheckoutRequest = createAction('CONFIRM_CHECKOUT_REQUEST');
export const confirmCheckoutSuccess = createAction('CONFIRM_CHECKOUT_SUCCESS');
export const confirmCheckoutFailure = createAction('CONFIRM_CHECKOUT_FAILURE');

export const getCustomersPaymentMethodsRequest = createAction(
  'GET_CUSTOMERS_PAYMENT_METHODS_REQUEST'
);
export const getCustomersPaymentMethodsSuccess = createAction(
  'GET_CUSTOMERS_PAYMENT_METHODS_SUCCESS'
);
export const getCustomersPaymentMethodsFailure = createAction(
  'GET_CUSTOMERS_PAYMENT_METHODS_FAILURE'
);

export const createCustomerPaymentMethodRequest = createAction(
  'CREATE_CUSTOMER_PAYMENT_METHOD_REQUEST'
);
export const createCustomerPaymentMethodSuccess = createAction(
  'CREATE_CUSTOMER_PAYMENT_METHOD_SUCCESS'
);
export const createCustomerPaymentMethodFailure = createAction(
  'CREATE_CUSTOMER_PAYMENT_METHOD_FAILURE'
);

export const attachCustomerDefaultPaymentMethodRequest = createAction(
  'ATTACH_CUSTOMER_DEFAULT_PAYMENT_METHOD_REQUEST'
);
export const attachCustomerDefaultPaymentMethodSuccess = createAction(
  'ATTACH_CUSTOMER_DEFAULT_PAYMENT_METHOD_SUCCESS'
);
export const attachCustomerDefaultPaymentMethodFailure = createAction(
  'ATTACH_CUSTOMER_DEFAULT_PAYMENT_METHOD_FAILURE'
);

export const deleteCustomerPaymentMethodRequest = createAction(
  'DELETE_CUSTOMER_PAYMENT_METHOD_REQUEST'
);
export const deleteCustomerPaymentMethodSuccess = createAction(
  'DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS'
);
export const deleteCustomerPaymentMethodFailure = createAction(
  'DELETE_CUSTOMER_PAYMENT_METHOD_FAILURE'
);
