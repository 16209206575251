import { createAction } from 'redux-actions';

export const createPromotionRequest = createAction('CREATE_PROMOTION_REQUEST');
export const createPromotionSuccess = createAction('CREATE_PROMOTION_SUCCESS');
export const createPromotionFailure = createAction('CREATE_PROMOTION_FAILURE');

export const getPromotionsRequest = createAction('GET_PROMOTIONS_REQUEST');
export const getPromotionsSuccess = createAction('GET_PROMOTIONS_SUCCESS');
export const getPromotionsFailure = createAction('GET_PROMOTIONS_FAILURE');

export const deletePromotionRequest = createAction('DELETE_PROMOTION_REQUEST');
export const deletePromotionSuccess = createAction('DELETE_PROMOTION_SUCCESS');
export const deletePromotionFailure = createAction('DELETE_PROMOTION_FAILURE');

export const updatePromotionRequest = createAction('UPDATE_PROMOTION_REQUEST');
export const updatePromotionSuccess = createAction('UPDATE_PROMOTION_SUCCESS');
export const updatePromotionFailure = createAction('UPDATE_PROMOTION_FAILURE');

export const enablePromotionRequest = createAction('ENABLE_PROMOTION_REQUEST');
export const enablePromotionSuccess = createAction('ENABLE_PROMOTION_SUCCESS');
export const enablePromotionFailure = createAction('ENABLE_PROMOTION_FAILURE');
