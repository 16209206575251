import { SolutionOutlined } from '@ant-design/icons';

const icons = { SolutionOutlined };

const customerOrders = {
  id: 'customer-orders',
  title: 'Orders',
  icon: icons.SolutionOutlined,
  type: 'item',
  resource: ['masterAgent', 'subAgent'],
  url: '/e-commerce/orders/customer-order',
};

export default customerOrders;
