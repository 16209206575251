import usePrevious from './usePrevious';

const usePreviousList = (items) => {
  const list = [];
  items.forEach((item) => {
    list.push(usePrevious(item));
  });

  return list;
};

export default usePreviousList;
