import { handleActions } from 'redux-actions';

import {
  createExternalPaymentRequest,
  createExternalPaymentSuccess,
  createExternalPaymentFailure,
  getExternalPaymentRequest,
  getExternalPaymentSuccess,
  getExternalPaymentFailure,
  getMonthlyProfitLossRequest,
  getMonthlyProfitLossSuccess,
  getMonthlyProfitLossFailure,
  getMonthlyRevenueRequest,
  getMonthlyRevenueSuccess,
  getMonthlyRevenueFailure,
  updateExternalPaymentRequest,
  updateExternalPaymentSuccess,
  updateExternalPaymentFailure,
  deleteExternalPaymentRequest,
  deleteExternalPaymentSuccess,
  deleteExternalPaymentFailure,
  getTotalCustomersProfitLossRequest,
  getTotalCustomersProfitLossSuccess,
  getTotalCustomersProfitLossFailure,
  getMonthlyExpensesRequest,
  getMonthlyExpensesSuccess,
  getMonthlyExpensesFailure,
  refreshProfitLossReportRequest,
  refreshProfitLossReportSuccess,
  refreshProfitLossReportFailure,
  refreshReportsRequest,
  refreshReportsSuccess,
  refreshReportsFailure,
} from './actions';

const initialState = {
  isCreateExternalPayment: false,
  isCreateExternalPaymentSuccess: false,
  isCreateExternalPaymentFailure: false,
  successMessage: '',
  errorMessage: '',
  isGettingExternalPayments: true,
  externalPaymentsList: [],
  externalPaymentsCount: 0,
  isGettingMonthlyProfitLoss: false,
  monthlyProfit: {},
  isGettingMonthlyRevenue: true,
  revenueData: {},
  isUpdatingExternalPayment: false,
  isUpdatingExternalPaymentSuccess: false,
  isUpdatingExternalPaymentFailure: false,
  isDeletingExternalPayment: false,
  isDeletingExternalPaymentSuccess: false,
  isDeletingExternalPaymentFailure: false,
  isGettingTotalCustomersProfitLoss: false,
  customerTotalReport: {},
  isGettingMonthlyExpenses: true,
  expensesData: {},
  isRefreshingProfitLossReport: false,
  isRefreshingProfitLossReportSuccess: false,
  isRefreshingReports: false,
  isRefreshingReportsSuccess: false,
};

const reducer = handleActions(
  {
    [createExternalPaymentRequest]: (state) => ({
      ...state,
      isCreateExternalPayment: true,
      isCreateExternalPaymentSuccess: false,
      isCreateExternalPaymentFailure: false,
    }),
    [createExternalPaymentSuccess]: (state, { payload }) => ({
      ...state,
      isCreateExternalPayment: false,
      isCreateExternalPaymentSuccess: true,
      successMessage: payload.message,
      externalPaymentsList: [...state.externalPaymentsList, payload.data],
    }),
    [createExternalPaymentFailure]: (state, { payload }) => ({
      ...state,
      isCreateExternalPayment: false,
      isCreateExternalPaymentFailure: true,
      errorMessage: payload,
    }),
    [getExternalPaymentRequest]: (state) => ({
      ...state,
      isGettingExternalPayments: true,
    }),
    [getExternalPaymentSuccess]: (state, { payload }) => ({
      ...state,
      isGettingExternalPayments: false,
      externalPaymentsList: payload.data.rows,
      externalPaymentsCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getExternalPaymentFailure]: (state, { payload }) => ({
      ...state,
      isGettingExternalPayments: false,
      errorMessage: payload,
    }),
    [getMonthlyProfitLossRequest]: (state) => ({
      ...state,
      isGettingMonthlyProfitLoss: true,
    }),
    [getMonthlyProfitLossSuccess]: (state, { payload }) => ({
      ...state,
      isGettingMonthlyProfitLoss: false,
      successMessage: payload.message,
      monthlyProfit: payload.data,
    }),
    [getMonthlyProfitLossFailure]: (state, { payload }) => ({
      ...state,
      isGettingMonthlyProfitLoss: false,
      errorMessage: payload,
    }),
    [getMonthlyRevenueRequest]: (state) => ({
      ...state,
      isGettingMonthlyRevenue: true,
    }),
    [getMonthlyRevenueSuccess]: (state, { payload }) => ({
      ...state,
      isGettingMonthlyRevenue: false,
      successMessage: payload.message,
      revenueData: payload.data,
    }),
    [getMonthlyRevenueFailure]: (state, { payload }) => ({
      ...state,
      isGettingMonthlyRevenue: false,
      errorMessage: payload,
    }),
    [updateExternalPaymentRequest]: (state) => ({
      ...state,
      isUpdatingExternalPayment: true,
      isUpdatingExternalPaymentSuccess: false,
      isUpdatingExternalPaymentFailure: false,
    }),
    [updateExternalPaymentSuccess]: (state, { payload }) => {
      const paymentsCopy = [...state.externalPaymentsList];
      const index = paymentsCopy.findIndex((p) => p.id === payload.data.id);
      if (index !== -1) {
        paymentsCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingExternalPayment: false,
        isUpdatingExternalPaymentSuccess: true,
        successMessage: payload.message,
        externalPaymentsList: paymentsCopy,
      };
    },
    [updateExternalPaymentFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingExternalPayment: false,
      isUpdatingExternalPaymentFailure: true,
      errorMessage: payload,
    }),
    [deleteExternalPaymentRequest]: (state) => ({
      ...state,
      isDeletingExternalPayment: true,
      isDeletingExternalPaymentSuccess: false,
      isDeletingExternalPaymentFailure: false,
    }),
    [deleteExternalPaymentSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingExternalPayment: false,
      isDeletingExternalPaymentSuccess: true,
      successMessage: payload.message,
      externalPaymentsList: state.externalPaymentsList.filter(
        (p) => p.id !== +payload.data
      ),
    }),
    [deleteExternalPaymentFailure]: (state, { payload }) => ({
      ...state,
      isDeletingExternalPayment: false,
      isDeletingExternalPaymentFailure: true,
      errorMessage: payload,
    }),
    [getTotalCustomersProfitLossRequest]: (state, { payload }) => ({
      ...state,
      isGettingTotalCustomersProfitLoss: true,
    }),
    [getTotalCustomersProfitLossSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTotalCustomersProfitLoss: false,
      successMessage: payload.message,
      customerTotalReport: payload.data,
    }),
    [getTotalCustomersProfitLossFailure]: (state, { payload }) => ({
      ...state,
      isGettingTotalCustomersProfitLoss: false,
      errorMessage: payload,
    }),
    [getMonthlyExpensesRequest]: (state) => ({
      ...state,
      isGettingMonthlyExpenses: true,
    }),
    [getMonthlyExpensesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingMonthlyExpenses: false,
      successMessage: payload.message,
      expensesData: payload.data,
    }),
    [getMonthlyExpensesFailure]: (state, { payload }) => ({
      ...state,
      isGettingMonthlyExpenses: false,
      errorMessage: payload,
    }),
    [refreshProfitLossReportRequest]: (state) => ({
      ...state,
      isRefreshingProfitLossReport: true,
      isRefreshingProfitLossReportSuccess: false,
    }),
    [refreshProfitLossReportSuccess]: (state, { payload }) => ({
      ...state,
      isRefreshingProfitLossReport: false,
      isRefreshingProfitLossReportSuccess: true,
      successMessage: payload.message,
      monthlyProfit: payload.data,
    }),
    [refreshProfitLossReportFailure]: (state, { payload }) => ({
      ...state,
      isRefreshingProfitLossReport: false,
      errorMessage: payload,
    }),
    [refreshReportsRequest]: (state) => ({
      ...state,
      isRefreshingReports: true,
      isRefreshingReportsSuccess: false,
    }),
    [refreshReportsSuccess]: (state, { payload }) => ({
      ...state,
      isRefreshingReports: false,
      isRefreshingReportsSuccess: true,
      successMessage: payload.message,
    }),
    [refreshReportsFailure]: (state, { payload }) => ({
      ...state,
      isRefreshingReports: false,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
