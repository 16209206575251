import { handleActions } from 'redux-actions';

import {
  getMetricsRequest,
  getMetricsSuccess,
  getMetricsFailure,
  getMetricsTotalSuccess,
  getMetricsTotalFailure,
  getMetricsTotalRequest,
  getPlansStatisticsRequest,
  getPlansStatisticsSuccess,
  getPlansStatisticsFailure,
  getProfitLossChartRequest,
  getProfitLossChartSuccess,
  getProfitLossChartFailure,
} from './actions';

const initialState = {
  isGettingMetrics: true,
  isGettingMetricsSuccess: false,
  isGettingMetricsFailure: false,
  metrics: [],
  isGettingMetricsTotal: true,
  isGettingMetricsTotalSuccess: false,
  isGettingMetricsTotalFailure: false,
  metricsTotal: [],
  isGettingPlanStatistics: true,
  isGettingPlanStatisticsSuccess: false,
  isGettingPlanStatisticsFailure: false,
  planStatistics: [],
  isGettingProfitLossChart: false,
  chartData: {},
};

const reducer = handleActions(
  {
    [getMetricsRequest]: (state) => ({
      ...state,
      isGettingMetrics: true,
      isGettingMetricsSuccess: false,
      isGettingMetricsFailure: false,
    }),
    [getMetricsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingMetrics: false,
      isGettingMetricsSuccess: true,
      isGettingMetricsFailure: false,
      metrics: payload.data,
    }),
    [getMetricsFailure]: (state, { payload }) => ({
      ...state,
      isGettingMetrics: false,
      isGettingMetricsSuccess: false,
      isGettingMetricsFailure: true,
      errorMessage: payload,
    }),
    [getMetricsTotalRequest]: (state) => ({
      ...state,
      isGettingMetricsTotal: true,
      isGettingMetricsTotalSuccess: false,
      isGettingMetricsTotalFailure: false,
    }),
    [getMetricsTotalSuccess]: (state, { payload }) => ({
      ...state,
      isGettingMetricsTotal: false,
      isGettingMetricsTotalSuccess: true,
      metricsTotal: payload.data,
    }),
    [getMetricsTotalFailure]: (state, { payload }) => ({
      ...state,
      isGettingMetricsTotal: false,
      isGettingMetricsTotalFailure: true,
      errorMessage: payload,
    }),
    [getPlansStatisticsRequest]: (state) => ({
      ...state,
      isGettingPlanStatistics: true,
      isGettingPlanStatisticsSuccess: false,
      isGettingPlanStatisticsFailure: false,
    }),
    [getPlansStatisticsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPlanStatistics: false,
      isGettingPlanStatisticsSuccess: true,
      successMessage: payload.message,
      planStatistics: payload.data,
    }),
    [getPlansStatisticsFailure]: (state, { payload }) => ({
      ...state,
      isGettingPlanStatistics: false,
      isGettingPlanStatisticsFailure: true,
      errorMessage: payload,
    }),
    [getProfitLossChartRequest]: (state) => ({
      ...state,
      isGettingProfitLossChart: true,
    }),
    [getProfitLossChartSuccess]: (state, { payload }) => ({
      ...state,
      isGettingProfitLossChart: false,
      successMessage: payload.message,
      chartData: payload.data,
    }),
    [getProfitLossChartFailure]: (state, { payload }) => ({
      ...state,
      isGettingProfitLossChart: false,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
