export const getLookupKeys = (customerCartData) => {
  try {
    let lookupKeys = [];
    let resultArray = [];

    customerCartData.forEach((item) => {
      if (
        item.plan &&
        item.plan.stripeProduct &&
        item.plan.stripeProduct.prices &&
        item.plan.stripeProduct.prices.length > 0
      ) {
        const lastPrice = item.plan.stripeProduct.prices.at(-1);
        lookupKeys.push({
          key: lastPrice?.lookup_key,
          retailPlanId: item.plan.id,
          stripeProductId: lastPrice.stripeProductId,
          quantity: 1,
        });
      }

      if (
        item.variant &&
        item.variant.stripeProduct &&
        item.variant.stripeProduct?.prices &&
        item.variant.stripeProduct?.prices.length > 0
      ) {
        const stripeProductId = item?.stripeProductPriceId;

        const currentPrice = item.variant.stripeProduct?.prices.find(
          (key) => key.id === stripeProductId
        );

        lookupKeys.push({
          key: currentPrice?.lookup_key,
          variantId: item.variant.id,
          stripeProductId: currentPrice.id,
          quantity: 1,
        });
      }
    });

    lookupKeys.forEach((item) => {
      const existingItem = resultArray.find(
        (existing) =>
          (existing.key === item.key &&
            existing.retailPlanId === item.retailPlanId) ||
          (existing.key === item.key &&
            existing.stripeProductId === item.stripeProductId)
      );

      if (existingItem) {
        // If item already exists, update quantity
        existingItem.quantity += item.quantity;
      } else {
        // If item doesn't exist, add it to the result array
        resultArray.push({ ...item });
      }
    });

    return resultArray;
  } catch (e) {
    console.log('Error lookupKey:', e);
  }
};
