import { handleActions } from 'redux-actions';
import {
  saveSmtpConfigsRequest,
  saveSmtpConfigsSuccess,
  saveSmtpConfigsFailure,
  getSmtpConfigsRequest,
  getSmtpConfigsSuccess,
  getSmtpConfigsFailure,
} from './actions';

const initialState = {
  successMessage: '',
  errorMessage: '',
  isSavingSmtpConfigs: false,
  isSavingSmtpConfigsFailure: false,
  isSavingSmtpConfigsSuccess: false,
  isGettingSmtpConfigs: true,
  isGettingSmtpConfigsSuccess: false,
  isGettingSmtpConfigsFailure: false,
  configs: {},
};

const reducer = handleActions(
  {
    [saveSmtpConfigsRequest]: (state) => ({
      ...state,
      isSavingSmtpConfigs: true,
      isSavingSmtpConfigsFailure: false,
      isSavingSmtpConfigsSuccess: false,
    }),
    [saveSmtpConfigsSuccess]: (state, { payload }) => ({
      ...state,
      isSavingSmtpConfigs: false,
      isSavingSmtpConfigsSuccess: true,
      successMessage: payload.message,
    }),
    [saveSmtpConfigsFailure]: (state, { payload }) => ({
      ...state,
      isSavingSmtpConfigs: false,
      isSavingSmtpConfigsFailure: true,
      errorMessage: payload,
    }),
    [getSmtpConfigsRequest]: (state) => ({
      ...state,
      isGettingSmtpConfigs: true,
      isGettingSmtpConfigsSuccess: false,
      isGettingSmtpConfigsFailure: false,
    }),
    [getSmtpConfigsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSmtpConfigs: false,
      isGettingSmtpConfigsSuccess: true,
      successMessage: payload.message,
      configs: payload.data,
    }),
    [getSmtpConfigsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSmtpConfigs: false,
      isGettingSmtpConfigsFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
