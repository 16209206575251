import { axiosApiInstance, config } from 'custom-configs';

import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  saveSmtpConfigsRequest,
  saveSmtpConfigsSuccess,
  saveSmtpConfigsFailure,
  getSmtpConfigsSuccess,
  getSmtpConfigsFailure,
  getSmtpConfigsRequest,
} from './actions';

const URL = `${config.TENANT}/settings`;

function* saveSmtpConfigs({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/smtp/save`, payload)
    );
    if (response?.status === 200) {
      yield put(saveSmtpConfigsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for saveSmtpConfigs, error', e);
    if (e?.response?.data) {
      yield put(saveSmtpConfigsFailure(catchResponseMessages(e)));
    }
  }
}

function* getConfigs() {
  try {
    const response = yield call(() => axiosApiInstance.get(`${URL}/smtp`));
    if (response?.status === 200) {
      yield put(getSmtpConfigsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getConfigs, error', e);
    if (e?.response?.data) {
      yield put(getSmtpConfigsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(saveSmtpConfigsRequest, saveSmtpConfigs);
  yield takeLatest(getSmtpConfigsRequest, getConfigs);
}
