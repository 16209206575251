import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  loginAsUserRequest,
  loginAsUserFailure,
  loginAsAgentFailure,
  loginAsAgentRequest,
  getAuthUserSuccess,
  getAuthUserFailure,
  getAuthUserRequest,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  checkVerificationCodeRequest,
  checkVerificationCodeSuccess,
  checkVerificationCodeFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  loginTenantsUsersSuccess,
  loginTenantsUsersFailure,
  loginTenantsUsersRequest,
  registerTenantUserSuccess,
  registerTenantUserFailure,
  registerTenantUserRequest,
  resendCodeSuccess,
  resendCodeFailure,
  resendCodeRequest,
  verifyTenantUserSuccess,
  verifyTenantUserFailure,
  verifyTenantUserRequest,
  loginAsCustomerSuccess,
  loginAsCustomerFailure,
  loginAsCustomerRequest,
} from './actions';
import { axiosApiInstance, config } from 'custom-configs';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages, getDefaultPathByRole } from 'utils/methods';
import { isPortal } from 'utils/windowUtils';

const URL = `${config.DOMAIN}/auth`;
const URL_TENANT = `${config.TENANT}/auth`;

const isAdminOrTenant = isPortal() ? URL_TENANT : URL;

function* auth({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/login`, payload)
    );
    if (response?.status === 200) {
      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      window.location.replace(getDefaultPathByRole('admin'));

      yield put(loginSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for auth, error`, e);
    if (e?.response?.data) {
      yield put(loginFailure(catchResponseMessages(e)));
    }
  }
}
function* logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('adminAccessToken');
  localStorage.removeItem('adminUserData');
  localStorage.removeItem('tenantUserData');
  localStorage.removeItem('tenantAccessToken');
  localStorage.removeItem('userData');
  yield put(logoutSuccess());
}

function* loginAsUser(action) {
  try {
    const { payload } = action;
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/login-as-user`, payload)
    );
    if (response?.status === 200) {
      localStorage.setItem('adminUserData', localStorage.getItem('userData'));
      localStorage.setItem(
        'adminAccessToken',
        localStorage.getItem('accessToken')
      );
      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      window.location.replace(getDefaultPathByRole(response.data.user?.role));
    }
  } catch (e) {
    console.log(`Catch for loginAsUser, error`, e);
    if (e?.response?.data) {
      yield put(loginAsUserFailure(catchResponseMessages(e)));
    }
  }
}

function* loginAsAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/login-as-agent`, payload)
    );
    if (response?.status === 200) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData.role === 'masterAgent') {
        localStorage.setItem(
          'masterAgentUserData',
          localStorage.getItem('userData')
        );
        localStorage.setItem(
          'masterAgentAccessToken',
          localStorage.getItem('accessToken')
        );
      } else {
        localStorage.setItem(
          'tenantUserData',
          localStorage.getItem('userData')
        );
        localStorage.setItem(
          'tenantAccessToken',
          localStorage.getItem('accessToken')
        );
      }

      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      window.location.replace(getDefaultPathByRole(response.data.user?.role));
    }
  } catch (e) {
    console.log(`Catch for loginAsAgent, error`, e);
    if (e?.response?.data) {
      yield put(loginAsAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${isAdminOrTenant}/forgot-password`, payload)
    );

    if (response?.status === 200) {
      yield put(forgotPasswordSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for forgotPassword, error`, e);
    if (e?.response?.data) {
      yield put(forgotPasswordFailure(catchResponseMessages(e)));
    }
  }
}

function* checkVerificationCode({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${isAdminOrTenant}/check-verification-code`,
        payload
      )
    );

    if (response?.status === 200) {
      yield put(checkVerificationCodeSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for forgotPassword, error`, e);
    if (e?.response?.data) {
      yield put(checkVerificationCodeFailure(catchResponseMessages(e)));
    }
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${isAdminOrTenant}/reset-password`, payload)
    );

    if (response?.status === 200) {
      yield put(resetPasswordSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for forgotPassword, error`, e);
    if (e?.response?.data) {
      yield put(resetPasswordFailure(catchResponseMessages(e)));
    }
  }
}

function* getAuthUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${!['admin', 'tenant'].includes(payload) ? URL_TENANT : URL}/user`
      )
    );
    if (response?.status === 200) {
      yield put(getAuthUserSuccess(response.data.data));
    }
  } catch (e) {
    console.log(`Catch for getAuthUser, error`, e);
    yield put(getAuthUserFailure(catchResponseMessages(e)));
  }
}

function* authTenantsUsers({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/login`, payload)
    );
    if (response?.status === 200) {
      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      window.location.replace(getDefaultPathByRole('tenant'));
      yield put(loginTenantsUsersSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for authTenantsUsers, error`, e);
    if (e?.response?.data) {
      yield put(loginTenantsUsersFailure(catchResponseMessages(e)));
    }
  }
}

function* registerTenantUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/register`, payload)
    );
    if (response?.status === 200) {
      yield put(registerTenantUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for registerTenantUser, error`, e);
    if (e?.response?.data) {
      yield put(registerTenantUserFailure(catchResponseMessages(e)));
    }
  }
}

function* resendCode({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/resend`, payload)
    );
    if (response?.status === 200) {
      yield put(resendCodeSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for resendCode, error`, e);
    if (e?.response?.data) {
      yield put(resendCodeFailure(catchResponseMessages(e)));
    }
  }
}

function* verifyUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/verify`, payload)
    );
    if (response?.status === 200) {
      yield put(verifyTenantUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for verifyUser, error`, e);
    if (e?.response?.data) {
      yield put(verifyTenantUserFailure(catchResponseMessages(e)));
    }
  }
}
function* loginAsCustomer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/login-as-customer`, payload)
    );
    if (response?.status === 200) {
      yield put(loginAsCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for loginAsCustomer, error`, e);
    if (e?.response?.data) {
      yield put(loginAsCustomerFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(loginRequest, auth);
  yield takeLatest(logoutRequest, logout);
  yield takeLatest(loginAsUserRequest, loginAsUser);
  yield takeLatest(loginAsAgentRequest, loginAsAgent);
  yield takeLatest(forgotPasswordRequest, forgotPassword);
  yield takeLatest(checkVerificationCodeRequest, checkVerificationCode);
  yield takeLatest(resetPasswordRequest, resetPassword);
  yield takeLatest(getAuthUserRequest, getAuthUser);
  yield takeLatest(loginTenantsUsersRequest, authTenantsUsers);
  yield takeLatest(registerTenantUserRequest, registerTenantUser);
  yield takeLatest(resendCodeRequest, resendCode);
  yield takeLatest(verifyTenantUserRequest, verifyUser);
  yield takeLatest(loginAsCustomerRequest, loginAsCustomer);
}
