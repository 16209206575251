import { useEffect, useState, useContext } from 'react';

import { useSelector } from 'react-redux';
import { dispatch as reduxDispatch } from 'redux/store';

import usePreviousList from './utils/hooks/usePreviousList';
import Notistack from 'components/third-party/Notistack';
import Snackbar from 'components/@extended/Snackbar';
import { isUserLoggedIn } from './utils/methods';
import ScrollTop from 'components/ScrollTop';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import Loader from 'components/Loader';
import Routes from 'routes';

import { getSiteThemeRequest } from './redux/settings/siteSettings/actions';
import { getAuthUserRequest, updateBalance } from './redux/auth/actions';
import { ConfirmProvider } from 'material-ui-confirm';
import { fetchDashboard } from 'store/reducers/menu';
import { SocketContext } from 'contexts/Socket';
import { dispatch } from 'store';

const App = () => {
  const socket = useContext(SocketContext);
  const { isGetAuthUserSuccess, isGetAuthUserFailure } = useSelector(
    (state) => state.auth
  );
  const [loading, setLoading] = useState(false);
  const { userData } = isUserLoggedIn();

  const [prevIsGetAuthUserSuccess, prevIsGetAuthUserFailure] = usePreviousList([
    isGetAuthUserSuccess,
    isGetAuthUserFailure,
  ]);

  const onUserBalanceUpdate = (data) => {
    reduxDispatch(updateBalance(+data.balance));
  };

  useEffect(() => {
    dispatch(fetchDashboard()).then(() => {
      setLoading(true);
    });

    if (userData.role && userData.role !== 'admin') {
      reduxDispatch(getSiteThemeRequest()); // TODO optimize this endpoint it is broking the server
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      reduxDispatch(getAuthUserRequest(userData?.role));
    } else {
      setLoading(true);
    }

    return () => {
      if (socket) {
        socket.off(`user-balance-updated-${userData.id}`, onUserBalanceUpdate);
      }
    };
  }, []);

  useEffect(() => {
    if (isGetAuthUserSuccess && prevIsGetAuthUserSuccess === false) {
      socket.emit('join', { userId: userData?.id });

      socket.on(`user-balance-updated-${userData?.id}`, onUserBalanceUpdate);
      setLoading(true);
    } else if (isGetAuthUserFailure && prevIsGetAuthUserFailure === false) {
      localStorage.removeItem('accessToken');
      setLoading(true);
    }
  }, [isGetAuthUserSuccess, isGetAuthUserFailure]);

  if (!loading) return <Loader />;
  return (
    <ThemeCustomization>
      <ConfirmProvider>
        <Locales>
          <ScrollTop>
            <Notistack>
              <Routes role={userData?.role} />
              <Snackbar />
            </Notistack>
          </ScrollTop>
        </Locales>
      </ConfirmProvider>
    </ThemeCustomization>
  );
};

export default App;
