import { FormattedMessage } from 'react-intl';

import { UsergroupDeleteOutlined, UserSwitchOutlined } from '@ant-design/icons';

const icons = {
  UsergroupDeleteOutlined,
  UserSwitchOutlined,
};

const agents = [
  {
    id: 'group-agents',
    title: <FormattedMessage id="Agents" />,
    icon: icons.UsergroupDeleteOutlined,
    type: 'collapse',
    resource: ['tenant', 'all'],
    children: [
      {
        id: 'MasterAgents',
        title: 'Master Agents',
        type: 'item',
        url: '/agents/master-agents',
      },
      {
        id: 'SubAgents',
        title: 'Sub Agents',
        type: 'item',
        url: '/agents/sub-agents',
      },
    ],
  },
  {
    id: 'item-sub-agent',
    title: 'Sub Agents',
    icon: icons.UserSwitchOutlined,
    type: 'item',
    resource: ['masterAgent'],
    url: '/agents/sub-agents',
  },
];
export default agents;
