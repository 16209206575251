import { SolutionOutlined } from '@ant-design/icons';

const icons = { SolutionOutlined };

const orders = {
  id: 'E-commerce-orders',
  title: 'Orders',
  icon: icons.SolutionOutlined,
  type: 'collapse',
  resource: ['tenant', 'all'],
  children: [
    {
      id: 'customer-order',
      title: 'Customer Order',
      type: 'item',
      url: '/e-commerce/orders/customer-order',
    },
    {
      id: 'agent-order',
      title: 'Agent Order',
      type: 'item',
      url: '/e-commerce/orders/agent-order',
    },
    // {
    //   id: 'Order-History',
    //   title: 'Order History',
    //   type: 'item',
    //   url: '/e-commerce/orders/history',
    // },
  ],
};

export default orders;
