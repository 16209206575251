import { axiosApiInstance, config } from '../../custom-configs';
import { catchResponseMessages } from '../../utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getMailingListFailure,
  getMailingListRequest,
  getMailingListSuccess,
} from './actions';

const URL_TENANT = `${config.TENANT}/customer`;

function* getMailingList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/email-subscriptions${payload}`)
    );
    if (response?.status === 200) {
      yield put(getMailingListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for mailingList, error`, e);
    if (e?.response?.data) {
      yield put(getMailingListFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getMailingListRequest, getMailingList);
}
