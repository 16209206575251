import { createAction } from 'redux-actions';

export const createVendorRequest = createAction('CREATE_VENDOR_REQUEST');
export const createVendorSuccess = createAction('CREATE_VENDOR_SUCCESS');
export const createVendorFailure = createAction('CREATE_VENDOR_FAILURE');

export const getVendorsListRequest = createAction('GET_VENDORS_LIST_REQUEST');
export const getVendorsListSuccess = createAction('GET_VENDORS_LIST_SUCCESS');
export const getVendorsListFailure = createAction('GET_VENDORS_LIST_FAILURE');

export const handleVendorData = createAction('HANDLE_VENDOR_DATA');

export const updateVendorRequest = createAction('UPDATE_VENDOR_REQUEST');
export const updateVendorSuccess = createAction('UPDATE_VENDOR_SUCCESS');
export const updateVendorFailure = createAction('UPDATE_VENDOR_FAILURE');

export const deleteVendorRequest = createAction('DELETE_VENDOR_REQUEST');
export const deleteVendorSuccess = createAction('DELETE_VENDOR_SUCCESS');
export const deleteVendorFailure = createAction('DELETE_VENDOR_FAILURE');
