import { handleActions } from 'redux-actions';
import {
  clearAccessoryData,
  clearDeviceData,
  createAccessoriesFailure,
  createAccessoriesRequest,
  createAccessoriesSuccess,
  createDeviceFailure,
  createDeviceRequest,
  createDeviceSuccess,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,
  deleteVariantFailure,
  deleteVariantFileFailure,
  deleteVariantFileRequest,
  deleteVariantFileSuccess,
  deleteVariantRequest,
  deleteVariantSuccess,
  findAccessoryDataRequest,
  findAccessoryDataSuccess,
  findDeviceRequest,
  findDeviceSuccess,
  getAccessoriesListRequest,
  getAccessoriesListSuccess,
  getCategorySelectFailure,
  getCategorySelectRequest,
  getCategorySelectSuccess,
  getDevicesListFailure,
  getDevicesListRequest,
  getDevicesListSuccess,
  getManufacturerSelectFailure,
  getManufacturerSelectRequest,
  getManufacturerSelectSuccess,
  getVendorSelectFailure,
  getVendorSelectRequest,
  getVendorSelectSuccess,
  removeFilesFailure,
  removeFilesRequest,
  removeFilesSuccess,
  updateAccessoryFailure,
  updateAccessoryRequest,
  updateAccessorySuccess,
  updateDeviceFailure,
  updateDeviceRequest,
  updateDeviceSuccess,
  getDevicesSelectDataRequest,
  getDevicesSelectDataSuccess,
  getVariantsSelectDataRequest,
  getVariantsSelectDataSuccess,
  getUsedCategoriesSelectRequest,
  getUsedCategoriesSelectSuccess,
  getUsedCategoriesSelectFailure,
  duplicateDeviceRequest,
  duplicateDeviceSuccess,
  duplicateDeviceFailure,
  makeImageMainRequest,
  makeImageMainSuccess,
  makeImageMainFailure,
  getAllVariantsSelectOptionsRequest,
  getAllVariantsSelectOptionsFailure,
  getAllVariantsSelectOptionsSuccess,
} from './actions';

const initialState = {
  devices: [],
  devicesCount: 0,
  categorySelect: [],
  manufacturerSelect: [],
  vendorsSelect: [],
  isGettingCategoryListFailure: false,
  successMessage: '',
  errorMessage: '',
  isGettingManufacturerSelectFailure: false,
  isGettingVendorSelectFailure: false,
  isCreatingDevice: false,
  isCreatingDeviceSuccess: false,
  isCreatingDeviceFailure: false,
  isGettingDevicesList: true,
  isGettingDevicesListSuccess: false,
  isGettingDevicesListFailure: false,
  isDeletedProductSuccess: false,
  isDeletedProductFailure: false,
  deviceData: {},
  isGettingDevice: true,
  isUpdatingDevice: false,
  isUpdatingDeviceSuccess: false,
  isUpdatingDeviceFailure: false,
  isDeletingVariantSuccess: false,
  isDeletingVariantFailure: false,
  isDeletingVariantFileSuccess: false,
  isDeletingVariantFileFailure: false,
  isCreatingAccessory: false,
  isCreatingAccessorySuccess: false,
  isCreatingAccessoryFailure: false,
  isGettingAccessoriesList: true,
  accessoriesCount: 0,
  accessories: [],
  accessoryData: {},
  isGettingAccessoryData: false,
  isUpdatingAccessory: false,
  isUpdatingAccessorySuccess: false,
  isUpdatingAccessoryFailure: false,
  isGettingDevicesSelectData: false,
  devicesSelectData: [],
  isGettingVariantsSelectData: false,
  variantsSelectData: [],
  isGettingCategoriesSelectData: false,
  categorySelectData: [],
  isDuplicating: false,
  isDuplicatingSuccess: false,
  isDuplicatingFailure: false,
  isMakingMain: false,
  isMakingMainSuccess: false,
  isMakingMainFailure: false,
  isGettingVariantsSelectOptions: false,
  allVariantsSelectData: [],
};

const reducer = handleActions(
  {
    [getCategorySelectRequest]: (state) => ({
      ...state,
      isGettingCategoryListFailure: false,
    }),
    [getCategorySelectSuccess]: (state, { payload }) => ({
      ...state,
      categorySelect: payload.data,
    }),
    [getCategorySelectFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      isGettingCategorySelectFailure: true,
    }),
    [getManufacturerSelectRequest]: (state) => ({
      ...state,
      isGettingManufacturerSelectFailure: false,
    }),
    [getManufacturerSelectSuccess]: (state, { payload }) => ({
      ...state,
      manufacturerSelect: payload.data,
    }),
    [getManufacturerSelectFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      isGettingManufacturerSelectFailure: true,
    }),
    [getVendorSelectRequest]: (state) => ({
      ...state,
      isGettingVendorSelectFailure: false,
    }),
    [getVendorSelectSuccess]: (state, { payload }) => ({
      ...state,
      vendorsSelect: payload.data,
    }),
    [getVendorSelectFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      isGettingVendorSelectFailure: true,
    }),
    [createDeviceRequest]: (state) => ({
      ...state,
      isCreatingDevice: true,
      isCreatingDeviceSuccess: false,
      isCreatingDeviceFailure: false,
    }),
    [createDeviceSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingDevice: false,
      isCreatingDeviceSuccess: true,
      successMessage: payload.message,
    }),
    [createDeviceFailure]: (state, { payload }) => ({
      ...state,
      isCreatingDevice: false,
      isCreatingDeviceFailure: true,
      errorMessage: payload,
    }),
    [getDevicesListRequest]: (state) => ({
      ...state,
      isGettingDevicesList: true,
      isGettingDevicesListSuccess: false,
      isGettingDevicesListFailure: false,
    }),
    [getDevicesListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDevicesList: false,
      isGettingDevicesListSuccess: true,
      successMessage: payload.message,
      devices: payload.data.rows,
      devicesCount: payload.data.count,
    }),
    [getDevicesListFailure]: (state, { payload }) => ({
      ...state,
      isGettingDevicesList: false,
      isGettingDevicesListFailure: true,
      errorMessage: payload,
    }),
    [deleteProductRequest]: (state) => ({
      ...state,
      isDeletedProductSuccess: false,
      isDeletedProductFailure: false,
    }),
    [deleteProductSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedProductSuccess: true,
      successMessage: payload.message,
      devices: state.devices.filter((i) => i.id !== payload.data),
      devicesCount: state.devicesCount - 1,
      accessories: state.accessories.filter((i) => i.id !== payload.data),
      accessoriesCount: state.accessoriesCount - 1,
    }),
    [deleteProductFailure]: (state, { payload }) => ({
      ...state,
      isDeletedProductFailure: true,
      errorMessage: payload,
    }),
    [findDeviceRequest]: (state) => ({
      ...state,
      isGettingDevice: true,
    }),
    [findDeviceSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDevice: false,
      deviceData: payload.data,
    }),
    [removeFilesRequest]: (state) => ({
      ...state,
      isRemovedFilesSuccess: false,
      isRemovedFilesFailure: false,
    }),
    [removeFilesSuccess]: (state, { payload }) => ({
      ...state,
      isRemovedFilesSuccess: true,
      successMessage: payload.message,
    }),
    [removeFilesFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      isRemovedFilesFailure: true,
    }),
    [updateDeviceRequest]: (state) => ({
      ...state,
      isUpdatingDevice: true,
      isUpdatingDeviceSuccess: false,
      isUpdatingDeviceFailure: false,
    }),
    [updateDeviceSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingDevice: false,
      isUpdatingDeviceSuccess: true,
      successMessage: payload.message,
      deviceData: payload.data,
    }),
    [updateDeviceFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingDevice: false,
      isUpdatingDeviceFailure: true,
      errorMessage: payload,
    }),
    [clearDeviceData]: (state) => ({
      ...state,
      deviceData: {},
    }),
    [deleteVariantRequest]: (state) => ({
      ...state,
      isDeletingVariantSuccess: false,
      isDeletingVariantFailure: false,
    }),
    [deleteVariantSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingVariantSuccess: true,
      successMessage: payload.message,
    }),
    [deleteVariantFailure]: (state, { payload }) => ({
      ...state,
      isDeletingVariantFailure: true,
      errorMessage: payload,
    }),
    [deleteVariantFileRequest]: (state) => ({
      ...state,
      isDeletingVariantFileSuccess: false,
      isDeletingVariantFileFailure: false,
    }),
    [deleteVariantFileSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingVariantFileSuccess: true,
      successMessage: payload.message,
    }),
    [deleteVariantFileFailure]: (state, { payload }) => ({
      ...state,
      isDeletingVariantFileFailure: true,
      errorMessage: payload,
    }),
    [createAccessoriesRequest]: (state) => ({
      ...state,
      isCreatingAccessory: true,
      isCreatingAccessorySuccess: false,
      isCreatingAccessoryFailure: false,
    }),
    [createAccessoriesSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingAccessory: false,
      isCreatingAccessorySuccess: true,
      successMessage: payload.message,
    }),
    [createAccessoriesFailure]: (state, { payload }) => ({
      ...state,
      isCreatingAccessory: false,
      isCreatingAccessoryFailure: true,
      errorMessage: payload,
    }),
    [getAccessoriesListRequest]: (state) => ({
      ...state,
      isGettingAccessoriesList: true,
    }),
    [getAccessoriesListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAccessoriesList: false,
      accessories: payload.data.rows,
      accessoriesCount: payload.data.count,
      message: payload.message,
    }),
    [findAccessoryDataRequest]: (state) => ({
      ...state,
      isGettingAccessoryData: true,
    }),
    [findAccessoryDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAccessoryData: false,
      accessoryData: payload.data,
      message: payload.message,
    }),
    [clearAccessoryData]: (state) => ({
      ...state,
      accessoryData: {},
    }),
    [updateAccessoryRequest]: (state) => ({
      ...state,
      isUpdatingAccessory: true,
      isUpdatingAccessorySuccess: false,
      isUpdatingAccessoryFailure: false,
    }),
    [updateAccessorySuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingAccessory: false,
      isUpdatingAccessorySuccess: true,
      successMessage: payload.message,
    }),
    [updateAccessoryFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingAccessory: false,
      isUpdatingAccessoryFailure: true,
      errorMessage: payload,
    }),
    [getDevicesSelectDataRequest]: (state) => ({
      ...state,
      isGettingDevicesSelectData: true,
    }),
    [getDevicesSelectDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDevicesSelectData: false,
      successMessage: payload.message,
      devicesSelectData: payload.data,
    }),
    [getVariantsSelectDataRequest]: (state) => ({
      ...state,
      isGettingVariantsSelectData: true,
    }),
    [getVariantsSelectDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingVariantsSelectData: false,
      variantsSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getUsedCategoriesSelectRequest]: (state) => ({
      ...state,
      isGettingCategoriesSelectData: true,
    }),
    [getUsedCategoriesSelectSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCategoriesSelectData: false,
      categorySelectData: payload.data,
      successMessage: payload.message,
    }),
    [getUsedCategoriesSelectFailure]: (state, { payload }) => ({
      ...state,
      isGettingCategoriesSelectData: false,
      errorMessage: payload,
    }),
    [duplicateDeviceRequest]: (state) => ({
      ...state,
      isDuplicating: true,
      isDuplicatingSuccess: false,
      isDuplicatingFailure: false,
    }),
    [duplicateDeviceSuccess]: (state, { payload }) => ({
      ...state,
      isDuplicating: false,
      isDuplicatingSuccess: true,
      successMessage: payload.message,
      devices: [...state.devices, payload.data],
      devicesCount: state.devicesCount + 1,
    }),
    [duplicateDeviceFailure]: (state, { payload }) => ({
      ...state,
      isDuplicating: false,
      isDuplicatingFailure: true,
      errorMessage: payload,
    }),
    [makeImageMainRequest]: (state) => ({
      ...state,
      isMakingMain: true,
      isMakingMainSuccess: false,
      isMakingMainFailure: false,
    }),
    [makeImageMainSuccess]: (state, { payload }) => ({
      ...state,
      isMakingMain: false,
      isMakingMainSuccess: true,
      successMessage: payload.message,
    }),
    [makeImageMainFailure]: (state, { payload }) => ({
      ...state,
      isMakingMain: false,
      isMakingMainFailure: true,
      errorMessage: payload,
    }),
    [getAllVariantsSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingVariantsSelectOptions: true,
    }),
    [getAllVariantsSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingVariantsSelectOptions: false,
      allVariantsSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getAllVariantsSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingVariantsSelectOptions: false,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
