import services from 'utils/mockAdapter';

import { DashboardOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined,
};

const dashboard = {
  id: 'dashboard',
  type: 'item',
  title: 'Dashboard',
  icon: icons.DashboardOutlined,
  url: '/dashboard/default',
  resource: ['tenant', 'masterAgent', 'subAgent'],
};
export default dashboard;

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard: dashboard });
