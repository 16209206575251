import { handleActions } from 'redux-actions';
import {
  getAcpDevicesRequest,
  getAcpDevicesSuccess,
  getAcpDevicesFailure,
  addToCartRequest,
  addToCartSuccess,
  addToCartFailure,
  updateProductCartRequest,
  updateProductCartSuccess,
  updateProductCartFailure,
  deleteFromCartRequest,
  deleteFromCartSuccess,
  deleteFromCartFailure,
  getCartDataRequest,
  getCartDataSuccess,
  getCartDataFailure,
  getShippingAddressesRequest,
  getShippingAddressesSuccess,
  getShippingAddressesFailure,
  selectShippingPriceRequest,
  getPlansRequest,
  getPlansSuccess,
  getPlansFailure,
  selectPlanRequest,
  selectPlanSuccess,
  selectPlanFailure,
  removeSelectedPlan,
  checkPhoneNumberRequest,
  checkPhoneNumberSuccess,
  checkPhoneNumberFailure,
  portInPhoneNumberRequest,
  portInPhoneNumberSuccess,
  portInPhoneNumberFailure,
  createCustomerOrderRequest,
  createCustomerOrderSuccess,
  createCustomerOrderFailure,
  createHandoverOrderRequest,
  createHandoverOrderSuccess,
  createHandoverOrderFailure,
  resetShippingAddresses,
} from './actions';

import {
  getTotalAmount,
  shippingAmount,
  totalShippingAmount,
} from 'utils/constants';
import { getLookupKeys } from 'utils/getLookupKeys';

const initialState = {
  isGetAcpDevices: false,
  isGetAcpDevicesSuccess: false,
  isGetAcpDevicesFailure: false,
  acpDevicesData: [],
  devicesData: [],
  totalAmount: 0,
  lookupKeys: [],
  shippingPrice: { price: 0, shippingPrice: null, rateId: '' },

  isAddToCart: false,
  isAddToCartSuccess: false,
  isAddToCartFailure: false,

  isUpdateProductCart: false,
  isUpdateProductCartSuccess: false,
  isUpdateProductCartFailure: false,

  isDeleteFromCart: false,
  isDeleteFromCartSuccess: false,
  isDeleteFromCartFailure: false,

  isGetCartData: true,
  isGetCartDataSuccess: false,
  isGetCartDataFailure: false,
  cartData: [],
  cartDataCount: 0,

  isGetShippingAddresses: false,
  isGetShippingAddressesSuccess: false,
  isGetShippingAddressesFailure: false,
  shippingAddresses: [],
  shippingDetails: {},

  isGetPlans: false,
  isGetPlansSuccess: false,
  isGetPlansFailure: false,
  plansData: [],

  isSelectPlan: false,
  isSelectPlanSuccess: false,
  isSelectPlanFailure: false,
  selectedPlan: {},

  isCheckPhoneNumber: false,
  isCheckPhoneNumberSuccess: false,
  isCheckPhoneNumberFailure: false,

  isPortInPhoneNumber: false,
  isPortInPhoneNumberSuccess: false,
  isPortInPhoneNumberFailure: false,

  isCreateCustomerOrder: false,
  isCreateCustomerOrderSuccess: false,
  isCreateCustomerOrderFailure: false,
  orderDetails: {},

  isCreateHandoverOrder: false,
  isCreateHandoverOrderSuccess: false,
  isCreateHandoverOrderFailure: false,
  handoverOrderId: '',

  successMessage: '',
  errorMessage: '',
};

const reducer = handleActions(
  {
    [getAcpDevicesRequest]: (state) => ({
      ...state,
      isGetAcpDevices: true,
      isGetAcpDevicesSuccess: false,
      isGetAcpDevicesFailure: false,
    }),
    [getAcpDevicesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAcpDevices: false,
      isGetAcpDevicesSuccess: true,
      acpDevicesData: payload.data.acpDevices,
      devicesData: payload.data.devices,
    }),
    [getAcpDevicesFailure]: (state, { payload }) => ({
      ...state,
      isGetAcpDevices: false,
      isGetAcpDevicesFailure: true,
      errorMessage: payload,
    }),

    [addToCartRequest]: (state) => ({
      ...state,
      isAddToCart: true,
      isAddToCartSuccess: false,
      isAddToCartFailure: false,
    }),
    [addToCartSuccess]: (state, { payload }) => {
      const cartData = payload.data.rows;
      const total = getTotalAmount(cartData);

      return {
        ...state,
        isAddToCart: false,
        isAddToCartSuccess: true,
        cartData,
        cartDataCount: payload.data.count,
        totalAmount: total,
        successMessage: payload.message,
      };
    },
    [addToCartFailure]: (state, { payload }) => ({
      ...state,
      isAddToCart: false,
      isAddToCartFailure: true,
      errorMessage: payload,
    }),

    [updateProductCartRequest]: (state) => ({
      ...state,
      isUpdateProductCart: true,
      isUpdateProductCartSuccess: false,
      isUpdateProductCartFailure: false,
    }),
    [updateProductCartSuccess]: (state, { payload }) => {
      const newData = state.cartData.map((i) => {
        if (i.id === payload.data.id) {
          i.count = payload.data.count;
        }
        return i;
      });
      const total = getTotalAmount(newData);
      const count = newData.reduce((acc, item) => acc + item.count, 0);
      const lookupKeys = getLookupKeys(newData);

      return {
        ...state,
        isUpdateProductCart: false,
        isUpdateProductCartSuccess: true,
        cartData: newData,
        lookupKeys,
        cartDataCount: count,
        totalAmount: total,
        successMessage: payload.message,
      };
    },
    [updateProductCartFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductCart: false,
      isUpdateProductCartFailure: true,
      errorMessage: payload,
    }),

    [deleteFromCartRequest]: (state) => ({
      ...state,
      isDeleteFromCart: true,
      isDeleteFromCartSuccess: false,
      isDeleteFromCartFailure: false,
    }),
    [deleteFromCartSuccess]: (state, { payload }) => {
      const { variantId, id } = payload.data;

      const newData = state.cartData.reduce((acc, i) => {
        if (i.id !== +id) {
          acc.push(i);
        } else if (variantId && i.retailPlanId) {
          i.variant = null;
          i.variantId = null;
          acc.push(i);
        }

        return acc;
      }, []);
      const total = getTotalAmount(newData);
      const count = newData.reduce((acc, item) => acc + item.count, 0);
      const lookupKeys = getLookupKeys(newData);

      return {
        ...state,
        isDeleteFromCart: false,
        isDeleteFromCartSuccess: true,
        cartData: newData,
        lookupKeys,
        cartDataCount: count,
        totalAmount: total,
        successMessage: payload.message,
      };
    },
    [deleteFromCartFailure]: (state, { payload }) => ({
      ...state,
      isDeleteFromCart: false,
      isDeleteFromCartFailure: true,
      errorMessage: payload,
    }),

    [getCartDataRequest]: (state) => ({
      ...state,
      isGetCartData: true,
      isGetCartDataSuccess: false,
      isGetCartDataFailure: false,
    }),
    [getCartDataSuccess]: (state, { payload }) => {
      const cartData = payload.data.rows;
      const total = getTotalAmount(cartData);

      return {
        ...state,
        isGetCartData: false,
        isGetCartDataSuccess: true,
        cartData: payload.data.rows,
        cartDataCount: payload.data.count,
        totalAmount: total,
      };
    },
    [getCartDataFailure]: (state, { payload }) => ({
      ...state,
      isGetCartData: false,
      isGetCartDataFailure: true,
      errorMessage: payload,
    }),

    [getShippingAddressesRequest]: (state) => ({
      ...state,
      isGetShippingAddresses: true,
      isGetShippingAddressesSuccess: false,
      isGetShippingAddressesFailure: false,
    }),
    [getShippingAddressesSuccess]: (state, { payload }) => {
      const { response, data } = payload;
      let updatedData = {
        shippingAddresses: [],
      };

      if (response.data?.rates.length) {
        const sorted = response.data?.rates?.sort((a, b) => {
          return shippingAmount(a) - shippingAmount(b);
        });
        const firstAddress = sorted[0];
        const shippingPrice = shippingAmount(firstAddress);

        updatedData.shippingAddresses = sorted;
        updatedData.shippingDetails = data;
        updatedData.shippingPrice = {
          price: shippingAmount(firstAddress),
          rateId: firstAddress.rate_id,
          shippingPrice,
        };
      }

      return {
        ...state,
        isGetShippingAddresses: false,
        isGetShippingAddressesSuccess: true,
        ...updatedData,
      };
    },
    [getShippingAddressesFailure]: (state, { payload }) => ({
      ...state,
      isGetShippingAddresses: false,
      isGetShippingAddressesFailure: true,
      shippingAddresses: [],
      errorMessage: payload,
    }),
    [resetShippingAddresses]: (state) => ({
      ...state,
      isGetShippingAddresses: false,
      isGetShippingAddressesFailure: false,
      isGetShippingAddressesSuccess: false,
      shippingAddresses: [],
      shippingDetails: [],
      shippingPrice: {},
    }),

    [selectShippingPriceRequest]: (state, { payload }) => ({
      ...state,
      shippingPrice: payload,
    }),

    [getPlansRequest]: (state) => ({
      ...state,
      isGetPlans: true,
      isGetPlansSuccess: false,
      isGetPlansFailure: false,
      selectedPlan: {},
    }),
    [getPlansSuccess]: (state, { payload }) => ({
      ...state,
      isGetPlans: false,
      isGetPlansSuccess: true,
      plansData: payload.data.rows,
    }),
    [getPlansFailure]: (state, { payload }) => ({
      ...state,
      isGetPlans: false,
      isGetPlansFailure: true,
      errorMessage: payload,
    }),

    [selectPlanRequest]: (state) => ({
      ...state,
      isSelectPlan: true,
      isSelectPlanSuccess: false,
      isSelectPlanFailure: false,
    }),
    [selectPlanSuccess]: (state, { payload }) => {
      const { cartData } = payload.data;
      const total = getTotalAmount(cartData);
      const lookupKeys = getLookupKeys(cartData);

      return {
        ...state,
        isSelectPlan: false,
        isSelectPlanSuccess: true,
        selectedPlan: payload.data.plan,
        cartData,
        lookupKeys,
        cartDataCount: payload.data.count,
        totalAmount: total,
        successMessage: payload.message,
      };
    },
    [selectPlanFailure]: (state, { payload }) => ({
      ...state,
      isSelectPlan: false,
      isSelectPlanFailure: true,
      errorMessage: payload,
    }),
    [removeSelectedPlan]: (state) => ({
      ...state,
      selectedPlan: {},
    }),

    [checkPhoneNumberRequest]: (state) => ({
      ...state,
      isCheckPhoneNumber: true,
      isCheckPhoneNumberSuccess: false,
      isCheckPhoneNumberFailure: false,
    }),
    [checkPhoneNumberSuccess]: (state, { payload }) => ({
      ...state,
      isCheckPhoneNumber: false,
      isCheckPhoneNumberSuccess: true,
      successMessage: payload.message,
    }),
    [checkPhoneNumberFailure]: (state, { payload }) => ({
      ...state,
      isCheckPhoneNumber: false,
      isCheckPhoneNumberFailure: true,
      errorMessage: payload,
    }),

    [portInPhoneNumberRequest]: (state) => ({
      ...state,
      isPortInPhoneNumber: true,
      isPortInPhoneNumberSuccess: false,
      isPortInPhoneNumberFailure: false,
    }),
    [portInPhoneNumberSuccess]: (state, { payload }) => ({
      ...state,
      isPortInPhoneNumber: false,
      isPortInPhoneNumberSuccess: true,
      successMessage: payload.message,
    }),
    [portInPhoneNumberFailure]: (state, { payload }) => ({
      ...state,
      isPortInPhoneNumber: false,
      isPortInPhoneNumberFailure: true,
      errorMessage: payload,
    }),

    [createCustomerOrderRequest]: (state) => ({
      ...state,
      isCreateCustomerOrder: true,
      isCreateCustomerOrderSuccess: false,
      isCreateCustomerOrderFailure: false,
    }),
    [createCustomerOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCreateCustomerOrder: false,
      isCreateCustomerOrderSuccess: true,
      selectedPlan: {},
      shippingAddress: {},
      cartData: [],
      orderDetails: payload.data,
      successMessage: payload.message,
    }),
    [createCustomerOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreateCustomerOrder: false,
      isCreateCustomerOrderFailure: true,
      errorMessage: payload,
    }),

    [createHandoverOrderRequest]: (state) => ({
      ...state,
      isCreateHandoverOrder: true,
      isCreateHandoverOrderSuccess: false,
      isCreateHandoverOrderFailure: false,
    }),
    [createHandoverOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCreateHandoverOrder: false,
      isCreateHandoverOrderSuccess: true,
      selectedPlan: {},
      cartData: [],
      handoverOrderId: payload.data,
      successMessage: payload.message,
    }),
    [createHandoverOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreateHandoverOrder: false,
      isCreateHandoverOrderFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
