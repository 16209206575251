import { handleActions } from 'redux-actions';

import {
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFailure,
  createUserCustomerRequest,
  createUserCustomerSuccess,
  createUserCustomerFailure,
  getCustomersListRequest,
  getCustomersListSuccess,
  getCustomersListFailure,
  continueCustomerStepRequest,
  continueCustomerStepSuccess,
  continueCustomerStepFailure,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  getOneCustomerRequest,
  getOneCustomerSuccess,
  getOneCustomerFailure,
  availabilityCheckRequest,
  availabilityCheckSuccess,
  availabilityCheckFailure,
  saveAsDraftAcpRequest,
  saveAsDraftAcpSuccess,
  saveAsDraftAcpFailure,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFailure,
  checkEligibilityRequest,
  checkEligibilitySuccess,
  checkEligibilityFailure,
  checkEligibilityStatusRequest,
  checkEligibilityStatusSuccess,
  checkEligibilityStatusFailure,
  verifyDeviceEligibilityRequest,
  verifyDeviceEligibilitySuccess,
  verifyDeviceEligibilityFailure,
  updateCustomerPersonalInfoRequest,
  updateCustomerPersonalInfoSuccess,
  updateCustomerPersonalInfoFailure,
  updateCustomerAddressesRequest,
  updateCustomerAddressesSuccess,
  updateCustomerAddressesFailure,
  bulkDeleteCustomersRequest,
  bulkDeleteCustomersSuccess,
  bulkDeleteCustomersFailure,
  transferSubscriberRequest,
  transferSubscriberSuccess,
  transferSubscriberFailure,
  subscriberReportRequest,
  subscriberReportSuccess,
  subscriberReportFailure,
  transactionReportRequest,
  transactionReportSuccess,
  transactionReportFailure,
  snapshotReportRequest,
  snapshotReportSuccess,
  snapshotReportFailure,
  recertificationReportRequest,
  recertificationReportSuccess,
  recertificationReportFailure,
  notProcessedReportRequest,
  notProcessedReportSuccess,
  notProcessedReportFailure,
  createSubscriberRequest,
  createSubscriberSuccess,
  createSubscriberFailure,
  deleteSubscriberRequest,
  deleteSubscriberSuccess,
  deleteSubscriberFailure,
  updateCustomerLinesRequest,
  updateCustomerLinesSuccess,
  updateCustomerLinesFailure,
  getUpdateHistoryRequest,
  getUpdateHistorySuccess,
  getUpdateHistoryFailure,
  clearCustomerData,
  getUsageDataRequest,
  getUsageDataSuccess,
  getUsageDataFailure,
  updateBillingDetailsRequest,
  updateBillingDetailsSuccess,
  updateBillingDetailsFailure,
  paymentMethodsSelectOptionsRequest,
  paymentMethodsSelectOptionsSuccess,
  paymentMethodsSelectOptionsFailure,
  updateCustomerLinesAndShipRequest,
  updateCustomerLinesAndShipSuccess,
  updateCustomerLinesAndShipFailure,
  resetUpdateCustomerLinesAndShip,
} from './actions';

const initialState = {
  isCreatingCustomer: false,
  isCreatingCustomerSuccess: false,
  isCreatingCustomerFailure: false,
  isCreateUserCustomer: false,
  isCreateUserCustomerSuccess: false,
  isCreateUserCustomerFailure: false,
  isGettingCustomersList: true,
  isGettingCustomersListSuccess: false,
  isGettingCustomersListFailure: false,
  customersList: [],
  customersCount: 0,
  isContinueCustomerStep: false,
  isContinueCustomerStepSuccess: false,
  isContinueCustomerStepFailure: false,
  continueCustomerData: {},
  isDeletingCustomerSuccess: false,
  isDeletingCustomerFailure: false,
  isGettingOneCustomer: true,
  isGettingOneCustomerSuccess: false,
  isGettingOneCustomerFailure: false,
  customer: {},
  isAvailabilityCheck: false,
  isAvailabilityCheckSuccess: false,
  isAvailabilityCheckFailure: false,
  availabilityData: {},
  isSaveAsDraftAcp: false,
  isSaveAsDraftAcpSuccess: false,
  isSaveAsDraftAcpFailure: false,
  saveAsDraftAcpId: '',
  isUpdateCustomer: false,
  isUpdateCustomerSuccess: false,
  isUpdateCustomerFailure: false,
  isCheckingEligibility: false,
  isCheckEligibilitySuccess: false,
  isCheckEligibilityFailure: false,
  isCheckingEligibilityStatus: false,
  isCheckEligibilityStatusSuccess: false,
  isCheckEligibilityStatusFailure: false,
  eligibilityStatusData: {},
  isVerifyDeviceEligibility: false,
  isVerifyDeviceEligibilitySuccess: false,
  isVerifyDeviceEligibilityFailure: false,
  isEligible: false,
  isUpdatingCustomer: false,
  isUpdatingCustomerSuccess: false,
  isUpdatingCustomerFailure: false,
  isUpdatingCustomerAddresses: false,
  isUpdatingCustomerAddressesSuccess: false,
  isUpdatingCustomerAddressesFailure: false,
  isBulkDeletingCustomers: false,
  isBulkDeletingCustomersSuccess: false,
  isBulkDeletingCustomersFailure: false,
  isTransferSubscriber: false,
  isTransferSubscriberSuccess: false,
  isTransferSubscriberFailure: false,
  transferSubscriberData: {},
  isSubscriberReport: false,
  isSubscriberReportSuccess: false,
  isSubscriberReportFailure: false,
  subscriberReportData: [],
  subscriberReportHeader: [],
  isTransactionReport: false,
  isTransactionReportSuccess: false,
  isTransactionReportFailure: false,
  transactionReportData: [],
  transactionReportHeader: [],
  isSnapshotReport: false,
  isSnapshotReportSuccess: false,
  isSnapshotReportFailure: false,
  snapshotReportData: [],
  snapshotReportHeader: [],
  isRecertificationReport: false,
  isRecertificationReportSuccess: false,
  isRecertificationReportFailure: false,
  recertificationReportData: [],
  recertificationReportHeader: [],
  isNotProcessedReport: false,
  isNotProcessedReportSuccess: false,
  isNotProcessedReportFailure: false,
  notProcessedReportData: [],
  notProcessedReportHeader: [],
  isCreateSubscriber: false,
  isCreateSubscriberSuccess: false,
  isCreateSubscriberFailure: false,
  isDeleteSubscriber: false,
  isDeleteSubscriberSuccess: false,
  isDeleteSubscriberFailure: false,
  successMessage: '',
  errorMessage: '',
  isUpdatingLine: false,
  isUpdatingLineSuccess: false,
  isUpdatingLineFailure: false,
  isGettingLineHistory: false,
  lineHistory: [],
  isGettingUsageData: false,
  isGettingUsageDataSuccess: true,
  usageData: [],
  usageDataCount: 0,
  dataUsageTotal: {},
  isUpdatingBillingDetails: false,
  isUpdatingBillingDetailsSuccess: false,
  isUpdatingBillingDetailsFailure: false,
  isGettingPaymentMethodsSelectOptions: false,
  paymentMethodsOptions: [],
  isUpdatingOtherLineRequest: false,
  isUpdatingOtherLineSuccess: false,
  isUpdatingOtherLineFailure: false,
};

const reducer = handleActions(
  {
    [createCustomerRequest]: (state) => ({
      ...state,
      isCreatingCustomer: true,
      isCreatingCustomerSuccess: false,
      isCreatingCustomerFailure: false,
    }),
    [createCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingCustomer: false,
      isCreatingCustomerSuccess: true,
      successMessage: payload.message,
    }),
    [createCustomerFailure]: (state, { payload }) => ({
      ...state,
      isCreatingCustomer: false,
      isCreatingCustomerFailure: true,
      errorMessage: payload,
    }),

    [createUserCustomerRequest]: (state) => ({
      ...state,
      isCreateUserCustomer: true,
      isCreateUserCustomerSuccess: false,
      isCreateUserCustomerFailure: false,
    }),
    [createUserCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isCreateUserCustomer: false,
      isCreateUserCustomerSuccess: true,
      successMessage: payload.message,
    }),
    [createUserCustomerFailure]: (state, { payload }) => ({
      ...state,
      isCreateUserCustomer: false,
      isCreateUserCustomerFailure: true,
      errorMessage: payload,
    }),

    [getCustomersListRequest]: (state) => ({
      ...state,
      isGettingCustomersList: true,
      isGettingCustomersListSuccess: false,
      isGettingCustomersListFailure: false,
    }),
    [getCustomersListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomersList: false,
      isGettingCustomersListSuccess: true,
      customersList: payload.data.rows,
      customersCount:
        payload.data.count === null ? state.customersCount : payload.data.count,
      successMessage: payload.message,
    }),
    [getCustomersListFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomersList: false,
      isGettingCustomersListFailure: true,
      errorMessage: payload,
    }),

    [continueCustomerStepRequest]: (state) => ({
      ...state,
      isContinueCustomerStep: true,
      isContinueCustomerStepSuccess: false,
      isContinueCustomerStepFailure: false,
    }),
    [continueCustomerStepSuccess]: (state, { payload }) => ({
      ...state,
      isContinueCustomerStep: false,
      isContinueCustomerStepSuccess: true,
      continueCustomerData: payload.data,
    }),
    [continueCustomerStepFailure]: (state, { payload }) => ({
      ...state,
      isContinueCustomerStep: false,
      isContinueCustomerStepFailure: true,
      errorMessage: payload,
    }),

    [deleteCustomerRequest]: (state) => ({
      ...state,
      isDeletingCustomerSuccess: false,
      isDeletingCustomerFailure: false,
    }),
    [deleteCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingCustomerSuccess: true,
      successMessage: payload.message,
      customersList: state.customersList.filter((i) => +i.id !== +payload.data),
      customersCount: state.customersCount - 1,
    }),
    [deleteCustomerFailure]: (state, { payload }) => ({
      ...state,
      isDeletingCustomerFailure: true,
      errorMessage: payload,
    }),
    [getOneCustomerRequest]: (state) => ({
      ...state,
      isGettingOneCustomer: true,
      isGettingOneCustomerSuccess: false,
      isGettingOneCustomerFailure: false,
    }),
    [getOneCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGettingOneCustomer: false,
      isGettingOneCustomerSuccess: true,
      customer: payload.data,
      successMessage: payload.message,
    }),
    [getOneCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGettingOneCustomer: false,
      isGettingOneCustomerFailure: true,
      errorMessage: payload,
    }),
    [availabilityCheckRequest]: (state) => ({
      ...state,
      isAvailabilityCheck: true,
      isAvailabilityCheckSuccess: false,
      isAvailabilityCheckFailure: false,
    }),
    [availabilityCheckSuccess]: (state, { payload }) => ({
      ...state,
      isAvailabilityCheck: false,
      isAvailabilityCheckSuccess: true,
      availabilityData: payload,
    }),
    [availabilityCheckFailure]: (state, { payload }) => ({
      ...state,
      isAvailabilityCheck: false,
      isAvailabilityCheckFailure: true,
      errorMessage: payload,
    }),
    [saveAsDraftAcpRequest]: (state) => ({
      ...state,
      isSaveAsDraftAcp: true,
      isSaveAsDraftAcpSuccess: false,
      isSaveAsDraftAcpFailure: false,
    }),
    [saveAsDraftAcpSuccess]: (state, { payload }) => ({
      ...state,
      isSaveAsDraftAcp: false,
      isSaveAsDraftAcpSuccess: true,
      successMessage: payload.message,
      saveAsDraftAcpId: payload.data,
    }),
    [saveAsDraftAcpFailure]: (state, { payload }) => ({
      ...state,
      isSaveAsDraftAcp: false,
      isSaveAsDraftAcpFailure: true,
      errorMessage: payload,
    }),

    [updateCustomerRequest]: (state) => ({
      ...state,
      isUpdateCustomer: true,
      isUpdateCustomerSuccess: false,
      isUpdateCustomerFailure: false,
    }),
    [updateCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateCustomer: false,
      isUpdateCustomerSuccess: true,
      successMessage: payload.message,
    }),
    [updateCustomerFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCustomer: false,
      isUpdateCustomerFailure: true,
      errorMessage: payload,
    }),

    [checkEligibilityRequest]: (state) => ({
      ...state,
      isCheckingEligibility: true,
      isCheckEligibilitySuccess: false,
      isCheckEligibilityFailure: false,
    }),
    [checkEligibilitySuccess]: (state, { payload }) => ({
      ...state,
      isCheckingEligibility: false,
      isCheckEligibilitySuccess: true,
      eligibilityStatusData: payload.data,
      successMessage: payload.message,
    }),
    [checkEligibilityFailure]: (state, { payload }) => ({
      ...state,
      isCheckingEligibility: false,
      isCheckEligibilityFailure: true,
      errorMessage: payload,
    }),
    [checkEligibilityStatusRequest]: (state) => ({
      ...state,
      isCheckingEligibilityStatus: true,
      isCheckEligibilityStatusSuccess: false,
      isCheckEligibilityStatusFailure: false,
    }),
    [checkEligibilityStatusSuccess]: (state, { payload }) => {
      let updatedCustomer = {};
      if (payload.type === 'drafts') {
        updatedCustomer.customersList = [...state.customersList].map((item) => {
          return { ...item, ...(payload.id === item.id ? payload.data : {}) };
        });
      }

      return {
        ...state,
        ...updatedCustomer,
        isCheckingEligibilityStatus: false,
        isCheckEligibilityStatusSuccess: true,
        customer: { ...state.customer, ...payload.data },
        eligibilityStatusData: payload.data,
        successMessage: payload.message,
      };
    },
    [checkEligibilityStatusFailure]: (state, { payload }) => ({
      ...state,
      isCheckingEligibilityStatus: false,
      isCheckEligibilityStatusFailure: true,
      errorMessage: payload,
    }),

    [verifyDeviceEligibilityRequest]: (state) => ({
      ...state,
      isVerifyDeviceEligibility: true,
      isVerifyDeviceEligibilitySuccess: false,
      isVerifyDeviceEligibilityFailure: false,
    }),
    [verifyDeviceEligibilitySuccess]: (state, { payload }) => ({
      ...state,
      isVerifyDeviceEligibility: false,
      isVerifyDeviceEligibilitySuccess: true,
      isEligible: payload.data === 'PERSON_IS_ELIGIBLE',
      successMessage: payload.message,
    }),
    [verifyDeviceEligibilityFailure]: (state, { payload }) => ({
      ...state,
      isVerifyDeviceEligibility: false,
      isVerifyDeviceEligibilityFailure: true,
      errorMessage: payload,
    }),

    [updateCustomerPersonalInfoRequest]: (state) => ({
      ...state,
      isUpdatingCustomer: true,
      isUpdatingCustomerSuccess: false,
      isUpdatingCustomerFailure: false,
    }),
    [updateCustomerPersonalInfoSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingCustomer: false,
      isUpdatingCustomerSuccess: true,
      successMessage: payload.message,
      customer: payload.data,
    }),
    [updateCustomerPersonalInfoFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingCustomer: false,
      isUpdatingCustomerFailure: true,
      errorMessage: payload,
    }),
    [updateCustomerAddressesRequest]: (state) => ({
      ...state,
      isUpdatingCustomerAddresses: true,
      isUpdatingCustomerAddressesSuccess: false,
      isUpdatingCustomerAddressesFailure: false,
    }),
    [updateCustomerAddressesSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingCustomerAddresses: false,
      isUpdatingCustomerAddressesSuccess: true,
      successMessage: payload.message,
      customer: payload.data,
    }),
    [updateCustomerAddressesFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingCustomerAddresses: false,
      isUpdatingCustomerAddressesFailure: true,
      errorMessage: payload,
    }),
    [bulkDeleteCustomersRequest]: (state) => ({
      ...state,
      isBulkDeletingCustomers: true,
      isBulkDeletingCustomersSuccess: false,
      isBulkDeletingCustomersFailure: false,
    }),
    [bulkDeleteCustomersSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletingCustomers: false,
      isBulkDeletingCustomersSuccess: true,
      successMessage: payload.message,
      customersList: state.customersList.filter(
        (i) => !payload.data.includes(i.id)
      ),
    }),
    [bulkDeleteCustomersFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletingCustomers: false,
      isBulkDeletingCustomersFailure: true,
      errorMessage: payload,
    }),
    [transferSubscriberRequest]: (state) => ({
      ...state,
      isTransferSubscriber: true,
      isTransferSubscriberSuccess: false,
      isTransferSubscriberFailure: false,
    }),
    [transferSubscriberSuccess]: (state, { payload }) => ({
      ...state,
      isTransferSubscriber: false,
      isTransferSubscriberSuccess: true,
      transferSubscriberData: payload,
    }),
    [transferSubscriberFailure]: (state, { payload }) => ({
      ...state,
      isTransferSubscriber: false,
      isTransferSubscriberFailure: true,
      errorMessage: payload,
    }),
    [subscriberReportRequest]: (state) => ({
      ...state,
      isSubscriberReport: true,
      isSubscriberReportSuccess: false,
      isSubscriberReportFailure: false,
    }),
    [subscriberReportSuccess]: (state, { payload }) => ({
      ...state,
      isSubscriberReport: false,
      isSubscriberReportSuccess: true,
      subscriberReportData: payload.data.data,
      subscriberReportHeader: payload.data.header,
    }),
    [subscriberReportFailure]: (state, { payload }) => ({
      ...state,
      isSubscriberReport: false,
      isSubscriberReportFailure: true,
      errorMessage: payload,
    }),
    [transactionReportRequest]: (state) => ({
      ...state,
      isTransactionReport: true,
      isTransactionReportSuccess: false,
      isTransactionReportFailure: false,
    }),
    [transactionReportSuccess]: (state, { payload }) => ({
      ...state,
      isTransactionReport: false,
      isTransactionReportSuccess: true,
      transactionReportData: payload.data.data,
      transactionReportHeader: payload.data.header,
    }),
    [transactionReportFailure]: (state, { payload }) => ({
      ...state,
      isTransactionReport: false,
      isTransactionReportFailure: true,
      errorMessage: payload,
    }),
    [snapshotReportRequest]: (state) => ({
      ...state,
      isSnapshotReport: true,
      isSnapshotReportSuccess: false,
      isSnapshotReportFailure: false,
    }),
    [snapshotReportSuccess]: (state, { payload }) => ({
      ...state,
      isSnapshotReport: false,
      isSnapshotReportSuccess: true,
      snapshotReportData: payload.data.data,
      snapshotReportHeader: payload.data.header,
    }),
    [snapshotReportFailure]: (state, { payload }) => ({
      ...state,
      isSnapshotReport: false,
      isSnapshotReportFailure: true,
      errorMessage: payload,
    }),
    [recertificationReportRequest]: (state) => ({
      ...state,
      isRecertificationReport: true,
      isRecertificationReportSuccess: false,
      isRecertificationReportFailure: false,
    }),
    [recertificationReportSuccess]: (state, { payload }) => ({
      ...state,
      isRecertificationReport: false,
      isRecertificationReportSuccess: true,
      recertificationReportData: payload.data.data,
      recertificationReportHeader: payload.data.header,
    }),
    [recertificationReportFailure]: (state, { payload }) => ({
      ...state,
      isRecertificationReport: false,
      isRecertificationReportFailure: true,
      errorMessage: payload,
    }),
    [notProcessedReportRequest]: (state) => ({
      ...state,
      isNotProcessedReport: true,
      isNotProcessedReportSuccess: false,
      isNotProcessedReportFailure: false,
    }),
    [notProcessedReportSuccess]: (state, { payload }) => ({
      ...state,
      isNotProcessedReport: false,
      isNotProcessedReportSuccess: true,
      notProcessedReportData: payload.data.data,
      notProcessedReportHeader: payload.data.header,
    }),
    [notProcessedReportFailure]: (state, { payload }) => ({
      ...state,
      isNotProcessedReport: false,
      isNotProcessedReportFailure: true,
      errorMessage: payload,
    }),

    [createSubscriberRequest]: (state) => ({
      ...state,
      isCreateSubscriber: true,
      isCreateSubscriberSuccess: false,
      isCreateSubscriberFailure: false,
    }),
    [createSubscriberSuccess]: (state, { payload }) => ({
      isCreateSubscriber: false,
      isCreateSubscriberSuccess: true,
      successMessage: payload.message,
    }),
    [createSubscriberFailure]: (state, { payload }) => ({
      ...state,
      isCreateSubscriber: false,
      isCreateSubscriberFailure: true,
      errorMessage: payload,
    }),

    [deleteSubscriberRequest]: (state) => ({
      ...state,
      isDeleteSubscriber: true,
      isDeleteSubscriberSuccess: false,
      isDeleteSubscriberFailure: false,
    }),
    [deleteSubscriberSuccess]: (state, { payload }) => ({
      isDeleteSubscriber: false,
      isDeleteSubscriberSuccess: true,
      successMessage: payload.message,
    }),
    [deleteSubscriberFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSubscriber: false,
      isDeleteSubscriberFailure: true,
      errorMessage: payload,
    }),
    [updateCustomerLinesRequest]: (state) => ({
      ...state,
      isUpdatingLine: true,
      isUpdatingLineSuccess: false,
      isUpdatingLineFailure: false,
    }),
    [updateCustomerLinesSuccess]: (state, { payload }) => {
      const customerCopy = { ...state.customer };
      const customerOrdersCopy = [...customerCopy.orders];
      const index = customerOrdersCopy.findIndex(
        (i) => i.id === payload.data.id
      );
      if (index !== -1) {
        customerOrdersCopy[index] = payload.data;
        customerCopy.orders = customerOrdersCopy;
      }
      return {
        ...state,
        isUpdatingLine: false,
        isUpdatingLineSuccess: true,
        successMessage: payload.message,
        customer: customerCopy,
      };
    },
    [updateCustomerLinesFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingLine: false,
      isUpdatingLineFailure: true,
      errorMessage: payload,
    }),
    [getUpdateHistoryRequest]: (state) => ({
      ...state,
      isGettingLineHistory: true,
    }),
    [getUpdateHistorySuccess]: (state, { payload }) => ({
      ...state,
      isGettingLineHistory: false,
      message: payload.message,
      lineHistory: payload.data,
    }),
    [getUpdateHistoryFailure]: (state, { payload }) => ({
      ...state,
      isGettingLineHistory: false,
      errorMessage: payload,
    }),
    [clearCustomerData]: (state) => ({
      ...state,
      customer: {},
    }),
    [getUsageDataRequest]: (state) => ({
      ...state,
      isGettingUsageData: true,
      isGettingUsageDataSuccess: false,
      isGettingUsageDataFailure: false,
    }),
    [getUsageDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingUsageData: false,
      isGettingUsageDataSuccess: true,
      successMessage: payload.message,
      usageData: payload.data.rows,
      usageDataCount: payload.data.count,
      dataUsageTotal: payload.data.dataUsageTotal,
    }),
    [getUsageDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingUsageData: false,
      isGettingUsageDataFailure: true,
      errorMessage: payload,
    }),
    [updateBillingDetailsRequest]: (state) => ({
      ...state,
      isUpdatingBillingDetails: true,
      isUpdatingBillingDetailsSuccess: false,
      isUpdatingBillingDetailsFailure: false,
    }),
    [updateBillingDetailsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingBillingDetails: false,
      isUpdatingBillingDetailsSuccess: true,
      successMessage: payload.message,
    }),
    [updateBillingDetailsFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingBillingDetails: false,
      isUpdatingBillingDetailsFailure: true,
      errorMessage: payload,
    }),
    [paymentMethodsSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingPaymentMethodsSelectOptions: true,
    }),
    [paymentMethodsSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPaymentMethodsSelectOptions: false,
      paymentMethodsOptions: payload.data,
      successMessage: payload.message,
    }),
    [paymentMethodsSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingPaymentMethodsSelectOptions: false,
      errorMessage: payload,
    }),
    [updateCustomerLinesAndShipRequest]: (state) => ({
      ...state,
      isUpdatingOtherLineRequest: true,
    }),
    [updateCustomerLinesAndShipSuccess]: (state, { payload }) => {
      const customerCopy = { ...state.customer };

      if (state?.customer?.orders) {
        const customerOrdersCopy = [...customerCopy.orders];
        const index = customerOrdersCopy.findIndex(
          (i) => i.id === payload.data.id
        );
        if (index !== -1) {
          customerOrdersCopy[index] = payload.data;
          customerCopy.orders = customerOrdersCopy;
        }
      }

      return {
        ...state,
        isUpdatingOtherLineRequest: false,
        isUpdatingOtherLineSuccess: true,
        successMessage: payload.message,
        customer: customerCopy,
      };
    },
    [updateCustomerLinesAndShipFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingOtherLineRequest: false,
      isUpdatingOtherLineFailure: true,
      errorMessage: payload,
    }),
    [resetUpdateCustomerLinesAndShip]: (state, { payload }) => ({
      ...state,
      isUpdatingOtherLineRequest: false,
      isUpdatingOtherLineSuccess: false,
      isUpdatingOtherLineFailure: false,
    }),
  },
  initialState
);
export default reducer;
