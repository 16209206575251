import { createAction } from 'redux-actions';

export const createUserRequest = createAction('CREATE_USER_REQUEST');
export const createUserSuccess = createAction('CREATE_USER_SUCCESS');
export const createUserFailure = createAction('CREATE_USER_FAILURE');

export const createUserRoleRequest = createAction('CREATE_USER_ROLE_REQUEST');
export const createUserRoleSuccess = createAction('CREATE_USER_ROLE_SUCCESS');
export const createUserRoleFailure = createAction('CREATE_USER_ROLE_FAILURE');

export const findRoleRequest = createAction('FIND_ROLE_REQUEST');
export const findRoleSuccess = createAction('FIND_ROLE_SUCCESS');
export const findRoleFailure = createAction('FIND_ROLE_FAILURE');

export const getRolesLIstRequest = createAction('GET_ROLES_LIST_REQUEST');
export const getRolesLIstSuccess = createAction('GET_ROLES_LIST_SUCCESS');
export const getRolesLIstFailure = createAction('GET_ROLES_LIST_FAILURE');

export const updateRoleRequest = createAction('UPDATE_ROLE_REQUEST');
export const updateRoleSuccess = createAction('UPDATE_ROLE_SUCCESS');
export const updateRoleFailure = createAction('UPDATE_ROLE_FAILURE');

export const deleteRoleRequest = createAction('DELETE_ROLE_REQUEST');
export const deleteRoleSuccess = createAction('DELETE_ROLE_SUCCESS');
export const deleteRoleFailure = createAction('DELETE_ROLE_FAILURE');

export const getRolesOptionsRequest = createAction('GET_ROLES_OPTIONS_REQUEST');
export const getRolesOptionsSuccess = createAction('GET_ROLES_OPTIONS_SUCCESS');
export const getRolesOptionsFailure = createAction('GET_ROLES_OPTIONS_FAILURE');

export const getUsersListRequest = createAction('GET_USERS_LIST_REQUEST');
export const getUsersListSuccess = createAction('GET_USERS_LIST_SUCCESS');
export const getUsersListFailure = createAction('GET_USERS_LIST_FAILURE');

export const toggleUsersStatusRequest = createAction(
  'TOGGLE_USERS_STATUS_REQUEST'
);
export const toggleUsersStatusSuccess = createAction(
  'TOGGLE_USERS_STATUS_SUCCESS'
);
export const toggleUsersStatusFailure = createAction(
  'TOGGLE_USERS_STATUS_FAILURE'
);

export const deleteUserRequest = createAction('DELETE_USER_REQUEST');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');
export const deleteUserFailure = createAction('DELETE_USER_FAILURE');

export const findUserRequest = createAction('FIND_USER_REQUEST');
export const findUserSuccess = createAction('FIND_USER_SUCCESS');
export const findUserFailure = createAction('FIND_USER_FAILURE');

export const updateUserRequest = createAction('UPDATE_USER_REQUEST');
export const updateUserSuccess = createAction('UPDATE_USER_SUCCESS');
export const updateUserFailure = createAction('UPDATE_USER_FAILURE');

export const bulkDeleteRolesRequest = createAction('BULK_DELETE_ROLE_REQUEST');
export const bulkDeleteRolesSuccess = createAction('BULK_DELETE_ROLE_SUCCESS');
export const bulkDeleteRolesFailure = createAction('BULK_DELETE_ROLE_FAILURE');

export const clearRoleData = createAction('CLEAR_ROLE_DATA');

export const clearUserData = createAction('CLEAR_USER_DATA');
