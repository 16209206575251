import { axiosApiInstance, config } from 'custom-configs';

import {
  createTenantRequest,
  createTenantSuccess,
  createTenantFailure,
  getTenantsListSuccess,
  getTenantsListFailure,
  getTenantsListRequest,
  toggleTenantSuccess,
  toggleTenantFailure,
  toggleTenantRequest,
  deleteTenantRequest,
  deleteTenantSuccess,
  deleteTenantFailure,
  findOneTenantSuccess,
  findOneTenantFailure,
  findOneTenantRequest,
  updateTenantRequest,
  updateTenantSuccess,
  updateTenantFailure,
  bulkDeleteTenantsSuccess,
  bulkDeleteTenantsFailure,
  bulkDeleteTenantsRequest,
  createFromAddressSuccess,
  createFromAddressFailure,
  createFromAddressRequest,
  getFromAddressListSuccess,
  getFromAddressListRequest,
  getFromAddressListFailure,
  updateFromAddressSuccess,
  updateFromAddressFailure,
  updateFromAddressRequest,
  makeDefaultAddressSuccess,
  makeDefaultAddressFailure,
  makeDefaultAddressRequest,
  deleteFromAddressSuccess,
  deleteFromAddressFailure,
  deleteFromAddressRequest,
  getAddressSelectDataSuccess,
  getAddressSelectDataFailure,
  getAddressSelectDataRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL = `${config.DOMAIN}/tenants`;

function* createTenant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-tenant`, payload)
    );
    if (response?.status === 200) {
      yield put(createTenantSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createTenant, error`, e);
    if (e?.response?.data) {
      yield put(createTenantFailure(catchResponseMessages(e)));
    }
  }
}

function* getTenantsList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/tenants-list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getTenantsListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getTenantsList, error`, e);
    if (e?.response?.data) {
      yield put(getTenantsListFailure(catchResponseMessages(e)));
    }
  }
}

function* toggleTenant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/tenants-toggle/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(toggleTenantSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for toggleTenant, error`, e);
    if (e?.response?.data) {
      yield put(toggleTenantFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteTenant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete-tenant/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteTenantSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteTenant, error`, e);
    if (e?.response?.data) {
      yield put(deleteTenantFailure(catchResponseMessages(e)));
    }
  }
}
function* findOneTenant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find-one/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(findOneTenantSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for findOneTenant, error`, e);
    if (e?.response?.data) {
      yield put(findOneTenantFailure(catchResponseMessages(e)));
    }
  }
}
function* updateTenant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-tenant/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(updateTenantSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateTenant, error`, e);
    if (e?.response?.data) {
      yield put(updateTenantFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDeleteTenants({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/bulk-delete-tenants`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkDeleteTenantsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDeleteTenants, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteTenantsFailure(catchResponseMessages(e)));
    }
  }
}

function* createFromAddress({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-from-address`, payload)
    );
    if (response?.status === 200) {
      yield put(createFromAddressSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createFromAddress, error`, e);
    if (e?.response?.data) {
      yield put(createFromAddressFailure(catchResponseMessages(e)));
    }
  }
}
function* getFromAddresses({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/from-addresses${payload}`)
    );
    if (response?.status === 200) {
      yield put(getFromAddressListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getFromAddresses, error`, e);
    if (e?.response?.data) {
      yield put(getFromAddressListFailure(catchResponseMessages(e)));
    }
  }
}

function* updateFromAddress({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-address/${payload.id}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(updateFromAddressSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateFromAddress, error`, e);
    if (e?.response?.data) {
      yield put(updateFromAddressFailure(catchResponseMessages(e)));
    }
  }
}
function* makeAddressDefault({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/default-address/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(makeDefaultAddressSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for makeAddressDefault, error`, e);
    if (e?.response?.data) {
      yield put(makeDefaultAddressFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteFromAddress({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete-address/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(deleteFromAddressSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteFromAddress, error`, e);
    if (e?.response?.data) {
      yield put(deleteFromAddressFailure(catchResponseMessages(e)));
    }
  }
}

function* addressSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/address-select-options`)
    );
    if (response?.status === 200) {
      yield put(getAddressSelectDataSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for addressSelect, error`, e);
    if (e?.response?.data) {
      yield put(getAddressSelectDataFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createTenantRequest, createTenant);
  yield takeLatest(getTenantsListRequest, getTenantsList);
  yield takeLatest(toggleTenantRequest, toggleTenant);
  yield takeLatest(deleteTenantRequest, deleteTenant);
  yield takeLatest(findOneTenantRequest, findOneTenant);
  yield takeLatest(updateTenantRequest, updateTenant);
  yield takeLatest(bulkDeleteTenantsRequest, bulkDeleteTenants);
  yield takeLatest(createFromAddressRequest, createFromAddress);
  yield takeLatest(getFromAddressListRequest, getFromAddresses);
  yield takeLatest(updateFromAddressRequest, updateFromAddress);
  yield takeLatest(makeDefaultAddressRequest, makeAddressDefault);
  yield takeLatest(deleteFromAddressRequest, deleteFromAddress);
  yield takeLatest(getAddressSelectDataRequest, addressSelect);
}
