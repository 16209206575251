import { FormattedMessage } from 'react-intl';
import {
  ShoppingCartOutlined,
  CreditCardOutlined,
  NotificationOutlined,
} from '@ant-design/icons';

const icons = {
  ShoppingCartOutlined,
  CreditCardOutlined,
  NotificationOutlined,
};

const eCommerce = [
  {
    id: 'Ecommerce1',
    title: <FormattedMessage id="E-commerce" />,
    icon: icons.ShoppingCartOutlined,
    type: 'collapse',
    resource: ['tenant', 'all'],
    children: [
      {
        id: 'simCards',
        title: 'SIM Cards',
        icon: icons.UserOutlined,
        type: 'item',
        url: '/e-commerce/sim-cards',
      },
      {
        id: 'vendors',
        title: 'Vendors',
        type: 'item',
        url: '/e-commerce/vendors',
      },
      {
        id: 'categories',
        title: 'Categories',
        type: 'item',
        url: '/e-commerce/categories',
      },
      {
        id: 'Manufacturers',
        title: 'Manufacturers',
        type: 'item',
        url: '/e-commerce/manufacturers',
      },
      {
        id: 'Devices',
        title: 'Devices',
        type: 'item',
        url: '/e-commerce/devices',
      },
      {
        id: 'DeviceInventory',
        title: 'Device Inventory',
        type: 'item',
        url: '/e-commerce/inventory/device-inventory',
      },
      {
        id: 'Accessories',
        title: 'Accessories',
        type: 'item',
        url: '/e-commerce/accessories',
        chip: {
          label: 'Soon',
          color: 'default',
          variant: 'filled',
          size: 'medium',
          icon: icons.NotificationOutlined,
        },
      },
      {
        id: 'OtherInventory',
        title: 'Accessories Inventory',
        type: 'item',
        url: '/e-commerce/inventory/accessories-inventory',
        chip: {
          label: 'Soon',
          color: 'default',
          variant: 'filled',
          size: 'medium',
          icon: icons.NotificationOutlined,
        },
      },
      {
        id: 'promotion',
        title: 'Promotions',
        type: 'item',
        url: '/e-commerce/promotions',
      },
    ],
  },
  {
    id: 'Ecommerce2',
    title: <FormattedMessage id="E-commerce" />,
    icon: icons.ShoppingCartOutlined,
    type: 'collapse',
    resource: ['masterAgent', 'subAgent'],
    children: [
      {
        id: 'DeviceInventory1',
        title: 'Device Inventory',
        type: 'item',
        url: '/e-commerce/inventory/device-inventory',
      },
      {
        id: 'Inventoryrequests',
        title: 'Inventory requests',
        type: 'item',
        url: '/e-commerce/inventory/inventory-requests',
      },
    ],
  },
  {
    id: 'simCardSingle',
    title: 'Sim Cards',
    icon: icons.CreditCardOutlined,
    type: 'item',
    resource: ['masterAgent', 'subAgent'],
    url: '/e-commerce/sim-cards',
  },
];
export default eCommerce;
