import { createAction } from 'redux-actions';

export const createTenantRequest = createAction('CREATE_TENANT_REQUEST');
export const createTenantSuccess = createAction('CREATE_TENANT_SUCCESS');
export const createTenantFailure = createAction('CREATE_TENANT_FAILURE');

export const getTenantsListRequest = createAction('GET_TENANTS_LIST_REQUEST');
export const getTenantsListSuccess = createAction('GET_TENANTS_LIST_SUCCESS');
export const getTenantsListFailure = createAction('GET_TENANTS_LIST_FAILURE');

export const toggleTenantRequest = createAction('TOGGLE_TENANT_REQUEST');
export const toggleTenantSuccess = createAction('TOGGLE_TENANT_SUCCESS');
export const toggleTenantFailure = createAction('TOGGLE_TENANT_FAILURE');

export const deleteTenantRequest = createAction('DELETE_TENANT_REQUEST');
export const deleteTenantSuccess = createAction('DELETE_TENANT_SUCCESS');
export const deleteTenantFailure = createAction('DELETE_TENANT_FAILURE');

export const findOneTenantRequest = createAction('FIND_ONE_TENANT_REQUEST');
export const findOneTenantSuccess = createAction('FIND_ONE_TENANT_SUCCESS');
export const findOneTenantFailure = createAction('FIND_ONE_TENANT_FAILURE');

export const updateTenantRequest = createAction('UPDATE_TENANT_REQUEST');
export const updateTenantSuccess = createAction('UPDATE_TENANT_SUCCESS');
export const updateTenantFailure = createAction('UPDATE_TENANT_FAILURE');

export const clearTenantData = createAction('CLEAR_TENANT_DATA');

export const bulkDeleteTenantsRequest = createAction(
  'BULK_DELETE_TENANTS_REQUEST'
);
export const bulkDeleteTenantsSuccess = createAction(
  'BULK_DELETE_TENANTS_SUCCESS'
);
export const bulkDeleteTenantsFailure = createAction(
  'BULK_DELETE_TENANTS_FAILURE'
);

export const createFromAddressRequest = createAction(
  'CREATE_FROM_ADDRESS_REQUEST'
);
export const createFromAddressSuccess = createAction(
  'CREATE_FROM_ADDRESS_SUCCESS'
);
export const createFromAddressFailure = createAction(
  'CREATE_FROM_ADDRESS_FAILURE'
);

export const getFromAddressListRequest = createAction(
  'GET_FROM_ADDRESS_LIST_REQUEST'
);
export const getFromAddressListSuccess = createAction(
  'GET_FROM_ADDRESS_LIST_SUCCESS'
);
export const getFromAddressListFailure = createAction(
  'GET_FROM_ADDRESS_LIST_FAILURE'
);

export const updateFromAddressRequest = createAction(
  'UPDATE_FROM_ADDRESS_REQUEST'
);
export const updateFromAddressSuccess = createAction(
  'UPDATE_FROM_ADDRESS_SUCCESS'
);
export const updateFromAddressFailure = createAction(
  'UPDATE_FROM_ADDRESS_FAILURE'
);

export const makeDefaultAddressRequest = createAction(
  'MAKE_DEFAULT_ADDRESS_REQUEST'
);

export const makeDefaultAddressSuccess = createAction(
  'MAKE_DEFAULT_ADDRESS_SUCCESS'
);

export const makeDefaultAddressFailure = createAction(
  'MAKE_DEFAULT_ADDRESS_FAILURE'
);

export const deleteFromAddressRequest = createAction(
  'DELETE_FROM_ADDRESS_REQUEST'
);
export const deleteFromAddressSuccess = createAction(
  'DELETE_FROM_ADDRESS_SUCCESS'
);
export const deleteFromAddressFailure = createAction(
  'DELETE_FROM_ADDRESS_FAILURE'
);

export const getAddressSelectDataRequest = createAction(
  'GET_ADDRESS_SELECT_DATA_REQUEST'
);
export const getAddressSelectDataSuccess = createAction(
  'GET_ADDRESS_SELECT_DATA_SUCCESS'
);
export const getAddressSelectDataFailure = createAction(
  'GET_ADDRESS_SELECT_DATA_FAILURE'
);
