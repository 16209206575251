import { FormattedMessage } from 'react-intl';
import { DollarOutlined } from '@ant-design/icons';

const icons = {
  DollarOutlined,
};

const billing = {
  id: 'group-billings',
  title: <FormattedMessage id="Billing" />,
  icon: icons.DollarOutlined,
  type: 'collapse',
  resource: ['tenant', 'all'],
  children: [
    {
      id: 'billing',
      title: 'Payment Methods',
      type: 'item',
      url: '/billing/payment-methods',
    },
    {
      id: 'payments',
      title: 'Payments History',
      type: 'item',
      url: '/billing/payments-history',
    },
    {
      id: 'paymentsSubscription',
      title: 'Subscriptions',
      type: 'item',
      url: '/billing/subscriptions',
    },
  ],
};
export default billing;
