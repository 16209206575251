import { UserOutlined } from '@ant-design/icons';

const icons = {
  UserOutlined,
};
const Profile = {
  id: 'Profile',
  title: 'Profile',
  icon: icons.UserOutlined,
  type: 'item',
  resource: ['customer'],
  url: '/customers/profile',
};
export default Profile;
