import { all } from 'redux-saga/effects';
import auth from './auth/saga';
import tenants from './tenants/saga';
import notifications from './notifications/saga';
import customers from './customers/saga';
import notes from './notes/saga';
import agents from './agents/saga';
import tickets from './tickets/saga';
import users from './users/saga';
import acpSettings from './settings/acpSettings/saga';
import simCardSettings from './settings/simCardSettings/saga';
import simCards from './eCommerce/simCards/saga';
import vendors from './eCommerce/vendors/saga';
import categories from './eCommerce/categories/saga';
import manufacturers from './eCommerce/manufacturers/saga';
import products from './eCommerce/products/saga';
import smtp from './settings/smtp/saga';
import apc from './settings/aps/saga';
import wsPlan from './settings/wsPlan/saga';
import inventory from './eCommerce/inventory/saga';
import retailPlan from './settings/retailPlan/saga';
import orders from './eCommerce/orders/saga';
import paymentMethods from './billing/paymentMethods/saga';
import carriers from './carriers/saga';
import shop from './eCommerce/shop/saga';
import siteSettings from './settings/siteSettings/saga';
import subscriptions from './billing/subscriptions/saga';
import profitLossReports from './billing/profitLossReports/saga';
import mails from './mails/saga';
import promotions from './eCommerce/promotions/saga';
import dashboard from './dashboard/saga';
import globalSearch from './globalSearch/saga';
import phoneNumbers from './phoneNumbers/saga';

export default function* rootSaga() {
  yield all([
    auth(),
    tenants(),
    notifications(),
    customers(),
    notes(),
    agents(),
    tickets(),
    users(),
    acpSettings(),
    simCardSettings(),
    simCards(),
    vendors(),
    categories(),
    manufacturers(),
    products(),
    smtp(),
    apc(),
    wsPlan(),
    inventory(),
    retailPlan(),
    orders(),
    paymentMethods(),
    carriers(),
    shop(),
    siteSettings(),
    subscriptions(),
    mails(),
    promotions(),
    dashboard(),
    globalSearch(),
    phoneNumbers(),
    profitLossReports(),
  ]);
}
