import { handleActions } from 'redux-actions';
import {
  createZipCodesRequest,
  createZipCodesSuccess,
  createZipCodesFailure,
  getZipCodeListRequest,
  getZipCodeListSuccess,
  getZipCodeListFailure,
  getAllAcpStatesRequest,
  getAllAcpStatesSuccess,
  getAllAcpStatesFailure,
  updateZipCodeRequest,
  updateZipCodeSuccess,
  updateZipCodeFailure,
  deleteZipCodeRequest,
  deleteZipCodeSuccess,
  deleteZipCodeFailure,
  addSpinCodeRequest,
  addSpinCodeSuccess,
  addSpinCodeFailure,
} from './actions';

const initialState = {
  isCreateZipCodes: false,
  isCreateZipCodesSuccess: false,
  isCreateZipCodesFailure: false,
  isGetZipCodeList: false,
  isGetZipCodeListSuccess: false,
  isGetZipCodeListFailure: false,
  zipCodeList: [],
  zipCodeCount: 0,
  isGetAllAcpStates: false,
  isGetAllAcpStatesSuccess: false,
  isGetAllAcpStatesFailure: false,
  allAcpStates: [],
  isUpdateZipCode: false,
  isUpdateZipCodeSuccess: false,
  isUpdateZipCodeFailure: false,
  isDeleteZipCode: false,
  isDeleteZipCodeSuccess: false,
  isDeleteZipCodeFailure: false,
  successMessage: '',
  errorMessage: '',
  isAddingSpinCode: false,
  isAddingSpinCodeSuccess: false,
  isAddingSpinCodeFailure: false,
};

const reducer = handleActions(
  {
    [createZipCodesRequest]: (state) => ({
      ...state,
      isCreateZipCodes: true,
      isCreateZipCodesSuccess: false,
      isCreateZipCodesFailure: false,
    }),
    [createZipCodesSuccess]: (state, { payload }) => ({
      ...state,
      isCreateZipCodes: false,
      isCreateZipCodesSuccess: true,
      zipCodeList: [...state.zipCodeList, ...payload.data],
      zipCodeCount: state.zipCodeCount + payload.data.length,
      successMessage: payload.message,
    }),
    [createZipCodesFailure]: (state, { payload }) => ({
      ...state,
      isCreateZipCodes: false,
      isCreateZipCodesFailure: true,
      errorMessage: payload,
    }),

    [getZipCodeListRequest]: (state) => ({
      ...state,
      isGetZipCodeList: true,
      isGetZipCodeListSuccess: false,
      isGetZipCodeListFailure: false,
    }),
    [getZipCodeListSuccess]: (state, { payload }) => ({
      ...state,
      isGetZipCodeList: false,
      isGetZipCodeListSuccess: true,
      zipCodeList: payload.data.rows,
      zipCodeCount: payload.data.count,
    }),
    [getZipCodeListFailure]: (state, { payload }) => ({
      ...state,
      isGetZipCodeList: false,
      isGetZipCodeListFailure: true,
      errorMessage: payload,
    }),

    [getAllAcpStatesRequest]: (state) => ({
      ...state,
      isGetZipCodeList: true,
      isGetZipCodeListSuccess: false,
      isGetZipCodeListFailure: false,
    }),
    [getAllAcpStatesSuccess]: (state, { payload }) => ({
      ...state,
      isGetZipCodeList: false,
      isGetZipCodeListSuccess: true,
      allAcpStates: payload.data,
    }),
    [getAllAcpStatesFailure]: (state, { payload }) => ({
      ...state,
      isGetZipCodeList: false,
      isGetZipCodeListFailure: true,
      errorMessage: payload,
    }),

    [updateZipCodeRequest]: (state) => ({
      ...state,
      isUpdateZipCode: true,
      isUpdateZipCodeSuccess: false,
      isUpdateZipCodeFailure: false,
    }),
    [updateZipCodeSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateZipCode: false,
      isUpdateZipCodeSuccess: true,
      zipCodeList: state.zipCodeList.map((i) => {
        if (i.id === payload.data.id) {
          return { ...i, ...payload.data };
        } else {
          return i;
        }
      }),
      successMessage: payload.message,
    }),
    [updateZipCodeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateZipCode: false,
      isUpdateZipCodeFailure: true,
      errorMessage: payload,
    }),
    [deleteZipCodeRequest]: (state) => ({
      ...state,
      isDeleteZipCode: true,
      isDeleteZipCodeSuccess: false,
      isDeleteZipCodeFailure: false,
    }),
    [deleteZipCodeSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteZipCode: false,
      isDeleteZipCodeSuccess: true,
      zipCodeList: state.zipCodeList.filter((i) => i.id !== payload.data),
      zipCodeCount: --state.zipCodeCount,
      successMessage: payload.message,
    }),
    [deleteZipCodeFailure]: (state, { payload }) => ({
      ...state,
      isDeleteZipCode: false,
      isDeleteZipCodeFailure: true,
      errorMessage: payload,
    }),
    [addSpinCodeRequest]: (state) => ({
      ...state,
      isAddingSpinCode: true,
      isAddingSpinCodeSuccess: false,
      isAddingSpinCodeFailure: false,
    }),
    [addSpinCodeSuccess]: (state, { payload }) => ({
      ...state,
      isAddingSpinCode: false,
      isAddingSpinCodeSuccess: true,
      successMessage: payload.message,
    }),
    [addSpinCodeFailure]: (state, { payload }) => ({
      ...state,
      isAddingSpinCode: false,
      isAddingSpinCodeFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
