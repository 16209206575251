import {
  deleteLogoFailure,
  deleteLogoRequest,
  deleteLogoSuccess,
  deleteFaviconRequest,
  deleteFaviconSuccess,
  deleteFaviconFailure,
  getAPIKeysFailure,
  getAPIKeysRequest,
  getAPIKeysSuccess,
  getSiteSettingsFailure,
  getSiteSettingsRequest,
  getSiteSettingsSuccess,
  getSiteThemeRequest,
  getSiteThemeSuccess,
  getSiteThemeFailure,
  updateAPIKeysFailure,
  updateAPIKeysRequest,
  updateAPIKeysSuccess,
  updateSiteSettingsFailure,
  updateSiteSettingsRequest,
  updateSiteSettingsSuccess,
  updateTermsRequest,
  updateTermsSuccess,
  updateTermsFailure,
  getTermsRequest,
  getTermsSuccess,
  getTermsFailure,
  getAllWebhooksRequest,
  getAllWebhooksSuccess,
  getAllWebhooksFailure,
  syncWebhooksRequest,
  syncWebhooksSuccess,
  syncWebhooksFailure,
  addWebhookRequest,
  addWebhookSuccess,
  addWebhookFailure,
  deleteWebhookRequest,
  deleteWebhookSuccess,
  deleteWebhookFailure,
  changeThemeModeRequest,
  changeThemeModeSuccess,
  changeThemeModeFailure,
} from './actions';

import { handleActions } from 'redux-actions';

const initialState = {
  isUpdatingSiteSettings: false,
  isUpdatingSiteSettingsSuccess: false,
  isUpdatingSiteSettingsFailure: false,
  isGettingSiteSettings: true,
  siteSettings: {},
  isGetSiteTheme: false,
  isGetSiteThemeSuccess: false,
  isGetSiteThemeFailure: false,
  siteTheme: {},
  successMessage: '',
  errorMessage: '',
  isDeletingLogo: false,
  isDeletingLogoSuccess: false,
  isDeletingLogoFailure: false,
  isDeletingFavicon: false,
  isDeletingFaviconSuccess: false,
  isDeletingFaviconFailure: false,
  isUpdatingKeys: false,
  isUpdatingKeysSuccess: false,
  isUpdatingKeysFailure: false,
  isGettingKeys: false,
  keys: {},
  isUpdatingTerms: false,
  isUpdateTermsSuccess: false,
  isUpdateTermsFailure: false,
  termsDraft: null,
  isGettingTerms: false,
  isGetTermsSuccess: false,
  isGetTermsFailure: false,
  isGettingAllWebhooks: true,
  isGettingAllWebhooksSuccess: false,
  isGettingAllWebhooksFailure: false,
  webhooks: [],
  webhooksCount: 0,
  isSyncWebhooks: false,
  isSyncWebhooksSuccess: false,
  isSyncWebhooksFailure: false,
  isAddingWebhook: false,
  isAddingWebhookFailure: false,
  isAddingWebhookSuccess: false,
  isDeletingWebhook: false,
  isDeletingWebhookSuccess: false,
  isDeletingWebhookFailure: false,
  isChangedThemeModeSuccess: false,
  isChangedThemeModeFailure: false,
};

const reducer = handleActions(
  {
    [updateSiteSettingsRequest]: (state) => ({
      ...state,
      isUpdatingSiteSettings: true,
      isUpdatingSiteSettingsSuccess: false,
      isUpdatingSiteSettingsFailure: false,
    }),
    [updateSiteSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingSiteSettings: false,
      isUpdatingSiteSettingsSuccess: true,
      successMessage: payload.message,
    }),
    [updateSiteSettingsFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingSiteSettings: false,
      isUpdatingSiteSettingsFailure: true,
      errorMessage: payload,
    }),
    [getSiteSettingsRequest]: (state) => ({
      ...state,
      isGettingSiteSettings: true,
    }),
    [getSiteSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSiteSettings: false,
      siteSettings: payload.data,
      successMessage: payload.message,
    }),
    [getSiteSettingsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSiteSettings: false,
      errorMessage: payload,
    }),

    [getSiteThemeRequest]: (state) => ({
      ...state,
      isGetSiteTheme: true,
      isGetSiteThemeSuccess: false,
      isGetSiteThemeFailure: false,
      siteTheme: {},
    }),
    [getSiteThemeSuccess]: (state, { payload }) => ({
      ...state,
      isGetSiteTheme: false,
      isGetSiteThemeSuccess: true,
      siteTheme: payload.data,
      successMessage: payload.message,
    }),
    [getSiteThemeFailure]: (state, { payload }) => ({
      ...state,
      isGetSiteTheme: false,
      isGetSiteThemeFailure: true,
      errorMessage: payload,
    }),

    [deleteLogoRequest]: (state) => ({
      ...state,
      isDeletingLogo: true,
      isDeletingLogoSuccess: false,
      isDeletingLogoFailure: false,
    }),
    [deleteLogoSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingLogo: false,
      isDeletingLogoSuccess: true,
      successMessage: payload.message,
    }),
    [deleteLogoFailure]: (state, { payload }) => ({
      ...state,
      isDeletingLogo: false,
      isDeletingLogoFailure: true,
      errorMessage: payload,
    }),
    [deleteFaviconRequest]: (state) => ({
      ...state,
      isDeletingFavicon: true,
      isDeletingFaviconSuccess: false,
      isDeletingFaviconFailure: false,
    }),
    [deleteFaviconSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingFavicon: false,
      isDeletingFaviconSuccess: true,
      successMessage: payload.message,
    }),
    [deleteFaviconFailure]: (state, { payload }) => ({
      ...state,
      isDeletingFavicon: false,
      isDeletingFaviconFailure: true,
      errorMessage: payload,
    }),
    [updateAPIKeysRequest]: (state) => ({
      ...state,
      isUpdatingKeys: true,
      isUpdatingKeysSuccess: false,
      isUpdatingKeysFailure: false,
    }),
    [updateAPIKeysSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingKeys: false,
      isUpdatingKeysSuccess: true,
      successMessage: payload.message,
    }),
    [updateAPIKeysFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingKeys: false,
      isUpdatingKeysFailure: true,
      errorMessage: payload,
    }),
    [getAPIKeysRequest]: (state) => ({
      ...state,
      isGettingKeys: true,
    }),
    [getAPIKeysSuccess]: (state, { payload }) => ({
      ...state,
      isGettingKeys: false,
      keys: payload.data,
      successMessage: payload.message,
    }),
    [getAPIKeysFailure]: (state, { payload }) => ({
      ...state,
      isGettingKeys: false,
      errorMessage: payload,
    }),
    [updateTermsRequest]: (state) => ({
      ...state,
      isUpdatingTerms: true,
      isUpdateTermsSuccess: false,
      isUpdateTermsFailure: false,
    }),
    [updateTermsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingTerms: false,
      isUpdateTermsSuccess: true,
      termsDraft: payload.data,
      successMessage: payload.message,
    }),
    [updateTermsFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingTerms: false,
      isUpdateTermsFailure: true,
      errorMessage: payload,
    }),
    [getTermsRequest]: (state) => ({
      ...state,
      isGettingTerms: true,
      isGetTermsSuccess: false,
      isGetTermsFailure: false,
      termsDraft: null,
    }),
    [getTermsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTerms: false,
      isGetTermsSuccess: true,
      termsDraft: JSON.parse(payload.data.terms),
      successMessage: payload.message,
    }),
    [getTermsFailure]: (state, { payload }) => ({
      ...state,
      isGettingTerms: false,
      isGetTermsFailure: true,
      errorMessage: payload,
    }),
    [getAllWebhooksRequest]: (state) => ({
      ...state,
      isGettingAllWebhooks: true,
      isGettingAllWebhooksSuccess: false,
      isGettingAllWebhooksFailure: false,
    }),
    [getAllWebhooksSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAllWebhooks: false,
      isGettingAllWebhooksSuccess: true,
      successMessage: payload.message,
      webhooks: payload.data.rows,
      webhooksCount: payload.data.count,
    }),
    [getAllWebhooksFailure]: (state, { payload }) => ({
      ...state,
      isGettingAllWebhooks: false,
      isGettingAllWebhooksFailure: true,
      errorMessage: payload,
    }),
    [syncWebhooksRequest]: (state, { payload }) => ({
      ...state,
      isSyncWebhooks: true,
      isSyncWebhooksSuccess: false,
      isSyncWebhooksFailure: false,
    }),
    [syncWebhooksSuccess]: (state, { payload }) => ({
      ...state,
      isSyncWebhooks: false,
      isSyncWebhooksSuccess: true,
      successMessage: payload.message,
      webhooks: payload.data.rows,
      webhooksCount: payload.data.rows,
    }),
    [syncWebhooksFailure]: (state, { payload }) => ({
      ...state,
      isSyncWebhooks: false,
      isSyncWebhooksFailure: true,
      errorMessage: payload,
    }),
    [addWebhookRequest]: (state, { payload }) => ({
      ...state,
      isAddingWebhook: true,
      isAddingWebhookFailure: false,
      isAddingWebhookSuccess: false,
    }),
    [addWebhookSuccess]: (state, { payload }) => ({
      ...state,
      isAddingWebhook: false,
      isAddingWebhookSuccess: true,
      successMessage: payload.message,
      webhooks: [payload.data, ...state.webhooks],
      webhooksCount: state.webhooksCount + 1,
    }),
    [addWebhookFailure]: (state, { payload }) => ({
      ...state,
      isAddingWebhook: false,
      isAddingWebhookFailure: true,
      errorMessage: payload,
    }),
    [deleteWebhookRequest]: (state, { payload }) => ({
      ...state,
      isDeletingWebhook: true,
      isDeletingWebhookSuccess: false,
      isDeletingWebhookFailure: false,
    }),
    [deleteWebhookSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingWebhook: false,
      isDeletingWebhookSuccess: true,
      successMessage: payload.message,
      webhooks: state.webhooks.filter((item) => item.id !== payload.data),
    }),
    [deleteWebhookFailure]: (state, { payload }) => ({
      ...state,
      isDeletingWebhook: false,
      isDeletingWebhookFailure: true,
      errorMessage: payload,
    }),
    [changeThemeModeRequest]: (state) => ({
      ...state,
      isChangedThemeModeSuccess: false,
      isChangedThemeModeFailure: false,
    }),
    [changeThemeModeSuccess]: (state, { payload }) => ({
      ...state,
      isChangedThemeModeSuccess: true,
      successMessage: payload.message,
      siteSettings: payload.data,
    }),
    [changeThemeModeFailure]: (state, { payload }) => ({
      ...state,
      isChangedThemeModeFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
