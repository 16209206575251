import { axiosApiInstance, config } from 'custom-configs';

import {
  createTicketRequest,
  createTicketSuccess,
  createTicketFailure,
  getTicketUsersListRequest,
  getTicketUsersListSuccess,
  getTicketUsersListFailure,
  getTicketsListRequest,
  getTicketsListFailure,
  getTicketsListSuccess,
  getTicketRequest,
  getTicketFailure,
  getTicketSuccess,
  updateTicketStatusSuccess,
  updateTicketStatusFailure,
  updateTicketStatusRequest,
  deleteTicketFailure,
  deleteTicketSuccess,
  deleteTicketRequest,
  updateTicketFailure,
  updateTicketRequest,
  updateTicketSuccess,
  bulkDeleteTicketsSuccess,
  bulkDeleteTicketsFailure,
  bulkDeleteTicketsRequest,
  createTicketColumnSuccess,
  createTicketColumnFailure,
  createTicketColumnRequest,
  getTicketsColumnsSuccess,
  getTicketsColumnsFailure,
  getTicketsColumnsRequest,
  updateTicketColumnRequest,
  updateTicketColumnSuccess,
  updateTicketColumnFailure,
  deleteColumnRequest,
  deleteColumnFailure,
  deleteColumnSuccess,
  removeTicketFilesRequest,
  removeTicketFilesSuccess,
  removeTicketFilesFailure,
  addCommentSuccess,
  addCommentFailure,
  addCommentRequest,
  deleteCommentFailure,
  deleteCommentSuccess,
  deleteCommentRequest,
  createTicketGroupSuccess,
  createTicketGroupFailure,
  createTicketGroupRequest,
  getTicketGroupListSuccess,
  getTicketGroupListFailure,
  getTicketGroupListRequest,
  deleteTicketGroupSuccess,
  deleteTicketGroupFailure,
  deleteTicketGroupRequest,
  bulkDeleteTicketGroupsRequest,
  bulkDeleteTicketGroupsSuccess,
  bulkDeleteTicketGroupsFailure,
  updateTicketGroupRequest,
  updateTicketGroupSuccess,
  updateTicketGroupFailure,
  getTicketGroupSelectSuccess,
  getTicketGroupSelectFailure,
  getTicketGroupSelectRequest,
  assignTicketToUserSuccess,
  assignTicketToUserFailure,
  assignTicketToUserRequest,
  getCustomersNamesSuccess,
  getCustomersNamesFailure,
  getCustomersNamesRequest,
  makeCommentsSeenSuccess,
  makeCommentsSeenFailure,
  makeCommentsSeenRequest,
  getTicketCommentsSuccess,
  getTicketCommentsFailure,
  getTicketCommentsRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL_TENANT = `${config.TENANT}/tickets`;

function* createTicket({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createTicketSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createTicket, error`, e);
    if (e?.response?.data) {
      yield put(createTicketFailure(catchResponseMessages(e)));
    }
  }
}

function* usersList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/get-users-select`, payload)
    );
    if (response?.status === 200) {
      yield put(getTicketUsersListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for usersList, error`, e);
    if (e?.response?.data) {
      yield put(getTicketUsersListFailure(catchResponseMessages(e)));
    }
  }
}

function* ticketsList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/get-tickets-list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getTicketsListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for ticketsList, error`, e);
    if (e?.response?.data) {
      yield put(getTicketsListFailure(catchResponseMessages(e)));
    }
  }
}

function* getTicket({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/get-ticket/${payload}`)
    );
    if (response?.status === 200) {
      yield put(getTicketSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getTicket, error`, e);
    if (e?.response?.data) {
      yield put(getTicketFailure(catchResponseMessages(e)));
    }
  }
}

function* updateStatus({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/update-tickets-status`, payload)
    );
    if (response?.status === 200) {
      yield put(updateTicketStatusSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateStatus, error`, e);
    if (e?.response?.data) {
      yield put(updateTicketStatusFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteTicket({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_TENANT}/delete-tickets/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteTicketSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteTicket, error`, e);
    if (e?.response?.data) {
      yield put(deleteTicketFailure(catchResponseMessages(e)));
    }
  }
}

function* updateTicket({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/update-ticket/${payload.id}`,
        payload.data
      )
    );
    if (response?.status === 200) {
      yield put(updateTicketSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateTicket, error`, e);
    if (e?.response?.data) {
      yield put(updateTicketFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDelete({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/bulk-delete`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkDeleteTicketsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDelete, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteTicketsFailure(catchResponseMessages(e)));
    }
  }
}

function* createColumn({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/create-column`, payload)
    );
    if (response?.status === 200) {
      yield put(createTicketColumnSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createColumn, error`, e);
    if (e?.response?.data) {
      yield put(createTicketColumnFailure(catchResponseMessages(e)));
    }
  }
}

function* getColumns() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/get-columns`)
    );
    if (response?.status === 200) {
      yield put(getTicketsColumnsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getColumns, error`, e);
    if (e?.response?.data) {
      yield put(getTicketsColumnsFailure(catchResponseMessages(e)));
    }
  }
}

function* updateColumn({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/update-column/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(updateTicketColumnSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateColumn, error`, e);
    if (e?.response?.data) {
      yield put(updateTicketColumnFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteColumn({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_TENANT}/delete-column/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteColumnSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteColumn, error`, e);
    if (e?.response?.data) {
      yield put(deleteColumnFailure(catchResponseMessages(e)));
    }
  }
}

function* removeTicketFiles({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/delete-file`, payload)
    );
    if (response?.status === 200) {
      yield put(removeTicketFilesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for removeTicketFiles, error`, e);
    if (e?.response?.data) {
      yield put(removeTicketFilesFailure(catchResponseMessages(e)));
    }
  }
}

function* addComment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/add-comment`, payload)
    );
    if (response?.status === 200) {
      yield put(addCommentSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for addComment, error`, e);
    if (e?.response?.data) {
      yield put(addCommentFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteComment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL_TENANT}/delete-comment/${payload.id}/${payload.ticketId}`
      )
    );
    if (response?.status === 200) {
      yield put(deleteCommentSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteComment, error`, e);
    if (e?.response?.data) {
      yield put(deleteCommentFailure(catchResponseMessages(e)));
    }
  }
}

function* crateTicketGroup({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/create-group`, payload)
    );
    if (response?.status === 200) {
      yield put(createTicketGroupSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for crateTicketGroup, error`, e);
    if (e?.response?.data) {
      yield put(createTicketGroupFailure(catchResponseMessages(e)));
    }
  }
}

function* getGroupList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/group-list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getTicketGroupListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getGroupList, error`, e);
    if (e?.response?.data) {
      yield put(getTicketGroupListFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteGroup({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_TENANT}/group-delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteTicketGroupSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteGroup, error`, e);
    if (e?.response?.data) {
      yield put(deleteTicketGroupFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDeleteGroups({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/group-bulk-delete`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkDeleteTicketGroupsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDeleteGroups, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteTicketGroupsFailure(catchResponseMessages(e)));
    }
  }
}

function* updateGroup({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/group-update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateTicketGroupSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateGroup, error`, e);
    if (e?.response?.data) {
      yield put(updateTicketGroupFailure(catchResponseMessages(e)));
    }
  }
}

function* getGroupSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/group-select-data`)
    );
    if (response?.status === 200) {
      yield put(getTicketGroupSelectSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getGroupSelect, error`, e);
    if (e?.response?.data) {
      yield put(getTicketGroupSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* assignTicketToUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/assign-ticket/${payload.userId}/${payload.ticketId}`
      )
    );
    if (response?.status === 200) {
      yield put(assignTicketToUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for assignTicketToUser, error`, e);
    if (e?.response?.data) {
      yield put(assignTicketToUserFailure(catchResponseMessages(e)));
    }
  }
}

function* getCustomerNames() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/customer-names`)
    );
    if (response?.status === 200) {
      yield put(getCustomersNamesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCustomerNames, error`, e);
    if (e?.response?.data) {
      yield put(getCustomersNamesFailure(catchResponseMessages(e)));
    }
  }
}

function* makeCommentsSeen({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/make-comments-seen/${payload}`)
    );
    if (response?.status === 200) {
      yield put(makeCommentsSeenSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for makeCommentsSeen, error`, e);
    if (e?.response?.data) {
      yield put(makeCommentsSeenFailure(catchResponseMessages(e)));
    }
  }
}

function* getComments({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/comments/${payload.ticketId}`)
    );
    if (response?.status === 200) {
      yield put(getTicketCommentsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getComments, error`, e);
    if (e?.response?.data) {
      yield put(getTicketCommentsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createTicketRequest, createTicket);
  yield takeLatest(getTicketUsersListRequest, usersList);
  yield takeLatest(getTicketsListRequest, ticketsList);
  yield takeLatest(getTicketRequest, getTicket);
  yield takeLatest(updateTicketStatusRequest, updateStatus);
  yield takeLatest(deleteTicketRequest, deleteTicket);
  yield takeLatest(updateTicketRequest, updateTicket);
  yield takeLatest(bulkDeleteTicketsRequest, bulkDelete);
  yield takeLatest(createTicketColumnRequest, createColumn);
  yield takeLatest(getTicketsColumnsRequest, getColumns);
  yield takeLatest(updateTicketColumnRequest, updateColumn);
  yield takeLatest(deleteColumnRequest, deleteColumn);
  yield takeLatest(removeTicketFilesRequest, removeTicketFiles);
  yield takeLatest(addCommentRequest, addComment);
  yield takeLatest(deleteCommentRequest, deleteComment);
  yield takeLatest(createTicketGroupRequest, crateTicketGroup);
  yield takeLatest(getTicketGroupListRequest, getGroupList);
  yield takeLatest(deleteTicketGroupRequest, deleteGroup);
  yield takeLatest(bulkDeleteTicketGroupsRequest, bulkDeleteGroups);
  yield takeLatest(updateTicketGroupRequest, updateGroup);
  yield takeLatest(getTicketGroupSelectRequest, getGroupSelect);
  yield takeLatest(assignTicketToUserRequest, assignTicketToUser);
  yield takeLatest(getCustomersNamesRequest, getCustomerNames);
  yield takeLatest(makeCommentsSeenRequest, makeCommentsSeen);
  yield takeLatest(getTicketCommentsRequest, getComments);
}
