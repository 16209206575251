import { createAction } from 'redux-actions';

export const getCategorySelectRequest = createAction(
  'GET_CATEGORY_SELECT_REQUEST'
);
export const getCategorySelectSuccess = createAction(
  'GET_CATEGORY_SELECT_SUCCESS'
);
export const getCategorySelectFailure = createAction(
  'GET_CATEGORY_SELECT_FAILURE'
);

export const getManufacturerSelectRequest = createAction(
  'GET_MANUFACTURER_SELECT_REQUEST'
);
export const getManufacturerSelectSuccess = createAction(
  'GET_MANUFACTURER_SELECT_SUCCESS'
);
export const getManufacturerSelectFailure = createAction(
  'GET_MANUFACTURER_SELECT_FAILURE'
);

export const getVendorSelectRequest = createAction('GET_VENDOR_SELECT_REQUEST');
export const getVendorSelectSuccess = createAction('GET_VENDOR_SELECT_SUCCESS');
export const getVendorSelectFailure = createAction('GET_VENDOR_SELECT_FAILURE');

export const createDeviceRequest = createAction('CREATE_DEVICE_REQUEST');
export const createDeviceSuccess = createAction('CREATE_DEVICE_SUCCESS');
export const createDeviceFailure = createAction('CREATE_DEVICE_FAILURE');

export const getDevicesListRequest = createAction('GET_DEVICES_LIST_REQUEST');

export const getDevicesListSuccess = createAction('GET_DEVICES_LIST_SUCCESS');

export const getDevicesListFailure = createAction('GET_DEVICES_LIST_FAILURE');

export const deleteProductRequest = createAction('DELETE_PRODUCT_REQUEST');

export const deleteProductSuccess = createAction('DELETE_PRODUCT_SUCCESS');

export const deleteProductFailure = createAction('DELETE_PRODUCT_FAILURE');

export const findDeviceRequest = createAction('FIND_DEVICE_REQUEST');

export const findDeviceSuccess = createAction('FIND_DEVICE_SUCCESS');

export const findDeviceFailure = createAction('FIND_DEVICE_FAILURE');

export const removeFilesRequest = createAction('REMOVE_FILES_REQUEST');
export const removeFilesSuccess = createAction('REMOVE_FILES_SUCCESS');
export const removeFilesFailure = createAction('REMOVE_FILES_FAILURE');

export const updateDeviceRequest = createAction('UPDATE_DEVICE_REQUEST');
export const updateDeviceSuccess = createAction('UPDATE_DEVICE_SUCCESS');
export const updateDeviceFailure = createAction('UPDATE_DEVICE_FAILURE');

export const clearDeviceData = createAction('CLEAR_DEVICE_DATA');

export const deleteVariantRequest = createAction('DELETE_VARIANT_REQUEST');
export const deleteVariantSuccess = createAction('DELETE_VARIANT_SUCCESS');
export const deleteVariantFailure = createAction('DELETE_VARIANT_FAILURE');

export const deleteVariantFileRequest = createAction(
  'DELETE_VARIANT_FILE_REQUEST'
);
export const deleteVariantFileSuccess = createAction(
  'DELETE_VARIANT_FILE_SUCCESS'
);
export const deleteVariantFileFailure = createAction(
  'DELETE_VARIANT_FILE_FAILURE'
);

export const createAccessoriesRequest = createAction(
  'CREATE_ACCESSORIES_REQUEST'
);
export const createAccessoriesSuccess = createAction(
  'CREATE_ACCESSORIES_SUCCESS'
);
export const createAccessoriesFailure = createAction(
  'CREATE_ACCESSORIES_FAILURE'
);

export const getAccessoriesListRequest = createAction(
  'GET_ACCESSORIES_LIST_REQUEST'
);
export const getAccessoriesListSuccess = createAction(
  'GET_ACCESSORIES_LIST_SUCCESS'
);
export const getAccessoriesListFailure = createAction(
  'GET_ACCESSORIES_LIST_FAILURE'
);

export const findAccessoryDataRequest = createAction(
  'FIND_ACCESSORY_DATA_REQUEST'
);
export const findAccessoryDataSuccess = createAction(
  'FIND_ACCESSORY_DATA_SUCCESS'
);
export const findAccessoryDataFailure = createAction(
  'FIND_ACCESSORY_DATA_FAILURE'
);

export const clearAccessoryData = createAction('CLEAR_ACCESSORY_DATA');

export const updateAccessoryRequest = createAction('UPDATE_ACCESSORY_REQUEST');
export const updateAccessorySuccess = createAction('UPDATE_ACCESSORY_SUCCESS');
export const updateAccessoryFailure = createAction('UPDATE_ACCESSORY_FAILURE');

export const getDevicesSelectDataRequest = createAction(
  'GET_DEVICES_SELECT_DATA_REQUEST'
);
export const getDevicesSelectDataSuccess = createAction(
  'GET_DEVICES_SELECT_DATA_SUCCESS'
);
export const getDevicesSelectDataFailure = createAction(
  'GET_DEVICES_SELECT_DATA_FAILURE'
);

export const getVariantsSelectDataRequest = createAction(
  'GET_VARIANTS_SELECT_DATA_REQUEST'
);
export const getVariantsSelectDataSuccess = createAction(
  'GET_VARIANTS_SELECT_DATA_SUCCESS'
);
export const getVariantsSelectDataFailure = createAction(
  'GET_VARIANTS_SELECT_DATA_FAILURE'
);

export const getUsedCategoriesSelectRequest = createAction(
  'GET_USED_CATEGORIES_SELECT_DATA'
);
export const getUsedCategoriesSelectSuccess = createAction(
  'GET_USED_CATEGORIES_SELECT_SUCCESS'
);
export const getUsedCategoriesSelectFailure = createAction(
  'GET_USED_CATEGORIES_SELECT_FAILURE'
);

export const duplicateDeviceRequest = createAction('DUPLICATE_DEVICE_REQUEST');
export const duplicateDeviceSuccess = createAction('DUPLICATE_DEVICE_SUCCESS');
export const duplicateDeviceFailure = createAction('DUPLICATE_DEVICE_FAILURE');

export const makeImageMainRequest = createAction('MAKE_IMAGE_MAIN_REQUEST');
export const makeImageMainSuccess = createAction('MAKE_IMAGE_MAIN_SUCCESS');
export const makeImageMainFailure = createAction('MAKE_IMAGE_MAIN_FAILURE');

export const getAllVariantsSelectOptionsRequest = createAction(
  'GET_ALL_VARIANTS_SELECT_OPTIONS_REQUEST'
);
export const getAllVariantsSelectOptionsSuccess = createAction(
  'GET_ALL_VARIANTS_SELECT_OPTIONS_SUCCESS'
);
export const getAllVariantsSelectOptionsFailure = createAction(
  'GET_ALL_VARIANTS_SELECT_OPTIONS_FAILURE'
);
