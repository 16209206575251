import { UserOutlined, FieldNumberOutlined } from '@ant-design/icons';

const icons = {
  UserOutlined,
  FieldNumberOutlined,
};

const tenant = [
  {
    id: 'group-tenant',
    title: 'Tenants',
    icon: icons.UserOutlined,
    type: 'item',
    resource: ['admin'],
    url: '/tenants/list',
  },
  {
    id: 'mdns',
    title: 'Phone Numbers',
    icon: icons.FieldNumberOutlined,
    type: 'item',
    resource: ['admin'],
    url: '/admin/phone-numbers',
  },
];
export default tenant;
