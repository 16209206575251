import { createAction } from 'redux-actions';

export const createWSPlanRequest = createAction('CREATE_WS_PLAN_REQUEST');
export const createWSPlanSuccess = createAction('CREATE_WS_PLAN_SUCCESS');
export const createWSPlanFailure = createAction('CREATE_WS_PLAN_FAILURE');

export const getWSPlanRequest = createAction('GET_WS_PLAN_REQUEST');
export const getWSPlanSuccess = createAction('GET_WS_PLAN_SUCCESS');
export const getWSPlanFailure = createAction('GET_WS_PLAN_FAILURE');

export const updateWSPlanRequest = createAction('UPDATE_WS_PLAN_REQUEST');
export const updateWSPlanSuccess = createAction('UPDATE_WS_PLAN_SUCCESS');
export const updateWSPlanFailure = createAction('UPDATE_WS_PLAN_FAILURE');

export const getWSPlanSelectOptionsRequest = createAction(
  'GET_WS_PLAN_SELECT_OPTIONS_REQUEST'
);
export const getWSPlanSelectOptionsSuccess = createAction(
  'GET_WS_PLAN_SELECT_SUCCESS_SUCCESS'
);
export const getWSPlanSelectOptionsFailure = createAction(
  'GET_WS_PLAN_SELECT_FAILURE_FAILURE'
);

export const findWSPlanRequest = createAction('FIND_WS_PLAN_REQUEST');
export const findWSPlanSuccess = createAction('FIND_WS_PLAN_SUCCESS');
export const findWSPlanFailure = createAction('FIND_WS_PLAN_FAILURE');

export const clearWSPlanData = createAction('CLEAR_WS_PLAN_DATA');

export const duplicateWSPlanRequest = createAction('DUPLICATE_WS_PLAN_REQUEST');
export const duplicateWSPlanSuccess = createAction('DUPLICATE_WS_PLAN_SUCCESS');
export const duplicateWSPlanFailure = createAction('DUPLICATE_WS_PLAN_FAILURE');

export const getTopUpWSPlanRequest = createAction('GET_TOP_UP_WS_PLAN_REQUEST');
export const getTopUpWSPlanSuccess = createAction('GET_TOP_UP_WS_PLAN_SUCCESS');
export const getTopUpWSPlanFailure = createAction('GET_TOP_UP_WS_PLAN_FAILURE');
