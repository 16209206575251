import { FormattedMessage } from 'react-intl';
import {
  ShoppingCartOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';

const icons = {
  ShoppingCartOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
};

const reports = {
  id: 'reports',
  title: <FormattedMessage id="Reports" />,
  icon: icons.FileSearchOutlined,
  type: 'collapse',
  resource: ['tenant', 'all'],
  children: [
    {
      id: 'subscriber-report',
      title: 'Subscriber Report',
      type: 'item',
      url: '/report/subscriber-report',
    },
    {
      id: 'subscriber-transaction',
      title: 'Transaction Report',
      type: 'item',
      url: '/report/transaction-report',
    },
    {
      id: 'snapshot-report',
      title: 'Snapshot Report',
      type: 'item',
      url: '/report/snapshot-report',
    },
    {
      id: 'recertification-report',
      title: 'Recertification Report',
      type: 'item',
      url: '/report/recertification-report',
    },
    {
      id: 'not-processed',
      title: 'Not Processed Report',
      type: 'item',
      url: '/report/not-processed-report',
    },
    {
      id: 'optimization',
      title: 'Optimization',
      type: 'item',
      url: '/report/optimization',
    },
    {
      id: 'profitLoss',
      title: 'Profit & Loss',
      type: 'item',
      url: '/report/profit-loss',
    },
  ],
};

export default reports;
