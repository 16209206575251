import { createAction } from 'redux-actions';

export const createCarrierRequest = createAction('CREATE_CARRIER_REQUEST');
export const createCarrierSuccess = createAction('CREATE_CARRIER_SUCCESS');
export const createCarrierFailure = createAction('CREATE_CARRIER_FAILURE');

export const getExternalAPIsRequest = createAction('GET_EXTERNAL_APIS_REQUEST');
export const getExternalAPIsSuccess = createAction('GET_EXTERNAL_APIS_SUCCESS');
export const getExternalAPIsFailure = createAction('GET_EXTERNAL_APIS_FAILURE');

export const getCarriersListDataRequest = createAction(
  'GET_CARRIERS_LIST_DATA_REQUEST'
);
export const getCarriersListDataSuccess = createAction(
  'GET_CARRIERS_LIST_DATA_SUCCESS'
);
export const getCarriersListDataFailure = createAction(
  'GET_CARRIERS_LIST_DATA_FAILURE'
);

export const getCarrierRequest = createAction('GET_CARRIER_REQUEST');
export const getCarrierSuccess = createAction('GET_CARRIER_SUCCESS');
export const getCarrierFailure = createAction('GET_CARRIER_FAILURE');

export const deleteCarrierRequest = createAction('DELETE_CARRIER_REQUEST');
export const deleteCarrierSuccess = createAction('DELETE_CARRIER_SUCCESS');
export const deleteCarrierFailure = createAction('DELETE_CARRIER_FAILURE');

export const deleteCarrierFileRequest = createAction(
  'DELETE_CARRIER_FILE_REQUEST'
);
export const deleteCarrierFileSuccess = createAction(
  'DELETE_CARRIER_FILE_SUCCESS'
);
export const deleteCarrierFileFailure = createAction(
  'DELETE_CARRIER_FILE_FAILURE'
);

export const updateCarrierRequest = createAction('UPDATE_CARRIER_REQUEST');
export const updateCarrierSuccess = createAction('UPDATE_CARRIER_SUCCESS');
export const updateCarrierFailure = createAction('UPDATE_CARRIER_FAILURE');

export const getCarriersServicesRequest = createAction(
  'GET_CARRIERS_SERVICES_REQUEST'
);
export const getCarriersServicesSuccess = createAction(
  'GET_CARRIERS_SERVICES_SUCCESS'
);
export const getCarriersServicesFailure = createAction(
  'GET_CARRIERS_SERVICES_FAILURE'
);

export const getShippingCarriersSelectRequest = createAction(
  'GET_SHIPPING_CARRIERS_SELECT_REQUEST'
);
export const getShippingCarriersSelectSuccess = createAction(
  'GET_SHIPPING_CARRIERS_SELECT_SUCCESS'
);
export const getShippingCarriersSelectFailure = createAction(
  'GET_SHIPPING_CARRIERS_SELECT_FAILURE'
);

export const toggleCarrierServiceRequest = createAction(
  'TOGGLE_CARRIER_SERVICE_REQUEST'
);
export const toggleCarrierServiceSuccess = createAction(
  'TOGGLE_CARRIER_SERVICE_SUCCESS'
);
export const toggleCarrierServiceFailure = createAction(
  'TOGGLE_CARRIER_SERVICE_FAILURE'
);

export const activateSIMMDNPlanRequest = createAction(
  'ACTIVATE_SIM_MDN_PLAN_REQUEST'
);
export const activateSIMMDNPlanSuccess = createAction(
  'ACTIVATE_SIM_MDN_PLAN_SUCCESS'
);
export const activateSIMMDNPlanFailure = createAction(
  'ACTIVATE_SIM_MDN_PLAN_FAILURE'
);

export const enableIsFreeServiceRequest = createAction(
  'ENABLE_IS_FREE_SERVICE_REQUEST'
);
export const enableIsFreeServiceSuccess = createAction(
  'ENABLE_IS_FREE_SERVICE_SUCCESS'
);
export const enableIsFreeServiceFailure = createAction(
  'ENABLE_IS_FREE_SERVICE_FAILURE'
);

export const clearMdnSelect = createAction('CREATE_MDN_SELECT');

export const getPortInRequest = createAction('GET_PORT_IN_REQUEST');
export const getPortInSuccess = createAction('GET_PORT_IN_SUCCESS');
export const getPortInFailure = createAction('GET_PORT_IN_FAILURE');

export const checkPortInStatusRequest = createAction('CHECK_PORT_IN_REQUEST');
export const checkPortInStatusSuccess = createAction('CHECK_PORT_IN_SUCCESS');
export const checkPortInStatusFailure = createAction('CHECK_PORT_IN_FAILURE');

export const clearCarriersListData = createAction('CLEAR_CARRIERS_LIST_DATA');

export const createManuallyPortInRequest = createAction(
  'CREATE_MANUALLY_PORT_IN_REQUEST'
);
export const createManuallyPortInSuccess = createAction(
  'CREATE_MANUALLY_PORT_IN_SUCCESS'
);
export const createManuallyPortInFailure = createAction(
  'CREATE_MANUALLY_PORT_IN_FAILURE'
);

export const cancelPortInRequest = createAction('CANCEL_PORT_IN_REQUEST');
export const cancelPortInSuccess = createAction('CANCEL_PORT_IN_SUCCESS');
export const cancelPortInFailure = createAction('CANCEL_PORT_IN_FAILURE');

export const activateFWCRequest = createAction('ACTIVATE_FWC_REQUEST');
export const activateFWCSuccess = createAction('ACTIVATE_FWC_SUCCESS');
export const activateFWCFailure = createAction('ACTIVATE_FWC_FAILURE');

export const validateShippingRatesRequest = createAction(
  'VALIDATE_SHIPPING_RATES_REQUEST'
);
export const validateShippingRatesSuccess = createAction(
  'VALIDATE_SHIPPING_RATES_SUCCESS'
);
export const validateShippingRatesFailure = createAction(
  'VALIDATE_SHIPPING_RATES_FAILURE'
);

export const clearRateOptions = createAction('CLEAR_RATE_OPTIONS');

export const changePlanRequest = createAction('CHANGE_PLAN_REQUEST');
export const changePlanSuccess = createAction('CHANGE_PLAN_SUCCESS');
export const changePlanFailure = createAction('CHANGE_PLAN_FAILURE');

export const generateShippingPriceRequest = createAction(
  'GENERATE_SHIPPING_PRICE_REQUEST'
);
export const generateShippingPriceSuccess = createAction(
  'GENERATE_SHIPPING_PRICE_SUCCESS'
);
export const generateShippingPriceFailure = createAction(
  'GENERATE_SHIPPING_PRICE_FAILURE'
);

export const updatePortRequest = createAction('UPDATE_PORT_REQUEST');
export const updatePortSuccess = createAction('UPDATE_PORT_SUCCESS');
export const updatePortFailure = createAction('UPDATE_PORT_FAILURE');

export const resubmitPortRequest = createAction('RESUBMIT_PORT_REQUEST');
export const resubmitPortSuccess = createAction('RESUBMIT_PORT_SUCCESS');
export const resubmitPortFailure = createAction('RESUBMIT_PORT_FAILURE');

export const activateHotlineRequest = createAction('ACTIVATE_HOTLINE_REQUEST');
export const activateHotlineSuccess = createAction('ACTIVATE_HOTLINE_SUCCESS');
export const activateHotlineFailure = createAction('ACTIVATE_HOTLINE_FAILURE');

export const removeHotlineRequest = createAction('REMOVE_HOTLINE_REQUEST');
export const removeHotlineSuccess = createAction('REMOVE_HOTLINE_SUCCESS');
export const removeHotlineFailure = createAction('REMOVE_HOTLINE_FAILURE');

export const refreshLineRequest = createAction('REFRESH_LINE_REQUEST');
export const refreshLineSuccess = createAction('REFRESH_LINE_SUCCESS');
export const refreshLineFailure = createAction('REFRESH_LINE_FAILURE');

export const portWithNewESNRequest = createAction('PORT_WITH_SAME_ESN_REQUEST');
export const portWithNewESNSuccess = createAction('PORT_WITH_SAME_ESN_SUCCESS');
export const portWithNewESNFailure = createAction('PORT_WITH_SAME_ESN_FAILURE');

export const changeMDNAtCarrierRequest = createAction(
  'CHANGE_MDN_AT_CARRIER_REQUEST'
);
export const changeMDNAtCarrierSuccess = createAction(
  'CHANGE_MDN_AT_CARRIER_SUCCESS'
);
export const changeMDNAtCarrierFailure = createAction(
  'CHANGE_MDN_AT_CARRIER_FAILURE'
);

export const cancelLinePortRequest = createAction('CANCEL_LINE_PORT_REQUEST');
export const cancelLinePortSuccess = createAction('CANCEL_LINE_PORT_SUCCESS');
export const cancelLinePortFailure = createAction('CANCEL_LINE_PORT_FAILURE');

export const changeCostPlanRequest = createAction('CHANGE_COST_PLAN_REQUEST');
export const changeCostPlanSuccess = createAction('CHANGE_COST_PLAN_SUCCESS');
export const changeCostPlanFailure = createAction('CHANGE_COST_PLAN_FAILURE');

export const getCDRReportRequest = createAction('GET_CDR_REPORT_REQUEST');
export const getCDRReportSuccess = createAction('GET_CDR_REPORT_SUCCESS');
export const getCDRReportFailure = createAction('GET_CDR_REPORT_FAILURE');
