import { axiosApiInstance, config } from 'custom-configs';

import {
  getMetricsRequest,
  getMetricsSuccess,
  getMetricsFailure,
  getMetricsTotalSuccess,
  getMetricsTotalFailure,
  getMetricsTotalRequest,
  getPlansStatisticsSuccess,
  getPlansStatisticsFailure,
  getPlansStatisticsRequest,
  getProfitLossChartSuccess,
  getProfitLossChartFailure,
  getProfitLossChartRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';
import { makeSearchQuery } from '../../utils/constants';

const URL_TENANT = `${config.TENANT}/dashboard`;

function* getMetrics({ payload }) {
  try {
    const query = makeSearchQuery(payload);

    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/metrics${query}`)
    );
    if (response?.status === 200) {
      yield put(getMetricsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getMetrics, error`, e);
    if (e?.response?.data) {
      yield put(getMetricsFailure(catchResponseMessages(e)));
    }
  }
}

function* getMetricsTotal() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/metrics-total`)
    );
    if (response?.status === 200) {
      yield put(getMetricsTotalSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getMetricsTotal, error`, e);
    if (e?.response?.data) {
      yield put(getMetricsTotalFailure(catchResponseMessages(e)));
    }
  }
}

function* getPlanStatistics({ payload }) {
  try {
    const query = makeSearchQuery(payload);
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/plans-statistics${query}`)
    );
    if (response?.status === 200) {
      yield put(getPlansStatisticsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getPlanStatistics, error`, e);
    if (e?.response?.data) {
      yield put(getPlansStatisticsFailure(catchResponseMessages(e)));
    }
  }
}

function* getProfitLossChart({ payload }) {
  try {
    const query = makeSearchQuery(payload);
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/profit-loss${query}`)
    );
    if (response?.status === 200) {
      yield put(getProfitLossChartSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getProfitLossChart, error`, e);
    if (e?.response?.data) {
      yield put(getProfitLossChartFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getMetricsRequest, getMetrics);
  yield takeLatest(getMetricsTotalRequest, getMetricsTotal);
  yield takeLatest(getPlansStatisticsRequest, getPlanStatistics);
  yield takeLatest(getProfitLossChartRequest, getProfitLossChart);
}
