import { axiosApiInstance, config } from 'custom-configs';

import {
  bulkDeleteNotesFailure,
  bulkDeleteNotesRequest,
  bulkDeleteNotesSuccess,
  createNoteFailure,
  createNoteRequest,
  createNoteSuccess,
  deleteNoteFailure,
  deleteNoteRequest,
  deleteNoteSuccess,
  getNotesListFailure,
  getNotesListRequest,
  getNotesListSuccess,
  updateNoteFailure,
  updateNoteRequest,
  updateNoteSuccess,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL = `${config.TENANT}/notes`;

function* createNote({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-note`, payload)
    );
    if (response?.status === 200) {
      yield put(createNoteSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createNote, error`, e);
    if (e?.response?.data) {
      yield put(createNoteFailure(catchResponseMessages(e)));
    }
  }
}
function* getNotes({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/notes-list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getNotesListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getNotes, error`, e);
    if (e?.response?.data) {
      yield put(getNotesListFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteNote({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/delete-note/${payload.id}/${payload.customerId}`
      )
    );
    if (response?.status === 200) {
      yield put(deleteNoteSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteNote, error`, e);
    if (e?.response?.data) {
      yield put(deleteNoteFailure(catchResponseMessages(e)));
    }
  }
}

function* updateNote({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/update-note/${payload.id}/${payload.customerId}`,
        payload
      )
    );
    if (response?.status === 200) {
      yield put(updateNoteSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateNote, error`, e);
    if (e?.response?.data) {
      yield put(updateNoteFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDeleteNotes({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/bulk-delete`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkDeleteNotesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDeleteNotes, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteNotesFailure(catchResponseMessages(e)));
    }
  }
}
export default function* () {
  yield takeLatest(createNoteRequest, createNote);
  yield takeLatest(getNotesListRequest, getNotes);
  yield takeLatest(deleteNoteRequest, deleteNote);
  yield takeLatest(updateNoteRequest, updateNote);
  yield takeLatest(bulkDeleteNotesRequest, bulkDeleteNotes);
}
