import { createAction } from 'redux-actions';

export const getInventoryVariantsRequest = createAction(
  'GET_INVENTORY_VARIANTS_REQUEST'
);
export const getInventoryVariantsSuccess = createAction(
  'GET_INVENTORY_VARIANTS_SUCCESS'
);
export const getInventoryVariantsFailure = createAction(
  'GET_INVENTORY_VARIANTS_FAILURE'
);

export const findVariantRequest = createAction('FIND_VARIANT_REQUEST');
export const findVariantSuccess = createAction('FIND_VARIANT_SUCCESS');
export const findVariantFailure = createAction('FIND_VARIANT_FAILURE');

export const createInventoryRequest = createAction('CREATE_INVENTORY_REQUEST');
export const createInventorySuccess = createAction('CREATE_INVENTORY_SUCCESS');
export const createInventoryFailure = createAction('CREATE_INVENTORY_FAILURE');

export const getVariantInventoryListRequest = createAction(
  'GET_VARIANTS_INVENTORY_LIST_REQUEST'
);
export const getVariantInventoryListSuccess = createAction(
  'GET_VARIANTS_INVENTORY_LIST_SUCCESS'
);
export const getVariantInventoryListFailure = createAction(
  'GET_VARIANTS_INVENTORY_LIST_FAILURE'
);

export const setAvailableCountInventoryRequest = createAction(
  'CHECK_AVAILABLE_COUNT_INVENTORY_REQUEST'
);
export const setAvailableCountInventorySuccess = createAction(
  'CHECK_AVAILABLE_COUNT_INVENTORY_SUCCESS'
);
export const setAvailableCountInventoryFailure = createAction(
  'CHECK_AVAILABLE_COUNT_INVENTORY_FAILURE'
);

export const deleteInventoryRequest = createAction('DELETE_INVENTORY_REQUEST');
export const deleteInventorySuccess = createAction('DELETE_INVENTORY_SUCCESS');
export const deleteInventoryFailure = createAction('DELETE_INVENTORY_FAILURE');

export const deleteInventoryVariantRequest = createAction(
  'DELETE_INVENTORY_VARIANT_REQUEST'
);
export const deleteInventoryVariantSuccess = createAction(
  'DELETE_INVENTORY_VARIANT_SUCCESS'
);
export const deleteInventoryVariantFailure = createAction(
  'DELETE_INVENTORY_VARIANT_FAILURE'
);

export const getInventoryMasterAgentSelectRequest = createAction(
  'GET_INVENTORY_MASTER_AGENT_SELECT_REQUEST'
);
export const getInventoryMasterAgentSelectSuccess = createAction(
  'GET_INVENTORY_MASTER_AGENT_SELECT_SUCCESS'
);
export const getInventoryMasterAgentSelectFailure = createAction(
  'GET_INVENTORY_MASTER_AGENT_SELECT_FAILURE'
);

export const getInventorySubAgentsSelectRequest = createAction(
  'GET_INVENTORY_SUB_AGENTS_SELECT_REQUEST'
);
export const getInventorySubAgentsSelectSuccess = createAction(
  'GET_INVENTORY_SUB_AGENTS_SELECT_SUCCESS'
);
export const getInventorySubAgentsSelectFailure = createAction(
  'GET_INVENTORY_SUB_AGENTS_SELECT_FAILURE'
);

export const clearSubAgentSelectData = createAction(
  'CLEAR_SUB_AGENT_SELECT_DATA'
);

export const assignInventoryToAgentsRequest = createAction(
  'ASSIGN_INVENTORY_TO_AGENTS_REQUEST'
);
export const assignInventoryToAgentsSuccess = createAction(
  'ASSIGN_INVENTORY_TO_AGENTS_SUCCESS'
);
export const assignInventoryToAgentsFailure = createAction(
  'ASSIGN_INVENTORY_TO_AGENTS_FAILURE'
);

export const uploadInventoryRequest = createAction('UPLOAD_INVENTORY_REQUEST');
export const uploadInventorySuccess = createAction('UPLOAD_INVENTORY_SUCCESS');
export const uploadInventoryFailure = createAction('UPLOAD_INVENTORY_FAILURE');

export const createAndAssignInventoryRequest = createAction(
  'CREATE_AND_ASSIGN_INVENTORY_REQUEST'
);
export const createAndAssignInventorySuccess = createAction(
  'CREATE_AND_ASSIGN_INVENTORY_SUCCESS'
);
export const createAndAssignInventoryFailure = createAction(
  'CREATE_AND_ASSIGN_INVENTORY_FAILURE'
);

export const assignSIMCardToIMEIRequest = createAction(
  'ASSIGN_SIM_CARD_TO_IMEI_REQUEST'
);
export const assignSIMCardToIMEISuccess = createAction(
  'ASSIGN_SIM_CARD_TO_IMEI_SUCCESS'
);
export const assignSIMCardToIMEIFailure = createAction(
  'ASSIGN_SIM_CARD_TO_IMEI_FAILURE'
);

export const requestInventoryRequest = createAction(
  'REQUEST_INVENTORY_REQUEST'
);
export const requestInventorySuccess = createAction(
  'REQUEST_INVENTORY_SUCCESS'
);
export const requestInventoryFailure = createAction(
  'REQUEST_INVENTORY_FAILURE'
);

export const getDeviceCategorySelectRequest = createAction(
  'GET_DEVICE_CATEGORY_SELECT_REQUEST'
);
export const getDeviceCategorySelectSuccess = createAction(
  'GET_DEVICE_CATEGORY_SELECT_SUCCESS'
);
export const getDeviceCategorySelectFailure = createAction(
  'GET_DEVICE_CATEGORY_SELECT_FAILURE'
);

export const getSubAgentDataRequest = createAction(
  'GET_SUB_AGENT_DATA_REQUEST'
);
export const getSubAgentDataSuccess = createAction(
  'GET_SUB_AGENT_DATA_SUCCESS'
);
export const getSubAgentDataFailure = createAction(
  'GET_SUB_AGENT_DATA_FAILURE'
);

export const clearSubAgentData = createAction('CLEAR_SUB_AGENT_DATA');

export const checkDeviceIEMIRequest = createAction('CHECK_DEVICE_IEMI_REQUEST');
export const checkDeviceIEMISuccess = createAction('CHECK_DEVICE_IEMI_SUCCESS');
export const checkDeviceIEMIFailure = createAction('CHECK_DEVICE_IEMI_FAILURE');
