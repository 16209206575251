import { axiosApiInstance, config } from 'custom-configs';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

import {
  createCategoryFailure,
  createCategoryRequest,
  createCategorySuccess,
  deleteCategoryFailure,
  deleteCategoryRequest,
  deleteCategorySuccess,
  getCategoryListFailure,
  getCategoryListRequest,
  getCategoryListSuccess,
  updateCategoryFailure,
  updateCategoryRequest,
  updateCategorySuccess,
} from './actions';

const URL = `${config.TENANT}/e-commerce/categories`;

function* createCategory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createCategorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createCategory, error', e);
    if (e?.response?.data) {
      yield put(createCategoryFailure(catchResponseMessages(e)));
    }
  }
}
function* getList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCategoryListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getList, error', e);
    if (e?.response?.data) {
      yield put(getCategoryListFailure(catchResponseMessages(e)));
    }
  }
}
function* updateCategory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateCategorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateCategory, error', e);
    if (e?.response?.data) {
      yield put(updateCategoryFailure(catchResponseMessages(e)));
    }
  }
}
function* deleteCategory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteCategorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteCategory, error', e);
    if (e?.response?.data) {
      yield put(deleteCategoryFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createCategoryRequest, createCategory);
  yield takeLatest(getCategoryListRequest, getList);
  yield takeLatest(updateCategoryRequest, updateCategory);
  yield takeLatest(deleteCategoryRequest, deleteCategory);
}
