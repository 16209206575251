import { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { getDefaultPathByRole } from 'utils/methods';

const conditionalLocationLastItems = [
  'device-inventory',
  'accessories-inventory',
  'all-customers',
  'acp-customers',
]; // for example /e-commerce/inventory/:type -> type === device-inventory || accessories-inventory

export default function ThemeRoutes({ role }) {
  try {
    const navigate = useNavigate();
    const locationArray = window.location.pathname
      .split('/')
      .filter((i) => !!i);
    const lastItem = locationArray[locationArray.length - 1];

    const location = window.location.pathname.includes('register/master-agent')
      ? 'register/master-agent/:agentId'
      : window.location.pathname.includes('register/sub-agent') ||
        window.location.pathname.includes('reset-password')
      ? 'register/sub-agent/:parentAgentId/:agentId'
      : (typeof +lastItem === 'number' && !isNaN(+lastItem)) ||
        conditionalLocationLastItems.includes(lastItem)
      ? locationArray.slice(0, locationArray.length - 1).join('/')
      : locationArray.join('/');
    const currentRoutes = [];

    useEffect(() => {
      getRoutes([...LoginRoutes.children[0].children, ...MainRoutes]);
    }, []);

    useEffect(() => {
      try {
        if (!location && !role) {
          navigate('/login');
        }
        if (!location && role) {
          navigate(getDefaultPathByRole());
        }
        if (location && !role && location.includes('register')) {
          navigate(window.location.pathname);
        }

        const pageExists = currentRoutes.map((i) => {
          let location = i.path;
          if (i.children?.length) {
            return i.children.map((ch) => {
              const childrenArr = ch.path.split('/');

              return childrenArr.length > 1 &&
                childrenArr[childrenArr.length - 1].includes(':')
                ? location +
                    '/' +
                    childrenArr.splice(0, childrenArr.length - 1).join('/')
                : location + '/' + childrenArr.join('/');
            });
          } else {
            return [location];
          }
        });

        const isExistsOrNot = pageExists.some((route) =>
          route.includes(location)
        );

        if (!isExistsOrNot && currentRoutes.length) {
          return navigate(getDefaultPathByRole());
        }
      } catch (e) {
        console.log(e);
      }
    }, [location]);

    const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if (route.children?.length) {
          getRoutes(route.children);
        }

        if (
          (route.resource?.length && route?.resource.includes(role)) ||
          !route.default
        ) {
          currentRoutes.push(route);
        } else if (
          route?.resource?.includes('all') &&
          !['admin', 'masterAgent', 'Agent', 'customer'].includes(role)
        ) {
          currentRoutes.push(route);
        }

        return null;
      });

    return useRoutes([LoginRoutes, ...MainRoutes]);
  } catch (e) {
    console.log(e);
  }
}
