import { handleActions } from 'redux-actions';

import {
  createPhoneNumberFailure,
  createPhoneNumberRequest,
  createPhoneNumberSuccess,
  deletePhoneNumberFailure,
  deletePhoneNumberRequest,
  deletePhoneNumberSuccess,
  editPhoneNumberFailure,
  editPhoneNumberRequest,
  editPhoneNumberSuccess,
  getPhoneNumbersFailure,
  getPhoneNumbersRequest,
  getPhoneNumbersSuccess,
  uploadPhoneNumbersRequest,
  uploadPhoneNumbersSuccess,
} from './actions';

const initialState = {
  isUploadingPhoneNumbers: false,
  isUploadingPhoneNumbersSuccess: false,
  isUploadingPhoneNumbersFailure: false,
  isGettingPhoneNumbers: true,
  isGettingPhoneNumbersSuccess: false,
  isGettingPhoneNumbersFailure: false,
  phoneNumbers: [],
  phoneNumbersCount: 0,
  isCreatingPhoneNumber: false,
  isCreatingPhoneNumberSuccess: false,
  isCreatingPhoneNumberFailure: false,
  isEditingPhoneNumber: false,
  isEditingPhoneNumberSuccess: false,
  isEditingPhoneNumberFailure: false,
  isDeletingPhoneNumber: false,
  isDeletingPhoneNumberSuccess: false,
  isDeletingPhoneNumberFailure: false,
};

const reducer = handleActions(
  {
    [uploadPhoneNumbersRequest]: (state) => ({
      ...state,
      isUploadingPhoneNumbers: true,
      isUploadingPhoneNumbersSuccess: false,
      isUploadingPhoneNumbersFailure: false,
    }),
    [uploadPhoneNumbersSuccess]: (state, { payload }) => ({
      ...state,
      isUploadingPhoneNumbers: false,
      isUploadingPhoneNumbersSuccess: true,
      successMessage: payload.message,
    }),
    [uploadPhoneNumbersRequest]: (state, { payload }) => ({
      ...state,
      isUploadingPhoneNumbers: false,
      isUploadingPhoneNumbersFailure: true,
      errorMessage: payload,
    }),
    [getPhoneNumbersRequest]: (state) => ({
      ...state,
      isGettingPhoneNumbers: true,
      isGettingPhoneNumbersSuccess: false,
      isGettingPhoneNumbersFailure: false,
    }),
    [getPhoneNumbersSuccess]: (state, { payload }) => ({
      ...state,
      isGettingPhoneNumbers: false,
      isGettingPhoneNumbersSuccess: true,
      successMessage: payload.message,
      phoneNumbers: payload.data.rows,
      phoneNumbersCount: payload.data.count,
    }),
    [getPhoneNumbersFailure]: (state, { payload }) => ({
      ...state,
      isGettingPhoneNumbers: false,
      isGettingPhoneNumbersFailure: true,
      errorMessage: payload,
    }),
    [createPhoneNumberRequest]: (state) => ({
      ...state,
      isCreatingPhoneNumber: true,
      isCreatingPhoneNumberSuccess: false,
      isCreatingPhoneNumberFailure: false,
    }),
    [createPhoneNumberSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingPhoneNumber: false,
      isCreatingPhoneNumberSuccess: true,
      successMessage: payload.message,
      phoneNumbers: [...state.phoneNumbers, payload.data],
    }),
    [createPhoneNumberFailure]: (state, { payload }) => ({
      ...state,
      isCreatingPhoneNumber: false,
      isCreatingPhoneNumberFailure: true,
      errorMessage: payload,
    }),
    [editPhoneNumberRequest]: (state) => ({
      ...state,
      isEditingPhoneNumber: true,
      isEditingPhoneNumberSuccess: false,
      isEditingPhoneNumberFailure: false,
    }),
    [editPhoneNumberSuccess]: (state, { payload }) => {
      const mdnCopy = [...state.phoneNumbers];
      const index = mdnCopy.findIndex((item) => item.id === payload.data.id);

      if (index !== -1) {
        mdnCopy[index] = payload.data;
      }
      return {
        ...state,
        isEditingPhoneNumber: false,
        isEditingPhoneNumberSuccess: true,
        successMessage: payload.message,
        phoneNumbers: mdnCopy,
      };
    },
    [editPhoneNumberFailure]: (state, { payload }) => ({
      ...state,
      isEditingPhoneNumber: false,
      isEditingPhoneNumberFailure: true,
      errorMessage: payload,
    }),
    [deletePhoneNumberRequest]: (state) => ({
      ...state,
      isDeletingPhoneNumber: true,
      isDeletingPhoneNumberSuccess: false,
      isDeletingPhoneNumberFailure: false,
    }),
    [deletePhoneNumberSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingPhoneNumber: false,
      isDeletingPhoneNumberSuccess: true,
      successMessage: payload.message,
      phoneNumbers: state.phoneNumbers.filter(
        (number) => number.id !== payload.data
      ),
    }),
    [deletePhoneNumberFailure]: (state, { payload }) => ({
      ...state,
      isDeletingPhoneNumber: false,
      isDeletingPhoneNumberFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
