import { SplitCellsOutlined } from '@ant-design/icons';

const icons = {
  SplitCellsOutlined,
};
const portIn = {
  id: 'ports',
  title: 'Ports',
  icon: icons.SplitCellsOutlined,
  type: 'item',
  resource: ['tenant', 'masterAgent', 'subAgent', 'all'],
  url: '/dashboard/ports',
};
export default portIn;
