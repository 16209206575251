import { getCategorySelectFailure } from '../../eCommerce/products/actions';
import { catchResponseMessages } from '../../../utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  attachCustomerDefaultPaymentMethodFailure,
  attachCustomerDefaultPaymentMethodRequest,
  attachCustomerDefaultPaymentMethodSuccess,
  confirmCheckoutFailure,
  confirmCheckoutRequest,
  confirmCheckoutSuccess,
  createCustomerPaymentMethodFailure,
  createCustomerPaymentMethodRequest,
  createCustomerPaymentMethodSuccess,
  createPaymentMethodFailure,
  createPaymentMethodRequest,
  createPaymentMethodSuccess,
  defaultPaymentMethodFailure,
  defaultPaymentMethodRequest,
  defaultPaymentMethodSuccess,
  deleteCustomerPaymentMethodFailure,
  deleteCustomerPaymentMethodRequest,
  deleteCustomerPaymentMethodSuccess,
  deletePaymentMethodFailure,
  deletePaymentMethodRequest,
  deletePaymentMethodSuccess,
  getCustomersPaymentMethodsFailure,
  getCustomersPaymentMethodsRequest,
  getCustomersPaymentMethodsSuccess,
  getPaymentMethodListFailure,
  getPaymentMethodListRequest,
  getPaymentMethodListSuccess,
  getPaymentMethodsSelectDataFailure,
  getPaymentMethodsSelectDataRequest,
  getPaymentMethodsSelectDataSuccess,
  getPaymentsListFailure,
  getPaymentsListRequest,
  getPaymentsListSuccess,
  makePaymentFailure,
  makePaymentRequest,
  makePaymentSuccess,
  stripePaymentAuthorizationFailure,
  stripePaymentAuthorizationRequest,
  stripePaymentAuthorizationSuccess,
} from './actions';

import { axiosApiInstance, config } from '../../../custom-configs';

const URL_TENANT = `${config.TENANT}/payments`;

function* stripeAuthorization() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/setup-intend`)
    );
    if (response.status === 200) {
      yield put(stripePaymentAuthorizationSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for stripeAuthorization, ERROR:', e);
    if (e?.response?.data) {
      yield put(stripePaymentAuthorizationFailure(catchResponseMessages(e)));
    }
  }
}
function* createPaymentMethod({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/add-payment-method`, payload)
    );
    if (response.status === 200) {
      yield put(createPaymentMethodSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createPaymentMethod, ERROR:', e);
    if (e?.response?.data) {
      yield put(createPaymentMethodFailure(catchResponseMessages(e)));
    }
  }
}
function* getPaymentMethodList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/payment-method-list${payload}`)
    );
    if (response.status === 200) {
      yield put(getPaymentMethodListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getPaymentMethodList, ERROR:', e);
    if (e?.response?.data) {
      yield put(getPaymentMethodListFailure(catchResponseMessages(e)));
    }
  }
}
function* defaultPaymentMethod({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/attach-default-method/${payload.id}`,
        payload
      )
    );
    if (response.status === 200) {
      yield put(
        defaultPaymentMethodSuccess({ data: response.data, id: payload.id })
      );
    }
  } catch (e) {
    console.log('Catch for defaultPaymentMethod, ERROR:', e);
    if (e?.response?.data) {
      yield put(defaultPaymentMethodFailure(catchResponseMessages(e)));
    }
  }
}
function* deleteDefaultPaymentMethod({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_TENANT}/delete-default-method/${payload}`)
    );
    if (response.status === 200) {
      yield put(deletePaymentMethodSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteDefaultPaymentMethod, ERROR:', e);
    if (e?.response?.data) {
      yield put(deletePaymentMethodFailure(catchResponseMessages(e)));
    }
  }
}
function* paymentMethodsSelectData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/payment-method-select-data`)
    );
    if (response.status === 200) {
      yield put(getPaymentMethodsSelectDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for paymentMethodsSelectData, ERROR:', e);
    if (e?.response?.data) {
      yield put(getPaymentMethodsSelectDataFailure(catchResponseMessages(e)));
    }
  }
}
function* makePayment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/make-payment`, payload)
    );
    if (response.status === 200) {
      yield put(makePaymentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for makePayment, ERROR:', e);
    if (e?.response?.data) {
      yield put(makePaymentFailure(catchResponseMessages(e)));
    }
  }
}

function* paymentsList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/payments-list-data${payload}`)
    );
    if (response.status === 200) {
      yield put(getPaymentsListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for paymentsList, ERROR:', e);
    if (e?.response?.data) {
      yield put(getPaymentsListFailure(catchResponseMessages(e)));
    }
  }
}

function* confirmCheckout({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/confirm-checkout`, payload)
    );
    if (response.status === 200) {
      yield put(confirmCheckoutSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for confirmCheckout, ERROR:', e);
    if (e?.response?.data) {
      yield put(confirmCheckoutFailure(catchResponseMessages(e)));
    }
  }
}

function* customerPaymentMethods({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL_TENANT}/customer-payment-methods/${payload.customerId}/${payload.query}`
      )
    );
    if (response.status === 200) {
      yield put(getCustomersPaymentMethodsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for customerPaymentMethods, ERROR:', e);
    if (e?.response?.data) {
      yield put(getCustomersPaymentMethodsFailure(catchResponseMessages(e)));
    }
  }
}

function* createCustomerPaymentMethod({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL_TENANT}/add-customer-payment-method`,
        payload
      )
    );
    if (response.status === 200) {
      yield put(createCustomerPaymentMethodSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createCustomerPaymentMethod, ERROR:', e);
    if (e?.response?.data) {
      yield put(createCustomerPaymentMethodFailure(catchResponseMessages(e)));
    }
  }
}
function* attachCustomerDefault({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/attach-customer-default-method/${payload.id}/${payload.customerId}`
      )
    );
    if (response.status === 200) {
      yield put(attachCustomerDefaultPaymentMethodSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for attachCustomerDefault, ERROR:', e);
    if (e?.response?.data) {
      yield put(
        attachCustomerDefaultPaymentMethodFailure(catchResponseMessages(e))
      );
    }
  }
}
function* deleteCustomerPaymentMethod({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL_TENANT}/delete-customer-payment-method/${payload.id}/${payload.customerId}`
      )
    );
    if (response.status === 200) {
      yield put(deleteCustomerPaymentMethodSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteCustomerPaymentMethod, ERROR:', e);
    if (e?.response?.data) {
      yield put(deleteCustomerPaymentMethodFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(stripePaymentAuthorizationRequest, stripeAuthorization);
  yield takeLatest(createPaymentMethodRequest, createPaymentMethod);
  yield takeLatest(getPaymentMethodListRequest, getPaymentMethodList);
  yield takeLatest(defaultPaymentMethodRequest, defaultPaymentMethod);
  yield takeLatest(deletePaymentMethodRequest, deleteDefaultPaymentMethod);
  yield takeLatest(makePaymentRequest, makePayment);
  yield takeLatest(getPaymentsListRequest, paymentsList);
  yield takeLatest(confirmCheckoutRequest, confirmCheckout);
  yield takeLatest(getCustomersPaymentMethodsRequest, customerPaymentMethods);
  yield takeLatest(
    attachCustomerDefaultPaymentMethodRequest,
    attachCustomerDefault
  );
  yield takeLatest(
    createCustomerPaymentMethodRequest,
    createCustomerPaymentMethod
  );
  yield takeLatest(
    getPaymentMethodsSelectDataRequest,
    paymentMethodsSelectData
  );
  yield takeLatest(
    deleteCustomerPaymentMethodRequest,
    deleteCustomerPaymentMethod
  );
}
