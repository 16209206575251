import { createAction } from 'redux-actions';

export const updateSiteSettingsRequest = createAction(
  'UPDATE_SITE_SETTINGS_REQUEST'
);
export const updateSiteSettingsSuccess = createAction(
  'UPDATE_SITE_SETTINGS_SUCCESS'
);
export const updateSiteSettingsFailure = createAction(
  'UPDATE_SITE_SETTINGS_FAILURE'
);

export const getSiteSettingsRequest = createAction('GET_SITE_SETTINGS_REQUEST');
export const getSiteSettingsSuccess = createAction('GET_SITE_SETTINGS_SUCCESS');
export const getSiteSettingsFailure = createAction('GET_SITE_SETTINGS_FAILURE');

export const getSiteThemeRequest = createAction('GET_SITE_THEME_REQUEST');
export const getSiteThemeSuccess = createAction('GET_SITE_THEME_SUCCESS');
export const getSiteThemeFailure = createAction('GET_SITE_THEME_FAILURE');

export const deleteLogoRequest = createAction('DELETE_LOGO_REQUEST');
export const deleteLogoSuccess = createAction('DELETE_LOGO_SUCCESS');
export const deleteLogoFailure = createAction('DELETE_LOGO_FAILURE');

export const deleteFaviconRequest = createAction('DELETE_FAVICON_REQUEST');
export const deleteFaviconSuccess = createAction('DELETE_FAVICON_SUCCESS');
export const deleteFaviconFailure = createAction('DELETE_FAVICON_FAILURE');

export const updateAPIKeysRequest = createAction(
  'UPDATE_STRIPE_API_KEYS_REQUEST'
);
export const updateAPIKeysSuccess = createAction(
  'UPDATE_STRIPE_API_KEYS_SUCCESS'
);
export const updateAPIKeysFailure = createAction(
  'UPDATE_STRIPE_API_KEYS_FAILURE'
);

export const getAPIKeysRequest = createAction('GET_API_KEYS_REQUEST');
export const getAPIKeysSuccess = createAction('GET_API_KEYS_SUCECSS');
export const getAPIKeysFailure = createAction('GET_API_KEYS_FAILURE');

export const updateTermsRequest = createAction('UPDATE_TERMS_REQUEST');
export const updateTermsSuccess = createAction('UPDATE_TERMS_SUCCESS');
export const updateTermsFailure = createAction('UPDATE_TERMS_FAILURE');

export const getTermsRequest = createAction('GET_TERMS_REQUEST');
export const getTermsSuccess = createAction('GET_TERMS_SUCCESS');
export const getTermsFailure = createAction('GET_TERMS_FAILURE');

export const getAllWebhooksRequest = createAction('GET_ALL_WEBHOOKS_REQUEST');
export const getAllWebhooksSuccess = createAction('GET_ALL_WEBHOOKS_SUCCESS');
export const getAllWebhooksFailure = createAction('GET_ALL_WEBHOOKS_FAILURE');

export const syncWebhooksRequest = createAction('SYNC_WEBHOOKS_REQUEST');
export const syncWebhooksSuccess = createAction('SYNC_WEBHOOKS_SUCCESS');
export const syncWebhooksFailure = createAction('SYNC_WEBHOOKS_FAILURE');

export const addWebhookRequest = createAction('ADD_WEBHOOK_REQUEST');
export const addWebhookSuccess = createAction('ADD_WEBHOOK_SUCCESS');
export const addWebhookFailure = createAction('ADD_WEBHOOK_FAILURE');

export const deleteWebhookRequest = createAction('DELETE_WEBHOOK_REQUEST');
export const deleteWebhookSuccess = createAction('DELETE_WEBHOOK_SUCCESS');
export const deleteWebhookFailure = createAction('DELETE_WEBHOOK_FAILURE');

export const changeThemeModeRequest = createAction('CHANGE_THEME_MODE_REQUEST');
export const changeThemeModeSuccess = createAction('CHANGE_THEME_MODE_SUCCESS');
export const changeThemeModeFailure = createAction('CHANGE_THEME_MODE_FAILURE');
