import { axiosApiInstance, config } from 'custom-configs';

import {
  createAgentFailure,
  createAgentRequest,
  createAgentSuccess,
  generateAgentIdFailure,
  generateAgentIdRequest,
  generateAgentIdSuccess,
  getAgentsListFailure,
  getAgentsListRequest,
  getAgentsListSuccess,
  getAgentSelectOptionsFailure,
  getAgentSelectOptionsRequest,
  getAgentSelectOptionsSuccess,
  findOneAgentSuccess,
  findOneAgentFailure,
  findOneAgentRequest,
  editAgentRequest,
  editAgentSuccess,
  editAgentFailure,
  deleteAgentSuccess,
  deleteAgentFailure,
  deleteAgentRequest,
  getSubAgentsListDataRequest,
  getSubAgentsListDataFailure,
  getSubAgentsListDataSuccess,
  createSubAgentSuccess,
  createSubAgentFailure,
  createSubAgentRequest,
  findSubAgentRequest,
  findSubAgentFailure,
  findSubAgentSuccess,
  updateSubAgentFailure,
  updateSubAgentSuccess,
  updateSubAgentRequest,
  toggleAgentRequest,
  toggleAgentSuccess,
  toggleAgentFailure,
  duplicateAgentSuccess,
  duplicateAgentFailure,
  duplicateAgentRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

const URL = `${config.TENANT}/agents`;

function* generateAgentId() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/generate-agentID`)
    );
    if (response?.status === 200) {
      yield put(generateAgentIdSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for generateAgentId, error', e);
    if (e?.response?.data) {
      yield put(generateAgentIdFailure(catchResponseMessages(e)));
    }
  }
}
function* createAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-agent`, payload)
    );
    if (response?.status === 200) {
      yield put(createAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createAgent, error', e);
    if (e?.response?.data) {
      yield put(createAgentFailure(catchResponseMessages(e)));
    }
  }
}
function* getAgentsList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getAgentsListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAgentsList, error', e);
    if (e?.response?.data) {
      yield put(getAgentsListFailure(catchResponseMessages(e)));
    }
  }
}
function* getAgentsSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/agent-select`)
    );
    if (response?.status === 200) {
      yield put(getAgentSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAgentsSelect, error', e);
    if (e?.response?.data) {
      yield put(getAgentSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* findOneAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find-one/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findOneAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findOneAgent, error', e);
    if (e?.response?.data) {
      yield put(findOneAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* updateAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-agent/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(editAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateAgent, error', e);
    if (e?.response?.data) {
      yield put(editAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete-agent/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteAgent, error', e);
    if (e?.response?.data) {
      yield put(deleteAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* getSubAgentsList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/sub-agents-list-data${payload}`)
    );
    if (response?.status === 200) {
      yield put(getSubAgentsListDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSubAgentsList, error', e);
    if (e?.response?.data) {
      yield put(getSubAgentsListDataFailure(catchResponseMessages(e)));
    }
  }
}

function* createSubAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-sub-agent`, payload)
    );
    if (response?.status === 200) {
      yield put(createSubAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createSubAgent, error', e);
    if (e?.response?.data) {
      yield put(createSubAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* findSubAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find-one-sub-agent/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findSubAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findSubAgent, error', e);
    if (e?.response?.data) {
      yield put(findSubAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* updateSubAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-sub-agent/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(updateSubAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateSubAgent, error', e);
    if (e?.response?.data) {
      yield put(updateSubAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* toggleAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/toggle-agent/${payload}`)
    );
    if (response?.status === 200) {
      yield put(toggleAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for toggleAgent, error', e);
    if (e?.response?.data) {
      yield put(toggleAgentFailure(catchResponseMessages(e)));
    }
  }
}

function* duplicateAgent({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/duplicate-agent/${payload}`)
    );
    if (response?.status === 200) {
      yield put(duplicateAgentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for duplicateAgent, error', e);
    if (e?.response?.data) {
      yield put(duplicateAgentFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(generateAgentIdRequest, generateAgentId);
  yield takeLatest(createAgentRequest, createAgent);
  yield takeLatest(getAgentsListRequest, getAgentsList);
  yield takeLatest(getAgentSelectOptionsRequest, getAgentsSelect);
  yield takeLatest(findOneAgentRequest, findOneAgent);
  yield takeLatest(editAgentRequest, updateAgent);
  yield takeLatest(deleteAgentRequest, deleteAgent);
  yield takeLatest(getSubAgentsListDataRequest, getSubAgentsList);
  yield takeLatest(createSubAgentRequest, createSubAgent);
  yield takeLatest(findSubAgentRequest, findSubAgent);
  yield takeLatest(updateSubAgentRequest, updateSubAgent);
  yield takeLatest(toggleAgentRequest, toggleAgent);
  yield takeLatest(duplicateAgentRequest, duplicateAgent);
}
