import { axiosApiInstance, config } from 'custom-configs';

import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createWSPlanRequest,
  createWSPlanSuccess,
  createWSPlanFailure,
  getWSPlanRequest,
  getWSPlanSuccess,
  getWSPlanFailure,
  updateWSPlanRequest,
  updateWSPlanSuccess,
  updateWSPlanFailure,
  getWSPlanSelectOptionsSuccess,
  getWSPlanSelectOptionsFailure,
  getWSPlanSelectOptionsRequest,
  findWSPlanSuccess,
  findWSPlanFailure,
  findWSPlanRequest,
  duplicateWSPlanSuccess,
  duplicateWSPlanFailure,
  duplicateWSPlanRequest,
  getTopUpWSPlanSuccess,
  getTopUpWSPlanFailure,
  getTopUpWSPlanRequest,
} from './actions';

const URL = `${config.TENANT}/settings`;

function* createWholesalePlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-ws-plan`, payload)
    );
    if (response?.status === 200) {
      yield put(createWSPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getConfigs, error', e);
    if (e?.response?.data) {
      yield put(createWSPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getWholesalePlan({ payload = '' }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/ws-plan${payload}`)
    );
    if (response?.status === 200) {
      yield put(getWSPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getConfigs, error', e);
    if (e?.response?.data) {
      yield put(getWSPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* findWSPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find-ws-plan/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findWSPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findWSPlan, error', e);
    if (e?.response?.data) {
      yield put(findWSPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* updateWholesalePlan({ payload }) {
  try {
    const { id, ...rest } = payload;
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-ws-plan/${id}`, rest)
    );
    if (response?.status === 200) {
      yield put(updateWSPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getConfigs, error', e);
    if (e?.response?.data) {
      yield put(updateWSPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getWSPlanOptions() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/select-options`)
    );
    if (response?.status === 200) {
      yield put(getWSPlanSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getWSPlanOptions, error', e);
    if (e?.response?.data) {
      yield put(getWSPlanSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* duplicateWSPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/duplicate-ws-plan/${payload}`)
    );
    if (response?.status === 200) {
      yield put(duplicateWSPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for duplicateWSPlan, error', e);
    if (e?.response?.data) {
      yield put(duplicateWSPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getTopUpWSPlan() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/top-up-ws-plans`)
    );
    if (response?.status === 200) {
      yield put(getTopUpWSPlanSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getTopUpWSPlan, error', e);
    if (e?.response?.data) {
      yield put(getTopUpWSPlanFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createWSPlanRequest, createWholesalePlan);
  yield takeLatest(getWSPlanRequest, getWholesalePlan);
  yield takeLatest(updateWSPlanRequest, updateWholesalePlan);
  yield takeLatest(getWSPlanSelectOptionsRequest, getWSPlanOptions);
  yield takeLatest(findWSPlanRequest, findWSPlan);
  yield takeLatest(duplicateWSPlanRequest, duplicateWSPlan);
  yield takeLatest(getTopUpWSPlanRequest, getTopUpWSPlan);
}
