import { axiosApiInstance, config } from 'custom-configs';

import {
  getSearchHistoryFailure,
  getSearchHistoryRequest,
  getSearchHistorySuccess,
  globalSearchFailure,
  globalSearchRequest,
  globalSearchSuccess,
  removeSearchHistoryFailure,
  removeSearchHistoryRequest,
  removeSearchHistorySuccess,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL_SEARCH = `${config.API_HOST}/api/global-search`;

function* globalSearch({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_SEARCH}/search${payload}`)
    );
    if (response?.status === 200) {
      yield put(globalSearchSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for globalSearch, error`, e);
    if (e?.response?.data) {
      yield put(globalSearchFailure(catchResponseMessages(e)));
    }
  }
}

function* getSearchHistory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_SEARCH}/search-history${payload}`)
    );
    if (response?.status === 200) {
      yield put(getSearchHistorySuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getSearchHistory, error`, e);
    if (e?.response?.data) {
      yield put(getSearchHistoryFailure(catchResponseMessages(e)));
    }
  }
}

function* removeHistory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_SEARCH}/remove-history/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(removeSearchHistorySuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for removeHistory, error`, e);
    if (e?.response?.data) {
      yield put(removeSearchHistoryFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(globalSearchRequest, globalSearch);
  yield takeLatest(getSearchHistoryRequest, getSearchHistory);
  yield takeLatest(removeSearchHistoryRequest, removeHistory);
}
