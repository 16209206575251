import { handleActions } from 'redux-actions';
import {
  createManufacturerFailure,
  createManufacturerRequest,
  createManufacturerSuccess,
  deleteManufacturerFailure,
  deleteManufacturerFilesFailure,
  deleteManufacturerFilesRequest,
  deleteManufacturerFilesSuccess,
  deleteManufacturerRequest,
  deleteManufacturerSuccess,
  getManufacturersListFailure,
  getManufacturersListRequest,
  getManufacturersListSuccess,
  handleManufacturerData,
  updateManufacturerFailure,
  updateManufacturerRequest,
  updateManufacturerSuccess,
} from './actions';

const initialState = {
  isCreatingManufacturer: false,
  isCreatingManufacturerSuccess: false,
  isCreatingManufacturerFailure: false,
  successMessage: '',
  manufacturers: [],
  manufacturersCount: 0,
  isGettingManufacturersList: true,
  isGettingManufacturersListSuccess: false,
  isGettingManufacturersListFailure: false,
  isDeletedManufacturerSuccess: false,
  isDeletedManufacturerFailure: false,
  manufacturerData: {},
  isDeletedFileFailure: false,
  isDeletedFileSuccess: false,
  isUpdatingManufacturer: false,
  isUpdatingManufacturerSuccess: false,
  isUpdatingManufacturerFailure: false,
};

const reducer = handleActions(
  {
    [createManufacturerRequest]: (state) => ({
      ...state,
      isCreatingManufacturer: true,
      isCreatingManufacturerSuccess: false,
      isCreatingManufacturerFailure: false,
    }),
    [createManufacturerSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingManufacturer: false,
      isCreatingManufacturerSuccess: true,
      successMessage: payload.message,
      manufacturers: [payload.data, ...state.manufacturers],
      manufacturersCount: state.manufacturersCount + 1,
    }),
    [createManufacturerFailure]: (state, { payload }) => ({
      ...state,
      isCreatingManufacturer: false,
      isCreatingManufacturerFailure: true,
      errorMessage: payload,
    }),
    [getManufacturersListRequest]: (state) => ({
      ...state,
      isGettingManufacturersList: true,
      isGettingManufacturersListSuccess: false,
      isGettingManufacturersListFailure: false,
    }),
    [getManufacturersListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingManufacturersList: false,
      isGettingManufacturersListSuccess: true,
      manufacturers: payload.data.rows,
      manufacturersCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getManufacturersListFailure]: (state, { payload }) => ({
      ...state,
      isGettingManufacturersList: false,
      isGettingManufacturersListFailure: true,
      errorMessage: payload,
    }),
    [deleteManufacturerRequest]: (state) => ({
      ...state,
      isDeletedManufacturerSuccess: false,
      isDeletedManufacturerFailure: false,
    }),
    [deleteManufacturerSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedManufacturerSuccess: true,
      successMessage: payload.message,
      manufacturers: state.manufacturers.filter((i) => i.id !== payload.data),
      manufacturersCount: state.manufacturersCount - 1,
    }),
    [deleteManufacturerFailure]: (state, { payload }) => ({
      ...state,
      isDeletedManufacturerFailure: true,
      errorMessage: payload,
    }),
    [handleManufacturerData]: (state, { payload }) => ({
      ...state,
      manufacturerData: payload,
    }),
    [deleteManufacturerFilesRequest]: (state) => ({
      ...state,
      isDeletedFileFailure: false,
      isDeletedFileSuccess: false,
    }),
    [deleteManufacturerFilesSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedFileSuccess: true,
      successMessage: payload.message,
      manufacturers: state.manufacturers.map((i) => {
        if (i.id === payload.data.id) {
          return payload.data;
        } else return i;
      }),
    }),
    [deleteManufacturerFilesFailure]: (state, { payload }) => ({
      ...state,
      isDeletedFileFailure: true,
      errorMessage: payload,
    }),

    [updateManufacturerRequest]: (state) => ({
      ...state,
      isUpdatingManufacturer: true,
      isUpdatingManufacturerSuccess: false,
      isUpdatingManufacturerFailure: false,
    }),
    [updateManufacturerSuccess]: (state, { payload }) => {
      const manufacturersCopy = [...state.manufacturers];

      const index = manufacturersCopy.findIndex(
        (i) => i.id === payload.data.id
      );

      if (index !== -1) {
        manufacturersCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingManufacturer: false,
        isUpdatingManufacturerSuccess: true,
        successMessage: payload.message,
        manufacturers: manufacturersCopy,
      };
    },
    [updateManufacturerFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingManufacturer: false,
      isUpdatingManufacturerFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
