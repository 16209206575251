import { axiosApiInstance, config } from 'custom-configs';

import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  bulkDeleteRolesFailure,
  bulkDeleteRolesRequest,
  bulkDeleteRolesSuccess,
  createUserFailure,
  createUserRequest,
  createUserRoleFailure,
  createUserRoleRequest,
  createUserRoleSuccess,
  createUserSuccess,
  deleteRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  findRoleFailure,
  findRoleRequest,
  findRoleSuccess,
  findUserFailure,
  findUserRequest,
  findUserSuccess,
  getRolesLIstFailure,
  getRolesLIstRequest,
  getRolesLIstSuccess,
  getRolesOptionsFailure,
  getRolesOptionsRequest,
  getRolesOptionsSuccess,
  getUsersListFailure,
  getUsersListRequest,
  getUsersListSuccess,
  toggleUsersStatusFailure,
  toggleUsersStatusRequest,
  toggleUsersStatusSuccess,
  updateRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
} from './actions';

const URL_TENANT = `${config.TENANT}/users`;
function* createUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createUser, error`, e);
    if (e?.response?.data) {
      yield put(createUserFailure(catchResponseMessages(e)));
    }
  }
}
function* createUserRole({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/role/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createUserRoleSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createUserRole, error`, e);
    if (e?.response?.data) {
      yield put(createUserRoleFailure(catchResponseMessages(e)));
    }
  }
}
function* findRole({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/role/find/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(findRoleSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for findRole, error`, e);
    if (e?.response?.data) {
      yield put(findRoleFailure(catchResponseMessages(e)));
    }
  }
}
function* rolesList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/roles/list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getRolesLIstSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for rolesList, error`, e);
    if (e?.response?.data) {
      yield put(getRolesLIstFailure(catchResponseMessages(e)));
    }
  }
}

function* updateRole({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/role/update/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(updateRoleSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateRole, error`, e);
    if (e?.response?.data) {
      yield put(updateRoleFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteRole({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_TENANT}/role/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteRoleSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteRole, error`, e);
    if (e?.response?.data) {
      yield put(deleteRoleFailure(catchResponseMessages(e)));
    }
  }
}

function* getRolesOptions() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/role/options`)
    );
    if (response?.status === 200) {
      yield put(getRolesOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getRolesOptions, error`, e);
    if (e?.response?.data) {
      yield put(getRolesOptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* usersList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getUsersListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for usersList, error`, e);
    if (e?.response?.data) {
      yield put(getUsersListFailure(catchResponseMessages(e)));
    }
  }
}

function* toggleUserStatus({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/toggle/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(toggleUsersStatusSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for toggleUserStatus, error`, e);
    if (e?.response?.data) {
      yield put(toggleUsersStatusFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL_TENANT}/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteUser, error`, e);
    if (e?.response?.data) {
      yield put(deleteUserFailure(catchResponseMessages(e)));
    }
  }
}

function* findUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/find/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for findUser, error`, e);
    if (e?.response?.data) {
      yield put(findUserFailure(catchResponseMessages(e)));
    }
  }
}

function* updateUser({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/update-user/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(updateUserSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateUser, error`, e);
    if (e?.response?.data) {
      yield put(updateUserFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDeleteRoles({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/roles/bulk-delete`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkDeleteRolesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDeleteRoles, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteRolesFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createUserRequest, createUser);
  yield takeLatest(createUserRoleRequest, createUserRole);
  yield takeLatest(findRoleRequest, findRole);
  yield takeLatest(getRolesLIstRequest, rolesList);
  yield takeLatest(updateRoleRequest, updateRole);
  yield takeLatest(deleteRoleRequest, deleteRole);
  yield takeLatest(getRolesOptionsRequest, getRolesOptions);
  yield takeLatest(getUsersListRequest, usersList);
  yield takeLatest(toggleUsersStatusRequest, toggleUserStatus);
  yield takeLatest(deleteUserRequest, deleteUser);
  yield takeLatest(findUserRequest, findUser);
  yield takeLatest(updateUserRequest, updateUser);
  yield takeLatest(bulkDeleteRolesRequest, bulkDeleteRoles);
}
