import { axiosApiInstance, config } from 'custom-configs';

import {
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFailure,
  createUserCustomerRequest,
  createUserCustomerSuccess,
  createUserCustomerFailure,
  getCustomersListSuccess,
  getCustomersListFailure,
  getCustomersListRequest,
  continueCustomerStepRequest,
  continueCustomerStepSuccess,
  continueCustomerStepFailure,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  getOneCustomerRequest,
  getOneCustomerFailure,
  getOneCustomerSuccess,
  availabilityCheckRequest,
  availabilityCheckSuccess,
  availabilityCheckFailure,
  saveAsDraftAcpRequest,
  saveAsDraftAcpSuccess,
  saveAsDraftAcpFailure,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFailure,
  checkEligibilityRequest,
  checkEligibilitySuccess,
  checkEligibilityFailure,
  checkEligibilityStatusRequest,
  checkEligibilityStatusSuccess,
  checkEligibilityStatusFailure,
  verifyDeviceEligibilityRequest,
  verifyDeviceEligibilitySuccess,
  verifyDeviceEligibilityFailure,
  updateCustomerPersonalInfoSuccess,
  updateCustomerPersonalInfoFailure,
  updateCustomerPersonalInfoRequest,
  updateCustomerAddressesSuccess,
  updateCustomerAddressesRequest,
  bulkDeleteCustomersSuccess,
  bulkDeleteCustomersFailure,
  bulkDeleteCustomersRequest,
  transferSubscriberRequest,
  transferSubscriberSuccess,
  transferSubscriberFailure,
  subscriberReportRequest,
  subscriberReportSuccess,
  subscriberReportFailure,
  transactionReportRequest,
  transactionReportSuccess,
  transactionReportFailure,
  snapshotReportRequest,
  snapshotReportSuccess,
  snapshotReportFailure,
  recertificationReportRequest,
  recertificationReportSuccess,
  recertificationReportFailure,
  notProcessedReportRequest,
  notProcessedReportSuccess,
  notProcessedReportFailure,
  createSubscriberRequest,
  createSubscriberSuccess,
  createSubscriberFailure,
  deleteSubscriberRequest,
  deleteSubscriberSuccess,
  deleteSubscriberFailure,
  updateCustomerLinesSuccess,
  updateCustomerLinesFailure,
  updateCustomerLinesRequest,
  getUpdateHistorySuccess,
  getUpdateHistoryFailure,
  getUpdateHistoryRequest,
  getUsageDataSuccess,
  getUsageDataFailure,
  getUsageDataRequest,
  updateBillingDetailsSuccess,
  updateBillingDetailsFailure,
  updateBillingDetailsRequest,
  paymentMethodsSelectOptionsSuccess,
  paymentMethodsSelectOptionsRequest,
  paymentMethodsSelectOptionsFailure,
  updateCustomerLinesAndShipFailure,
  updateCustomerLinesAndShipRequest,
  updateCustomerLinesAndShipSuccess,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';
import { makeSearchQuery } from '../../utils/constants';

const URL = `${config.TENANT}/customer`;

function* createCustomer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-customer`, payload)
    );
    if (response?.status === 200) {
      yield put(createCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createCustomer, error`, e);
    if (e?.response?.data) {
      yield put(createCustomerFailure(catchResponseMessages(e)));
    }
  }
}

function* createUserCustomer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-user-customer/${payload}`)
    );

    if (response?.status === 200) {
      yield put(createUserCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createUserCustomer, error`, e);
    if (e?.response?.data) {
      yield put(createUserCustomerFailure(catchResponseMessages(e)));
    }
  }
}

function* getCustomersList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/get-customers-list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCustomersListSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCustomersList, error`, e);
    if (e?.response?.data) {
      yield put(getCustomersListFailure(catchResponseMessages(e)));
    }
  }
}

function* continueCustomerStep({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/continue-customer/${payload}`)
    );

    if (response?.status === 200) {
      yield put(continueCustomerStepSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCustomersList, error`, e);
    if (e?.response?.data) {
      yield put(continueCustomerStepFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteCustomer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete-customer/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteCustomer, error`, e);
    if (e?.response?.data) {
      yield put(deleteCustomerFailure(catchResponseMessages(e)));
    }
  }
}

function* getOneCustomer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/find-customer/${payload}`)
    );
    if (response?.status === 200) {
      yield put(getOneCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getOneCustomer, error`, e);
    if (e?.response?.data) {
      yield put(getOneCustomerFailure(catchResponseMessages(e)));
    }
  }
}

function* availabilityCheck({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/availability`, payload)
    );

    if (response?.status === 200) {
      yield put(availabilityCheckSuccess(response.data.data));
    }
  } catch (e) {
    console.log(`Catch for availabilityCheck, error`, e);
    if (e.response?.data) {
      yield put(availabilityCheckFailure(catchResponseMessages(e)));
    }
  }
}

function* saveAsDraftAcp({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/save-as-draft`, payload)
    );

    if (response?.status === 200) {
      yield put(saveAsDraftAcpSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for saveAsDraftAcp, error`, e);
    if (e?.response?.data) {
      yield put(saveAsDraftAcpFailure(catchResponseMessages(e)));
    }
  }
}

function* updateCustomer({ payload }) {
  try {
    const { id, data } = payload;
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/update-customer/${id}`, data)
    );

    if (response?.status === 200) {
      yield put(updateCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateCustomer, error`, e);
    if (e?.response?.data) {
      yield put(updateCustomerFailure(catchResponseMessages(e)));
    }
  }
}

function* eligibilityCheck({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/eligibility-check`, payload)
    );

    if (response?.status === 200) {
      yield put(checkEligibilitySuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for eligibilityCheck, error`, e);
    if (e?.response?.data) {
      yield put(checkEligibilityFailure(catchResponseMessages(e)));
    }
  }
}

function* eligibilityStatus({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/eligibility-check-status`, payload)
    );

    if (response?.status === 200) {
      yield put(
        checkEligibilityStatusSuccess({ ...response.data, ...payload })
      );
    }
  } catch (e) {
    console.log(`Catch for eligibilityStatus, error`, e);
    if (e?.response?.data) {
      yield put(checkEligibilityStatusFailure(catchResponseMessages(e)));
    }
  }
}

function* verifyDeviceEligibility({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/verifyDeviceEligibility/${payload}`)
    );

    if (response?.status === 200) {
      yield put(verifyDeviceEligibilitySuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for verifyDeviceEligibility, error`, e);
    if (e?.response?.data) {
      yield put(verifyDeviceEligibilityFailure(catchResponseMessages(e)));
    }
  }
}

function* updatePersonalInfo({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/update-customer-personal/${payload.id}`,
        payload
      )
    );
    if (response?.status === 200) {
      yield put(updateCustomerPersonalInfoSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updatePersonalInfo, error`, e);
    if (e?.response?.data) {
      yield put(updateCustomerPersonalInfoFailure(catchResponseMessages(e)));
    }
  }
}
function* updateAddresses({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/update-customer-addresses/${payload.id}`,
        payload
      )
    );
    if (response?.status === 200) {
      yield put(updateCustomerAddressesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateAddresses, error`, e);
    if (e?.response?.data) {
      yield put(updateCustomerPersonalInfoFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDeleteCustomers({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/bulk-delete-customers`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkDeleteCustomersSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDeleteCustomers, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteCustomersFailure(catchResponseMessages(e)));
    }
  }
}

function* subscriberTransfer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/subscriber-transfer`, payload)
    );

    if (response?.status === 200) {
      yield put(transferSubscriberSuccess(response.data.data));
    }
  } catch (e) {
    console.log(`Catch for subscriberTransfer, error`, e);
    if (e.response?.data) {
      yield put(transferSubscriberFailure(catchResponseMessages(e)));
    }
  }
}

function* subscriberReport({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/subscriber-report`, payload)
    );

    if (response?.status === 200) {
      yield put(subscriberReportSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for subscriberTransfer, error`, e);
    if (e.response?.data) {
      yield put(subscriberReportFailure(catchResponseMessages(e)));
    }
  }
}

function* transactionReport({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/transaction-report`, payload)
    );

    if (response?.status === 200) {
      yield put(transactionReportSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for transactionReport, error`, e);
    if (e.response?.data) {
      yield put(transactionReportFailure(catchResponseMessages(e)));
    }
  }
}

function* snapshotReport({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/snapshot-report`, payload, {
        'Content-Type': 'text/csv',
      })
    );

    if (response?.status === 200) {
      yield put(snapshotReportSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for snapshotReport, error`, e);
    if (e.response?.data) {
      yield put(snapshotReportFailure(catchResponseMessages(e)));
    }
  }
}

function* recertificationReport({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/recertification`, payload, {
        'Content-Type': 'text/csv',
      })
    );

    if (response?.status === 200) {
      yield put(recertificationReportSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for recertificationReport, error`, e);
    if (e.response?.data) {
      yield put(recertificationReportFailure(catchResponseMessages(e)));
    }
  }
}

function* notProcessedReport({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/not-processed`, payload, {
        'Content-Type': 'text/csv',
      })
    );

    if (response?.status === 200) {
      yield put(notProcessedReportSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for notProcessedReport, error`, e);
    if (e.response?.data) {
      yield put(notProcessedReportFailure(catchResponseMessages(e)));
    }
  }
}

function* createSubscriber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/subscriber-create/${payload}`)
    );

    if (response?.status === 200) {
      yield put(createSubscriberSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createSubscriber, error`, e);
    if (e.response?.data) {
      yield put(createSubscriberFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteSubscriber({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/subscriber-delete/${payload}`)
    );

    if (response?.status === 200) {
      yield put(deleteSubscriberSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteSubscriber, error`, e);
    if (e.response?.data) {
      yield put(deleteSubscriberFailure(catchResponseMessages(e)));
    }
  }
}

function* updateCustomerLines({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(
        `${URL}/update-lines/${payload.customerId}/${payload.lineId}/${payload.orderId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updateCustomerLinesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateCustomerLines, error`, e);
    if (e.response?.data) {
      yield put(updateCustomerLinesFailure(catchResponseMessages(e)));
    }
  }
}

function* updateCustomerLinesAndShip({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(
        `${URL}/update-lines-and-ship/${payload.customerId}/${
          payload.orderId
        }${makeSearchQuery({
          lineId: payload?.lineId,
          otherId: payload?.otherId,
          lineType: payload?.lineType,
        })}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updateCustomerLinesAndShipSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateCustomerLines, error`, e);
    if (e.response?.data) {
      yield put(updateCustomerLinesAndShipFailure(catchResponseMessages(e)));
    }
  }
}

function* getLineHistory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/line-history/${payload.customerId}/${payload.lineId}/${payload.orderId}?slug=${payload.slug}`
      )
    );

    if (response?.status === 200) {
      yield put(getUpdateHistorySuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getLineHistory, error`, e);
    if (e.response?.data) {
      yield put(getUpdateHistoryFailure(catchResponseMessages(e)));
    }
  }
}

function* getUsageData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/usage-data/${payload.customerId}/${payload.lineId}${payload.query}`
      )
    );

    if (response?.status === 200) {
      yield put(getUsageDataSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getUsageData, error`, e);
    if (e.response?.data) {
      yield put(getUsageDataFailure(catchResponseMessages(e)));
    }
  }
}

function* paymentMethodsSelectOptions({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/payment-methods-options/${payload.customerId}`
      )
    );

    if (response?.status === 200) {
      yield put(paymentMethodsSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for paymentMethodsSelectOptions, error`, e);
    if (e.response?.data) {
      yield put(paymentMethodsSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* updateBillingDetails({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/update-billing-details/${payload.customerId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updateBillingDetailsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateBillingDetails, error`, e);
    if (e.response?.data) {
      yield put(updateBillingDetailsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createCustomerRequest, createCustomer);
  yield takeLatest(createUserCustomerRequest, createUserCustomer);
  yield takeLatest(getCustomersListRequest, getCustomersList);
  yield takeLatest(continueCustomerStepRequest, continueCustomerStep);
  yield takeLatest(deleteCustomerRequest, deleteCustomer);
  yield takeLatest(getOneCustomerRequest, getOneCustomer);
  yield takeLatest(availabilityCheckRequest, availabilityCheck);
  yield takeLatest(checkEligibilityStatusRequest, eligibilityStatus);
  yield takeLatest(verifyDeviceEligibilityRequest, verifyDeviceEligibility);
  yield takeLatest(saveAsDraftAcpRequest, saveAsDraftAcp);
  yield takeLatest(updateCustomerRequest, updateCustomer);
  yield takeLatest(checkEligibilityRequest, eligibilityCheck);
  yield takeLatest(updateCustomerPersonalInfoRequest, updatePersonalInfo);
  yield takeLatest(updateCustomerAddressesRequest, updateAddresses);
  yield takeLatest(bulkDeleteCustomersRequest, bulkDeleteCustomers);
  yield takeLatest(transferSubscriberRequest, subscriberTransfer);
  yield takeLatest(subscriberReportRequest, subscriberReport);
  yield takeLatest(transactionReportRequest, transactionReport);
  yield takeLatest(snapshotReportRequest, snapshotReport);
  yield takeLatest(recertificationReportRequest, recertificationReport);
  yield takeLatest(notProcessedReportRequest, notProcessedReport);
  yield takeLatest(createSubscriberRequest, createSubscriber);
  yield takeLatest(deleteSubscriberRequest, deleteSubscriber);
  yield takeLatest(updateCustomerLinesRequest, updateCustomerLines);
  yield takeLatest(getUpdateHistoryRequest, getLineHistory);
  yield takeLatest(getUsageDataRequest, getUsageData);
  yield takeLatest(
    paymentMethodsSelectOptionsRequest,
    paymentMethodsSelectOptions
  );
  yield takeLatest(updateBillingDetailsRequest, updateBillingDetails);
  yield takeLatest(
    updateCustomerLinesAndShipRequest,
    updateCustomerLinesAndShip
  );
}
