import axios from 'axios';
import { isObjectHasLength } from '../utils/constants';
import { getSubdomain, isPortal } from '../utils/windowUtils';
import moment from 'moment';

const axiosApiInstance = axios.create();
const userData = JSON.parse(
  localStorage.getItem('userData') || JSON.stringify({})
);
const tenantUserData = JSON.parse(
  localStorage.getItem('tenantUserData') || JSON.stringify({})
);
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  (config) => {
    try {
      const access_token = localStorage.getItem('accessToken');

      config.headers = {
        Authorization: `Bearer ${access_token}`,
      };
      if (
        isObjectHasLength(userData?.tenant || tenantUserData?.tenant) ||
        window.location
      ) {
        config.headers.Hostname =
          userData.tenant?.databaseName ||
          tenantUserData.tenant?.databaseName ||
          (window.location.hostname === 'localhost' && 'localhost') ||
          window.location.hostname
            .replace('www.', '')
            .split('.')
            .slice(0, -1)
            .join('.')
            .replace('-', '')
            .replace('portal.', '') ||
          window.location.host
            .split('.')
            .slice(0, -1)
            .join('.')
            .replace('-', '')
            .replace('portal.', '');
        config.headers.Location =
          userData?.tenant?.domainName || window.location.origin;
        // We don't need to set the Origin header
        // config.headers.Origin = window.location.origin;
        config.headers.Protocol = window.location.protocol;
        config.headers.localTime = moment.utc().toDate();
        const subDomain = getSubdomain();
        if (isPortal()) {
          config.headers.subDomain = window.location.hostname;
          config.headers.subDomainProtocol = subDomain;
        }
      }

      return config;
    } catch (e) {
      console.log(e);
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    const refreshToken = response.headers['refresh-token'];

    if (refreshToken) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken;
      localStorage.setItem('accessToken', refreshToken);
      axios.defaults.headers.common['refresh-token'] = '';
    }

    return response;
  },
  async function (error) {
    try {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = localStorage.getItem('refresh_token');
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token;
        return axiosApiInstance(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('adminUserData');
        localStorage.removeItem('adminAccessToken');
        localStorage.removeItem('tenantUserData');
        localStorage.removeItem('tenantAccessToken');
        window.location.reload();
      }
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export default axiosApiInstance;
