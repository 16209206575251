import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';

const icons = {
  UserOutlined,
};

const customers = {
  id: 'group-customers',
  title: <FormattedMessage id="Customers" />,
  type: 'collapse',
  icon: icons.UserOutlined,
  breadcrumbs: true,
  resource: ['tenant', 'masterAgent', 'subAgent', 'all'],
  children: [
    {
      id: 'AllCustomers',
      title: 'All Customers',
      type: 'item',
      url: '/customers/list/all-customers',
      breadcrumbs: true,
    },
    {
      id: 'AllACPCustomers',
      title: 'ACP Customers',
      type: 'item',
      url: '/customers/list/acp-customers',
      breadcrumbs: true,
    },
    {
      id: 'DraftACPCustomers',
      title: 'Drafts',
      type: 'item',
      url: '/customers/list/drafts',
      breadcrumbs: true,
    },
  ],
};
export default customers;
