import { handleActions } from 'redux-actions';
import {
  getMailingListFailure,
  getMailingListRequest,
  getMailingListSuccess,
} from './actions';

const initialState = {
  isGetMailingListRequest: false,
  isGetMailingListSuccess: false,
  isGetMailingListFailure: false,

  mailingList: [],
  emailsCount: 0,
  errorMessage: '',
};

const reducer = handleActions(
  {
    [getMailingListRequest]: (state) => ({
      ...state,
      isGetMailingListRequest: true,
      isGetMailingListSuccess: false,
      isGetMailingListFailure: false,
      mailingList: [],
    }),
    [getMailingListSuccess]: (state, { payload }) => ({
      ...state,
      isGetMailingListRequest: false,
      isGetMailingListSuccess: true,
      mailingList: payload.data.rows,
      emailsCount: payload.data.count,
    }),
    [getMailingListFailure]: (state) => ({
      ...state,
      isGetMailingListRequest: false,
      isGetMailingListFailure: true,
    }),
  },
  initialState
);

export default reducer;
