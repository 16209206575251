import { createAction } from 'redux-actions';

export const createExternalPaymentRequest = createAction(
  'CREATE_EXTERNAL_PAYMENT_REQUEST'
);
export const createExternalPaymentSuccess = createAction(
  'CREATE_EXTERNAL_PAYMENT_SUCCESS'
);
export const createExternalPaymentFailure = createAction(
  'CREATE_EXTERNAL_PAYMENT_FAILURE'
);

export const getExternalPaymentRequest = createAction(
  'GET_EXTERNAL_PAYMENT_REQUEST'
);
export const getExternalPaymentSuccess = createAction(
  'GET_EXTERNAL_PAYMENT_SUCCESS'
);
export const getExternalPaymentFailure = createAction(
  'GET_EXTERNAL_PAYMENT_FAILURE'
);

export const getMonthlyProfitLossRequest = createAction(
  'GET_MONTHLY_PROFIT_LOSS_REQUEST'
);
export const getMonthlyProfitLossSuccess = createAction(
  'GET_MONTHLY_PROFIT_LOSS_SUCCESS'
);
export const getMonthlyProfitLossFailure = createAction(
  'GET_MONTHLY_PROFIT_LOSS_FAILURE'
);

export const getMonthlyRevenueRequest = createAction(
  'GET_MONTHLY_REVENUE_REQUEST'
);
export const getMonthlyRevenueSuccess = createAction(
  'GET_MONTHLY_REVENUE_SUCCESS'
);
export const getMonthlyRevenueFailure = createAction(
  'GET_MONTHLY_REVENUE_FAILURE'
);

export const updateExternalPaymentRequest = createAction(
  'UPDATE_EXTERNAL_PAYMENT_REQUEST'
);
export const updateExternalPaymentSuccess = createAction(
  'UPDATE_EXTERNAL_PAYMENT_SUCCESS'
);
export const updateExternalPaymentFailure = createAction(
  'UPDATE_EXTERNAL_PAYMENT_FAILURE'
);

export const deleteExternalPaymentRequest = createAction(
  'DELETE_EXTERNAL_PAYMENT_REQUEST'
);
export const deleteExternalPaymentSuccess = createAction(
  'DELETE_EXTERNAL_PAYMENT_SUCCESS'
);
export const deleteExternalPaymentFailure = createAction(
  'DELETE_EXTERNAL_PAYMENT_FAILURE'
);

export const getTotalCustomersProfitLossRequest = createAction(
  'GET_TOTAL_CUSTOMERS_PROFIT_LOSS_REQUEST'
);
export const getTotalCustomersProfitLossSuccess = createAction(
  'GET_TOTAL_CUSTOMERS_PROFIT_LOSS_SUCCESS'
);
export const getTotalCustomersProfitLossFailure = createAction(
  'GET_TOTAL_CUSTOMERS_PROFIT_LOSS_FAILURE'
);

export const getMonthlyExpensesRequest = createAction(
  'GET_MONTHLY_EXPENSES_REQUEST'
);
export const getMonthlyExpensesSuccess = createAction(
  'GET_MONTHLY_EXPENSES_SUCCESS'
);
export const getMonthlyExpensesFailure = createAction(
  'GET_MONTHLY_EXPENSES_FAILURE'
);

export const refreshReportsRequest = createAction('REFRESH_REPORTS_REQUEST');
export const refreshReportsSuccess = createAction('REFRESH_REPORTS_SUCCESS');
export const refreshReportsFailure = createAction('REFRESH_REPORTS_FAILURE');

export const refreshProfitLossReportRequest = createAction(
  'REFRESH_PROFIT_LOSS_REPORT_REQUEST'
);
export const refreshProfitLossReportSuccess = createAction(
  'REFRESH_PROFIT_LOSS_REPORT_SUCCESS'
);
export const refreshProfitLossReportFailure = createAction(
  'REFRESH_PROFIT_LOSS_REPORT_FAILURE'
);
