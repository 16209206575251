import { createAction } from 'redux-actions';

export const generateAgentIdRequest = createAction('GENERATE_AGENT_ID_REQUEST');
export const generateAgentIdSuccess = createAction('GENERATE_AGENT_ID_SUCCESS');
export const generateAgentIdFailure = createAction('GENERATE_AGENT_ID_FAILURE');

export const createAgentRequest = createAction('CREATE_AGENT_REQUEST');
export const createAgentSuccess = createAction('CREATE_AGENT_SUCCESS');
export const createAgentFailure = createAction('CREATE_AGENT_FAILURE');

export const getAgentsListRequest = createAction('GET_AGENTS_LIST_REQUEST');
export const getAgentsListSuccess = createAction('GET_AGENTS_LIST_SUCCESS');
export const getAgentsListFailure = createAction('GET_AGENTS_LIST_FAILURE');

export const getAgentSelectOptionsRequest = createAction(
  'GET_AGENT_SELECT_OPTIONS_REQUEST'
);
export const getAgentSelectOptionsSuccess = createAction(
  'GET_AGENT_SELECT_OPTIONS_SUCCESS'
);
export const getAgentSelectOptionsFailure = createAction(
  'GET_AGENT_SELECT_OPTIONS_FAILURE'
);

export const findOneAgentRequest = createAction('FIND_ONE_AGENT_REQUEST');
export const findOneAgentSuccess = createAction('FIND_ONE_AGENT_SUCCESS');
export const findOneAgentFailure = createAction('FIND_ONE_AGENT_FAILURE');

export const clearAgentData = createAction('CLEAR_AGENT_DATA');

export const editAgentRequest = createAction('EDIT_ACTION_REQUEST');
export const editAgentSuccess = createAction('EDIT_ACTION_SUCCESS');
export const editAgentFailure = createAction('EDIT_ACTION_FAILURE');

export const deleteAgentRequest = createAction('DELETE_AGENT_REQUEST');
export const deleteAgentSuccess = createAction('DELETE_AGENT_SUCCESS');
export const deleteAgentFailure = createAction('DELETE_AGENT_FAILURE');

export const getSubAgentsListDataRequest = createAction(
  'GET_SUB_AGENTS_LIST_DATA_REQUEST'
);
export const getSubAgentsListDataSuccess = createAction(
  'GET_SUB_AGENTS_LIST_DATA_SUCCESS'
);
export const getSubAgentsListDataFailure = createAction(
  'GET_SUB_AGENTS_LIST_DATA_FAILURE'
);

export const createSubAgentRequest = createAction('CREATE_SUB_AGENT_REQUEST');
export const createSubAgentSuccess = createAction('CREATE_SUB_AGENT_SUCCESS');
export const createSubAgentFailure = createAction('CREATE_SUB_AGENT_FAILURE');

export const findSubAgentRequest = createAction('FIND_SUB_AGENT_REQUEST');
export const findSubAgentSuccess = createAction('FIND_SUB_AGENT_SUCCESS');
export const findSubAgentFailure = createAction('FIND_SUB_AGENT_FAILURE');

export const updateSubAgentRequest = createAction('UPDATE_SUB_AGENT_REQUEST');
export const updateSubAgentSuccess = createAction('UPDATE_SUB_AGENT_SUCCESS');
export const updateSubAgentFailure = createAction('UPDATE_SUB_AGENT_FAILURE');

export const toggleAgentRequest = createAction('TOGGLE_AGENT_REQUEST');
export const toggleAgentSuccess = createAction('TOGGLE_AGENT_SUCCESS');
export const toggleAgentFailure = createAction('TOGGLE_AGENT_FAILURE');

export const duplicateAgentRequest = createAction('DUPLICATE_AGENT_REQUEST');
export const duplicateAgentSuccess = createAction('DUPLICATE_AGENT_SUCCESS');
export const duplicateAgentFailure = createAction('DUPLICATE_AGENT_FAILURE');
