import { axiosApiInstance, config } from 'custom-configs';

import {
  getNotificationsSuccess,
  getNotificationsFailure,
  getNotificationsRequest,
  makeSeenNotificationsRequest,
  makeSeenNotificationsSuccess,
  makeSeenNotificationsFailure,
  getNotificationsListDataSuccess,
  getNotificationsListDataFailure,
  getNotificationsListDataRequest,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  deleteNotificationRequest,
  bulkDeleteNotificationsSuccess,
  bulkDeleteNotificationsFailure,
  bulkDeleteNotificationsRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages, isUserLoggedIn } from 'utils/methods';

const URL = `${config.DOMAIN}/notifications`;
const URLTenant = `${config.TENANT}/notifications`;

const agents = ['masterAgent', 'subAgent'];
const { userData } = isUserLoggedIn();

function* getNotifications({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${
          agents.includes(userData.role) ? URLTenant : URL
        }/notifications-list`,
        payload
      )
    );
    if (response?.status === 200) {
      yield put(getNotificationsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getNotifications, error`, e);
    if (e?.response?.data) {
      yield put(getNotificationsFailure(catchResponseMessages(e)));
    }
  }
}

function* makeSeenNotifications({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${agents.includes(userData.role) ? URLTenant : URL}/delete-make-seen`,
        payload
      )
    );
    if (response?.status === 200) {
      yield put(makeSeenNotificationsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for makeSeenNotifications, error`, e);
    if (e?.response?.data) {
      yield put(makeSeenNotificationsFailure(catchResponseMessages(e)));
    }
  }
}

function* getNotificationsDataList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${
          agents.includes(userData.role) ? URLTenant : URL
        }/notifications-data-list${payload}`
      )
    );
    if (response?.status === 200) {
      yield put(getNotificationsListDataSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getNotificationsDataList, error`, e);
    if (e?.response?.data) {
      yield put(getNotificationsListDataFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteNotification({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${
          agents.includes(userData.role) ? URLTenant : URL
        }/notification-delete/${payload}`
      )
    );
    if (response?.status === 200) {
      yield put(deleteNotificationSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteNotification, error`, e);
    if (e?.response?.data) {
      yield put(deleteNotificationFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkDeleteNotifications({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${agents.includes(userData.role) ? URLTenant : URL}/bulk-delete`,
        payload
      )
    );
    if (response?.status === 200) {
      yield put(bulkDeleteNotificationsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkDeleteNotifications, error`, e);
    if (e?.response?.data) {
      yield put(bulkDeleteNotificationsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getNotificationsRequest, getNotifications);
  yield takeLatest(makeSeenNotificationsRequest, makeSeenNotifications);
  yield takeLatest(getNotificationsListDataRequest, getNotificationsDataList);
  yield takeLatest(deleteNotificationRequest, deleteNotification);
  yield takeLatest(bulkDeleteNotificationsRequest, bulkDeleteNotifications);
}
