import { handleActions } from 'redux-actions';

import {
  getSearchHistoryFailure,
  getSearchHistoryRequest,
  getSearchHistorySuccess,
  globalSearchClear,
  globalSearchFailure,
  globalSearchRequest,
  globalSearchSuccess,
  removeSearchHistoryFailure,
  removeSearchHistoryRequest,
  removeSearchHistorySuccess,
} from './actions';

const initialState = {
  isGlobalSearchProcess: false,
  isGlobalSearchFailure: false,
  isGlobalSearchSuccess: false,
  errorMessage: '',
  data: [],
  isGettingSearchHistory: false,
  history: [],
  isGettingSearchHistorySuccess: false,
  isGettingSearchHistoryFailure: false,
  isRemovingSearchHistory: false,
  isRemovingSearchHistorySuccess: false,
};

const reducer = handleActions(
  {
    [globalSearchRequest]: (state) => ({
      ...state,
      isGlobalSearchProcess: true,
      isGlobalSearchFailure: false,
      isGlobalSearchSuccess: false,
    }),
    [globalSearchSuccess]: (state, { payload }) => ({
      ...state,
      isGlobalSearchProcess: false,
      isGlobalSearchSuccess: true,
      data: payload.data,
      history: [],
    }),
    [globalSearchFailure]: (state, { payload }) => ({
      ...state,
      isGlobalSearchProcess: false,
      isGlobalSearchFailure: true,
      errorMessage: payload,
    }),
    [globalSearchClear]: (state) => ({
      ...state,
      isGlobalSearchProcess: false,
      isGlobalSearchFailure: false,
      isGlobalSearchSuccess: false,
      isGettingSearchHistorySuccess: false,
      data: [],
      history: [],
    }),
    [getSearchHistoryRequest]: (state) => ({
      ...state,
      isGettingSearchHistory: true,
      isGettingSearchHistorySuccess: false,
      isGettingSearchHistoryFailure: false,
    }),
    [getSearchHistorySuccess]: (state, { payload }) => ({
      ...state,
      isGettingSearchHistory: false,
      isGettingSearchHistorySuccess: true,
      history: payload.data,
      successMessage: payload.message,
    }),
    [getSearchHistoryFailure]: (state, { payload }) => ({
      ...state,
      isGettingSearchHistory: false,
      isGettingSearchHistoryFailure: true,
      errorMessage: payload,
    }),
    [removeSearchHistoryRequest]: (state) => ({
      ...state,
      isRemovingSearchHistory: true,
      isRemovingSearchHistorySuccess: false,
    }),
    [removeSearchHistorySuccess]: (state, { payload }) => ({
      ...state,
      isRemovingSearchHistory: false,
      isRemovingSearchHistorySuccess: true,
      history: payload.data,
      successMessage: payload.message,
    }),
    [removeSearchHistoryFailure]: (state, { payload }) => ({
      ...state,
      isRemovingSearchHistory: false,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
