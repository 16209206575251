export function isPortal() {
  return getSubdomain() === 'portal';
}

export function getSubdomain() {
  var hostParts = window.location.host.split('.');
  if (hostParts[0] === 'www') {
    hostParts.shift(); // Remove 'www' if it exists
  }
  return hostParts[0];
}
