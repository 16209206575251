import { handleActions } from 'redux-actions';

import {
  createTicketRequest,
  createTicketSuccess,
  createTicketFailure,
  getTicketUsersListRequest,
  getTicketUsersListSuccess,
  getTicketUsersListFailure,
  getTicketRequest,
  getTicketSuccess,
  getTicketFailure,
  getTicketsListRequest,
  getTicketsListSuccess,
  getTicketsListFailure,
  updateTicketStatusRequest,
  updateTicketStatusSuccess,
  updateTicketStatusFailure,
  deleteTicketRequest,
  deleteTicketSuccess,
  deleteTicketFailure,
  updateTicketRequest,
  updateTicketSuccess,
  updateTicketFailure,
  bulkDeleteTicketsRequest,
  bulkDeleteTicketsSuccess,
  bulkDeleteTicketsFailure,
  createTicketColumnRequest,
  createTicketColumnSuccess,
  createTicketColumnFailure,
  getTicketsColumnsRequest,
  getTicketsColumnsSuccess,
  getTicketsColumnsFailure,
  updateTicketColumnRequest,
  updateTicketColumnSuccess,
  updateTicketColumnFailure,
  deleteColumnRequest,
  deleteColumnSuccess,
  deleteColumnFailure,
  updateColumnsOrder,
  removeTicketFilesRequest,
  removeTicketFilesSuccess,
  removeTicketFilesFailure,
  addCommentRequest,
  addCommentSuccess,
  addCommentFailure,
  deleteCommentRequest,
  deleteCommentSuccess,
  deleteCommentFailure,
  handleTicketData,
  createTicketGroupRequest,
  createTicketGroupSuccess,
  createTicketGroupFailure,
  getTicketGroupListRequest,
  getTicketGroupListSuccess,
  getTicketGroupListFailure,
  deleteTicketGroupRequest,
  deleteTicketGroupSuccess,
  deleteTicketGroupFailure,
  bulkDeleteTicketGroupsRequest,
  bulkDeleteTicketGroupsSuccess,
  bulkDeleteTicketGroupsFailure,
  updateTicketGroupRequest,
  updateTicketGroupSuccess,
  updateTicketGroupFailure,
  getTicketGroupSelectRequest,
  getTicketGroupSelectSuccess,
  getTicketGroupSelectFailure,
  assignTicketToUserRequest,
  assignTicketToUserSuccess,
  assignTicketToUserFailure,
  handleTicketGroupData,
  getCustomersNamesRequest,
  getCustomersNamesSuccess,
  getCustomersNamesFailure,
  makeCommentsSeenRequest,
  makeCommentsSeenSuccess,
  makeCommentsSeenFailure,
  getTicketCommentsRequest,
  getTicketCommentsSuccess,
  getTicketCommentsFailure,
} from './actions';

const initialState = {
  isCreatingTicket: false,
  isCreatingTicketSuccess: false,
  isCreatingTicketFailure: false,
  errorMessage: '',
  successMessage: '',
  isGettingUsersList: true,
  usersList: [],
  isGettingTicketsList: true,
  ticketsList: [],
  ticketsCount: 0,
  isUpdatingStatusFailure: false,
  isDeletedTicketFailure: false,
  isDeletedTicketSuccess: false,
  isUpdatingTicket: false,
  isUpdatingTicketSuccess: false,
  isUpdatingTicketFailure: false,
  isUpdatingStatusSuccess: false,
  isBulkDeletedSuccess: false,
  isBulkDeletedFailure: false,
  isCreatedTicketColumnSuccess: false,
  isCreatedTicketColumnFailure: false,
  isGettingColumnsSuccess: false,
  isGettingColumnsFailure: false,
  columns: [],
  isUpdatingTicketColumnSuccess: false,
  isUpdatingTicketColumnFailure: false,
  isDeletedColumnSuccess: false,
  isDeletedColumnFailure: false,
  items: [],
  isRemovedFilesSuccess: false,
  isRemovedFilesFailure: false,
  newTicket: {},
  isAddedCommentSuccess: false,
  isAddedCommentFailure: false,
  isDeletedCommentSuccess: false,
  isDeletedCommentFailure: false,
  ticketData: {},
  isCreatingTicketGroup: false,
  isCreatingTicketGroupSuccess: false,
  isCreatingTicketGroupFailure: false,
  groupList: [],
  groupCount: 0,
  isGettingTicketGroupList: true,
  isGettingTicketGroupListFailure: false,
  isDeletedTicketGroupSuccess: false,
  isDeletedTicketGroupFailure: false,
  isBulkDeletedTicketGroupSuccess: false,
  isBulkDeletedTicketGroupFailure: false,
  isUpdatedTicketGroupSuccess: false,
  isUpdatedTicketGroupFailure: false,
  groupSelectData: [],
  isAssignedTicketToUserSuccess: false,
  isAssignedTicketToUserFailure: false,
  groupData: {},
  isAddingComment: false,
  customersNames: [],
  isGettingCustomerNames: false,
  isGettingTicket: false,
  isGettingTicketFailure: false,
  isGettingTicketComments: false,
  comments: [],
};

const reducer = handleActions(
  {
    [createTicketRequest]: (state) => ({
      ...state,
      isCreatingTicket: true,
      isCreatingTicketSuccess: false,
      isCreatingTicketFailure: false,
    }),
    [createTicketSuccess]: (state, { payload }) => {
      const columnsCopy = [...state.columns];
      const index = columnsCopy.findIndex(
        (i) => i.id === payload.data.columnId
      );

      if (index !== -1) {
        columnsCopy[index].itemIds.push(payload.data.id.toString());
      }
      return {
        ...state,
        isCreatingTicket: false,
        isCreatingTicketSuccess: true,
        successMessage: payload.message,
        ticketsList: [...state.ticketsList, payload.data],
        items: [...state.ticketsList, payload.data],
        ticketsCount: state.ticketsCount + 1,
        newTicket: payload.data,
        columns: columnsCopy,
      };
    },
    [createTicketFailure]: (state, { payload }) => ({
      ...state,
      isCreatingTicket: false,
      isCreatingTicketFailure: true,
      errorMessage: payload,
    }),
    [getTicketUsersListRequest]: (state) => ({
      ...state,
      isGettingUsersList: true,
    }),
    [getTicketUsersListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingUsersList: false,
      usersList: payload.data,
    }),
    [getTicketUsersListFailure]: (state, { payload }) => ({
      ...state,
      isGettingUsersList: false,
      errorMessage: payload,
    }),
    [getTicketsListRequest]: (state) => ({
      ...state,
      isGettingTicketsList: true,
    }),
    [getTicketsListSuccess]: (state, { payload }) => ({
      ...state,
      isAddingComment: false,
      isGettingTicketsList: false,
      ticketsList: payload.data.rows,
      items: payload.data.rows,
      ticketsCount: payload.data.count,
      columns: payload.columns,
    }),
    [getTicketsListFailure]: (state, { payload }) => ({
      ...state,
      isGettingTicketsList: false,
      errorMessage: payload,
    }),
    [getTicketRequest]: (state) => ({
      ...state,
      isGettingTicket: true,
      isGettingTicketFailure: false,
    }),
    [getTicketSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTicket: false,
      isGettingTicketFailure: false,
      ticketData: payload.data,
    }),
    [getTicketFailure]: (state, { payload }) => ({
      ...state,
      isGettingTicket: false,
      isGettingTicketFailure: true,
      errorMessage: payload,
    }),
    [updateTicketStatusRequest]: (state) => ({
      ...state,
      isUpdatingStatusFailure: false,
      isUpdatingStatusSuccess: false,
    }),
    [updateTicketStatusSuccess]: (state, { payload }) => {
      const ticketsListCopy = [...state.ticketsList];
      const indexForList = ticketsListCopy.findIndex(
        (i) => i.id === payload.data.id
      );

      if (indexForList !== -1) {
        ticketsListCopy[indexForList] = [
          ...ticketsListCopy[indexForList],
          payload.data,
        ];
      }

      return {
        ...state,
        isUpdatingStatusSuccess: true,
        successMessage: payload.message,
        ticketsList: ticketsListCopy,
        items: ticketsListCopy,
      };
    },
    [updateTicketStatusFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingStatusFailure: true,
      errorMessage: payload,
    }),
    [deleteTicketRequest]: (state) => ({
      ...state,
      isDeletedTicketSuccess: false,
      isDeletedTicketFailure: false,
    }),
    [deleteTicketSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedTicketSuccess: true,
      successMessage: payload.message,
      ticketsList: state.ticketsList.filter((i) => i.id !== payload.data),
      items: state.items.filter((i) => i.id !== payload.data),
      columns: payload.columns,
      ticketsCount: state.ticketsCount - 1,
    }),
    [deleteTicketFailure]: (state, { payload }) => ({
      ...state,
      isDeletedTicketFailure: true,
      errorMessage: payload,
    }),
    [updateTicketRequest]: (state) => ({
      ...state,
      isUpdatingTicket: true,
      isUpdatingTicketSuccess: false,
      isUpdatingTicketFailure: false,
    }),
    [updateTicketSuccess]: (state, { payload }) => {
      const ticketsListCopy = [...state.ticketsList];
      const indexForList = ticketsListCopy.findIndex(
        (i) => i.id === payload.data.id
      );
      if (indexForList !== -1) {
        ticketsListCopy[indexForList] = payload.data;
      }

      return {
        ...state,
        isUpdatingTicket: false,
        isUpdatingTicketSuccess: true,
        successMessage: payload.message,
        ticketsList: ticketsListCopy,
        items: ticketsListCopy,
        columns: payload.columns,
      };
    },
    [updateTicketFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingTicket: false,
      isUpdatingTicketFailure: true,
      errorMessage: payload,
    }),
    [bulkDeleteTicketsRequest]: (state) => ({
      ...state,
      isBulkDeletedSuccess: false,
      isBulkDeletedFailure: false,
    }),
    [bulkDeleteTicketsSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletedSuccess: true,
      successMessage: payload.message,
      ticketsList: state.ticketsList.filter(
        (i) => !payload.data.includes(i.id)
      ),
      items: state.ticketsList.filter((i) => !payload.data.includes(i.id)),
      ticketsCount: state.ticketsCount - payload.data.length,
    }),
    [bulkDeleteTicketsFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletedFailure: true,
      errorMessage: payload,
    }),
    [createTicketColumnRequest]: (state) => ({
      ...state,
      isCreatedTicketColumnSuccess: false,
      isCreatedTicketColumnFailure: false,
    }),
    [createTicketColumnSuccess]: (state, { payload }) => ({
      ...state,
      isCreatedTicketColumnSuccess: true,
      columns: [...state.columns, payload.data],
      successMessage: payload.message,
    }),
    [createTicketColumnFailure]: (state, { payload }) => ({
      ...state,
      isCreatedTicketColumnFailure: true,
      errorMessage: payload,
    }),
    [getTicketsColumnsRequest]: (state) => ({
      ...state,
      isGettingColumnsSuccess: false,
      isGettingColumnsFailure: false,
    }),
    [getTicketsColumnsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingColumnsSuccess: true,
      columns: payload.data,
      successMessage: payload.message,
    }),
    [getTicketsColumnsFailure]: (state, { payload }) => ({
      ...state,
      isGettingColumnsFailure: true,
      errorMessage: payload,
    }),
    [updateTicketColumnRequest]: (state) => ({
      ...state,
      isUpdatingTicketColumnSuccess: false,
      isUpdatingTicketColumnFailure: false,
    }),
    [updateTicketColumnSuccess]: (state, { payload }) => {
      const columnsCopy = [...state.columns];
      const index = columnsCopy.findIndex((i) => i?.id === payload?.data?.id);
      if (index !== -1) {
        columnsCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingTicketColumnSuccess: true,
        successMessage: payload.message,
        columns: columnsCopy,
      };
    },
    [updateTicketColumnFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingTicketColumnFailure: true,
      errorMessage: payload,
    }),
    [deleteColumnRequest]: (state) => ({
      ...state,
      isDeletedColumnSuccess: false,
      isDeletedColumnFailure: false,
    }),
    [deleteColumnSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedColumnSuccess: true,
      successMessage: payload.message,
      columns: state.columns.filter((i) => i.id !== payload.data),
    }),
    [deleteColumnFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      isDeletedColumnFailure: true,
    }),
    [updateColumnsOrder]: (state, { payload }) => ({
      ...state,
      columns: payload,
    }),
    [removeTicketFilesRequest]: (state) => ({
      ...state,
      isRemovedFilesSuccess: false,
      isRemovedFilesFailure: false,
    }),
    [removeTicketFilesSuccess]: (state, { payload }) => ({
      ...state,
      isRemovedFilesSuccess: true,
      successMessage: payload.message,
    }),
    [removeTicketFilesFailure]: (state, { payload }) => ({
      ...state,
      isRemovedFilesFailure: true,
      errorMessage: payload,
    }),
    [addCommentRequest]: (state) => ({
      ...state,
      isAddingComment: true,
      isAddedCommentSuccess: false,
      isAddedCommentFailure: false,
    }),
    [addCommentSuccess]: (state, { payload }) => {
      const itemsCopy = [...state.items];
      const index = itemsCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        itemsCopy[index] = payload.data;
      }
      return {
        ...state,
        isAddingComment: false,
        isAddedCommentSuccess: true,
        successMessage: payload.message,
        items: itemsCopy,
        ticketData: payload.data,
      };
    },
    [addCommentFailure]: (state, { payload }) => ({
      ...state,
      isAddingComment: false,
      isAddedCommentFailure: true,
      errorMessage: payload,
    }),
    [deleteCommentRequest]: (state) => ({
      ...state,
      isAddingComment: true,
      isDeletedCommentSuccess: false,
      isDeletedCommentFailure: false,
    }),
    [deleteCommentSuccess]: (state, { payload }) => {
      const itemsCopy = [...state.items];
      const index = itemsCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        itemsCopy[index] = payload.data;
      }
      return {
        ...state,
        isAddingComment: false,
        isDeletedCommentSuccess: true,
        successMessage: payload.message,
        items: itemsCopy,
        ticketData: payload.data,
      };
    },
    [deleteCommentFailure]: (state, { payload }) => ({
      ...state,
      isAddingComment: false,
      isDeletedCommentFailure: true,
      errorMessage: payload,
    }),
    [handleTicketData]: (state, { payload }) => ({
      ...state,
      isAddingComment: false,
      ticketData: payload,
    }),
    [createTicketGroupRequest]: (state) => ({
      ...state,
      isCreatingTicketGroup: true,
      isCreatingTicketGroupSuccess: false,
      isCreatingTicketGroupFailure: false,
    }),
    [createTicketGroupSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingTicketGroup: false,
      isCreatingTicketGroupSuccess: true,
      successMessage: payload.message,
      groupList: [...state.groupList, ...payload.data],
      groupCount: state.groupCount + 1,
    }),
    [createTicketGroupFailure]: (state, { payload }) => ({
      ...state,
      isCreatingTicketGroup: false,
      isCreatingTicketGroupFailure: true,
      errorMessage: payload,
    }),
    [getTicketGroupListRequest]: (state) => ({
      ...state,
      isGettingTicketGroupList: true,
      isGettingTicketGroupListFailure: false,
    }),
    [getTicketGroupListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTicketGroupList: false,
      successMessage: payload.message,
      groupList: payload.data.rows,
      groupCount: payload.data.count,
    }),
    [getTicketGroupListFailure]: (state, { payload }) => ({
      ...state,
      isGettingTicketGroupList: false,
      isGettingTicketGroupListFailure: true,
      errorMessage: payload,
    }),
    [deleteTicketGroupRequest]: (state) => ({
      ...state,
      isDeletedTicketGroupSuccess: false,
      isDeletedTicketGroupFailure: false,
    }),
    [deleteTicketGroupSuccess]: (state, { payload }) => ({
      ...state,
      isDeletedTicketGroupSuccess: true,
      successMessage: payload.message,
      groupList: state.groupList.filter((i) => i.id !== payload.data),
      groupCount: state.groupCount - 1,
    }),
    [deleteTicketGroupFailure]: (state, { payload }) => ({
      ...state,
      isDeletedTicketGroupFailure: true,
      errorMessage: payload,
    }),
    [bulkDeleteTicketGroupsRequest]: (state) => ({
      ...state,
      isBulkDeletedTicketGroupSuccess: false,
      isBulkDeletedTicketGroupFailure: false,
    }),
    [bulkDeleteTicketGroupsSuccess]: (state, { payload }) => ({
      ...state,
      isBulkDeletedTicketGroupSuccess: true,
      successMessage: payload.message,
      groupList: state.groupList.filter((i) => !payload.data.includes(i.id)),
      groupCount: state.groupCount - payload.data.length,
    }),
    [bulkDeleteTicketGroupsFailure]: (state, { payload }) => ({
      ...state,
      isBulkDeletedTicketGroupFailure: true,
      errorMessage: payload,
    }),
    [updateTicketGroupRequest]: (state) => ({
      ...state,
      isUpdatedTicketGroupSuccess: false,
      isUpdatedTicketGroupFailure: false,
    }),
    [updateTicketGroupSuccess]: (state, { payload }) => {
      const groupListCopy = [...state.groupList];
      const index = groupListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        groupListCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatedTicketGroupSuccess: true,
        successMessage: payload.message,
        groupList: groupListCopy,
      };
    },
    [updateTicketGroupFailure]: (state, { payload }) => ({
      ...state,
      isUpdatedTicketGroupFailure: true,
      errorMessage: payload,
    }),
    [getTicketGroupSelectRequest]: (state) => ({
      ...state,
      groupSelectData: [],
    }),
    [getTicketGroupSelectSuccess]: (state, { payload }) => ({
      ...state,
      groupSelectData: payload.data,
    }),
    [getTicketGroupSelectFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
    }),
    [assignTicketToUserRequest]: (state) => ({
      ...state,
      isAssignedTicketToUserSuccess: false,
      isAssignedTicketToUserFailure: false,
    }),
    [assignTicketToUserSuccess]: (state, { payload }) => {
      const ticketsListCopy = [...state.ticketsList];

      const index = ticketsListCopy.findIndex(
        (i) => i.id === payload.data.ticketId
      );
      if (index !== -1) {
        ticketsListCopy[index].userId = payload.data.userId;
      }
      return {
        ...state,
        isAssignedTicketToUserSuccess: true,
        successMessage: payload.message,
        ticketsList: ticketsListCopy,
      };
    },
    [assignTicketToUserFailure]: (state, { payload }) => ({
      ...state,
      isAssignedTicketToUserFailure: true,
      errorMessage: payload.message,
    }),
    [handleTicketGroupData]: (state, { payload }) => ({
      ...state,
      groupData: payload,
    }),
    [getCustomersNamesRequest]: (state) => ({
      ...state,
      isGettingCustomerNames: true,
    }),
    [getCustomersNamesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomerNames: false,
      customersNames: payload.data,
      successMessage: payload.message,
    }),
    [getCustomersNamesFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomerNames: false,
      errorMessage: payload,
    }),
    [makeCommentsSeenRequest]: (state) => ({
      ...state,
      isMakeCommentSeen: true,
    }),
    [makeCommentsSeenSuccess]: (state, { payload }) => ({
      ...state,
      isMakeCommentSeen: false,
      successMessage: payload.message,
    }),
    [makeCommentsSeenFailure]: (state, { payload }) => ({
      ...state,
      isMakeCommentSeen: false,
      errorMessage: payload,
    }),
    [getTicketCommentsRequest]: (state) => ({
      ...state,
      isGettingTicketComments: true,
    }),
    [getTicketCommentsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTicketComments: false,
      comments: payload.data,
      successMessage: payload.message,
    }),
    [getTicketCommentsFailure]: (state, { payload }) => ({
      ...state,
      isGettingTicketComments: false,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
