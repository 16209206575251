import { MailOutlined } from '@ant-design/icons';

const mailingList = {
  id: 'mailing-list',
  title: 'Mailing List',
  icon: MailOutlined,
  type: 'item',
  resource: ['tenant', 'all'],
  url: '/mailing/list',
};
export default mailingList;
