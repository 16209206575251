import dashboard from '_api/dashboard';
import customers from './customers';
import eCommerce from './eCommerce';
import settings from './settings';
import billing from './billing';
import tickets from './tickets';
import reports from './reports';
import portIn from './portIn';
import tenant from './tenant';
import agents from './agents';
import orders from './orders';
import mailingList from './mailingList';
import Profile from './customerProfile';
import customerOrders from './customer-orders';
import lineReports from './lineReports';

const menuItems = {
  items: [
    dashboard,
    customers,
    portIn,
    orders,
    customerOrders,
    tickets,
    ...agents,
    ...eCommerce,
    billing,
    ...tenant,
    lineReports,
    reports,
    settings,
    mailingList,
    Profile,
  ],
};

export default menuItems;
