import { axiosApiInstance, config } from 'custom-configs';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

import {
  assignInventoryToAgentsFailure,
  assignInventoryToAgentsRequest,
  assignInventoryToAgentsSuccess,
  createInventoryFailure,
  createInventoryRequest,
  createInventorySuccess,
  deleteInventoryFailure,
  deleteInventoryRequest,
  deleteInventorySuccess,
  deleteInventoryVariantRequest,
  deleteInventoryVariantSuccess,
  deleteInventoryVariantFailure,
  findVariantFailure,
  findVariantRequest,
  findVariantSuccess,
  getInventoryMasterAgentSelectFailure,
  getInventoryMasterAgentSelectRequest,
  getInventoryMasterAgentSelectSuccess,
  getInventorySubAgentsSelectFailure,
  getInventorySubAgentsSelectRequest,
  getInventorySubAgentsSelectSuccess,
  getInventoryVariantsFailure,
  getInventoryVariantsRequest,
  getInventoryVariantsSuccess,
  getVariantInventoryListFailure,
  getVariantInventoryListRequest,
  getVariantInventoryListSuccess,
  setAvailableCountInventoryFailure,
  setAvailableCountInventoryRequest,
  setAvailableCountInventorySuccess,
  uploadInventoryFailure,
  uploadInventoryRequest,
  uploadInventorySuccess,
  createAndAssignInventoryRequest,
  createAndAssignInventorySuccess,
  createAndAssignInventoryFailure,
  assignSIMCardToIMEISuccess,
  assignSIMCardToIMEIFailure,
  assignSIMCardToIMEIRequest,
  requestInventorySuccess,
  requestInventoryFailure,
  requestInventoryRequest,
  getDeviceCategorySelectFailure,
  getDeviceCategorySelectSuccess,
  getDeviceCategorySelectRequest,
  getSubAgentDataSuccess,
  getSubAgentDataFailure,
  getSubAgentDataRequest,
  checkDeviceIEMISuccess,
  checkDeviceIEMIRequest,
  checkDeviceIEMIFailure,
} from './actions';
import { pushCreatedInventoryToOrdersList } from '../orders/actions';
import { makeSearchQuery } from 'utils/constants';

const URL = `${config.TENANT}/e-commerce/inventory`;

function* inventoryVariants({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/inventory-variants${payload}`)
    );
    if (response?.status === 200) {
      yield put(getInventoryVariantsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for inventoryVariants, error', e);
    if (e?.response?.data) {
      yield put(getInventoryVariantsFailure(catchResponseMessages(e)));
    }
  }
}

function* findVariant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/variant/${payload}`)
    );
    if (response?.status === 200) {
      yield put(findVariantSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for findVariant, error', e);
    if (e?.response?.data) {
      yield put(findVariantFailure(catchResponseMessages(e)));
    }
  }
}

function* createInventory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createInventorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createInventory, error', e);
    if (e?.response?.data) {
      yield put(createInventoryFailure(catchResponseMessages(e)));
    }
  }
}

function* inventoryList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list-data/${payload.id}${payload.query}`)
    );
    if (response?.status === 200) {
      yield put(getVariantInventoryListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for inventoryList, error', e);
    if (e?.response?.data) {
      yield put(getVariantInventoryListFailure(catchResponseMessages(e)));
    }
  }
}

function* setAvailability({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/set-available/${payload.id}`, payload)
    );
    if (response?.status === 200) {
      yield put(setAvailableCountInventorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for setAvailability, error', e);
    if (e?.response?.data) {
      yield put(setAvailableCountInventoryFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteInventory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteInventorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteInventory, error', e);
    if (e?.response?.data) {
      yield put(deleteInventoryFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteInventoryVariant({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete/variant/${payload}`)
    );

    if (response?.status === 200) {
      yield put(deleteInventoryVariantSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteInventoryVariant, error', e);
    if (e?.response?.data) {
      yield put(deleteInventoryVariantFailure(catchResponseMessages(e)));
    }
  }
}

function* masterAgentSelectData() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/master-agent-select`)
    );
    if (response?.status === 200) {
      yield put(getInventoryMasterAgentSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for masterAgentSelectData, error', e);
    if (e?.response?.data) {
      yield put(getInventoryMasterAgentSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* subAgentSelectData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/sub-agent-select/${payload}`)
    );
    if (response?.status === 200) {
      yield put(getInventorySubAgentsSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for subAgentSelectData, error', e);
    if (e?.response?.data) {
      yield put(getInventorySubAgentsSelectFailure(catchResponseMessages(e)));
    }
  }
}

function* assignInventoryToAgents({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/assign-agents`, payload)
    );
    if (response?.status === 200) {
      yield put(assignInventoryToAgentsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for assignInventoryToAgents, error', e);
    if (e?.response?.data) {
      yield put(assignInventoryToAgentsFailure(catchResponseMessages(e)));
    }
  }
}

function* uploadInventory({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/upload/${payload.variantId}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(uploadInventorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for uploadInventory, error', e);
    if (e?.response?.data) {
      yield put(uploadInventoryFailure(catchResponseMessages(e)));
    }
  }
}

function* createAndAssignInventory({ payload }) {
  try {
    const { query, ...data } = payload;
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/create-and-assign/${data.variantId}${query}`,
        data
      )
    );

    if (response?.status === 200) {
      yield put(createAndAssignInventorySuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createAndAssignInventory, error', e);
    if (e?.response?.data) {
      yield put(createAndAssignInventoryFailure(catchResponseMessages(e)));
    }
  }
}

function* assignSIMCardToIMEI({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/assign-sim-imei/${payload.id}`,
        payload.values
      )
    );

    if (response?.status === 200) {
      yield put(assignSIMCardToIMEISuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for assignSIMCardToIMEI, error', e);
    if (e?.response?.data) {
      yield put(assignSIMCardToIMEIFailure(catchResponseMessages(e)));
    }
  }
}

function* requestInventory({ payload }) {
  try {
    const { masterAgentId, ...data } = payload;
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/request-new-inventories/${masterAgentId}`,
        data
      )
    );

    if (response?.status === 200) {
      yield put(requestInventorySuccess(response.data));
      yield put(pushCreatedInventoryToOrdersList(response.data));
    }
  } catch (e) {
    console.log('Catch for requestInventory, error', e);
    if (e?.response?.data) {
      yield put(requestInventoryFailure(catchResponseMessages(e)));
    }
  }
}

function* deviceCategorySelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/device-category-select`)
    );

    if (response?.status === 200) {
      yield put(getDeviceCategorySelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deviceCategorySelect, error', e);
    if (e?.response?.data) {
      yield put(getDeviceCategorySelectFailure(catchResponseMessages(e)));
    }
  }
}

function* getSubAgentData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/sub-agent-data/${payload}`)
    );

    if (response?.status === 200) {
      yield put(getSubAgentDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSubAgentData, error', e);
    if (e?.response?.data) {
      yield put(getSubAgentDataFailure(catchResponseMessages(e)));
    }
  }
}

function* checkIEMI({ payload }) {
  try {
    const { type = '', ...rest } = payload;

    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/IEMI-check/${payload.customerId}${makeSearchQuery({ type })}`,
        rest
      )
    );

    if (response?.status === 200) {
      yield put(checkDeviceIEMISuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for checkIEMI, error', e);
    if (e?.response?.data) {
      yield put(checkDeviceIEMIFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getInventoryVariantsRequest, inventoryVariants);
  yield takeLatest(findVariantRequest, findVariant);
  yield takeLatest(createInventoryRequest, createInventory);
  yield takeLatest(getVariantInventoryListRequest, inventoryList);
  yield takeLatest(setAvailableCountInventoryRequest, setAvailability);
  yield takeLatest(deleteInventoryRequest, deleteInventory);
  yield takeLatest(deleteInventoryVariantRequest, deleteInventoryVariant);
  yield takeLatest(getInventoryMasterAgentSelectRequest, masterAgentSelectData);
  yield takeLatest(getInventorySubAgentsSelectRequest, subAgentSelectData);
  yield takeLatest(assignInventoryToAgentsRequest, assignInventoryToAgents);
  yield takeLatest(uploadInventoryRequest, uploadInventory);
  yield takeLatest(createAndAssignInventoryRequest, createAndAssignInventory);
  yield takeLatest(assignSIMCardToIMEIRequest, assignSIMCardToIMEI);
  yield takeLatest(requestInventoryRequest, requestInventory);
  yield takeLatest(getDeviceCategorySelectRequest, deviceCategorySelect);
  yield takeLatest(getSubAgentDataRequest, getSubAgentData);
  yield takeLatest(checkDeviceIEMIRequest, checkIEMI);
}
