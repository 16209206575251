import { getCategorySelectFailure } from '../../eCommerce/products/actions';
import { catchResponseMessages } from '../../../utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createExternalPaymentRequest,
  createExternalPaymentSuccess,
  createExternalPaymentFailure,
  getExternalPaymentSuccess,
  getExternalPaymentFailure,
  getExternalPaymentRequest,
  getMonthlyProfitLossSuccess,
  getMonthlyProfitLossFailure,
  getMonthlyProfitLossRequest,
  getMonthlyRevenueSuccess,
  getMonthlyRevenueFailure,
  getMonthlyRevenueRequest,
  updateExternalPaymentSuccess,
  updateExternalPaymentFailure,
  updateExternalPaymentRequest,
  deleteExternalPaymentSuccess,
  deleteExternalPaymentFailure,
  deleteExternalPaymentRequest,
  getTotalCustomersProfitLossSuccess,
  getTotalCustomersProfitLossFailure,
  getTotalCustomersProfitLossRequest,
  getMonthlyExpensesSuccess,
  getMonthlyExpensesFailure,
  getMonthlyExpensesRequest,
  refreshProfitLossReportSuccess,
  refreshProfitLossReportRequest,
  refreshProfitLossReportFailure,
  refreshReportsSuccess,
  refreshReportsFailure,
  refreshReportsRequest,
} from './actions';

import { axiosApiInstance, config } from '../../../custom-configs';

const URL_TENANT = `${config.TENANT}/payments`;

function* createExternalPayment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL_TENANT}/create-external-payment`, payload)
    );
    if (response.status === 200) {
      yield put(createExternalPaymentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createExternalPayment, ERROR:', e);
    if (e?.response?.data) {
      yield put(createExternalPaymentFailure(catchResponseMessages(e)));
    }
  }
}

function* getExternalPayments({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/external-payments${payload}`)
    );
    if (response.status === 200) {
      yield put(getExternalPaymentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getExternalPayments, ERROR:', e);
    if (e?.response?.data) {
      yield put(getExternalPaymentFailure(catchResponseMessages(e)));
    }
  }
}

function* getMonthlyProfitLoss({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/monthly-profit-loss${payload}`)
    );
    if (response.status === 200) {
      yield put(getMonthlyProfitLossSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getMonthlyProfitLoss, ERROR:', e);
    if (e?.response?.data) {
      yield put(getMonthlyProfitLossFailure(catchResponseMessages(e)));
    }
  }
}

function* getMonthlyRevenue({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/monthly-revenue${payload}`)
    );
    if (response.status === 200) {
      yield put(getMonthlyRevenueSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getMonthlyRevenue, ERROR:', e);
    if (e?.response?.data) {
      yield put(getMonthlyRevenueFailure(catchResponseMessages(e)));
    }
  }
}

function* updateExternalPayment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL_TENANT}/update-external-payment/${payload.id}`,
        payload.data
      )
    );
    if (response.status === 200) {
      yield put(updateExternalPaymentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateExternalPayment, ERROR:', e);
    if (e?.response?.data) {
      yield put(updateExternalPaymentFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteExternalPayment({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL_TENANT}/delete-external-payment/${payload}`
      )
    );
    if (response.status === 200) {
      yield put(deleteExternalPaymentSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteExternalPayment, ERROR:', e);
    if (e?.response?.data) {
      yield put(deleteExternalPaymentFailure(catchResponseMessages(e)));
    }
  }
}

function* getCustomerTotalReport({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/customers-total-report${payload}`)
    );
    if (response.status === 200) {
      yield put(getTotalCustomersProfitLossSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCustomerTotalReport, ERROR:', e);
    if (e?.response?.data) {
      yield put(getTotalCustomersProfitLossFailure(catchResponseMessages(e)));
    }
  }
}

function* getMonthlyExpenses({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL_TENANT}/monthly-expenses${payload}`)
    );
    if (response.status === 200) {
      yield put(getMonthlyExpensesSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getMonthlyExpenses, ERROR:', e);
    if (e?.response?.data) {
      yield put(getMonthlyExpensesFailure(catchResponseMessages(e)));
    }
  }
}

function* refreshProfitLossReport() {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/refresh-profit-loss`)
    );
    if (response.status === 200) {
      yield put(refreshProfitLossReportSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for refreshProfitLossReport, ERROR:', e);
    if (e?.response?.data) {
      yield put(refreshProfitLossReportFailure(catchResponseMessages(e)));
    }
  }
}

function* refreshReports() {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL_TENANT}/refresh-reports`)
    );
    if (response.status === 200) {
      yield put(refreshReportsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for refreshReports, ERROR:', e);
    if (e?.response?.data) {
      yield put(refreshReportsFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createExternalPaymentRequest, createExternalPayment);
  yield takeLatest(getExternalPaymentRequest, getExternalPayments);
  yield takeLatest(getMonthlyProfitLossRequest, getMonthlyProfitLoss);
  yield takeLatest(getMonthlyRevenueRequest, getMonthlyRevenue);
  yield takeLatest(updateExternalPaymentRequest, updateExternalPayment);
  yield takeLatest(deleteExternalPaymentRequest, deleteExternalPayment);
  yield takeLatest(getTotalCustomersProfitLossRequest, getCustomerTotalReport);
  yield takeLatest(getMonthlyExpensesRequest, getMonthlyExpenses);
  yield takeLatest(refreshProfitLossReportRequest, refreshProfitLossReport);
  yield takeLatest(refreshReportsRequest, refreshReports);
}
