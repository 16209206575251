import { isObjectHasLength } from './constants';
import menuItems from 'menu-items';

export const isUserLoggedIn = () => {
  const isLoggedIn = !!localStorage.getItem('userData');
  const userData = JSON.parse(localStorage.getItem('userData') || null) || {};
  const adminUserData = JSON.parse(localStorage.getItem('adminUserData')) || {};
  try {
    if (isObjectHasLength(userData) && isLoggedIn) {
      return {
        isLoggedIn,
        userData,
        adminUserData,
      };
    } else {
      return {
        isLoggedIn,
        userData,
        adminUserData,
      };
    }
  } catch (e) {
    return {
      isLoggedIn,
      userData,
      adminUserData,
    };
  }
};
export const catchResponseMessages = (e) => {
  if (e?.response?.data) {
    return !Array.isArray(e?.response?.data.message)
      ? [e?.response?.data.message]
      : e?.response?.data.message;
  }

  return [];
};
export const isJson = (json) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

export const hasDuplicateItems = (arr) => {
  const set = new Set();

  for (const item of arr) {
    if (item && set.has(item)) {
      return true;
    }
    set.add(item);
  }

  return false;
};

export const maskEmailInvisible = (email) => {
  // Split the email address into local and domain parts
  const [localPart, domain] = email.split('@');

  // Calculate the length of the local part
  const localPartLength = localPart.length;

  // Determine how many characters to mask (e.g., leaving the first and last two characters visible)
  const visibleChars = 2;
  const maskedChars = localPartLength - visibleChars * 2;

  // Create the masked local part with asterisks
  const maskedLocalPart = '*'.repeat(maskedChars);

  // Recreate the masked email address
  return (
    localPart.slice(0, visibleChars) +
    maskedLocalPart +
    localPart.slice(-visibleChars) +
    '@' +
    domain
  );
};

export const getDefaultPathByRole = (role = null) => {
  if (!role) {
    const { userData } = isUserLoggedIn();
    if (!userData.role) {
      return '/login';
    }
    role = userData.role;
  }

  const menuItemsArray = menuItems.items.filter(
    (i) =>
      i.resource.includes(role) ||
      (i.resource.includes('all') &&
        !['admin', 'masterAgent', 'subAgent'].includes(role))
  );

  const firstItem = menuItemsArray[0];
  const firstPath = !firstItem.url ? firstItem.children[0].url : firstItem.url;

  return firstPath;
};

export const getPathByModel = (model) => {
  let path = '';
  switch (model.name) {
    case 'ticket':
      path = `/tickets/list?ticketId=${model.id}`;
      break;
    case 'order':
      path = `/e-commerce/orders/customer-order/fulfill-order/${model.id}`;
      break;
  }

  return path;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.replace(/\D/g, '');

  return cleaned.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
};
