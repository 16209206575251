import { axiosApiInstance, config } from 'custom-configs';

import {
  getMasterAgentSelectRequest,
  getMasterAgentSelectSuccess,
  getMasterAgentSelectFailure,
  getSubAgentSelectRequest,
  getSubAgentSelectSuccess,
  getSubAgentSelectFailure,
  getCarriersSelectDataSuccess,
  getCarriersSelectDataFailure,
  getCarriersSelectDataRequest,
  getCustomersSelectDataFailure,
  getCustomersSelectDataSuccess,
  getCustomersSelectDataRequest,
  createSIMCardRequest,
  createSIMCardFailure,
  createSIMCardSuccess,
  getSIMCardListSuccess,
  getSIMCardListFailure,
  getSIMCardListRequest,
  deleteSimCardRequest,
  deleteSimCardSuccess,
  deleteSimCardFailure,
  assignSimCardToAgentsRequest,
  assignSimCardToAgentsSuccess,
  assignSimCardToAgentsFailure,
  updateSimCardSuccess,
  updateSimCardFailure,
  updateSimCardRequest,
  bulkUpdateSimCardsSuccess,
  bulkUpdateSimCardsFailure,
  bulkUpdateSimCardsRequest,
  uploadSimCardsRequest,
  uploadSimCardsSuccess,
  uploadSimCardsFailure,
  uploadESIMSuccess,
  uploadESIMFailure,
  uploadESIMRequest,
  getAvailableSimSuccess,
  getAvailableSimFailure,
  getAvailableSimRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { catchResponseMessages } from 'utils/methods';

const URL = `${config.TENANT}/e-commerce/sim-cards`;

function* getMasterAgentSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/master-agent-select`)
    );
    if (response?.status === 200) {
      yield put(getMasterAgentSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getMasterAgentSelect, error', e);
    if (e?.response?.data) {
      yield put(getMasterAgentSelectFailure(catchResponseMessages(e)));
    }
  }
}
function* getSubAgentSelect({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/sub-agent-select`, payload)
    );
    if (response?.status === 200) {
      yield put(getSubAgentSelectSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSubAgentSelect, error', e);
    if (e?.response?.data) {
      yield put(getSubAgentSelectFailure(catchResponseMessages(e)));
    }
  }
}
function* getCarriersSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/carriers-select`)
    );
    if (response?.status === 200) {
      yield put(getCarriersSelectDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCarriersSelect, error', e);
    if (e?.response?.data) {
      yield put(getCarriersSelectDataFailure(catchResponseMessages(e)));
    }
  }
}
function* getCustomersSelectData() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/customers-select`)
    );
    if (response?.status === 200) {
      yield put(getCustomersSelectDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCustomersSelectData, error', e);
    if (e?.response?.data) {
      yield put(getCustomersSelectDataFailure(catchResponseMessages(e)));
    }
  }
}
function* createSIMCArd({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create`, payload)
    );
    if (response?.status === 200) {
      yield put(createSIMCardSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createSIMCArd, error', e);
    if (e?.response?.data) {
      yield put(createSIMCardFailure(catchResponseMessages(e)));
    }
  }
}
function* getSimCardList({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/list${payload}`)
    );
    if (response?.status === 200) {
      yield put(getSIMCardListSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getSimCardList, error', e);
    if (e?.response?.data) {
      yield put(getSIMCardListFailure(catchResponseMessages(e)));
    }
  }
}
function* deleteSimCard({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/delete`, payload)
    );
    if (response?.status === 200) {
      yield put(deleteSimCardSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteSimCard, error', e);
    if (e?.response?.data) {
      yield put(deleteSimCardFailure(catchResponseMessages(e)));
    }
  }
}
function* assignSimCardToAgents({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/assign-to-agents`, payload)
    );
    if (response?.status === 200) {
      yield put(assignSimCardToAgentsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for assignSimCardToAgents, error', e);
    if (e?.response?.data) {
      yield put(assignSimCardToAgentsFailure(catchResponseMessages(e)));
    }
  }
}
function* updateSimCard({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update`, payload)
    );
    if (response?.status === 200) {
      yield put(updateSimCardSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateSimCard, error', e);
    if (e?.response?.data) {
      yield put(updateSimCardFailure(catchResponseMessages(e)));
    }
  }
}
function* bulkUpdateSimCards({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/bulk-update`, payload)
    );
    if (response?.status === 200) {
      yield put(bulkUpdateSimCardsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for bulkUpdateSimCards, error', e);
    if (e?.response?.data) {
      yield put(bulkUpdateSimCardsFailure(catchResponseMessages(e)));
    }
  }
}
function* uploadSimCards({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/upload`, payload)
    );
    if (response?.status === 200) {
      yield put(uploadSimCardsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for uploadSimCards, error', e);
    if (e?.response?.data) {
      yield put(uploadSimCardsFailure(catchResponseMessages(e)));
    }
  }
}

function* uploadESIM({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/upload-esim`, payload)
    );
    if (response?.status === 200) {
      yield put(uploadESIMSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for uploadESIM, error', e);
    if (e?.response?.data) {
      yield put(uploadESIMFailure(catchResponseMessages(e)));
    }
  }
}

function* getAvailableSim({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/available-sim${payload}`)
    );
    if (response?.status === 200) {
      yield put(getAvailableSimSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAvailableSim, error', e);
    if (e?.response?.data) {
      yield put(getAvailableSimFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getMasterAgentSelectRequest, getMasterAgentSelect);
  yield takeLatest(getSubAgentSelectRequest, getSubAgentSelect);
  yield takeLatest(getCarriersSelectDataRequest, getCarriersSelect);
  yield takeLatest(getCustomersSelectDataRequest, getCustomersSelectData);
  yield takeLatest(createSIMCardRequest, createSIMCArd);
  yield takeLatest(getSIMCardListRequest, getSimCardList);
  yield takeLatest(deleteSimCardRequest, deleteSimCard);
  yield takeLatest(assignSimCardToAgentsRequest, assignSimCardToAgents);
  yield takeLatest(updateSimCardRequest, updateSimCard);
  yield takeLatest(bulkUpdateSimCardsRequest, bulkUpdateSimCards);
  yield takeLatest(uploadSimCardsRequest, uploadSimCards);
  yield takeLatest(uploadESIMRequest, uploadESIM);
  yield takeLatest(getAvailableSimRequest, getAvailableSim);
}
